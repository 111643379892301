<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'objednavka.operator.nadpis' | lokalizace }}
        </div>

        <div class="container__content-body">

            <section>
                <div class="span-2">
                    <app-vytvorit-objednavku-operatora-wizard></app-vytvorit-objednavku-operatora-wizard>
                </div>
            </section>
        </div>
    </div>
</main>
