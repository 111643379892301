<div class="wizard content-fade-in">
    <div>
        <ul #wizard class="tabs">
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.ZVOLIT_UZIVATELE }}">{{ 'objednavka.wizard.zvolitUzivatele' | lokalizace }}</a>
            </li>
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.ZVOLIT_PRODUKT }}">{{ 'objednavka.wizard.zvolitProdukt' | lokalizace }}</a>
            </li>
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.VYPLNIT_FAKTURACNI_UDAJE }}">{{ 'objednavka.wizard.vyplnitFakturacniUdaje' | lokalizace }}</a>
            </li>
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.REKAPITULACE_OBJEDNAVKY }}">{{ 'objednavka.wizard.rekapitulaceObjednavky' | lokalizace }}</a>
            </li>
        </ul>
    </div>

    <div class="wizard-content" [class.hidden]="!jeWizardInicializovan">
        <app-zvolit-uzivatele id="{{ krokObjednavky.ZVOLIT_UZIVATELE }}"
                              (prejitNaKartu)="prejitNaKartu($event)"></app-zvolit-uzivatele>
        <app-zvolit-produkt id="{{ krokObjednavky.ZVOLIT_PRODUKT }}"
                            (prejitNaKartu)="prejitNaKartu($event)"></app-zvolit-produkt>
        <app-vyplnit-fakturacni-udaje id="{{ krokObjednavky.VYPLNIT_FAKTURACNI_UDAJE }}"
                                      [vybranyZpusobVytvoreniObjednavky]="zpusobVytvoreniObjednavky.OPERATOREM"
                                      (prejitNaKartu)="prejitNaKartu($event)"></app-vyplnit-fakturacni-udaje>
        <app-rekapitulace-objednavky-zvoleneho-produktu id="{{ krokObjednavky.REKAPITULACE_OBJEDNAVKY }}"
                                                        (prejitNaKartu)="prejitNaKartu($event)"></app-rekapitulace-objednavky-zvoleneho-produktu>
    </div>
</div>
