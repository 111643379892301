import {LokalniUlozisteNastaveni} from './lokalni-uloziste/lokalni-uloziste-nastaveni';
import {InformaceOprihlasenemUzivateli} from '../../data/dodavatel/informace-oprihlasenem-uzivateli';
import {NastrojeJsonu} from './nastroje-jsonu';

export class NastrojeAutentizace {

    private static readonly KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI = LokalniUlozisteNastaveni.NAZEV_AKTUALNI_VERZE_PROSTREDI + LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE + 'informaceOPrihlasenemUzivateli';

    public static ulozitInformaceOUzivateliDoLocalStorage(informaceOPrihlasenemUzivateli: InformaceOprihlasenemUzivateli): void {
        localStorage.setItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI, NastrojeJsonu.stringify(informaceOPrihlasenemUzivateli));
    }

    public static vratitInformaceOUzivateliZLocalStorage(): InformaceOprihlasenemUzivateli {
        return NastrojeJsonu.parse(localStorage.getItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI));
    }

    public static odstranitInformaceOUzivateliZLocalStorage(): void {
        localStorage.removeItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI);
    }

    public static pripravitNastaveniKeycloaku(keycloakUrl: string, realm: string, clientId: string, clientSecret: string): any {
        return {
            initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false
            },
            config: {
                url: keycloakUrl,
                realm: realm,
                clientId: clientId,
                'credentials': {
                    'secret': clientSecret
                }
            },
            enableBearerInterceptor: false,
        };
    }
}
