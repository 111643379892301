<app-vrtitko [zobrazit]="zobrazitVrtitko" xmlns="http://www.w3.org/1999/html"></app-vrtitko>

<div class="info-container no-padding">
    <h3 class="info-title span-2">{{ 'platba.udajeOPlatbe.nadpis' | lokalizace }}</h3>
    <div class="info-group" *ngIf="platba.idPlatby">
        <app-formular-output [popisek]="'platba.udajeOPlatbe.id'" [hodnota]="platba.idPlatby"></app-formular-output>
        <app-formular-output [popisek]="'platba.udajeOPlatbe.stav'" [hodnota]="((platba.zpusobPlatby | vyctovyTyp: 'ZpusobPlatby') + ' - ' + (platba.stav | vyctovyTyp: 'StavPlatby'))"></app-formular-output>
    </div>

    <div class="info-group" *ngIf="platba.idPlatby">
        <app-formular-output [popisek]="'platba.udajeOPlatbe.identifikatorObjednavky'" [hodnota]="platba.identifikatorObjednavky"></app-formular-output>
        <app-formular-output [popisek]="'platba.udajeOPlatbe.castka'" [hodnota]="(platba.castka / 100 | currency: platba.mena: 'symbol-narrow': '1.2-2':'cs') + ' ' + upresnujiTextODaniKCastce"></app-formular-output>
    </div>

    <div class="info-group" *ngIf="platba.idPlatby">
        <app-formular-output [popisek]="'platba.udajeOPlatbe.datumPlatbyGoPay'" [hodnota]="platba.datumPlatbyGoPay | datum: formatDataACasuNaMinuty"></app-formular-output>
    </div>

    <div class="info-group span-2" *ngIf="platba.subStav">
        <app-formular-output [popisek]="'platba.udajeOPlatbe.duvodSelhaniPlatby'" [hodnota]="platba.subStav | lokalizace"></app-formular-output>
    </div>

    <div class="info-group span-2" *ngIf="platba.stav == 'VYPRSELA_PLATNOST'">
        <app-formular-output [popisek]="'platba.udajeOPlatbe.duvodSelhaniPlatby'" [hodnota]="'platba.vyprselaPlatnost' | lokalizace"></app-formular-output>
    </div>

    <div *ngIf="!platba.idPlatby"
         class="span-2">
        <b>{{ 'platba.udajeOPlatbe.neexistuje' | lokalizace }}</b>
    </div>

</div>

<div *ngIf="platba.zpusobPlatby === zpusobyPlatby.PLATBA_KARTOU && platba.stav === stavyPlatby.VYTVORENA" class="button-area">
    <button type="button" (click)="otevritPlatebniBranu()" class="btn btn-secondary waves-effect waves-light right">
        <b>{{ 'tlacitko.zaplatit' | lokalizace }}</b>
        <i class="material-icons right">payment</i>
    </button>
</div>

<ng-container *ngIf="zobrazitInformaceOOpakovaniPlateb()">
    <div class="info-container no-padding">
        <h3 class="info-title span-2">{{ 'platba.udajeKOpakovani.nadpis' | lokalizace }}
            <a><i class="material-icons info-icon modal-trigger right" data-target="opakovana-platba-modalni-panel"
                title="{{ 'objednavka.opakovanaPlatba.napoveda' | lokalizace }}">info_outline</i></a>
        </h3>

        <div *ngIf="platba.nastaveniOpakovanePlatby.stavOpakovanePlatby === stavyOpakovanePlatby.ZASTAVENA"
             class="span-2">
            <b>{{ 'platba.udajeKOpakovani.zrusena' | lokalizace }}</b>
        </div>

        <div *ngIf="platba.nastaveniOpakovanePlatby.stavOpakovanePlatby !== stavyOpakovanePlatby.ZASTAVENA"
             class="info-group span-2">
            <app-formular-output [popisek]="'platba.udajeKOpakovani.stav'"
                                 [hodnota]="platba.nastaveniOpakovanePlatby.stavOpakovanePlatby | vyctovyTyp: 'StavOpakovanePlatby'"></app-formular-output>
            <app-formular-output [popisek]="'platba.udajeKOpakovani.casovyUsek'"
                                 [hodnota]="platba.nastaveniOpakovanePlatby.dobaPlatnostiOpakovani | datum : formatData"></app-formular-output>
        </div>
    </div>

    <div *ngIf="zobrazitZrusitOpakovaniPlatby()"
         class="button-area">
        <button type="button" (click)="potvrditZruseniOpakovaniPlatby()"
                class="btn btn-secondary waves-effect waves-light right">
            <b>{{ 'tlacitko.zruseniOpakovanePlatby' | lokalizace }}</b>
            <i class="material-icons right">clear</i>
        </button>
    </div>
</ng-container>

<app-textovy-modalni-panel (nesouhlas)="skrytModalniPanel()"
                           (souhlas)="zrusitOpakovaniPlatby()"
                           [zobrazit]="zobrazitPotvrzeni"
                           [idPrvku]="'potvrzeni-modalni-panel'"
                           [nazevAkcnihoTlacitka]="'tlacitko.zruseniOpakovanePlatby'"
                           [nadpis]="'platba.udajeKOpakovani.potvrzeniZruseni.nadpis'"
                           [text]="'platba.udajeKOpakovani.potvrzeniZruseni.text'"></app-textovy-modalni-panel>

<div #modalniPanel id="opakovana-platba-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'objednavka.opakovanaPlatba.modal.nadpis' | lokalizace }}
    </div>

    <div class="modal-content modal-body">
        <div class="modal-text">
            <ul class="dash">
                <li>{{ 'objednavka.opakovanaPlatba.text.castka' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.dobaPlatnosti' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.frekvenceStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.datumStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni1' | lokalizace }} <a
                        href="{{ platbyAObjednavkyUrl }}"
                        target="_blank">{{ 'platbyAObjednavky.nadpis' | lokalizace }}</a> {{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni2' | lokalizace }}
                    <a href="mailto:podpora@fen.cz">podpora@fen.cz</a>.
                </li>
            </ul>
            <br/>
            <p>{{ 'objednavka.opakovanaPlatba.text.zmenaPodminek' | lokalizace }}</p>
        </div>
    </div>

    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
    </div>
</div>
