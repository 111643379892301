import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TypPoctuZaznamuNaStranku} from '../../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {AbstraktniFormularComponent} from '../../../../../../../shared/formular/abstraktni-formular.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AutentizaceService} from '../../../../../../../service/bezpecnost/autentizace.service';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {TypUceluFormulare} from '../../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeEnumu} from '../../../../../../../shared/nastroje/nastroje-enumu';
import {NastrojeModalnihoPanelu} from '../../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import {NastrojeStrankovani} from '../../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-objednavek-operatora-nastaveni',
    templateUrl: './seznam-objednavek-operatora-nastaveni.component.html',
    styleUrls: ['./seznam-objednavek-operatora-nastaveni.component.scss']
})
export class SeznamObjednavekOperatoraNastaveniComponent extends AbstraktniFormularComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    public typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService,
        private seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService
    ) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    }

    odeslat(): void {
        const pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamObjednavekOperatoraNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(1);
        this.seznamObjednavekOperatoraService.aktualizovat();
    }

    private inicializovatFormularovaData() {
        const pocetZaznamuNaStranku = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;

        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    public pripravitMaximalniPocetStranek() {
        const seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    }

}
