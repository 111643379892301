import {StavUzivatele} from '../../../dodavatel/enum/stav-uzivatele.enum';

export class FiltrSeznamuUzivateluSDodavatelem {
    id?: number;
    prihlasovaciJmeno?: string;
    krestniJmeno?: string;
    prijmeni?: string;
    email?: string;
    stav?: StavUzivatele;
}
