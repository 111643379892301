import { enableProdMode } from '@angular/core';
import { prostredi } from './prostredi/prostredi';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// kvuli nestandardnimu chovani IE je potreba nacist materialize ikony v hlavicce stranky, jinak nedojde k jejich spravnemu zobrazeni
if (document['documentMode']) {
    document.write("\n        <link href=\"https://fonts.googleapis.com/icon?family=Material+Icons\" rel=\"stylesheet\">\n    ");
}
if (prostredi.verze === 'Produkční') {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.log(err); });
