<main class="container">
    <div class="container__content">
        <h2>Operátor</h2>
        <div class="button-area-reverse">
            <button type="button" (click)="presmerovatNaSekciOperatora()" class="btn btn-secondary waves-effect waves-light right">
                {{ 'tlacitko.sekceOperatora' | lokalizace }}
            </button>
            <button type="button" (click)="presmerovatNaSekciSlevoveKupony()" class="btn btn-secondary waves-effect waves-light right">
                {{ 'tlacitko.slevoveKupony' | lokalizace }}
            </button>
        </div>
    </div>
</main>
