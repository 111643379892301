import {Directive, ElementRef, HostListener} from '@angular/core';

declare var $: any;

@Directive({
    selector: '[pouzeCislice]'
})
export class PouzeCisliceDirective {

    constructor(Element: ElementRef) {
    }

    @HostListener('change', ['$event'])
    @HostListener('keyup', ['$event'])
    inputChanged(event) {
        const element = $(event.target);
        const puvodniHodnota = element.val();
        const ciselnaHodnota = puvodniHodnota.replace(/[^0-9]/g, '');

        if (puvodniHodnota !== ciselnaHodnota) {
            element.val(ciselnaHodnota);
        }
    }
}
