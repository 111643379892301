import { FormControl } from '@angular/forms';
import { ValidacniZprava } from '../formular/validator/validacni-zprava';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeValidace = /** @class */ (function () {
    function NastrojeValidace() {
    }
    NastrojeValidace.nastavitChybu = function (prvekFormulare, klicChyby) {
        var _a;
        if (!prvekFormulare.hasError(klicChyby)) {
            prvekFormulare.setErrors((_a = {}, _a[klicChyby] = true, _a));
        }
    };
    NastrojeValidace.odstranitChybu = function (prvekFormulare, klicChyby) {
        if (prvekFormulare.hasError(klicChyby)) {
            prvekFormulare.updateValueAndValidity();
        }
    };
    NastrojeValidace.pripravitChybovouZpravu = function (ovladacFormulare) {
        var klicChyby = Object.keys(ovladacFormulare.errors)[0];
        var validacniZprava = this.pripravitValidacniZpravy(ovladacFormulare.getError(klicChyby))[klicChyby];
        return validacniZprava !== undefined ? validacniZprava :
            new ValidacniZprava('formular.validace.neznamaValidace', klicChyby);
    };
    NastrojeValidace.jePouzitValidatorPovinneHodnoty = function (ovladacFormulare) {
        return this.jePouzitValidator(ovladacFormulare, this.nazevValidatoruPovinneHodnoty);
    };
    NastrojeValidace.jePouzitValidator = function (ovladacFormulare, nazevValidatoru) {
        var docasnyPrvek = new FormControl('', ovladacFormulare.validator);
        return docasnyPrvek.validator && docasnyPrvek.validator(docasnyPrvek) && !!docasnyPrvek.validator(docasnyPrvek)
            .hasOwnProperty(nazevValidatoru);
    };
    NastrojeValidace.jeAktivniChybaProValidatorPovinneHodnoty = function (ovladacFormulare) {
        return this.jeAktivniChybaProValidator(ovladacFormulare, this.nazevValidatoruPovinneHodnoty);
    };
    NastrojeValidace.jeAktivniChybaProValidator = function (ovladacFormulare, nazevValidatoru) {
        if (NastrojeObecne.jeHodnotaPrazdna(ovladacFormulare) || NastrojeObecne.jeHodnotaPrazdna(nazevValidatoru) || !ovladacFormulare.invalid) {
            return false;
        }
        return this.pripravitChybovouZpravu(ovladacFormulare).klic === this.pripravitValidacniZpravy(nazevValidatoru)[nazevValidatoru].klic;
    };
    NastrojeValidace.pripravitValidacniZpravy = function (data) {
        return {
            'required': new ValidacniZprava('formular.validace.povinne'),
            'email': new ValidacniZprava('formular.validace.email'),
            'minlength': new ValidacniZprava('formular.validace.minimalniDelka', data.requiredLength),
            'maxlength': new ValidacniZprava('formular.validace.maximalniDelka', data.requiredLength),
            'min': new ValidacniZprava('formular.validace.minimalniHodnota', data.min),
            'max': new ValidacniZprava('formular.validace.maximalniHodnota', data.max),
            'pattern': new ValidacniZprava('formular.validace.neplatnyFormat'),
            'rozsahPrvniHodnotaVetsiNezDruha': new ValidacniZprava('formular.validace.rozsahPrvniHodnotaVetsiNezDruha'),
            'maxVelikostSouboru': new ValidacniZprava('formular.validace.maximalniVelikostSouboru', data.val, data.max),
            'maxPocetSouboru': new ValidacniZprava('formular.validace.maximalniPocetSouboru', data.val, data.max),
            'minPocetSouboru': new ValidacniZprava('formular.validace.minimalniPocetSouboru', data.val, data.min),
            'hodnotySeNeshoduji': new ValidacniZprava('formular.validace.hodnotySeNeshoduji'),
            'nutneVyplnitAlesponJednuHodnotu': new ValidacniZprava('formular.validace.nutneVyplnitAlesponJednuHodnotu'),
            'neznamyNipezKod': new ValidacniZprava('formular.validace.neznamyNipezKod', data.nipez)
        };
    };
    NastrojeValidace.nazevValidatoruPovinneHodnoty = 'required';
    return NastrojeValidace;
}());
export { NastrojeValidace };
