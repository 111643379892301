import {getEntries} from '../../../polyfills';
import {StavUzivatele} from '../../data/dodavatel/enum/stav-uzivatele.enum';
import {TypAdresy} from '../../data/dodavatel/enum/typ-adresy.enum';
import {IdProduktu} from '../../data/e-shop/enum/id-produktu.enum';
import {KrokObjednavky} from '../../data/e-shop/enum/krok-objednavky.enum';
import {PoradoveCisloKrokuObjednavky} from '../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import {AtributRazeniUzivateluSDodavatelem} from '../../data/obecne/enum/atribut-razeni-uzivatelu-s-dodavatelem.enum';
import {TypPoctuZaznamuNaStranku} from '../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {TypFiltru} from '../../data/obecne/enum/typ-filtru.enum';
import {TypHodnotyRozsahu} from '../../data/obecne/enum/typ-hodnoty-rozsahu.enum';
import {TypRazeni} from '../../data/obecne/enum/typ-razeni.enum';
import {ZpusobOvereni} from '../../data/obecne/enum/zpusob-overeni.enum';
import {Stat} from '../../data/organizace/enum/stat.enum';
import {StavOrganizacniJednotky} from '../../data/organizace/enum/stav-organizacni-jednotky.enum';
import {TypNuts} from '../../data/organizace/enum/typ-nuts.enum';
import {TypPozadavku} from '../../service/obecne/abstraktni-service';
import {Uroven} from '../notifikace';
import {TypHodnotySeznamu} from '../seznam/enum/typ-hodnoty-seznamu';
import {NastrojeObecne} from './nastroje-obecne';
import {NastrojeRetezce} from './nastroje-retezce';
import {ZpusobPlatby} from '../../data/e-shop/enum/zpusob-platby.enum';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';
import {StavObjednavky} from '../../data/e-shop/enum/stav-objednavky.enum';
import {AtributRazeniObjednavekOperatora} from '../../data/obecne/enum/atribut-razeni-objednavek-operatora.enum';
import {AtributRazeniObjednavek} from '../../data/obecne/enum/atribut-razeni-objednavek.enum';
import {StavPlatby} from '../../data/e-shop/enum/stav-platby.enum';
import {Mena} from '../../data/obecne/enum/mena.enum';
import {CasovyUsek} from '../../data/e-shop/enum/casovy-usek.enum';
import {StavOpakovanePlatby} from '../../data/e-shop/enum/stav-opakovane-platby.enum';
import {StavFaktury} from '../../data/e-shop/enum/stav-faktury.enum';
import {AtributRazeniSlevovychKuponu} from '../../data/obecne/enum/atribut-razeni-slevovych-kuponu.enum';

export class NastrojeEnumu {

    private static readonly enumy = {
        AtributRazeniObjednavek: AtributRazeniObjednavek,
        AtributRazeniObjednavekOperatora: AtributRazeniObjednavekOperatora,
        AtributRazeniSlevovychKuponu: AtributRazeniSlevovychKuponu,
        AtributRazeniUzivateluSDodavatelem: AtributRazeniUzivateluSDodavatelem,
        CasovyUsek: CasovyUsek,
        IdProduktu: IdProduktu,
        KrokObjednavky: KrokObjednavky,
        Mena: Mena,
        PoradoveCisloKrokuObjednavky: PoradoveCisloKrokuObjednavky,
        Predplatne: TypPredplatneho,
        Stat: Stat,
        StavFaktury: StavFaktury,
        StavObjednavky: StavObjednavky,
        StavPlatby: StavPlatby,
        StavOpakovanePlatby: StavOpakovanePlatby,
        StavOrganizacniJednotky: StavOrganizacniJednotky,
        StavUzivatele: StavUzivatele,
        TypAdresy: TypAdresy,
        TypFiltru: TypFiltru,
        TypHodnotyRozsahu: TypHodnotyRozsahu,
        TypHodnotySeznamu: TypHodnotySeznamu,
        TypNuts: TypNuts,
        TypPoctuZaznamuNaStranku: TypPoctuZaznamuNaStranku,
        TypPozadavku: TypPozadavku,
        TypRazeni: TypRazeni,
        Uroven: Uroven,
        ZpusobOvereni: ZpusobOvereni,
        ZpusobPlatby: ZpusobPlatby
    };

    public static pripravitKlicEnumuZHodnoty(typEnumu, hodnotaEnumu): string {
        if (!NastrojeObecne.jeHodnotaPrazdna(typEnumu) && !NastrojeObecne.jeHodnotaPrazdna(hodnotaEnumu)) {
            for (const polozkaEnumu in typEnumu) {
                if (typEnumu[polozkaEnumu] === String(hodnotaEnumu)) {
                    return polozkaEnumu;
                }
            }
        }

        return null;
    }

    public static pripravitTypEnumuJakoRetezec(typEnumu: any): string {
        for (const polozka of getEntries(this.enumy)) {
            if (typEnumu === polozka[1]) {
                return polozka[0];
            }
        }

        return '';
    }

    public static pripravitKlicEnumuVTextech(typEnumu: string, klicEnumu: string): string {
        if (NastrojeObecne.jeHodnotaPrazdna(typEnumu) || NastrojeObecne.jeHodnotaPrazdna(klicEnumu)) {
            return '';
        }

        const nazevTridyEnumuVTextech = NastrojeRetezce.prevestUpperCamelCaseNaLowerCamelCase(typEnumu);
        const nazevKliceEnumuVTextech = NastrojeRetezce.prevestUpperSnakeCaseNaLowerCamelCase(klicEnumu);

        return NastrojeObecne.formatovatRetezec('{0}.{1}', nazevTridyEnumuVTextech, nazevKliceEnumuVTextech);
    }
}
