<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'slevovyKupon.nadpis.vytvorit' | lokalizace }}
        </div>

        <div class="container__content-body white">
            <ng-container *ngIf="slevovyKupon">
                <app-slevovy-kupon-zakladni-udaje [korenovaKomponenta]="this" [formGroup]="formGroup" [vytvorit]="true" [odeslan]="odeslan" [slevovyKupon]="slevovyKupon"></app-slevovy-kupon-zakladni-udaje>
                <app-slevovy-kupon-tabulka-produktu [formGroup]="formGroup" [vytvorit]="true" [odeslan]="odeslan" [slevovyKupon]="slevovyKupon"></app-slevovy-kupon-tabulka-produktu>

                <div class="container__content-footer">
                    <button type="submit" (click)="prejitZpet()" class="btn btn-secondary waves-effect waves-light right">
                        <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                        <i class="material-icons left icon-flipped">forward</i>
                    </button>
                    <button type="submit" (click)="onSubmit()" class="btn btn-primary waves-effect waves-light right">
                        {{ 'tlacitko.vytvorit' | lokalizace }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</main>

<app-textovy-modalni-panel (souhlas)="prejitZpet()" [zobrazit]="zobrazitPotvrzeni" [idPrvku]="'potvrzeni-modalni-panel'" [nadpis]="'slevovyKupon.vytvoreni.potvrzeni.nadpis'" [text]="'slevovyKupon.vytvoreni.potvrzeni.text'"></app-textovy-modalni-panel>
