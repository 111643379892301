<section class="filter-container">
    <form [formGroup]="formGroup" class="form-column-layout">
        <h3 class="info-title span-12">{{ 'slevoveKupony.seznam' | lokalizace }}</h3>

        <app-formular-input class="span-6"
                            [popisek]="'slevovyKupon.nazev'"
                            [odeslan]="odeslan"
                            [ovladacFormulare]="formGroup.controls.nazev"></app-formular-input>

        <app-formular-kalendar class="span-3"
                               [popisek]="'slevovyKupon.platnostOd'"
                               [odeslan]="odeslan"
                               [ovladacFormulare]="formGroup.controls.datumPlatnostiRozsahOd"></app-formular-kalendar>

        <app-formular-kalendar class="span-3"
                               [popisek]="'slevovyKupon.platnostDo'"
                               [odeslan]="odeslan"
                               [ovladacFormulare]="formGroup.controls.datumPlatnostiRozsahDo"></app-formular-kalendar>
    </form>
    <div class="button-area span-12">
        <button (click)="onSubmit()" class="btn waves-effect waves-light">
            <b>{{ 'tlacitko.vyhledat' | lokalizace }}</b>
            <i class="material-icons right icon-flipped">search</i>
        </button>
        <button (click)="resetovatFiltr()" class="btn btn-secondary waves-effect waves-light">
            <b>{{ 'tlacitko.resetovat' | lokalizace }}</b>
            <i class="material-icons right">clear</i>
        </button>
    </div>
</section>
