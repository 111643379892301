import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { initializer } from './service/bezpecnost/keycloak-init';
registerLocaleData(localeCs);
var ɵ0 = initializer;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
