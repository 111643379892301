import { ElementRef } from '@angular/core';
import { SeznamSlevovychKuponuNastaveniService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import { SeznamSlevovychKuponuService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import { NastrojeStrankovani } from '../../../../../../shared/nastroje/nastroje-strankovani';
import { NastrojeCisla } from '../../../../../../shared/nastroje/nastroje-cisla';
var SeznamSlevovychKuponuAktualniStrankaComponent = /** @class */ (function () {
    function SeznamSlevovychKuponuAktualniStrankaComponent(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) {
        this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
    }
    SeznamSlevovychKuponuAktualniStrankaComponent.prototype.pripravitAktualniStranku = function () {
        return this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamSlevovychKuponuAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamSlevovychKuponu = this.seznamSlevovychKuponuService.seznam;
        var nastaveniZobrazeniSeznamuSlevovychKuponu = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu);
    };
    SeznamSlevovychKuponuAktualniStrankaComponent.prototype.nastavitStranku = function () {
        var element = $(this.vstupniPole.nativeElement);
        var hodnota = element.val();
        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (hodnota <= 0) {
            hodnota = 1;
        }
        else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }
        element.blur();
        element.val(hodnota);
        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamSlevovychKuponuService.aktualizovat();
    };
    SeznamSlevovychKuponuAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranekProZobrazeni = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }
        return this.maximalniPocetStranekProZobrazeni;
    };
    SeznamSlevovychKuponuAktualniStrankaComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    };
    return SeznamSlevovychKuponuAktualniStrankaComponent;
}());
export { SeznamSlevovychKuponuAktualniStrankaComponent };
