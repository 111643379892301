import {HodnotaSeznamu} from './hodnota-seznamu';

export class RadekSeznamu {

    private _id;

    private _hodnoty: Array<HodnotaSeznamu>;

    private _url;

    private _jeVybrany;

    constructor(id: number | string, hodnoty: Array<HodnotaSeznamu>, url?: string, jeVybrany?: boolean) {
        this._id = id;
        this._hodnoty = hodnoty;
        this._url = url;
        this._jeVybrany = jeVybrany;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get hodnoty(): Array<HodnotaSeznamu> {
        return this._hodnoty;
    }

    set hodnoty(value: Array<HodnotaSeznamu>) {
        this._hodnoty = value;
    }

    get url() {
        return this._url;
    }

    set url(value) {
        this._url = value;
    }

    get jeVybrany() {
        return this._jeVybrany;
    }

    set jeVybrany(value) {
        this._jeVybrany = value;
    }
}
