import {Component, OnInit} from '@angular/core';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';

@Component({
    selector: 'app-seznam-objednavek-operatora',
    templateUrl: './seznam-objednavek-operatora.component.html',
    styleUrls: ['./seznam-objednavek-operatora.component.scss']
})
export class SeznamObjednavekOperatoraComponent implements OnInit {

    constructor(private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService, private seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService) {
    }

    ngOnInit() {
        this.seznamObjednavekOperatoraNastaveniService.inicializovat();
        this.seznamObjednavekOperatoraService.aktualizovat(false);
    }
}
