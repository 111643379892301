export class Strankovani {
    /**
     * Aktuálně zobrazená stránka
     */
    stranka?: number;
    /**
     * Počet záznamů na stránku
     */
    pocetZaznamu?: number;
}
