/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slevove-kupony.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "./seznam/seznam-slevovych-kuponu.component.ngfactory";
import * as i4 from "./seznam/seznam-slevovych-kuponu.component";
import * as i5 from "../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service";
import * as i6 from "../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service";
import * as i7 from "./slevove-kupony.component";
import * as i8 from "@angular/router";
var styles_SlevoveKuponyComponent = [i0.styles];
var RenderType_SlevoveKuponyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlevoveKuponyComponent, data: {} });
export { RenderType_SlevoveKuponyComponent as RenderType_SlevoveKuponyComponent };
export function View_SlevoveKuponyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 16, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "container__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "container__content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "section", [["class", "info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "span-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "info-container no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h3", [["class", "info-title span-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-secondary waves-effect waves-light"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.presmerovatNaVytvoreniSlevovehoKuponu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-seznam-slevovych-kuponu", [], null, null, null, i3.View_SeznamSlevovychKuponuComponent_0, i3.RenderType_SeznamSlevovychKuponuComponent)), i1.ɵdid(17, 114688, null, 0, i4.SeznamSlevovychKuponuComponent, [i5.SeznamSlevovychKuponuNastaveniService, i6.SeznamSlevovychKuponuService], null, null)], function (_ck, _v) { _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "slevoveKupony.nadpis")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), "slevovyKupon.nadpis")); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), "tlaticko.vytvoritSlevovyKupon")); _ck(_v, 14, 0, currVal_2); }); }
export function View_SlevoveKuponyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slevove-kupony", [], null, null, null, View_SlevoveKuponyComponent_0, RenderType_SlevoveKuponyComponent)), i1.ɵdid(1, 49152, null, 0, i7.SlevoveKuponyComponent, [i8.Router], null, null)], null, null); }
var SlevoveKuponyComponentNgFactory = i1.ɵccf("app-slevove-kupony", i7.SlevoveKuponyComponent, View_SlevoveKuponyComponent_Host_0, {}, {}, []);
export { SlevoveKuponyComponentNgFactory as SlevoveKuponyComponentNgFactory };
