<form id="formular-razeni" class="form-column-layout" [formGroup]="formGroup">
    <app-formular-select
            class="span-6"
            [popisek]="'seznam.razeni'"
            [skrytPomocnyText]="true"
            [polozky]="atributyRazeni"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.atributRazeni"
            [prvniHodnotaNull]="false"
            [hodnotaKliceZObjektu]="false">
    </app-formular-select>

    <app-formular-select
            class="span-6"
            [skrytPomocnyText]="true"
            [polozky]="typyRazeni"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.typRazeni"
            [prvniHodnotaNull]="false"
            [hodnotaKliceZObjektu]="false">
    </app-formular-select>
</form>