import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFiltrComponent} from '../../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AutentizaceService} from '../../../../../../service/bezpecnost/autentizace.service';
import {NastrojeDataACasu} from '../../../../../../shared/nastroje/nastroje-data-a-casu';
import {ValidatoryRozsahu} from '../../../../../../shared/formular/validator/validatory-rozsahu';
import {StavObjednavky} from '../../../../../../data/e-shop/enum/stav-objednavky.enum';
import {TypPredplatneho} from '../../../../../../data/e-shop/enum/predplatne.enum';

@Component({
    selector: 'app-seznam-objednavek-operatora-filtr-rozsirene',
    templateUrl: './seznam-objednavek-operatora-filtr-rozsirene.component.html',
    styleUrls: ['./seznam-objednavek-operatora-filtr-rozsirene.component.scss']
})
export class SeznamObjednavekOperatoraFiltrRozsireneComponent extends AbstraktniFiltrComponent implements OnInit {

    @Input() public formGroup: FormGroup;

    public readonly stavObjednavky = StavObjednavky;
    public readonly predplatne = TypPredplatneho;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        protected seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService,
        protected seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService
    ) {
        super(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    }

    odeslat(): void {
    }

    private inicializovatFormularovaData() {
        this.formGroup.addControl('identifikator', new FormControl('', [
            Validators.maxLength(20)
        ]));

        this.formGroup.addControl('nazevProduktu', new FormControl('', [
            Validators.maxLength(255)
        ]));

        this.formGroup.addControl('datumVytvoreniRozsahOd', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));

        this.formGroup.addControl('datumVytvoreniRozsahDo', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));

        this.formGroup.addControl('predplatne', new FormControl('', []));


        this.formGroup.addControl('datumSplatnostiRozsahOd', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));

        this.formGroup.addControl('datumSplatnostiRozsahDo', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));

        this.formGroup.addControl('stavObjednavky', new FormControl('', []));

        this.formGroup.setValidators([
            ValidatoryRozsahu.rozsah('datumVytvoreniRozsahOd', 'datumVytvoreniRozsahDo', 'Date'),
            ValidatoryRozsahu.rozsah('datumSplatnostiRozsahOd', 'datumSplatnostiRozsahDo', 'Date')
        ]);
    }
}
