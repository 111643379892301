var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniFormularComponent } from '../../formular/abstraktni-formular.component';
import { NastrojeFiltrace } from '../../nastroje/nastroje-filtrace';
var AbstraktniFiltrComponent = /** @class */ (function (_super) {
    __extends(AbstraktniFiltrComponent, _super);
    function AbstraktniFiltrComponent(abstraktniNastaveniSeznamuService, abstraktniSeznamService, autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.abstraktniNastaveniSeznamuService = abstraktniNastaveniSeznamuService;
        _this.abstraktniSeznamService = abstraktniSeznamService;
        _this.autentizaceService = autentizaceService;
        return _this;
    }
    AbstraktniFiltrComponent.prototype.vyhledat = function (filtr) {
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.abstraktniNastaveniSeznamuService.getNastaveniZobrazeni().filtr = filtr;
        this.abstraktniSeznamService.aktualizovat(false);
    };
    AbstraktniFiltrComponent.prototype.nastavitVychoziHodnoty = function () {
        NastrojeFiltrace.nastavitVychoziHodnoty(this.formGroup, this.abstraktniNastaveniSeznamuService.getNastaveniZobrazeni().filtr);
    };
    AbstraktniFiltrComponent.prototype.resetovatFiltr = function () {
        this.resetovatFormular();
        this.abstraktniNastaveniSeznamuService.resetovatFiltr();
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.abstraktniSeznamService.aktualizovat(false);
    };
    return AbstraktniFiltrComponent;
}(AbstraktniFormularComponent));
export { AbstraktniFiltrComponent };
