var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniNastaveniSeznamuService } from '../abstraktni-nastaveni-seznamu-service';
import { TypRazeni } from '../../../data/obecne/enum/typ-razeni.enum';
import { AtributRazeniObjednavekOperatora } from '../../../data/obecne/enum/atribut-razeni-objednavek-operatora.enum';
import { NastaveniZobrazeniSeznamuObjednavekOperatora } from '../../../data/seznam/seznam-objednavek-operatora/filtr/nastaveni-zobrazeni-seznamu-objednavek-operatora';
var SeznamObjednavekOperatoraNastaveniService = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraNastaveniService, _super);
    function SeznamObjednavekOperatoraNastaveniService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typAtributuRazeni = AtributRazeniObjednavekOperatora;
        _this.vychoziAtributRazeni = AtributRazeniObjednavekOperatora.DATUM_VYTVORENI;
        _this.typTypuRazeni = TypRazeni;
        _this.vychoziTypRazeni = TypRazeni.SESTUPNE;
        _this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-objednavek-operatora_strankovani-pocet-zaznamu-na-stranku';
        _this.nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-objednavek-operatora_strankovani-aktualni-stranka';
        _this.nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-objednavek-operatora_razeni-atribut-razeni';
        _this.nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-objednavek-operatora_razeni-typ-razeni';
        _this.nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuObjednavekOperatora();
        return _this;
    }
    SeznamObjednavekOperatoraNastaveniService.prototype.getTypFiltru = function () {
        return this.typFiltru;
    };
    SeznamObjednavekOperatoraNastaveniService.prototype.setTypFiltru = function (typFiltru) {
        this.typFiltru = typFiltru;
    };
    return SeznamObjednavekOperatoraNastaveniService;
}(AbstraktniNastaveniSeznamuService));
export { SeznamObjednavekOperatoraNastaveniService };
