<div class="info-container no-padding">
    <h3 class="info-title span-2">{{ 'objednavka.udajeOObjednavce' | lokalizace }}
        <a *ngIf="objednavka.typPredplatneho === predplatne.MESICNI_S_OBNOVOU">
            <i class="material-icons info-icon modal-trigger right"
               data-target="opakovana-platba-modalni-panel"
               title="{{ 'objednavka.opakovanaPlatba.napoveda' | lokalizace }}">info_outline</i>
        </a>
    </h3>

    <div class="info-group">
        <app-formular-output [popisek]="'objednavka.id'" [hodnota]="objednavka.identifikator"></app-formular-output>
        <app-formular-output [popisek]="'objednavka.nazevProduktu'" [hodnota]="objednavka.nazevProduktu"></app-formular-output>
        <app-formular-output [popisek]="'objednavka.stav'" [hodnota]="objednavka.stavObjednavky | vyctovyTyp: 'StavObjednavky'"></app-formular-output>
    </div>

    <div class="info-group">
        <app-formular-output [popisek]="'objednavka.cddIdUživatele'" [hodnota]="objednavka.idUzivatele"></app-formular-output>
        <app-formular-output *ngIf="objednavka.typPredplatneho" [popisek]="'objednavka.typPredplatneho'" [hodnota]="objednavka.typPredplatneho | vyctovyTyp: 'Predplatne'"></app-formular-output>
        <app-formular-output [popisek]="'objednavka.zpusobPlatby'" [hodnota]="objednavka.zpusobPlatby | vyctovyTyp : 'ZpusobPlatby' "></app-formular-output>
    </div>

    <div class="info-group span-2">
        <app-formular-output *ngIf="objednavka.datumPlatnostiPredplatnehoDo" [popisek]="'objednavka.datumPlatnostiPredplatnehoDo'" [hodnota]="objednavka.datumPlatnostiPredplatnehoDo | datum: formatData"></app-formular-output>
        <app-formular-output [popisek]="'objednavka.datumACasVytvoreni'" [hodnota]="objednavka.datumVytvoreni | datum: formatDataACasu : true"></app-formular-output>
        <app-formular-output *ngIf="objednavka.datumUskutecneniZdanitelnehoPlneni" [popisek]="'objednavka.datumUskutecneniZdanitelnehoPlneni'" [hodnota]="objednavka.datumUskutecneniZdanitelnehoPlneni | datum: formatData"></app-formular-output>
        <app-formular-output [popisek]="'objednavka.datumSplatnosti'" [hodnota]="objednavka.datumSplatnosti | datum: formatData"></app-formular-output>
    </div>

    <div class="info-group span-2">
        <app-formular-output [popisek]="'objednavka.cena'" [hodnota]="(objednavka.cena.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs') + ' ' + ('cena.sDph' | lokalizace) + '; ' + (objednavka.cena.cenaBezDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs') + ' ' + ('cena.bezDph' | lokalizace) + '; ' + ((objednavka.cena.cenaSDph - objednavka.cena.cenaBezDph) | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs') + ' ' + ('cena.hodnotaDph' | lokalizace)"></app-formular-output>
    </div>

    <div class="info-group span-2">
        <app-formular-output *ngIf="objednavka.nazevSlevovehoKuponu" [popisek]="'objednavka.nazevSlevovehoKuponu'" [hodnota]="objednavka.nazevSlevovehoKuponu"></app-formular-output>
        <app-formular-output *ngIf="objednavka.slevaVProcentech"  [popisek]="'objednavka.vyseSlevy'" [hodnota]="objednavka.slevaVProcentech"></app-formular-output>
    </div>

    <div *ngIf="objednavka.poznamka" class="info-group span-2">
        <app-formular-output [popisek]="'objednavka.poznamka.text'" [hodnota]="objednavka.poznamka"></app-formular-output>
    </div>
</div>

<div #modalniPanel id="opakovana-platba-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'objednavka.opakovanaPlatba.modal.nadpis' | lokalizace }}
    </div>

    <div class="modal-content modal-body">
        <div class="modal-text">
            <ul class="dash">
                <li>{{ 'objednavka.opakovanaPlatba.text.castka' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.dobaPlatnosti' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.frekvenceStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.datumStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni1' | lokalizace }} <a
                        href="{{ platbyAObjednavkyUrl }}"
                        target="_blank">{{ 'platbyAObjednavky.nadpis' | lokalizace }}</a> {{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni2' | lokalizace }}
                    <a href="mailto:podpora@fen.cz">podpora@fen.cz</a>.
                </li>
            </ul>
            <br/>
            <p>{{ 'objednavka.opakovanaPlatba.text.zmenaPodminek' | lokalizace }}</p>
        </div>
    </div>

    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
    </div>
</div>
