/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vytvorit-objednavku-operatora.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "./vytvorit-objednavku-operatora-wizard/vytvorit-objednavku-operatora-wizard.component.ngfactory";
import * as i4 from "./vytvorit-objednavku-operatora-wizard/vytvorit-objednavku-operatora-wizard.component";
import * as i5 from "../../../../service/e-shop/vytvorit-objednavku.service";
import * as i6 from "./vytvorit-objednavku-operatora.component";
import * as i7 from "../../../../service/bezpecnost/autentizace.service";
import * as i8 from "@angular/router";
var styles_VytvoritObjednavkuOperatoraComponent = [i0.styles];
var RenderType_VytvoritObjednavkuOperatoraComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VytvoritObjednavkuOperatoraComponent, data: {} });
export { RenderType_VytvoritObjednavkuOperatoraComponent as RenderType_VytvoritObjednavkuOperatoraComponent };
export function View_VytvoritObjednavkuOperatoraComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "container__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "container__content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "span-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-vytvorit-objednavku-operatora-wizard", [], null, null, null, i3.View_VytvoritObjednavkuOperatoraWizardComponent_0, i3.RenderType_VytvoritObjednavkuOperatoraWizardComponent)), i1.ɵdid(10, 114688, null, 0, i4.VytvoritObjednavkuOperatoraWizardComponent, [i5.VytvoritObjednavkuService], null, null)], function (_ck, _v) { _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "objednavka.operator.nadpis")); _ck(_v, 4, 0, currVal_0); }); }
export function View_VytvoritObjednavkuOperatoraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vytvorit-objednavku-operatora", [], null, null, null, View_VytvoritObjednavkuOperatoraComponent_0, RenderType_VytvoritObjednavkuOperatoraComponent)), i1.ɵdid(1, 114688, null, 0, i6.VytvoritObjednavkuOperatoraComponent, [i7.AutentizaceService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VytvoritObjednavkuOperatoraComponentNgFactory = i1.ɵccf("app-vytvorit-objednavku-operatora", i6.VytvoritObjednavkuOperatoraComponent, View_VytvoritObjednavkuOperatoraComponent_Host_0, {}, {}, []);
export { VytvoritObjednavkuOperatoraComponentNgFactory as VytvoritObjednavkuOperatoraComponentNgFactory };
