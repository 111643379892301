import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { NastrojeStrankovani } from '../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamUzivateluSDodavatelemStrankovaniComponent = /** @class */ (function () {
    function SeznamUzivateluSDodavatelemStrankovaniComponent(seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) {
        this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
    }
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.predchoziStranka = function () {
        var hodnota = this.pripravitHodnotu();
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.dalsiStranka = function () {
        var hodnota = this.pripravitHodnotu();
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.jePrvniStranka = function () {
        return this.pripravitHodnotu() === 1;
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.jePosledniStranka = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.pripravitHodnotu = function () {
        return this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    };
    SeznamUzivateluSDodavatelemStrankovaniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        var nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    };
    return SeznamUzivateluSDodavatelemStrankovaniComponent;
}());
export { SeznamUzivateluSDodavatelemStrankovaniComponent };
