import {Component, Input, OnInit} from '@angular/core';
import {Objednavka} from '../../../data/e-shop/objednavka';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';

@Component({
    selector: 'app-zruseni-objednavky',
    templateUrl: './zruseni-objednavky.component.html',
    styleUrls: ['./zruseni-objednavky.component.scss']
})
export class ZruseniObjednavkyComponent implements OnInit {

    @Input() objednavka: Objednavka;

    public readonly formatDataACasu = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;

    ngOnInit() {
    }
}
