var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { TypPredplatneho } from '../../../../../data/e-shop/enum/predplatne.enum';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeCisla } from '../../../../../shared/nastroje/nastroje-cisla';
import { Notifikace, Uroven } from '../../../../../shared/notifikace';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { ProduktService } from '../../../../../service/e-shop/produkt.service';
import { ZpusobPlatby } from '../../../../../data/e-shop/enum/zpusob-platby.enum';
import { Mena } from '../../../../../data/obecne/enum/mena.enum';
import { DecimalPipe } from '@angular/common';
import { Cena } from '../../../../../data/e-shop/cena';
import { Lokalizace } from '../../../../../shared/lokalizace';
var ZvolitProduktComponent = /** @class */ (function (_super) {
    __extends(ZvolitProduktComponent, _super);
    function ZvolitProduktComponent(formBuilder, autentizaceService, decimalPipe, produktService, router, vytvoritObjednavkuService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.decimalPipe = decimalPipe;
        _this.produktService = produktService;
        _this.router = router;
        _this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        _this.prejitNaKartu = new EventEmitter();
        _this.mena = Mena;
        _this.predplatne = TypPredplatneho;
        _this.zobrazitVrtitko = true;
        return _this;
    }
    ZvolitProduktComponent.prototype.ngOnInit = function () {
        this.pripravitSeznamProduktu();
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
    };
    ZvolitProduktComponent.prototype.onSubmit = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.vybranyProdukt)) {
            _super.prototype.onSubmit.call(this);
        }
        else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'formular.validace.nevybranyProdukt');
        }
    };
    ZvolitProduktComponent.prototype.odeslat = function () {
        if (this.autentizaceService.jePrihlasenyUzivatelOperator()) {
            if (this.formGroup.controls.cena.value != this.vybranyProdukt.cena.cenaSDph) {
                var cena = new Cena();
                cena.cenaSDph = NastrojeCisla.prevestNaCislo(this.formGroup.controls.cena.value);
                cena.cenaBezDph = NastrojeCisla.delitCisla(NastrojeCisla.prevestNaCislo(cena.cenaSDph), NastrojeCisla.secistCisla(1, NastrojeCisla.prevestNaCislo(this.vybranyProdukt.cena.koeficientDph)));
                cena.koeficientDph = this.vybranyProdukt.cena.koeficientDph;
                this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranePredplatne, cena, this.delkaPlatnostiPredplatneho);
                this.vytvoritObjednavkuService.doplnitZpusobPlatby(ZpusobPlatby.BANKOVNI_PREVOD);
                this.vytvoritObjednavkuService.objednavka.nazevProduktu = this.pripravitTextProduktu();
            }
        }
        else {
            this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranePredplatne);
            this.vytvoritObjednavkuService.doplnitZpusobPlatby(ZpusobPlatby.BANKOVNI_PREVOD);
        }
        this.nasledujiciKarta();
    };
    ZvolitProduktComponent.prototype.vlozitVybranyProdukt = function (produktModalData) {
        this.vybranyProdukt = produktModalData.produkt;
        this.vybranePredplatne = produktModalData.vybranePredplatne;
        this.delkaPlatnostiPredplatneho = produktModalData.delkaPlatnostiPredplatneho;
        if (this.vybranePredplatne === TypPredplatneho.NA_MIRU) {
            this.formGroup.controls.cena.setValue(this.decimalPipe.transform(NastrojeCisla.nasobitCisla(this.vybranyProdukt.cena.cenaSDph, this.delkaPlatnostiPredplatneho), '1.2-2', 'cs'));
        }
        else {
            this.formGroup.controls.cena.setValue(this.decimalPipe.transform(this.vybranyProdukt.cena.cenaSDph, '1.2-2', 'cs'));
        }
    };
    ZvolitProduktComponent.prototype.presmerovatPriZruseni = function () {
        this.router.navigate(['/sekce-operatora']);
    };
    ZvolitProduktComponent.prototype.predchoziKarta = function () {
        this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_UZIVATELE);
    };
    ZvolitProduktComponent.prototype.pripravitTextProduktu = function () {
        if (this.vybranePredplatne === TypPredplatneho.NA_MIRU) {
            return this.vybranyProdukt.nazev + ' (' + this.delkaPlatnostiPredplatneho + ' ' + Lokalizace.pripravitText('predplatne.mesicuText') + ')';
        }
        return this.vybranyProdukt.nazev;
    };
    ZvolitProduktComponent.prototype.nasledujiciKarta = function () {
        this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
    };
    ZvolitProduktComponent.prototype.pripravitSeznamProduktu = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.produktService.nacistSeznamProduktu()
            .subscribe(function (data) {
            data.forEach(function (p) {
                p.puvodniNazev = p.nazev;
                p.nazev = (p.nazev + ' - ' + Lokalizace.pripravitText('predplatne.' + p.dostupnePredplatne[0].toLowerCase()));
            });
            _this.produkty = data;
            _this.zobrazitVrtitko = false;
        }, function () { return _this.zobrazitVrtitko = false; });
    };
    ZvolitProduktComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('cena', new FormControl('', [
            Validators.required,
            Validators.pattern(NastrojeCisla.VZOR_DECIMAL_DOT_AND_COMMA),
            Validators.maxLength(15)
        ]));
    };
    return ZvolitProduktComponent;
}(AbstraktniFormularComponent));
export { ZvolitProduktComponent };
