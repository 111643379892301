import { Objednavka } from '../../data/e-shop/objednavka';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeObjednavky = /** @class */ (function () {
    function NastrojeObjednavky() {
    }
    NastrojeObjednavky.pripravitDataObjednavkyProVypocetSlevy = function (idUzivatele, typPredplatneho, dic, idProduktu, identifikatorProduktu, slevovyKupon) {
        var objednavka = new Objednavka();
        objednavka.idUzivatele = idUzivatele;
        objednavka.typPredplatneho = typPredplatneho;
        objednavka.nazevSlevovehoKuponu = NastrojeObecne.jeHodnotaPrazdna(slevovyKupon) ? undefined : slevovyKupon.nazev;
        objednavka.dic = dic;
        objednavka.idProduktu = idProduktu;
        objednavka.identifikatorProduktu = identifikatorProduktu;
        return objednavka;
    };
    return NastrojeObjednavky;
}());
export { NastrojeObjednavky };
