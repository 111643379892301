import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {TypUceluFormulare} from '../../data/obecne/enum/typ-ucelu-formulare.enum';

export class NastrojeFormulare {

	public static resetovatFormularovaData(formGroup: FormGroup): void {
		formGroup.reset();
		formGroup.markAsPristine();
		formGroup.markAsUntouched();
	}

	public static resetovatValidatorFormularovehoPrvku(formGroup: FormGroup, nazevOvladaceFormulare: string): void {
		formGroup.controls[nazevOvladaceFormulare].clearValidators();
	}

	public static resetovatPrvekBezVyvolaniUdalosti(ovladacFormulare: AbstractControl): void {
		ovladacFormulare.reset(undefined, {
			onlySelf: true,
			emitEvent: false
		});
	}

	public static normalizovatHodnotu(hodnota: any) {
		return hodnota === undefined || hodnota === '' ? null : hodnota;
	}

	public static nastavitTypUceluFormulare(formGroup: FormGroup, typUceluFormulare: TypUceluFormulare) {
		(formGroup as any).typUceluFormulare = typUceluFormulare;
	}

	public static pripravitTypUceluFormulare(formGroup: FormGroup): TypUceluFormulare {
		return (formGroup as any).typUceluFormulare;
	}

	public static nastavitHodnotuBezVyvolaniUdalosti(ovladacFormulare: AbstractControl, hodnota: any) {
		ovladacFormulare.setValue(hodnota, {
			onlySelf: true,
			emitEvent: false
		});
	}

	public static aktivovatPrvekFormulareBezVyvolaniUdalosti(ovladacFormulare: AbstractControl) {
		ovladacFormulare.enable({
			onlySelf: true,
			emitEvent: false
		});
	}

	public static deaktivovatPrvekFormulareBezVyvolaniUdalosti(ovladacFormulare: AbstractControl) {
		ovladacFormulare.disable({
			onlySelf: true,
			emitEvent: false
		});
	}

	public static aktualizovatHodnotuAValidaciBezVyvolaniUdalosti(ovladacFormulare: AbstractControl) {
		ovladacFormulare.updateValueAndValidity({
			onlySelf: true,
			emitEvent: false
		});
	}

	public static pridatValidatory(ovladacFormulare: AbstractControl, validatory: ValidatorFn[]) {
		if (ovladacFormulare.validator != null) {
			validatory.unshift(ovladacFormulare.validator);

		}
		ovladacFormulare.setValidators(validatory);
	}
}
