var NastrojeModalnihoPanelu = /** @class */ (function () {
    function NastrojeModalnihoPanelu() {
    }
    NastrojeModalnihoPanelu.inicializovatModalniPanel = function (element, zakazatScrollovaniPriZobrazeni, zakazatMoznostZavritPanelJinakNezTlacitkem) {
        if (zakazatScrollovaniPriZobrazeni === void 0) { zakazatScrollovaniPriZobrazeni = false; }
        if (zakazatMoznostZavritPanelJinakNezTlacitkem === void 0) { zakazatMoznostZavritPanelJinakNezTlacitkem = true; }
        M.Modal.init(element, {
            preventScrolling: zakazatScrollovaniPriZobrazeni,
            dismissible: !zakazatMoznostZavritPanelJinakNezTlacitkem
        });
    };
    NastrojeModalnihoPanelu.otevritModalniPanel = function (element) {
        this.pripravitInstanciModalnihoPanelu(element).open();
    };
    NastrojeModalnihoPanelu.zavritModalniPanel = function (element, okamziteZavreni) {
        if (okamziteZavreni === void 0) { okamziteZavreni = false; }
        if (okamziteZavreni) {
            this.pripravitInstanciModalnihoPanelu(element).options['outDuration'] = 0;
        }
        this.pripravitInstanciModalnihoPanelu(element).close();
    };
    NastrojeModalnihoPanelu.pripravitInstanciModalnihoPanelu = function (element) {
        return M.Modal.getInstance(element);
    };
    return NastrojeModalnihoPanelu;
}());
export { NastrojeModalnihoPanelu };
