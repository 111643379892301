var HodnotaSeznamu = /** @class */ (function () {
    function HodnotaSeznamu(typHodnoty, hodnota, ikona, styl) {
        this._typHodnoty = typHodnoty;
        this._hodnota = hodnota;
        this._ikona = ikona;
        this._styl = styl;
    }
    Object.defineProperty(HodnotaSeznamu.prototype, "typHodnoty", {
        get: function () {
            return this._typHodnoty;
        },
        set: function (value) {
            this._typHodnoty = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HodnotaSeznamu.prototype, "hodnota", {
        get: function () {
            return this._hodnota;
        },
        set: function (value) {
            this._hodnota = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HodnotaSeznamu.prototype, "ikona", {
        get: function () {
            return this._ikona;
        },
        set: function (value) {
            this._ikona = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HodnotaSeznamu.prototype, "styl", {
        get: function () {
            return this._styl;
        },
        set: function (value) {
            this._styl = value;
        },
        enumerable: true,
        configurable: true
    });
    return HodnotaSeznamu;
}());
export { HodnotaSeznamu };
