import {NastrojeObecne} from './nastroje-obecne';
import {Polozka} from '../../data/obecne/polozka';
import {ActivatedRoute} from '@angular/router';

export class NastrojeUrl {

    public static otevritOdkaz(url: string) {
        window.location.href = url;
    }

    public static otevritOdkazVNoveZalozce(url: string) {
        window.open(url, '_blank');
    }

    public static prejitZpet(): void {
        window.history.back();
    }

    public static ziskatParametrRetezceDotazuZUrl(route: ActivatedRoute, klicParametru: string): string {
        return route.snapshot.queryParamMap.get(klicParametru);
    }

    public static pripravitUrl(korenoveUrl: string, cesta?: string, parametry?: Polozka[]): string {
        return NastrojeObecne.formatovatRetezec('{0}{1}{2}', korenoveUrl, this.pripravitCestu(cesta), this.pripravitRetezecUrlParametru(parametry));
    }

    private static pripravitCestu(cesta?: string): string {
        return NastrojeObecne.jeHodnotaPrazdna(cesta) ? '' : String(String(cesta).startsWith('/') ? cesta : '/' + cesta);
    }

    private static pripravitRetezecUrlParametru(parametry: Polozka[]) {
        let retezec = '';

        if (parametry) {
            for (const polozka of parametry) {
                const oddelovac = retezec.length === 0 ? '?' : '&';

                retezec += NastrojeObecne.formatovatRetezec('{0}{1}={2}', oddelovac, polozka.klic, polozka.hodnota);
            }
        }

        return retezec;
    }
}
