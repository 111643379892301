import {LokalniUlozisteNastaveni} from './lokalni-uloziste-nastaveni';
import {NastrojeObecne} from '../nastroje-obecne';
import {NastrojeAutentizace} from '../nastroje-autentizace';

export class LokalniUlozisteNastroje {

    private static readonly vychoziIdentifikatorUzivatele = 'neprihlaseny';

    public static getPolozka(klic: string) {
        return localStorage.getItem(this.pripravitKlicPolozky(klic));
    }

    public static setPolozka(klic: string, hodnota: string) {
        localStorage.setItem(this.pripravitKlicPolozky(klic), hodnota);
    }

    public static odstranitPolozku(klic: string) {
        localStorage.removeItem(this.pripravitKlicPolozky(klic));
    }

    public static setDocasnaPolozka(klic: string, hodnota: string) {
        localStorage.setItem(this.pripravitKlicDocasnePolozky(klic), hodnota);
    }

    public static getDocasnaPolozka(klic: string) {
        return localStorage.getItem(this.pripravitKlicDocasnePolozky(klic));
    }

    public static odstranitDocasnePolozky() {
        const klice = this.pripravitKliceDocasnychPolozek();

        for (let i = 0; i < klice.length; i++) {
            localStorage.removeItem(klice[i]);
        }
    }

    public static pripravitKlicePolozek() {
        const klice = [];
        for (let i = 0; i < localStorage.length; ++i) {
            const klic = localStorage.key(i);
            if (!NastrojeObecne.jeHodnotaPrazdna(klic)) {
                klice.push(klic.replace(this.pripravitPrefixKlicePolozky(), ''));
            }
        }

        return klice;
    }

    private static pripravitKlicPolozky(klic: string) {
        return this.pripravitPrefixKlicePolozky() + klic;
    }

    private static pripravitPrefixKlicePolozky() {
        const separator = LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE;
        const uzivatel = NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage();
        const idUzivatele = NastrojeObecne.jeHodnotaPrazdna(uzivatel) ? this.vychoziIdentifikatorUzivatele : uzivatel.informaceOUzivateli.id;

        return LokalniUlozisteNastaveni.NAZEV_AKTUALNI_VERZE_PROSTREDI + separator + idUzivatele + separator;
    }

    private static pripravitKlicDocasnePolozky(klic: string) {
        return LokalniUlozisteNastaveni.PREFIX_NAZVU_DOCASNEHO_KLICE + LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE + klic;
    }

    private static pripravitKliceDocasnychPolozek() {
        const klice = [];

        for (let i = 0; i < localStorage.length; i++) {
            const klic = localStorage.key(i);

            if (!NastrojeObecne.jeHodnotaPrazdna(klic) && this.jeDocasnaPolozkaUloziste(klic)) {
                klice.push(klic);
            }
        }

        return klice;
    }

    private static jeDocasnaPolozkaUloziste(klic: string) {
        for (let i = 0; i < LokalniUlozisteNastaveni.NAZVY_VSECH_VERZI_PROSTREDI.length; i++) {
            if (klic.startsWith(LokalniUlozisteNastaveni.NAZVY_VSECH_VERZI_PROSTREDI[i])) {
                return false;
            }
        }

        return true;
    }
}
