import {AbstractControl, FormControl} from '@angular/forms';
import {ValidacniZprava} from '../formular/validator/validacni-zprava';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeValidace {

    private static readonly nazevValidatoruPovinneHodnoty = 'required';

    public static nastavitChybu(prvekFormulare: AbstractControl, klicChyby: string) {
        if (!prvekFormulare.hasError(klicChyby)) {
            prvekFormulare.setErrors({[klicChyby]: true});
        }
    }

    public static odstranitChybu(prvekFormulare: AbstractControl, klicChyby: string) {
        if (prvekFormulare.hasError(klicChyby)) {
            prvekFormulare.updateValueAndValidity();
        }
    }

    public static pripravitChybovouZpravu(ovladacFormulare: AbstractControl): ValidacniZprava {
        const klicChyby = Object.keys(ovladacFormulare.errors)[0];
        const validacniZprava = this.pripravitValidacniZpravy(ovladacFormulare.getError(klicChyby))[klicChyby];

        return validacniZprava !== undefined ? validacniZprava :
            new ValidacniZprava('formular.validace.neznamaValidace', klicChyby);
    }

    public static jePouzitValidatorPovinneHodnoty(ovladacFormulare: AbstractControl) {
        return this.jePouzitValidator(ovladacFormulare, this.nazevValidatoruPovinneHodnoty);
    }

    public static jePouzitValidator(ovladacFormulare: AbstractControl, nazevValidatoru: string): boolean {
        const docasnyPrvek = new FormControl('', ovladacFormulare.validator);

        return docasnyPrvek.validator && docasnyPrvek.validator(docasnyPrvek) && !!docasnyPrvek.validator(docasnyPrvek)
            .hasOwnProperty(nazevValidatoru);
    }

    public static jeAktivniChybaProValidatorPovinneHodnoty(ovladacFormulare: AbstractControl): boolean {
        return this.jeAktivniChybaProValidator(ovladacFormulare, this.nazevValidatoruPovinneHodnoty);
    }

    public static jeAktivniChybaProValidator(ovladacFormulare: AbstractControl, nazevValidatoru: string): boolean {
        if (NastrojeObecne.jeHodnotaPrazdna(ovladacFormulare) || NastrojeObecne.jeHodnotaPrazdna(nazevValidatoru) || !ovladacFormulare.invalid) {
            return false;
        }

        return this.pripravitChybovouZpravu(ovladacFormulare).klic === this.pripravitValidacniZpravy(nazevValidatoru)[nazevValidatoru].klic;
    }

    private static pripravitValidacniZpravy(data?: any): any {
        return {
            'required': new ValidacniZprava('formular.validace.povinne'),
            'email': new ValidacniZprava('formular.validace.email'),
            'minlength': new ValidacniZprava('formular.validace.minimalniDelka', data.requiredLength),
            'maxlength': new ValidacniZprava('formular.validace.maximalniDelka', data.requiredLength),
            'min': new ValidacniZprava('formular.validace.minimalniHodnota', data.min),
            'max': new ValidacniZprava('formular.validace.maximalniHodnota', data.max),
            'pattern': new ValidacniZprava('formular.validace.neplatnyFormat'),
            'rozsahPrvniHodnotaVetsiNezDruha': new ValidacniZprava('formular.validace.rozsahPrvniHodnotaVetsiNezDruha'),
            'maxVelikostSouboru': new ValidacniZprava('formular.validace.maximalniVelikostSouboru', data.val, data.max),
            'maxPocetSouboru': new ValidacniZprava('formular.validace.maximalniPocetSouboru', data.val, data.max),
            'minPocetSouboru': new ValidacniZprava('formular.validace.minimalniPocetSouboru', data.val, data.min),
            'hodnotySeNeshoduji': new ValidacniZprava('formular.validace.hodnotySeNeshoduji'),
            'nutneVyplnitAlesponJednuHodnotu': new ValidacniZprava('formular.validace.nutneVyplnitAlesponJednuHodnotu'),
            'neznamyNipezKod': new ValidacniZprava('formular.validace.neznamyNipezKod', data.nipez)
        };
    }
}
