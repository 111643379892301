import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {enableProdMode} from '@angular/core';
import {prostredi} from './prostredi/prostredi';

// kvuli nestandardnimu chovani IE je potreba nacist materialize ikony v hlavicce stranky, jinak nedojde k jejich spravnemu zobrazeni
if (document['documentMode']) {
    document.write(`
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    `);
}

if (prostredi.verze === 'Produkční') {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
