import { PipeTransform } from '@angular/core';
import { Lokalizace } from '../../lokalizace';
import { NastrojeEnumu } from '../nastroje-enumu';
import { NastrojeObecne } from '../nastroje-obecne';
var VyctovyTypPipe = /** @class */ (function () {
    function VyctovyTypPipe() {
    }
    VyctovyTypPipe.prototype.transform = function (hodnota, typEnumu) {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota) && !NastrojeObecne.jeHodnotaPrazdna(typEnumu)) {
            var typEnumuJakoRetezec = (typeof typEnumu === 'string' || typEnumu instanceof String) ?
                typEnumu.toString() : NastrojeEnumu.pripravitTypEnumuJakoRetezec(typEnumu);
            if (!NastrojeObecne.jeHodnotaPrazdna(typEnumuJakoRetezec)) {
                return Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech(typEnumuJakoRetezec, hodnota));
            }
        }
        return hodnota;
    };
    return VyctovyTypPipe;
}());
export { VyctovyTypPipe };
