var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AtributRazeniUzivateluSDodavatelem } from '../../../data/obecne/enum/atribut-razeni-uzivatelu-s-dodavatelem.enum';
import { TypRazeni } from '../../../data/obecne/enum/typ-razeni.enum';
import { NastaveniZobrazeniSeznamuUzivateluSDodavatelem } from '../../../data/seznam/seznam-uzivatelu-s-dodavatelem/filtr/nastaveni-zobrazeni-seznamu-uzivatelu-s-dodavatelem';
import { AbstraktniNastaveniSeznamuService } from '../abstraktni-nastaveni-seznamu-service';
var SeznamUzivateluSDodavatelemNastaveniService = /** @class */ (function (_super) {
    __extends(SeznamUzivateluSDodavatelemNastaveniService, _super);
    function SeznamUzivateluSDodavatelemNastaveniService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typAtributuRazeni = AtributRazeniUzivateluSDodavatelem;
        _this.vychoziAtributRazeni = AtributRazeniUzivateluSDodavatelem.PRIHLASOVACI_JMENO;
        _this.typTypuRazeni = TypRazeni;
        _this.vychoziTypRazeni = TypRazeni.VZESTUPNE;
        _this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_strankovani-pocet-zaznamu-na-stranku';
        _this.nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_strankovani-aktualni-stranka';
        _this.nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_razeni-atribut-razeni';
        _this.nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_razeni-typ-razeni';
        _this.nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuUzivateluSDodavatelem();
        return _this;
    }
    return SeznamUzivateluSDodavatelemNastaveniService;
}(AbstraktniNastaveniSeznamuService));
export { SeznamUzivateluSDodavatelemNastaveniService };
