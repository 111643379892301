import {Component, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

/**
 * Usage:
 * <app-form-checkbox
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>">
 * </app-form-checkbox>
 */
@Component({
    selector: 'app-formular-checkbox',
    templateUrl: './formular-checkbox.component.html',
    styleUrls: ['./formular-checkbox.component.scss']
})
export class FormularCheckboxComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
