import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { PoradoveCisloKrokuObjednavky } from '../../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import { ZpusobVytvoreniObjednavky } from '../../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { GoogleAnalyticsTrackingService } from '../../../../../service/obecne/google-analytics-tracking.service';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { NastrojeFakturacnichUdaju } from '../../../../../shared/nastroje/nastroje-fakturacnich-udaju';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { ZahranicniStatEu } from '../../../../../data/organizace/enum/zahranicni-stat-eu.enum';
import { Stat } from '../../../../../data/organizace/enum/stat.enum';

@Component({
    selector: 'app-vyplnit-fakturacni-udaje',
    templateUrl: './vyplnit-fakturacni-udaje.component.html',
    styleUrls: ['./vyplnit-fakturacni-udaje.component.scss']
})
export class VyplnitFakturacniUdajeComponent extends AbstraktniFormularComponent implements OnInit {

    @Input() objednavka: Objednavka;
    @Input() vybranyZpusobVytvoreniObjednavky: ZpusobVytvoreniObjednavky;

    @Output() prejitNaKartu = new EventEmitter<string>();

    public readonly zpusobyVytvoreniObjednavky = ZpusobVytvoreniObjednavky;

    public zobrazitPotvrzeniNevyplneniIcoDic = false;
    public zobrazitDicJeMimoEu = false;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private router: Router,
        private vytvoritObjednavkuService: VytvoritObjednavkuService,
        private googleAnalyticsTrackingService: GoogleAnalyticsTrackingService
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        NastrojeFakturacnichUdaju.inicializovatFormularovaData(this.formGroup, this.vybranyZpusobVytvoreniObjednavky);
    }

    public odeslat(): void {
        if (!this.muzeVytvoritObjednavku()) {
            this.zobrazitDicJeMimoEu = true;
            return;
        }

        if (this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.OPERATOREM) {
            if (NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.ic.value) && NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.dic.value)) {
                this.zobrazitPotvrzeniNevyplneniIcoDic = true;
            } else {
                this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
            }
        } else {
            this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
        }
    }

    public potvrditSpravnostFakturacnichUdaju(): void {
        this.zobrazitPotvrzeniNevyplneniIcoDic = false;
        this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
    }

    public skrytModalniPanel(): void {
        this.zobrazitPotvrzeniNevyplneniIcoDic = false;
        this.zobrazitDicJeMimoEu = false;
    }

    public presmerovatPriZruseni(): void {
        switch (this.vybranyZpusobVytvoreniObjednavky) {
            case ZpusobVytvoreniObjednavky.OPERATOREM:
                this.router.navigate(['/sekce-operatora']);
                break;
            case ZpusobVytvoreniObjednavky.UZIVATELEM:
                this.googleAnalyticsTrackingService.evidovatZruseni(this.objednavka);
                this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
                this.router.navigate(['/']);
                break;
            default:
                this.router.navigate(['/']);
                break;
        }
    }

    public predchoziKarta(): void {
        this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_PRODUKT);
    }

    private ulozitFakturacniUdajeAPrejitNaDalsiKartu(): void {
        this.vytvoritObjednavkuService.doplnitFakturacniUdaje(this.formGroup);
        this.vytvoritObjednavkuService.vyvolatUdalostProPripravuCenyProduktu();
        this.nasledujiciKarta();
    }

    private nasledujiciKarta(): void {
        if (this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.UZIVATELEM) {
            this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
        }

        this.prejitNaKartu.emit(KrokObjednavky.REKAPITULACE_OBJEDNAVKY);
    }

    private muzeVytvoritObjednavku(): boolean {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.dic.value)) {
            const statZDic = this.formGroup.controls.dic.value.substr(0, 2);

            return Stat.CZ.toString() === statZDic || !NastrojeObecne.jeHodnotaPrazdna(ZahranicniStatEu[statZDic]);
        }

        return true;
    }
}
