import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AbstraktniFormularModule} from '../formular/abstraktni-formular-module';
import {VrtitkoModule} from '../layout/vrtitko/vrtitko.module';
import {NastrojeModule} from '../nastroje/nastroje.module';
import {RazeniComponent} from './razeni/razeni.component';
import {SeznamComponent} from './seznam.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AbstraktniFormularModule,
        VrtitkoModule,
        NastrojeModule
    ],
    declarations: [
        SeznamComponent,
        RazeniComponent
    ],
    exports: [
        SeznamComponent,
        RazeniComponent
    ]
})
export class SeznamModule {
}
