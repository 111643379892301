import { PipeTransform } from '@angular/core';
import { NastrojeAdresy } from '../nastroje-adresy';
var AdresaPipe = /** @class */ (function () {
    function AdresaPipe() {
    }
    AdresaPipe.prototype.transform = function (hodnota, zobrazitTypAdresy) {
        if (hodnota != null) {
            return NastrojeAdresy.prevestNaRetezec(hodnota, zobrazitTypAdresy);
        }
        return '';
    };
    return AdresaPipe;
}());
export { AdresaPipe };
