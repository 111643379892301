import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {KrokObjednavky} from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import {VytvoritObjednavkuService} from '../../../../../service/e-shop/vytvorit-objednavku.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AbstraktniFormularComponent} from '../../../../../shared/formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';
import {TypPredplatneho} from '../../../../../data/e-shop/enum/predplatne.enum';
import {Produkt} from '../../../../../data/e-shop/produkt';
import {TypUceluFormulare} from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeCisla} from '../../../../../shared/nastroje/nastroje-cisla';
import {Notifikace, Uroven} from '../../../../../shared/notifikace';
import {NastrojeObecne} from '../../../../../shared/nastroje/nastroje-obecne';
import {ProduktService} from '../../../../../service/e-shop/produkt.service';
import {ZpusobPlatby} from '../../../../../data/e-shop/enum/zpusob-platby.enum';
import {Mena} from '../../../../../data/obecne/enum/mena.enum';
import {DecimalPipe} from '@angular/common';
import {Cena} from '../../../../../data/e-shop/cena';
import {InformaceOVybranemTarifu} from '../../../../../data/e-shop/informace-o-vybranem-tarifu';
import {Lokalizace} from '../../../../../shared/lokalizace';

@Component({
	selector: 'app-zvolit-produkt',
	templateUrl: './zvolit-produkt.component.html',
	styleUrls: ['./zvolit-produkt.component.scss']
})
export class ZvolitProduktComponent extends AbstraktniFormularComponent implements OnInit {

	@ViewChild('modalniPanel', {static: false}) public modalniPanel: ElementRef;
	@Input() nazevProduktu;
	@Output() prejitNaKartu = new EventEmitter<string>();

	public readonly mena = Mena;
	public readonly predplatne = TypPredplatneho;

	public produkty: Produkt[];
	public vybranePredplatne: TypPredplatneho;
	public vybranyProdukt: Produkt;
	public delkaPlatnostiPredplatneho: number;
	public zobrazitVrtitko: Boolean = true;

	constructor(
		protected formBuilder: FormBuilder,
		protected autentizaceService: AutentizaceService,
		private decimalPipe: DecimalPipe,
		private produktService: ProduktService,
		private router: Router,
		private vytvoritObjednavkuService: VytvoritObjednavkuService
	) {
		super(autentizaceService);
	}

	ngOnInit() {
		this.pripravitSeznamProduktu();
		this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
		this.inicializovatFormularovaData();
	}

	onSubmit() {
		if (!NastrojeObecne.jeHodnotaPrazdna(this.vybranyProdukt)) {
			super.onSubmit();
		} else {
			Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'formular.validace.nevybranyProdukt');
		}
	}

	odeslat() {
		if (this.autentizaceService.jePrihlasenyUzivatelOperator()) {
			if (this.formGroup.controls.cena.value != this.vybranyProdukt.cena.cenaSDph) {
				const cena = new Cena();

				cena.cenaSDph = NastrojeCisla.prevestNaCislo(this.formGroup.controls.cena.value);
				cena.cenaBezDph = NastrojeCisla.delitCisla(NastrojeCisla.prevestNaCislo(cena.cenaSDph), NastrojeCisla.secistCisla(1, NastrojeCisla.prevestNaCislo(this.vybranyProdukt.cena.koeficientDph)));
				cena.koeficientDph = this.vybranyProdukt.cena.koeficientDph;

				this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranePredplatne, cena, this.delkaPlatnostiPredplatneho);
				this.vytvoritObjednavkuService.doplnitZpusobPlatby(ZpusobPlatby.BANKOVNI_PREVOD);
				this.vytvoritObjednavkuService.objednavka.nazevProduktu = this.pripravitTextProduktu();
			}
		} else {
			this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranePredplatne);
			this.vytvoritObjednavkuService.doplnitZpusobPlatby(ZpusobPlatby.BANKOVNI_PREVOD);
		}

		this.nasledujiciKarta();
	}

	public vlozitVybranyProdukt(produktModalData: InformaceOVybranemTarifu): void {
		this.vybranyProdukt = produktModalData.produkt;
		this.vybranePredplatne = produktModalData.vybranePredplatne;
		this.delkaPlatnostiPredplatneho = produktModalData.delkaPlatnostiPredplatneho;

		if (this.vybranePredplatne === TypPredplatneho.NA_MIRU) {
			this.formGroup.controls.cena.setValue(this.decimalPipe.transform(NastrojeCisla.nasobitCisla(this.vybranyProdukt.cena.cenaSDph, this.delkaPlatnostiPredplatneho), '1.2-2', 'cs'));
		} else {
			this.formGroup.controls.cena.setValue(this.decimalPipe.transform(this.vybranyProdukt.cena.cenaSDph, '1.2-2', 'cs'));
		}
	}

	public presmerovatPriZruseni() {
		this.router.navigate(['/sekce-operatora']);
	}

	public predchoziKarta() {
		this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_UZIVATELE);
	}

	public pripravitTextProduktu(): string {
		if (this.vybranePredplatne === TypPredplatneho.NA_MIRU) {
			return this.vybranyProdukt.nazev + ' (' + this.delkaPlatnostiPredplatneho + ' ' + Lokalizace.pripravitText('predplatne.mesicuText') + ')';
		}

		return this.vybranyProdukt.nazev;
	}

	private nasledujiciKarta() {
		this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
	}

	private pripravitSeznamProduktu(): void {
		this.zobrazitVrtitko = true;

		this.produktService.nacistSeznamProduktu()
			.subscribe(data => {
				data.forEach(p => {
					p.puvodniNazev = p.nazev;
					p.nazev = (p.nazev + ' - ' + Lokalizace.pripravitText('predplatne.' + p.dostupnePredplatne[0].toLowerCase()));
				});

				this.produkty = data;
				this.zobrazitVrtitko = false;
			}, () => this.zobrazitVrtitko = false);
	}

	private inicializovatFormularovaData() {
		this.formGroup.addControl('cena', new FormControl('', [
			Validators.required,
			Validators.pattern(NastrojeCisla.VZOR_DECIMAL_DOT_AND_COMMA),
			Validators.maxLength(15)
		]));
	}
}
