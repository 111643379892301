<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'platba.detailPlatby' | lokalizace }}
        </div>

        <div class="container__content-body">
            <app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

            <section *ngIf="platba" class="info-container">
                <div class="span-2">
                    <app-udaje-o-platbe [platba]="platba"></app-udaje-o-platbe>
                </div>
            </section>
        </div>

        <div class="button-area">
            <button *ngIf="jePrihlasenCDDUzivatel"
                    type="button" (click)="prejitNaStrankuProfiluPODO()"
                    class="btn btn-secondary waves-effect waves-light right">
                {{ 'tlacitko.prejitNaProfilPODO' | lokalizace }}
            </button>
            <button type="button" (click)="prejitNaStrankuDetailuObjednavky()"
                    class="btn btn-secondary waves-effect waves-light right">
                {{ 'tlacitko.prejitNaDetailObjednavky' | lokalizace }}
            </button>
        </div>
    </div>
</main>

<app-textovy-modalni-panel (souhlas)="zavritModalniPanel()"
                           [zobrazit]="zobrazitPotvrzeni"
                           [idPrvku]="'ceka-na-platbu-modalni-panel'"
                           [nadpis]="'platba.modal.nadpis'"
                           [text]="'platba.modal.text'"></app-textovy-modalni-panel>
