import { TypHodnotyRozsahu } from '../../data/obecne/enum/typ-hodnoty-rozsahu.enum';
import { Rozsah } from '../../data/obecne/rozsah';
import { NastrojeCisla } from './nastroje-cisla';
import { NastrojeDataACasu } from './nastroje-data-a-casu';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeFiltrace = /** @class */ (function () {
    function NastrojeFiltrace() {
    }
    NastrojeFiltrace.pripravitFiltr = function (filtr, formGroup) {
        for (var _i = 0, _a = Object.keys(formGroup.controls); _i < _a.length; _i++) {
            var nazevPrvkuFormulare = _a[_i];
            var hodnotaPrvkuFormulare = formGroup.controls[nazevPrvkuFormulare].value;
            if (!NastrojeObecne.jeHodnotaPrazdna(hodnotaPrvkuFormulare)) {
                var pouzitySuffixRozsahu = this.pripravitSuffixRozsahu(nazevPrvkuFormulare);
                if (pouzitySuffixRozsahu !== null) {
                    var nazevRozsahu = nazevPrvkuFormulare.slice(0, nazevPrvkuFormulare.length - pouzitySuffixRozsahu.length);
                    var suffixRozsahu = pouzitySuffixRozsahu.charAt(0).toLowerCase() + pouzitySuffixRozsahu.slice(1);
                    if (filtr[nazevRozsahu] === undefined) {
                        filtr[nazevRozsahu] = new Rozsah();
                    }
                    filtr[nazevRozsahu][suffixRozsahu] = this.pripravitHodnotuRozsahu(hodnotaPrvkuFormulare, suffixRozsahu);
                }
                else {
                    filtr[nazevPrvkuFormulare] = hodnotaPrvkuFormulare;
                }
            }
        }
        return filtr;
    };
    NastrojeFiltrace.nastavitVychoziHodnoty = function (formGroup, objektNastaveniFiltru) {
        var nazvyPouzitychFiltracnichAtributu = Object.keys(objektNastaveniFiltru);
        for (var i = 0; i < nazvyPouzitychFiltracnichAtributu.length; i++) {
            var nazevAtributu = nazvyPouzitychFiltracnichAtributu[i];
            var hodnota = objektNastaveniFiltru[nazevAtributu];
            if (hodnota instanceof Rozsah) {
                var hodnotaOd = this.pripravitVychoziHodnotu(hodnota.od);
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu + TypHodnotyRozsahu.OD, hodnotaOd);
                var hodnotaDo = this.pripravitVychoziHodnotu(hodnota.do);
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu + TypHodnotyRozsahu.DO, hodnotaDo);
            }
            else {
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu, this.pripravitVychoziHodnotu(hodnota));
            }
        }
    };
    NastrojeFiltrace.pripravitSuffixRozsahu = function (nazevHodnoty) {
        for (var _i = 0, _a = Object.keys(TypHodnotyRozsahu); _i < _a.length; _i++) {
            var typHodnotyRozsahu = _a[_i];
            var testovanySuffix = TypHodnotyRozsahu[typHodnotyRozsahu];
            if (nazevHodnoty.endsWith(testovanySuffix)) {
                return testovanySuffix;
            }
        }
        return null;
    };
    NastrojeFiltrace.pripravitHodnotuRozsahu = function (hodnota, suffix) {
        if (new RegExp(NastrojeCisla.VZOR_DECIMAL).test(hodnota)) {
            return NastrojeCisla.prevestNaCislo(hodnota);
        }
        else if (new RegExp(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA).test(hodnota)) {
            var datum = NastrojeDataACasu.prevestRetezecNaDatum(hodnota, NastrojeDataACasu.FORMAT_DATA);
            if (NastrojeDataACasu.jeValidniDatum(datum)) {
                return suffix === 'od' || suffix === 'rozsahOd' ? NastrojeDataACasu.nastavitCasZacatekDne(datum) : NastrojeDataACasu.nastavitCasKonecDne(datum);
            }
        }
        return hodnota;
    };
    NastrojeFiltrace.nastavitVychoziHodnotu = function (formGroup, nazevAtributu, hodnota) {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota)) {
            formGroup.controls[nazevAtributu].setValue(hodnota);
            formGroup.controls[nazevAtributu].markAsTouched();
            formGroup.controls[nazevAtributu].markAsDirty();
        }
    };
    NastrojeFiltrace.pripravitVychoziHodnotu = function (hodnota) {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota) && hodnota instanceof Date) {
            return NastrojeDataACasu.formatovatDatum(hodnota, NastrojeDataACasu.FORMAT_DATA);
        }
        return hodnota;
    };
    return NastrojeFiltrace;
}());
export { NastrojeFiltrace };
