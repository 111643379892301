import {Objednavka} from '../../data/e-shop/objednavka';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';
import {NastrojeObecne} from './nastroje-obecne';
import {SlevovyKupon} from '../../data/e-shop/slevovy-kupon';
import { Produkt } from "../../data/e-shop/produkt";

export class NastrojeObjednavky {

    public static pripravitDataObjednavkyProVypocetSlevy(idUzivatele: number, typPredplatneho: TypPredplatneho, dic: string, idProduktu: number, identifikatorProduktu: string, slevovyKupon?: SlevovyKupon): Objednavka {
        const objednavka: Objednavka = new Objednavka();

        objednavka.idUzivatele = idUzivatele;
        objednavka.typPredplatneho = typPredplatneho;
        objednavka.nazevSlevovehoKuponu = NastrojeObecne.jeHodnotaPrazdna(slevovyKupon) ? undefined : slevovyKupon.nazev;
        objednavka.dic = dic;
        objednavka.idProduktu = idProduktu
        objednavka.identifikatorProduktu = identifikatorProduktu;

        return objednavka;
    }
}
