import {NastrojeJsonu} from './nastroje-jsonu';

export class NastrojeObecne {

    public static jeHodnotaPrazdna(hodnota: any) {
        return hodnota === null || hodnota === undefined || hodnota === '';
    }

    public static formatovatRetezec(hodnota: string, ...parametry): string {
        return hodnota.replace(/{(\d+)}/g, function(prvek, poradi) {
            return typeof parametry[poradi] !== 'undefined' ? parametry[poradi] : prvek;
        });
    }

    public static vygenerovatNahodnyRetezec(pozadovanaDelka = 32): string {
        const povoleneZnaky = 'abcdefghijklmnopqrstuvwxyz'; // Pouze pismena, jelikoz prvni znak hodnoty html atributu nemuze byt cislo
        let vysledek = '';

        for (let i = 0; i < pozadovanaDelka; i++) {
            vysledek += povoleneZnaky.charAt(Math.floor(Math.random() * povoleneZnaky.length));
        }

        return vysledek;
    }

    public static vytvoritHlubokouKopii<T>(data: T): T {
        return NastrojeJsonu.parse(NastrojeJsonu.stringify(data));
    }
}
