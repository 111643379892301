<div [formGroup]="nadrazenaSkupinaFormulare">
    <div class="input-field" [class.responsive-input-field]="zmensitRozestupy">
        <label>
            <input type="checkbox"
                   [formControlName]="nazevOvladaceFormulare"
                   [attr.disabled]="zneaktivnit() ? true : null">
            <span>{{popisek | lokalizace}}
                <span class="asterisk-placeholder"></span>
            </span>
            <!--workaround pro validaci checkboxu-->
            <div class="select-wrapper"
                 [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
                 [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid">
            </div>
            <span *ngIf="!skrytPomocnyText" class="helper-text"
                  [attr.data-error]="pripravitChybovouZpravu()"
                  [attr.data-success]="''">
                {{pomocnyText | lokalizace}}
            </span>
        </label>
    </div>
</div>
