import {NgModule} from '@angular/core';
import {PredplatneService} from '../service/e-shop/predplatne.service';
import {DatumPipe} from '../shared/nastroje/pipes/datum.pipe';
import {RekapitulaceObjednavkyZvolenohoProduktuComponent} from './objednavka/vytvorit/sablony-wizardu/rekapitulace-objednavky-zvoleneho-produktu/rekapitulace-objednavky-zvoleneho-produktu.component';
import {VytvoritObjednavkuUzivateleComponent} from './objednavka/vytvorit/vytvorit-objednavku-uzivatele/vytvorit-objednavku-uzivatele.component';
import {ObjednavkaService} from '../service/e-shop/objednavka.service';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {AbstraktniFormularModule} from '../shared/formular/abstraktni-formular-module';
import {NastrojeModule} from '../shared/nastroje/nastroje.module';
import {VrtitkoModule} from '../shared/layout/vrtitko/vrtitko.module';
import {FakturacniUdajeInputComponent} from './sablony/fakturacni-udaje/fakturacni-udaje-input/fakturacni-udaje-input.component';
import {ProduktService} from '../service/e-shop/produkt.service';
import {VytvoritObjednavkuOperatoraComponent} from './objednavka/vytvorit/vytvorit-objednavku-operatora/vytvorit-objednavku-operatora.component';
import {VytvoritObjednavkuOperatoraWizardComponent} from './objednavka/vytvorit/vytvorit-objednavku-operatora/vytvorit-objednavku-operatora-wizard/vytvorit-objednavku-operatora-wizard.component';
import {ZvolitUzivateleComponent} from './objednavka/vytvorit/sablony-wizardu/zvolit-uzivatele/zvolit-uzivatele.component';
import {VyplnitFakturacniUdajeComponent} from './objednavka/vytvorit/sablony-wizardu/vyplnit-fakturacni-udaje/vyplnit-fakturacni-udaje.component';
import {SekceOperatoraComponent} from './sekce-operatora/sekce-operatora.component';
import {ZvolitProduktModalniPanelComponent} from './objednavka/vytvorit/sablony-wizardu/zvolit-produkt/zvolit-produkt-modalni-panel/zvolit-produkt-modalni-panel.component';
import {OrganizacniStrukturaModule} from '../organizacni-struktura/organizacni-struktura.module';
import {SeznamUzivateluSDodavatelemService} from '../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {TextovyModalniPanelModule} from '../shared/modalni-panel/textovy-modalni-panel/textovy-modalni-panel.module';
import {ZvolitProduktComponent} from './objednavka/vytvorit/sablony-wizardu/zvolit-produkt/zvolit-produkt.component';
import {VytvoritObjednavkuService} from '../service/e-shop/vytvorit-objednavku.service';
import {SeznamObjednavekOperatoraComponent} from './objednavka/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.component';
import {SeznamObjednavekOperatoraSeznamComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/seznam/seznam-objednavek-operatora-seznam.component';
import {SeznamObjednavekOperatoraObsahComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/seznam-objednavek-operatora-obsah.component';
import {SeznamObjednavekOperatoraFiltrRozsireneComponent} from './objednavka/seznam/seznam-objednavek-operatora/filtr/rozsirene/seznam-objednavek-operatora-filtr-rozsirene.component';
import {SeznamObjednavekOperatoraHlavickaComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/hlavicka/seznam-objednavek-operatora-hlavicka.component';
import {SeznamObjednavekOperatoraLegendaComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/hlavicka/legenda/seznam-objednavek-operatora-legenda.component';
import {SeznamObjednavekOperatoraRazeniComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/hlavicka/razeni/seznam-objednavek-operatora-razeni.component';
import {SeznamObjednavekOperatoraPatickaComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/paticka/seznam-objednavek-operatora-paticka.component';
import {SeznamObjednavekOperatoraAktualniStrankaComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/paticka/aktualni-stranka/seznam-objednavek-operatora-aktualni-stranka.component';
import {SeznamObjednavekOperatoraNastaveniComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/paticka/nastaveni/seznam-objednavek-operatora-nastaveni.component';
import {SeznamObjednavekOperatoraStrankovaniComponent} from './objednavka/seznam/seznam-objednavek-operatora/obsah/paticka/strankovani/seznam-objednavek-operatora-strankovani.component';
import {SeznamObjednavekOperatoraNastaveniService} from '../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {SeznamModule} from '../shared/seznam/seznam.module';
import {SeznamObjednavekOperatoraFiltrZakladniComponent} from './objednavka/seznam/seznam-objednavek-operatora/filtr/zakladni/seznam-objednavek-operatora-filtr-zakladni.component';
import {SeznamObjednavekOperatoraFiltrComponent} from './objednavka/seznam/seznam-objednavek-operatora/filtr/seznam-objednavek-operatora-filtr.component';
import {SeznamObjednavekComponent} from './objednavka/seznam/seznam-objednavek/seznam-objednavek.component';
import {SeznamObjednavekObsahComponent} from './objednavka/seznam/seznam-objednavek/obsah/seznam-objednavek-obsah.component';
import {SeznamObjednavekSeznamComponent} from './objednavka/seznam/seznam-objednavek/obsah/seznam/seznam-objednavek-seznam.component';
import {SeznamObjednavekRazeniComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/razeni/seznam-objednavek-razeni.component';
import {SeznamObjednavekHlavickaComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/seznam-objednavek-hlavicka.component';
import {SeznamObjednavekLegendaComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/legenda/seznam-objednavek-legenda.component';
import {SeznamObjednavekPatickaComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/paticka/seznam-objednavek-paticka.component';
import {SeznamObjednavekAktualniStrankaComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/paticka/aktualni-stranka/seznam-objednavek-aktualni-stranka.component';
import {SeznamObjednavekNastaveniComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/paticka/nastaveni/seznam-objednavek-nastaveni.component';
import {SeznamObjednavekStrankovaniComponent} from './objednavka/seznam/seznam-objednavek/obsah/hlavicka/paticka/strankovani/seznam-objednavek-strankovani.component';
import {PlatbyAObjednavkyComponent} from './platby-a-objednavky/platby-a-objednavky.component';
import {SeznamObjednavekNastaveniService} from '../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../service/seznam/seznam-objednavek/seznam-objednavek.service';
import {OnlinePlatbyService} from '../service/e-shop/online-platby.service';
import {DetailPlatbyComponent} from './platba/detail/detail-platby.component';
import {UdajeOPlatbeComponent} from './sablony/udaje-o-platbe/udaje-o-platbe.component';
import {TabulkaAktivnichPredplatnychComponent} from './sablony/tabulka-aktivnich-predplatnych/tabulka-aktivnich-predplatnych.component';
import {DetailObjednavkyComponent} from './objednavka/detail/detail-objednavky.component';
import {UdajeOObjednavceComponent} from './sablony/udaje-o-objednavce/udaje-o-objednavce.component';
import {FakturacniUdajeOutputComponent} from './sablony/fakturacni-udaje/fakturacni-udaje-output/fakturacni-udaje-output.component';
import {VytvoritObjednavkuUzivateleWizardComponent} from './objednavka/vytvorit/vytvorit-objednavku-uzivatele/vytvorit-objednavku-uzivatele-wizard/vytvorit-objednavku-uzivatele-wizard.component';
import {RekapitulaceObjednavkySVyberemComponent} from './objednavka/vytvorit/sablony-wizardu/rekapitulace-objednavky-s-vyberem/rekapitulace-objednavky-s-vyberem.component';
import {FakturaService} from '../service/e-shop/faktura.service';
import {TabulkaFakturStrankovanaComponent} from './sablony/tabulka-faktur-strankovana/tabulka-faktur-strankovana.component';
import {FakturacniUdajeService} from '../service/e-shop/fakturacni-udaje.service';
import {PresmerovaniNaPODO} from './platba/presmerovani-na-podo/presmerovani-na-podo.component';
import {SlevoveKuponyComponent} from './slevove-kupony/slevove-kupony.component';
import {SeznamSlevovychKuponuComponent} from './slevove-kupony/seznam/seznam-slevovych-kuponu.component';
import {SeznamSlevovychKuponuSeznamComponent} from './slevove-kupony/seznam/obsah/seznam/seznam-slevovych-kuponu-seznam.component';
import {SeznamSlevovychKuponuPatickaComponent} from './slevove-kupony/seznam/obsah/paticka/seznam-slevovych-kuponu-paticka.component';
import {SeznamSlevovychKuponuHlavickaComponent} from './slevove-kupony/seznam/obsah/hlavicka/seznam-slevovych-kuponu-hlavicka.component';
import {SeznamSlevovychKuponuFiltrComponent} from './slevove-kupony/seznam/obsah/filtr/seznam-slevovych-kuponu-filtr.component';
import {SeznamSlevovychKuponuNastaveniService} from '../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import {SeznamSlevovychKuponuRazeniComponent} from './slevove-kupony/seznam/obsah/hlavicka/razeni/seznam-slevovych-kuponu-razeni.component';
import {SeznamSlevovychKuponuLegendaComponent} from './slevove-kupony/seznam/obsah/hlavicka/legenda/seznam-slevovych-kuponu-legenda.component';
import {SeznamSlevovychKuponuAktualniStrankaComponent} from './slevove-kupony/seznam/obsah/paticka/aktualni-stranka/seznam-slevovych-kuponu-aktualni-stranka.component';
import {SeznamSlevovychKuponuNastaveniComponent} from './slevove-kupony/seznam/obsah/paticka/nastaveni/seznam-slevovych-kuponu-nastaveni.component';
import {SeznamSlevovychKuponuStrankovaniComponent} from './slevove-kupony/seznam/obsah/paticka/strankovani/seznam-slevovych-kuponu-strankovani.component';
import {DetailSlevovehoKuponuComponent} from './slevove-kupony/detail/detail-slevoveho-kuponu.component';
import {SlevovyKuponService} from '../service/e-shop/slevovy-kupon.service';
import {SlevovyKuponZakladniUdajeComponent} from './slevove-kupony/formular/slevovy-kupon-zakladni-udaje/slevovy-kupon-zakladni-udaje.component';
import {SlevovyKuponTabulkaProduktuComponent} from './slevove-kupony/formular/slevovy-kupon-tabulka-produktu/slevovy-kupon-tabulka-produktu.component';
import {UpravitSlevovyKuponComponent} from './slevove-kupony/upravit/upravit-slevovy-kupon.component';
import {VytvoritSlevovyKuponComponent} from './slevove-kupony/vytvorit/vytvorit-slevovy-kupon.component';
import {SlevaPipe} from '../shared/nastroje/pipes/sleva.pipe';
import {ZrusitObjednavkuModalniPanelComponent} from './objednavka/detail/zrusit-objednavku-modalni-panel/zrusit-objednavku-modalni-panel.component';
import {ZruseniObjednavkyComponent} from './sablony/zruseni-objednavky/zruseni-objednavky.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AbstraktniFormularModule,
        NastrojeModule,
        VrtitkoModule,
        RouterModule,
        OrganizacniStrukturaModule,
        TextovyModalniPanelModule,
        SeznamModule
    ],
    declarations: [
        VytvoritObjednavkuUzivateleComponent,
        FakturacniUdajeInputComponent,
        VytvoritObjednavkuOperatoraComponent,
        VytvoritObjednavkuOperatoraWizardComponent,
        ZvolitProduktComponent,
        ZvolitUzivateleComponent,
        VyplnitFakturacniUdajeComponent,
        SekceOperatoraComponent,
        ZvolitProduktModalniPanelComponent,
        SeznamObjednavekOperatoraComponent,
        SeznamObjednavekOperatoraSeznamComponent,
        SeznamObjednavekOperatoraObsahComponent,
        SeznamObjednavekOperatoraHlavickaComponent,
        SeznamObjednavekOperatoraLegendaComponent,
        SeznamObjednavekOperatoraRazeniComponent,
        SeznamObjednavekOperatoraPatickaComponent,
        SeznamObjednavekOperatoraAktualniStrankaComponent,
        SeznamObjednavekOperatoraNastaveniComponent,
        SeznamObjednavekOperatoraStrankovaniComponent,
        SeznamObjednavekOperatoraFiltrComponent,
        SeznamObjednavekOperatoraFiltrRozsireneComponent,
        SeznamObjednavekOperatoraFiltrZakladniComponent,
        SeznamObjednavekComponent,
        SeznamObjednavekObsahComponent,
        SeznamObjednavekSeznamComponent,
        SeznamObjednavekHlavickaComponent,
        SeznamObjednavekLegendaComponent,
        SeznamObjednavekRazeniComponent,
        SeznamObjednavekPatickaComponent,
        SeznamObjednavekAktualniStrankaComponent,
        SeznamObjednavekNastaveniComponent,
        SeznamObjednavekStrankovaniComponent,
        PlatbyAObjednavkyComponent,
        DetailPlatbyComponent,
        UdajeOPlatbeComponent,
        TabulkaAktivnichPredplatnychComponent,
        DetailObjednavkyComponent,
        UdajeOObjednavceComponent,
        FakturacniUdajeOutputComponent,
        VytvoritObjednavkuUzivateleWizardComponent,
        RekapitulaceObjednavkySVyberemComponent,
        RekapitulaceObjednavkyZvolenohoProduktuComponent,
        TabulkaFakturStrankovanaComponent,
        PresmerovaniNaPODO,
        SlevoveKuponyComponent,
        SeznamSlevovychKuponuComponent,
        SeznamSlevovychKuponuSeznamComponent,
        SeznamSlevovychKuponuPatickaComponent,
        SeznamSlevovychKuponuHlavickaComponent,
        SeznamSlevovychKuponuFiltrComponent,
        SeznamSlevovychKuponuRazeniComponent,
        SeznamSlevovychKuponuLegendaComponent,
        SeznamSlevovychKuponuAktualniStrankaComponent,
        SeznamSlevovychKuponuNastaveniComponent,
        SeznamSlevovychKuponuStrankovaniComponent,
        DetailSlevovehoKuponuComponent,
        SlevovyKuponZakladniUdajeComponent,
        SlevovyKuponTabulkaProduktuComponent,
        UpravitSlevovyKuponComponent,
        VytvoritSlevovyKuponComponent,
        ZrusitObjednavkuModalniPanelComponent,
        ZruseniObjednavkyComponent
    ],
    providers: [
        DatumPipe,
        SlevaPipe,
        FakturaService,
        FakturacniUdajeService,
        ObjednavkaService,
        OnlinePlatbyService,
        PredplatneService,
        ProduktService,
        VytvoritObjednavkuService,
        SeznamUzivateluSDodavatelemNastaveniService,
        SeznamUzivateluSDodavatelemService,
        SeznamObjednavekNastaveniService,
        SeznamObjednavekService,
        SeznamObjednavekOperatoraNastaveniService,
        SeznamObjednavekOperatoraService,
        SeznamSlevovychKuponuNastaveniService,
        SeznamSlevovychKuponuService,
        SlevovyKuponService
    ]
})
export class EShopModule {
}
