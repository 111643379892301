var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { StavUzivatele } from '../../../../../data/dodavatel/enum/stav-uzivatele.enum';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { FiltrSeznamuUzivateluSDodavatelem } from '../../../../../data/seznam/seznam-uzivatelu-s-dodavatelem/filtr/filtr-seznamu-uzivatelu-s-dodavatelem';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { NastrojeCisla } from '../../../../../shared/nastroje/nastroje-cisla';
import { NastrojeFiltrace } from '../../../../../shared/nastroje/nastroje-filtrace';
import { AbstraktniFiltrComponent } from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';
var SeznamUzivateluSDodavatelemFiltrComponent = /** @class */ (function (_super) {
    __extends(SeznamUzivateluSDodavatelemFiltrComponent, _super);
    function SeznamUzivateluSDodavatelemFiltrComponent(formBuilder, autentizaceService, seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) {
        var _this = _super.call(this, seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        _this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
        _this.stavUzivatele = StavUzivatele;
        return _this;
    }
    SeznamUzivateluSDodavatelemFiltrComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    };
    SeznamUzivateluSDodavatelemFiltrComponent.prototype.odeslat = function () {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuUzivateluSDodavatelem(), this.formGroup));
    };
    SeznamUzivateluSDodavatelemFiltrComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('id', new FormControl('', [
            Validators.pattern(NastrojeCisla.VZOR_INTEGER)
        ]));
        this.formGroup.addControl('prihlasovaciJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('email', new FormControl('', [
            Validators.maxLength(100)
        ]));
        this.formGroup.addControl('krestniJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('prijmeni', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('stav', new FormControl('', []));
        this.formGroup.addControl('nazevOrganizacniJednotky', new FormControl('', [
            Validators.minLength(2),
            Validators.maxLength(255)
        ]));
        this.formGroup.addControl('ic', new FormControl('', [
            Validators.minLength(2),
            Validators.maxLength(20)
        ]));
    };
    return SeznamUzivateluSDodavatelemFiltrComponent;
}(AbstraktniFiltrComponent));
export { SeznamUzivateluSDodavatelemFiltrComponent };
