var NastrojeFormulare = /** @class */ (function () {
    function NastrojeFormulare() {
    }
    NastrojeFormulare.resetovatFormularovaData = function (formGroup) {
        formGroup.reset();
        formGroup.markAsPristine();
        formGroup.markAsUntouched();
    };
    NastrojeFormulare.resetovatValidatorFormularovehoPrvku = function (formGroup, nazevOvladaceFormulare) {
        formGroup.controls[nazevOvladaceFormulare].clearValidators();
    };
    NastrojeFormulare.resetovatPrvekBezVyvolaniUdalosti = function (ovladacFormulare) {
        ovladacFormulare.reset(undefined, {
            onlySelf: true,
            emitEvent: false
        });
    };
    NastrojeFormulare.normalizovatHodnotu = function (hodnota) {
        return hodnota === undefined || hodnota === '' ? null : hodnota;
    };
    NastrojeFormulare.nastavitTypUceluFormulare = function (formGroup, typUceluFormulare) {
        formGroup.typUceluFormulare = typUceluFormulare;
    };
    NastrojeFormulare.pripravitTypUceluFormulare = function (formGroup) {
        return formGroup.typUceluFormulare;
    };
    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti = function (ovladacFormulare, hodnota) {
        ovladacFormulare.setValue(hodnota, {
            onlySelf: true,
            emitEvent: false
        });
    };
    NastrojeFormulare.aktivovatPrvekFormulareBezVyvolaniUdalosti = function (ovladacFormulare) {
        ovladacFormulare.enable({
            onlySelf: true,
            emitEvent: false
        });
    };
    NastrojeFormulare.deaktivovatPrvekFormulareBezVyvolaniUdalosti = function (ovladacFormulare) {
        ovladacFormulare.disable({
            onlySelf: true,
            emitEvent: false
        });
    };
    NastrojeFormulare.aktualizovatHodnotuAValidaciBezVyvolaniUdalosti = function (ovladacFormulare) {
        ovladacFormulare.updateValueAndValidity({
            onlySelf: true,
            emitEvent: false
        });
    };
    NastrojeFormulare.pridatValidatory = function (ovladacFormulare, validatory) {
        if (ovladacFormulare.validator != null) {
            validatory.unshift(ovladacFormulare.validator);
        }
        ovladacFormulare.setValidators(validatory);
    };
    return NastrojeFormulare;
}());
export { NastrojeFormulare };
