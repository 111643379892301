import {prostredi} from './../../../../prostredi/prostredi';

export class LokalniUlozisteNastaveni {

  public static readonly ODDELOVAC_HODNOT_NAZVU_KLICE = '_';
  public static readonly SUFIX_NAZVU_KLICE_TOKENU = 'token';
  public static readonly PREFIX_NAZVU_DOCASNEHO_KLICE = 'Tmp';

  public static readonly NAZEV_AKTUALNI_VERZE_PROSTREDI = prostredi.verze;
  public static readonly NAZVY_VSECH_VERZI_PROSTREDI = ['Lokální', 'Testovací', 'Produkční'];
}
