import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstraktniSeznamService} from '../abstraktni-seznam-service';
import {SeznamSlevovychKuponu} from '../../../data/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu';
import {AutentizaceService} from '../../bezpecnost/autentizace.service';
import {SeznamSlevovychKuponuNastaveniService} from './seznam-slevovych-kuponu-nastaveni.service';
import {TypPozadavku} from '../../obecne/abstraktni-service';
import {NastrojeJsonu} from '../../../shared/nastroje/nastroje-jsonu';
import {prostredi} from '../../../../prostredi/prostredi';
import {PolozkaSeznamuSlevovychKuponu} from '../../../data/seznam/seznam-slevovych-kuponu/polozka-seznamu-slevovych-kuponu';
import {NastrojeObecne} from '../../../shared/nastroje/nastroje-obecne';

@Injectable()
export class SeznamSlevovychKuponuService extends AbstraktniSeznamService {

    protected resourceClassUri = '';

    public seznam: SeznamSlevovychKuponu;

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService, private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService) {
        super(http, autentizaceService);

        this.url = prostredi.urlBackendEshopSlevoveKupony;
    }

    public aktualizovat(odrolovatNaZacatekSeznamu: boolean = true): void {
        this.seznam = new SeznamSlevovychKuponu();

        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-kuponu', NastrojeJsonu.stringifyRFC3339(this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(odpoved => {
                this.seznam = odpoved;

                if (NastrojeObecne.jeHodnotaPrazdna(this.seznam.polozky)) {
                    this.seznam.polozky = [] as PolozkaSeznamuSlevovychKuponu[];
                }
            }).catch(this.zpracovatChybu);

        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    }
}
