<div #modalniPanel id="zruseni-objednavky-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'objednavka.zruseniObjednavky' | lokalizace }}
    </div>

    <div class="modal-content modal-body">
        <form [formGroup]="formGroup" class="form-column-layout">
            <app-formular-input
                    class="span-12"
                    [popisek]="'objednavka.duvodZruseni'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.duvodZruseni"></app-formular-input>
        </form>
    </div>

    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>

        <button (click)="onSubmit()" class="waves-effect waves-light btn-flat">
            {{ 'tlacitko.zrusitObjednavku' | lokalizace }}
        </button>
    </div>
</div>
