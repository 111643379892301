/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-slevovych-kuponu-seznam.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/seznam/seznam.component.ngfactory";
import * as i3 from "../../../../../shared/seznam/seznam.component";
import * as i4 from "@angular/router";
import * as i5 from "./seznam-slevovych-kuponu-seznam.component";
import * as i6 from "../../../../../shared/nastroje/pipes/datum.pipe";
import * as i7 from "../../../../../shared/nastroje/pipes/sleva.pipe";
import * as i8 from "../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service";
var styles_SeznamSlevovychKuponuSeznamComponent = [i0.styles];
var RenderType_SeznamSlevovychKuponuSeznamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamSlevovychKuponuSeznamComponent, data: {} });
export { RenderType_SeznamSlevovychKuponuSeznamComponent as RenderType_SeznamSlevovychKuponuSeznamComponent };
export function View_SeznamSlevovychKuponuSeznamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam", [], null, null, null, i2.View_SeznamComponent_0, i2.RenderType_SeznamComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamComponent, [i4.Router], { celkovyPocetZaznamu: [0, "celkovyPocetZaznamu"], radky: [1, "radky"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pripravitCelkovyPocetZaznamu(); var currVal_1 = _co.pripravitRadkySeznamuProZobrazeni(); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SeznamSlevovychKuponuSeznamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-slevovych-kuponu-seznam", [], null, null, null, View_SeznamSlevovychKuponuSeznamComponent_0, RenderType_SeznamSlevovychKuponuSeznamComponent)), i1.ɵdid(1, 49152, null, 0, i5.SeznamSlevovychKuponuSeznamComponent, [i6.DatumPipe, i7.SlevaPipe, i8.SeznamSlevovychKuponuService], null, null)], null, null); }
var SeznamSlevovychKuponuSeznamComponentNgFactory = i1.ɵccf("app-seznam-slevovych-kuponu-seznam", i5.SeznamSlevovychKuponuSeznamComponent, View_SeznamSlevovychKuponuSeznamComponent_Host_0, {}, {}, []);
export { SeznamSlevovychKuponuSeznamComponentNgFactory as SeznamSlevovychKuponuSeznamComponentNgFactory };
