import { OnInit } from '@angular/core';
import { PoradoveCisloKrokuObjednavky } from '../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import { GoogleAnalyticsTrackingService } from '../../../../service/obecne/google-analytics-tracking.service';
import { NastrojeBase64 } from '../../../../shared/nastroje/nastroje-base64';
import { ActivatedRoute, Router } from '@angular/router';
import { NastrojeUrl } from '../../../../shared/nastroje/nastroje-url';
import { NastrojeJsonu } from '../../../../shared/nastroje/nastroje-jsonu';
import { NastrojeObecne } from '../../../../shared/nastroje/nastroje-obecne';
import { NastrojePrevoduObjednavky } from '../../../../shared/nastroje/nastroje-prevodu-objednavky';
var VytvoritObjednavkuUzivateleComponent = /** @class */ (function () {
    function VytvoritObjednavkuUzivateleComponent(route, router, googleAnalyticsTrackingService) {
        this.route = route;
        this.router = router;
        this.googleAnalyticsTrackingService = googleAnalyticsTrackingService;
    }
    VytvoritObjednavkuUzivateleComponent.prototype.ngOnInit = function () {
        this.zpracovatParametrRetezceDotazuZUrl();
    };
    VytvoritObjednavkuUzivateleComponent.prototype.zpracovatParametrRetezceDotazuZUrl = function () {
        var parametrRetezceDotazu = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'data');
        if (NastrojeObecne.jeHodnotaPrazdna(parametrRetezceDotazu)) {
            this.router.navigate(["/"]);
        }
        else {
            var podoObjekt = NastrojeJsonu.parse(NastrojeBase64.dekodovatBase64NaUnicode(parametrRetezceDotazu));
            this.objednavka = NastrojePrevoduObjednavky.prevestPodoObjednavkyJsonDoObjednavky(podoObjekt);
            this.googleAnalyticsTrackingService.pridatConfig(podoObjekt.user.podoId);
            this.googleAnalyticsTrackingService.evidovatPridaniZboziDoKosiku(this.objednavka);
            this.googleAnalyticsTrackingService.evidovatZapocetiCheckoutu(this.objednavka);
            this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
        }
    };
    return VytvoritObjednavkuUzivateleComponent;
}());
export { VytvoritObjednavkuUzivateleComponent };
