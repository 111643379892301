<div class="container__content-body">
    <app-seznam-uzivatelu-s-dodavatelem-obsah [ucelSeznamu]="ucelSeznamu.VYBER_RADKY"></app-seznam-uzivatelu-s-dodavatelem-obsah>
</div>

<div>
    <div class="container__content-footer">
        <button type="button" (click)="presmerovatPriZruseni()"
                class="btn btn-secondary">
            <b>{{ 'tlacitko.zrusit' | lokalizace }}</b>
            <i class="material-icons right">clear</i>
        </button>
        <button type="submit" (click)="odeslat()"
                class="btn btn-primary">
            <b>{{ 'tlacitko.pokracovat' | lokalizace }}</b>
            <i class="material-icons right">arrow_forward</i>
        </button>
    </div>
</div>
