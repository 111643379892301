import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KrokObjednavky} from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import {NastrojeTabu} from '../../../../../shared/nastroje/nastroje-tabu';
import {NastrojeOdrolovani} from '../../../../../shared/nastroje/nastroje-odrolovani';
import {VytvoritObjednavkuService} from '../../../../../service/e-shop/vytvorit-objednavku.service';
import {ZpusobVytvoreniObjednavky} from '../../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';

@Component({
    selector: 'app-vytvorit-objednavku-operatora-wizard',
    templateUrl: './vytvorit-objednavku-operatora-wizard.component.html',
    styleUrls: ['./vytvorit-objednavku-operatora-wizard.component.scss']
})
export class VytvoritObjednavkuOperatoraWizardComponent implements OnInit {

    @ViewChild('wizard', {static: false}) public wizard: ElementRef;

    public readonly krokObjednavky = KrokObjednavky;
    public readonly zpusobVytvoreniObjednavky = ZpusobVytvoreniObjednavky;

    public jeWizardInicializovan = false;

    constructor(
        private vytvoritObjednavkuService: VytvoritObjednavkuService
    ) {
    }

    ngOnInit() {
        this.vytvoritObjednavkuService.inicializovatObjednavku();
        this.inicializovatWizard();
    }

    public prejitNaKartu(idKarty: string): void {
        NastrojeTabu.prejitNaKartu(idKarty);
        NastrojeOdrolovani.odrolovat('zacatek-stranky');
    }

    private inicializovatWizard(): void {
        NastrojeTabu.inicializovatTaby();
        this.jeWizardInicializovan = true;
    }
}
