import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {prostredi} from '../../../../prostredi/prostredi';
import verzeJson from '../../../../version.json';
import {Verze} from '../../../data/obecne/verze';
import {VerzeBackenduService} from '../../../service/obecne/verze-backendu.service';
import {NastrojeDataACasu} from '../../nastroje/nastroje-data-a-casu';
import {NastrojeModalnihoPanelu} from '../../nastroje/nastroje-modalniho-panelu';

@Component({
    selector: 'app-paticka',
    templateUrl: './paticka.component.html',
    styleUrls: ['./paticka.component.scss', './paticka.component.ie.scss']
})
export class PatickaComponent implements OnInit {

    public readonly urlPODO: string = prostredi.urlPODO;

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    public aktualniRok: Date = new Date();
    public formatRoku: string = NastrojeDataACasu.FORMAT_ROKU;
    public verzeBackendu: Verze;
    public verzeFrontendu: string;
    public zakladniUrl: string = prostredi.zakladniUrl;

    constructor(private verzeBackenduService: VerzeBackenduService) {
    }

    ngOnInit() {
        this.inicializovatModalniPanel();
    }

    public inicializovat(): void {
        this.pripravitVerziBackendu();
        this.pripravitVerziFrontendu();
    }

    public pripravitVerziBackendu() {
        return this.verzeBackenduService.pripravitCisloVerzeBackendu()
            .subscribe(data => {
                this.verzeBackendu = data;
            });
    }

    public pripravitVerziFrontendu() {
        this.verzeFrontendu = (<any>verzeJson).version;
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }
}
