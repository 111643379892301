import {Lokalizace} from './lokalizace';

declare var M: any;

export class Notifikace {

    private static readonly pocetMilisekundNaZnakTextu = 80;
    private static readonly minimalniDobaZobrazeniZpravy = 3000;

    public static zobrazitZpravu(uroven: Uroven, text: string): void {
        M.toast({
            displayLength: Math.max(text.length * this.pocetMilisekundNaZnakTextu, this.minimalniDobaZobrazeniZpravy),
            classes: this.pripravitBarvu(uroven),
            html: `<div>${text}</div><i class="material-icons">${this.pripravitIkonu(uroven)}</i>`
        });
    }

    public static zobrazitLokalizovanouZpravu(uroven: Uroven, klicTextu: string, ...parametry: any[]): void {
        const text: string = Lokalizace.formatovatText(klicTextu.toString(), ...parametry);

        this.zobrazitZpravu(uroven, text);
    }

    private static pripravitBarvu(uroven: Uroven): string {
        switch (+uroven) {
            case Uroven.USPECH:
                return 'green';
            case Uroven.INFORMACE:
                return 'blue';
            case Uroven.VAROVANI:
                return 'orange';
            case Uroven.CHYBA:
                return 'red';
            default:
                return '#3276B1';
        }
    }

    private static pripravitIkonu(uroven: Uroven): string {
        switch (+uroven) {
            case Uroven.USPECH:
                return 'check_circle';
            case Uroven.INFORMACE:
                return 'info';
            case Uroven.VAROVANI:
                return 'warning';
            case Uroven.CHYBA:
                return 'cancel';
            default:
                return 'info';
        }
    }
}

export enum Uroven {
    USPECH,
    INFORMACE,
    VAROVANI,
    CHYBA
}
