import {ZahranicniStatEu} from '../../data/organizace/enum/zahranicni-stat-eu.enum';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeDic {

    public static jeNeplatceDph(dic: string): boolean {
        if (!NastrojeObecne.jeHodnotaPrazdna(dic)) {
            const statZDic = dic.substr(0, 2);

            return ZahranicniStatEu[statZDic];
        }

        return false;
    }
}
