var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Polozka } from '../../data/obecne/polozka';
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
import { prostredi } from '../../../prostredi/prostredi';
var ProduktService = /** @class */ (function (_super) {
    __extends(ProduktService, _super);
    function ProduktService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopProdukty;
        return _this;
    }
    ProduktService.prototype.nacistProdukt = function (nazevProduktu, vybranyTypPredplatneho) {
        var parametry = [new Polozka('id', nazevProduktu), new Polozka('typPredplatneho', vybranyTypPredplatneho.toString().toLocaleLowerCase())];
        return this.pozadavekSTokenem(TypPozadavku.GET, 'detail', null, parametry)
            .catch(this.zpracovatChybu);
    };
    ProduktService.prototype.nacistSeznamProduktu = function () {
        return this.pozadavekSTokenem(TypPozadavku.GET, 'seznam')
            .catch(this.zpracovatChybu);
    };
    return ProduktService;
}(AbstraktniService));
export { ProduktService };
