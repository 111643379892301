import {Component} from '@angular/core';
import {SeznamObjednavekNastaveniService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import {NastrojeStrankovani} from '../../../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-objednavek-strankovani',
    templateUrl: './seznam-objednavek-strankovani.component.html',
    styleUrls: ['./seznam-objednavek-strankovani.component.scss', 'seznam-objednavek-strankovani.component.ie.scss']
})
export class SeznamObjednavekStrankovaniComponent {

    constructor(private seznamObjednavekNastaveniService: SeznamObjednavekNastaveniService, private seznamObjednavekService: SeznamObjednavekService) {
    }

    public predchoziStranka() {
        const hodnota = this.pripravitHodnotu();
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamObjednavekService.aktualizovat();
    }

    public dalsiStranka() {
        const hodnota = this.pripravitHodnotu();
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamObjednavekService.aktualizovat();
    }

    public jePrvniStranka() {
        return this.pripravitHodnotu() === 1;
    }

    public jePosledniStranka() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    }

    public pripravitCelkovyPocetZaznamu() {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    }

    public pripravitMaximalniPocetStranek() {
        const seznamObjednavek = this.seznamObjednavekService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    private pripravitHodnotu() {
        return this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

}
