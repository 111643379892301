import { OnInit } from '@angular/core';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
var SeznamObjednavekOperatoraComponent = /** @class */ (function () {
    function SeznamObjednavekOperatoraComponent(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
    }
    SeznamObjednavekOperatoraComponent.prototype.ngOnInit = function () {
        this.seznamObjednavekOperatoraNastaveniService.inicializovat();
        this.seznamObjednavekOperatoraService.aktualizovat(false);
    };
    return SeznamObjednavekOperatoraComponent;
}());
export { SeznamObjednavekOperatoraComponent };
