<footer class="footer">
    <div class="footer__newsletter">
        <p>
            <a class="footer__newsletter__button" data-token="5e8da51b41533bc08eec433e214b3472" onclick="mjOpenPopin(event, this)">
                {{ 'paticka.prihlasitKOdberuNovinek' | lokalizace }}
                <i class="small material-icons">notifications_active</i>
            </a>
        </p>

        <data id="mj-w-res-data" data-token="5e8da51b41533bc08eec433e214b3472" class="mj-w-data" data-apikey="3Fkf" data-w-id="jGF" data-lang="en_US" data-base="https://app.mailjet.com" data-width="640" data-height="328" data-statics="statics"></data>
    </div>

    <div class="footer__content">
        <div class="footer__companies">
            <a href="https://tenderarena.cz" target="_blank">
                <img src="{{zakladniUrl}}assets/img/loga/TenderArena-logo.png" alt="{{ 'popisek.obrazek.ta' | lokalizace }}">
            </a>
            <a href="https://ezak.cz" target="_blank">
                <img src="{{zakladniUrl}}assets/img/loga/E-ZAK-logo.png" alt="{{ 'popisek.obrazek.ezak' | lokalizace }}">
            </a>
            <a href="https://www.vhodne-uverejneni.cz" target="_blank">
                <img src="{{zakladniUrl}}assets/img/loga/PVU-logo.png" alt="{{ 'popisek.obrazek.pvu' | lokalizace }}">
            </a>
            <a href="https://www.tendermarket.cz" target="_blank">
                <img src="{{zakladniUrl}}assets/img/loga/TenderMarket-logo.png" alt="{{ 'popisek.obrazek.tm' | lokalizace }}">
            </a>
            <a href="https://www.gemin.cz" target="_blank">
                <img src="{{zakladniUrl}}assets/img/loga/Gemin-logo.png" alt="{{ 'popisek.obrazek.gemin' | lokalizace }}">
            </a>
        </div>

        <div class="footer__separator"></div>

        <div class="footer__info">
            <div class="footer__operator-info">
                <h4 class="heading-4">
                    {{ 'paticka.informaceOProvozovateli' | lokalizace }}
                </h4>

                <p class="footer__subheading">{{ 'paticka.nazevSystemu' | lokalizace }}</p>

                <div class="footer__email">
                    <i class="material-icons">email</i>
                    <p><a href="mailto:informace@fen.cz">informace@fen.cz</a></p>
                </div>
                <div class="footer__email">
                    <i class="material-icons">send</i>{{ 'paticka.idDatoveSchranky' | lokalizace }}
                </div>
            </div>

            <div class="footer__helpline">
                <h4 class="heading-4">
                    {{ 'paticka.helpdesk' | lokalizace }}
                </h4>

                <p class="footer__subheading">{{ 'paticka.mateDotaz' | lokalizace }}</p>

                <div class="footer__helpline-contacts">
                    <i class="material-icons">phone</i>
                    <p><a href="tel:+420515917947">+420 515 917 947</a></p>
                    <p><a href="tel:+420226258888">+420 226 258 888</a><span>(Tender arena, TENDERMARKET)</span></p>
                    <p><a href="tel:+420538702719">+420 538 702 719</a><span>(E-ZAK, Gemin)</span></p>
                    <p><a href="tel:+420538702705">+420 538 702 705</a><span>(PVU)</span></p>
                    <i class="material-icons">mail_outline</i>
                    <p><a href="mailto:podpora@fen.cz">podpora@fen.cz</a></p>
                </div>
            </div>

            <div class="footer__sledujte-nas">
                <h4 class="heading-4">
                    {{ 'paticka.sledujteNas' | lokalizace }}
                </h4>

                <div class="footer__social-media">
                    <a href="https://x.com/FEN2018a" target="_blank">
                        <img src="{{zakladniUrl}}assets/img/ikony/socialni-site/x-logo-black-round.png" alt="{{ 'popisek.obrazek.x' | lokalizace }}">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="footer__copyright">
        <div class="footer__copyright__logo">
            <img src="{{zakladniUrl}}assets/img/loga/FEN-logo-small.png" alt="{{ 'popisek.obrazek.fen' | lokalizace }}">
        </div>
        <div class="footer__copyright__company">
            <p>&copy; {{ aktualniRok | datum: formatRoku }} {{ 'paticka.nazevSpolecnosti' | lokalizace }}</p>
        </div>
        <div class="footer__copyright__links">
            <i class="material-icons">more_vert</i>
            <a href="https://podo.fen.cz/resources/podo-vop.pdf" target="_blank">{{ 'paticka.obchodniPodminky' | lokalizace }}</a>
            <i class="material-icons">more_vert</i>
            <a href="https://storage.googleapis.com/cdd-public-soubory/provozni-rad-portalu-fen.pdf" target="_blank">{{ 'paticka.provozniRad' | lokalizace }}</a>
            <i class="material-icons">more_vert</i>
            <a class="modal-trigger" href="#o-aplikaci-modalni-panel" (click)="inicializovat()">{{ 'paticka.oAplikaci' | lokalizace }}</a>
        </div>

        <div class="footer__copyright__apps">
            <a href="https://play.google.com/store/apps/details?id=cz.fen.mobileapp" target="_blank">
                <img src="/assets/img/ikony/mobilni-aplikace/android-32x32-black.png" alt="{{ 'popisek.obrazek.googlePlay' | lokalizace }}">
            </a>

            <a href="https://apps.apple.com/cz/app/veřejné-zakázky/id1478878066" target="_blank">
                <img src="/assets/img/ikony/mobilni-aplikace/apple-32x32-black.png" alt="{{ 'popisek.obrazek.appStore' | lokalizace }}">
            </a>
        </div>
    </div>
</footer>

<div #modalniPanel id="o-aplikaci-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'paticka.oAplikaci' | lokalizace }}
    </div>
    <section class="info-container">
        <div class="info-group span-2">
            <div class="info-row">
                <label class="row">{{ 'paticka.oAplikaci.verzeBackendu' | lokalizace }}</label>
                <p *ngIf="verzeBackendu">{{verzeBackendu.cisloVerze}}</p>
            </div>
            <div class="info-row">
                <label>{{ 'paticka.oAplikaci.verzeFrontendu' | lokalizace }}</label>
                <p>{{verzeFrontendu}}</p>
            </div>
        </div>
    </section>
    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
    </div>
</div>
