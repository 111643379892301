import {FormGroup} from '@angular/forms';
import {NastrojeFormulare} from './nastroje-formulare';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeTabulkyProduktu {

    public static aktualizovatVybraneProduktyVeFormulari(formGroup: FormGroup, vybraneProdukty: string[]): void {
        Object.keys(formGroup.controls).forEach(klic => NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(formGroup.controls[klic], vybraneProdukty.includes(klic)));
    }

    public static hromadneNastavitStavVsemProduktum(formGroup: FormGroup, stav: boolean): void {
        Object.keys(formGroup.controls).forEach(klic => NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(formGroup.controls[klic], stav));
    }

    public static pripravitKolekciVybranychProduktu(formGroup: FormGroup): string[] {
        const vybraneProdukty: string[] = [];

        Object.keys(formGroup.controls).forEach(klic => {
            if (!NastrojeObecne.jeHodnotaPrazdna(formGroup.controls[klic].value) && formGroup.controls[klic].value) {
                vybraneProdukty.push(klic);
            }
        });

        return vybraneProdukty;
    }
}
