var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SlevovyKupon } from '../../../../data/e-shop/slevovy-kupon';
import { NastrojeModalnihoPanelu } from '../../../../shared/nastroje/nastroje-modalniho-panelu';
import { ProduktService } from '../../../../service/e-shop/produkt.service';
import { TypUceluFormulare } from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AbstraktniFormularComponent } from '../../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../../service/bezpecnost/autentizace.service';
import { NastrojeTabulkyProduktu } from '../../../../shared/nastroje/nastroje-tabulky-produktu';
import { TypPredplatneho } from '../../../../data/e-shop/enum/predplatne.enum';
var SlevovyKuponTabulkaProduktuComponent = /** @class */ (function (_super) {
    __extends(SlevovyKuponTabulkaProduktuComponent, _super);
    function SlevovyKuponTabulkaProduktuComponent(formBuilder, autentizaceService, produktService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.produktService = produktService;
        _this.vytvorit = false;
        _this.odeslan = false;
        _this.produkty = [];
        _this.zobrazitVrtitko = false;
        return _this;
    }
    SlevovyKuponTabulkaProduktuComponent.prototype.ngOnInit = function () {
        if (this.vytvorit) {
            this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
            this.inicializovatModalniPanel();
        }
        this.pripravitSeznamProduktu();
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.odeslat = function () {
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.pripravitNazevProduktu = function (klicProduktu) {
        return this.produkty.find(function (produkt) { return produkt.identifikator === klicProduktu; }).nazev;
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.smazatProdukt = function (index) {
        this.slevovyKupon.produkty.splice(index, 1);
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.otevritModalniPanel = function () {
        NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
        NastrojeTabulkyProduktu.aktualizovatVybraneProduktyVeFormulari(this.formGroup, this.slevovyKupon.produkty);
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.hromadneNastavitStavVsemProduktum = function (stav) {
        NastrojeTabulkyProduktu.hromadneNastavitStavVsemProduktum(this.formGroup, stav);
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.pridatProdukty = function () {
        this.slevovyKupon.produkty = NastrojeTabulkyProduktu.pripravitKolekciVybranychProduktu(this.formGroup);
        this.zavritModalniPanel();
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.zavritModalniPanel = function () {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.pripravitSeznamProduktu = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.produktService.nacistSeznamProduktu().subscribe(function (data) {
            _this.produkty = data.filter(function (produkt) { return !produkt.dostupnePredplatne.includes(TypPredplatneho.NA_MIRU); });
            _this.zobrazitVrtitko = false;
            if (_this.vytvorit) {
                _this.inicializovatFormularovaData();
            }
        }, function () { return _this.zobrazitVrtitko = false; });
    };
    SlevovyKuponTabulkaProduktuComponent.prototype.inicializovatFormularovaData = function () {
        var _this = this;
        this.produkty.forEach(function (produkt) { return _this.formGroup.addControl(produkt.identifikator, new FormControl('', [])); });
    };
    return SlevovyKuponTabulkaProduktuComponent;
}(AbstraktniFormularComponent));
export { SlevovyKuponTabulkaProduktuComponent };
