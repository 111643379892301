var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniVystupFormularovyPrvekComponent } from '../abstraktni-vystup-formularovy-prvek.component';
/**
 * Pouziti:
 *  <app-formular-output
 *  [popisek]="'Popisek'"
 *  [hodnota]="hodnota"
 * </app-formular-output>
 */
var FormularOutputComponent = /** @class */ (function (_super) {
    __extends(FormularOutputComponent, _super);
    function FormularOutputComponent() {
        return _super.call(this) || this;
    }
    return FormularOutputComponent;
}(AbstraktniVystupFormularovyPrvekComponent));
export { FormularOutputComponent };
