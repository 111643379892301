/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-operatora-aktualni-stranka.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../shared/nastroje/directives/pouze-cislice.directive";
import * as i3 from "@angular/common";
import * as i4 from "./seznam-objednavek-operatora-aktualni-stranka.component";
import * as i5 from "../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service";
import * as i6 from "../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service";
var styles_SeznamObjednavekOperatoraAktualniStrankaComponent = [i0.styles];
var RenderType_SeznamObjednavekOperatoraAktualniStrankaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekOperatoraAktualniStrankaComponent, data: {} });
export { RenderType_SeznamObjednavekOperatoraAktualniStrankaComponent as RenderType_SeznamObjednavekOperatoraAktualniStrankaComponent };
function View_SeznamObjednavekOperatoraAktualniStrankaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "current-page input-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["vstupniPole", 1]], null, 1, "input", [["class", "active"], ["onclick", "this.select()"], ["pouzeCislice", ""]], [[8, "value", 0], [8, "disabled", 0]], [[null, "change"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).inputChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).inputChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.nastavitStranku() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.PouzeCisliceDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["disabled", ""]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.pripravitAktualniStranku(), ""); var currVal_1 = (_co.pripravitCekovyPocetZaznamu() === undefined); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, " / ", _co.pripravitMaximalniPocetStranekProZobrazeni(), ""); _ck(_v, 3, 0, currVal_2); }); }
export function View_SeznamObjednavekOperatoraAktualniStrankaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { vstupniPole: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeznamObjednavekOperatoraAktualniStrankaComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pripravitMaximalniPocetStranekProZobrazeni() > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SeznamObjednavekOperatoraAktualniStrankaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-aktualni-stranka", [], null, null, null, View_SeznamObjednavekOperatoraAktualniStrankaComponent_0, RenderType_SeznamObjednavekOperatoraAktualniStrankaComponent)), i1.ɵdid(1, 49152, null, 0, i4.SeznamObjednavekOperatoraAktualniStrankaComponent, [i5.SeznamObjednavekOperatoraNastaveniService, i6.SeznamObjednavekOperatoraService], null, null)], null, null); }
var SeznamObjednavekOperatoraAktualniStrankaComponentNgFactory = i1.ɵccf("app-seznam-objednavek-operatora-aktualni-stranka", i4.SeznamObjednavekOperatoraAktualniStrankaComponent, View_SeznamObjednavekOperatoraAktualniStrankaComponent_Host_0, {}, {}, []);
export { SeznamObjednavekOperatoraAktualniStrankaComponentNgFactory as SeznamObjednavekOperatoraAktualniStrankaComponentNgFactory };
