import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SeznamUzivateluSDodavatelem} from '../../../data/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem';
import {NastrojeJsonu} from '../../../shared/nastroje/nastroje-jsonu';
import {AutentizaceService} from '../../bezpecnost/autentizace.service';
import {TypPozadavku} from '../../obecne/abstraktni-service';
import {AbstraktniSeznamService} from '../abstraktni-seznam-service';
import {SeznamUzivateluSDodavatelemNastaveniService} from './seznam-uzivatelu-s-dodavatelem-nastaveni.service';

@Injectable()
export class SeznamUzivateluSDodavatelemService extends AbstraktniSeznamService {

    protected resourceClassUri = '/uzivatele';

    public seznam: SeznamUzivateluSDodavatelem;

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService, private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService) {
        super(http, autentizaceService);
    }

    public aktualizovat(odrolovatNaZacatekSeznamu: boolean = true): void {
        this.seznam = new SeznamUzivateluSDodavatelem();

        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-s-dodavatelem', NastrojeJsonu.stringify(this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(odpoved => {
                this.seznam = odpoved;
            }).catch(this.zpracovatChybu);

        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    }
}
