import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {SlevovyKupon} from '../../../data/e-shop/slevovy-kupon';
import {TypUceluFormulare} from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {AbstraktniFormularComponent} from '../../../shared/formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {NastrojeObecne} from '../../../shared/nastroje/nastroje-obecne';
import {Notifikace, Uroven} from '../../../shared/notifikace';
import {NastrojeSlevovehoKuponu} from '../../../shared/nastroje/nastroje-slevoveho-kuponu';
import {SlevovyKuponService} from '../../../service/e-shop/slevovy-kupon.service';

@Component({
    selector: 'app-vytvorit-slevovy-kupon',
    templateUrl: './vytvorit-slevovy-kupon.component.html',
    styleUrls: ['./vytvorit-slevovy-kupon.component.scss']
})
export class VytvoritSlevovyKuponComponent extends AbstraktniFormularComponent implements OnInit {

    public slevovyKupon: SlevovyKupon;
    public zobrazitPotvrzeni = false;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        protected slevovykuponService: SlevovyKuponService,
        private router: Router
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.pripravitNovySlevovyKupon();
    }

    public odeslat(): void {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon.produkty) && this.slevovyKupon.produkty.length === 0) {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'slevovyKupon.validace.produkty');
            return;
        }

        this.zobrazitVrtitko = true;
        this.slevovyKupon = NastrojeSlevovehoKuponu.pripravitSlevovyKuponZFormulare(this.formGroup, this.slevovyKupon);

        this.slevovykuponService.vytvoritSlevovyKupon(this.slevovyKupon)
            .toPromise()
            .then(() => {
                this.zobrazitPotvrzeni = true;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    public prejitZpet(): void {
        this.router.navigate(['/slevove-kupony']);
    }

    private pripravitNovySlevovyKupon(): void {
        this.slevovyKupon = NastrojeSlevovehoKuponu.pripravitNovySlevovyKupon();
    }
}
