<div *ngIf="faktury.length !== 0">
    <h3 class="info-title span-2">{{ 'faktury.nadpis' | lokalizace }}</h3>

    <div class="container__content-body span-2">
        <table>
            <thead>
            <tr class="invoices-table-layout">
                <th>{{ 'faktury.tabulka.datumVystaveni' | lokalizace }}</th>
                <th class="hide-on-med-and-down">{{ 'faktury.tabulka.nazev' | lokalizace }}</th>
                <th>{{ 'faktury.tabulka.castka' | lokalizace }}</th>
                <th>{{ 'faktury.tabulka.keStazeni' | lokalizace }}</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let radek of zobrazeneFaktury">
                <td>{{ radek.datumVytvoreniFaktury | datum: formatData }}</td>
                <td class="hide-on-med-and-down">{{ radek.identifikatorFaktury  }}</td>
                <td>{{ radek.cena?.cenaSDph | currency: 'CZK': 'symbol-narrow': '1.2-2':'cs' }}</td>
                <td><a (click)=stahnoutFakturu(radek.identifikatorFaktury)><img src="{{zakladniUrl}}assets/img/ikony/soubory/file_extension_pdf.png"
                         alt="{{ 'popisek.obrazek.pdf' | lokalizace }}"></a></td>
            </tr>
            </tbody>
        </table>

        <section *ngIf="faktury.length > pocetZaznamuNaStranku" class="detail-card">
            <div class="current-page input-field">
                <input #vstupniPole class="active" onclick="this.select()" value="{{indexAktualniStranky}}" (change)="nastavitStranku()" pouzeCislice>
                <input value=" / {{ maximalniPocetStranek }}" disabled>
            </div>
            <div class="pagination">
                <button (click)="predchoziStranka()"
                        [class.disabled]="jeStrankaPrvni"
                        class="btn waves-effect waves-light">
                    <i class="material-icons left">keyboard_arrow_left</i>
                    <b>{{ 'tlacitko.predchozi' | lokalizace }}</b>
                </button>
                <button (click)="dalsiStranka()"
                        [class.disabled]="jeStrankaPosledni"
                        class="btn waves-effect waves-light">
                    <b>{{ 'tlacitko.dalsi' | lokalizace }}</b>
                    <i class="material-icons right">keyboard_arrow_right</i>
                </button>
            </div>
        </section>
    </div>
</div>
