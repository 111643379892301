var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstraktniFiltrComponent } from '../../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import { AutentizaceService } from '../../../../../../service/bezpecnost/autentizace.service';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
var SeznamObjednavekOperatoraFiltrZakladniComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraFiltrZakladniComponent, _super);
    function SeznamObjednavekOperatoraFiltrZakladniComponent(formBuilder, autentizaceService, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        var _this = _super.call(this, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        _this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
        return _this;
    }
    SeznamObjednavekOperatoraFiltrZakladniComponent.prototype.ngOnInit = function () {
        this.inicializovatFormularovaData();
    };
    SeznamObjednavekOperatoraFiltrZakladniComponent.prototype.odeslat = function () {
    };
    SeznamObjednavekOperatoraFiltrZakladniComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('nazevOrganizace', new FormControl('', [
            Validators.maxLength(255)
        ]));
        this.formGroup.addControl('ic', new FormControl('', [
            Validators.maxLength(20)
        ]));
        this.formGroup.addControl('email', new FormControl('', [
            Validators.maxLength(100)
        ]));
        this.formGroup.addControl('prihlasovaciJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('jmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('prijmeni', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('prihlasovaciJmenoOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('jmenoOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));
        this.formGroup.addControl('prijmeniOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));
    };
    return SeznamObjednavekOperatoraFiltrZakladniComponent;
}(AbstraktniFiltrComponent));
export { SeznamObjednavekOperatoraFiltrZakladniComponent };
