var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ZruseniObjednavky } from '../../../../data/e-shop/zruseni-objednavky';
import { AbstraktniFormularComponent } from '../../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeModalnihoPanelu } from '../../../../shared/nastroje/nastroje-modalniho-panelu';
import { Objednavka } from '../../../../data/e-shop/objednavka';
import { ObjednavkaService } from '../../../../service/e-shop/objednavka.service';
var ZrusitObjednavkuModalniPanelComponent = /** @class */ (function (_super) {
    __extends(ZrusitObjednavkuModalniPanelComponent, _super);
    function ZrusitObjednavkuModalniPanelComponent(formBuilder, autentizaceService, objednavkaService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.objednavkaService = objednavkaService;
        _this.zruseno = new EventEmitter();
        return _this;
    }
    ZrusitObjednavkuModalniPanelComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatModalniPanel();
        this.inicializovatFormularovaData();
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.odeslat = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.objednavkaService.zrusitObjednavku(this.pripravitDataZModalu()).toPromise().then(function () {
            _this.zobrazitVrtitko = false;
            _this.zruseno.emit();
        }).catch(function () { return _this.zobrazitVrtitko = false; });
        this.zavritModalniPanel();
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.pripravitDataZModalu = function () {
        return new ZruseniObjednavky(this.objednavka.identifikator, this.duvodZruseni);
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('duvodZruseni', new FormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]));
        this.inicializovatPosluchaceZmenHodnot();
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.inicializovatPosluchaceZmenHodnot = function () {
        var _this = this;
        this.formGroup.controls.duvodZruseni.valueChanges.subscribe(function (duvodZruseni) {
            _this.duvodZruseni = duvodZruseni;
        });
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    ZrusitObjednavkuModalniPanelComponent.prototype.zavritModalniPanel = function () {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    };
    return ZrusitObjednavkuModalniPanelComponent;
}(AbstraktniFormularComponent));
export { ZrusitObjednavkuModalniPanelComponent };
