var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
import { prostredi } from '../../../prostredi/prostredi';
var OnlinePlatbyService = /** @class */ (function (_super) {
    __extends(OnlinePlatbyService, _super);
    function OnlinePlatbyService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopOnlinePlatby;
        return _this;
    }
    OnlinePlatbyService.prototype.vytvoritPlatbu = function (objednavka) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zadani-platby/', JSON.stringify(objednavka))
            .catch(this.zpracovatChybu);
    };
    OnlinePlatbyService.prototype.nacistStavPlatby = function (idPlatby) {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/stav-platby/' + idPlatby)
            .catch(this.zpracovatChybu);
    };
    OnlinePlatbyService.prototype.nacistPosledniPlatbuPodleObjednavky = function (identifikatorObjednavky) {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/nacist-posledni-platbu/' + identifikatorObjednavky)
            .catch(this.zpracovatChybu);
    };
    OnlinePlatbyService.prototype.zrusitOpakovanePlatby = function (idPlatby) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zruseni-opakovane-platby/' + idPlatby)
            .catch(this.zpracovatChybu);
    };
    return OnlinePlatbyService;
}(AbstraktniService));
export { OnlinePlatbyService };
