var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { AbstraktniFormularComponent } from '../../../../../../../../shared/formular/abstraktni-formular.component';
import { TypPoctuZaznamuNaStranku } from '../../../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AutentizaceService } from '../../../../../../../../service/bezpecnost/autentizace.service';
import { SeznamObjednavekNastaveniService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import { SeznamObjednavekService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import { TypUceluFormulare } from '../../../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeEnumu } from '../../../../../../../../shared/nastroje/nastroje-enumu';
import { NastrojeModalnihoPanelu } from '../../../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { NastrojeStrankovani } from '../../../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamObjednavekNastaveniComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekNastaveniComponent, _super);
    function SeznamObjednavekNastaveniComponent(formBuilder, autentizaceService, seznamObjednavekNastaveniService, seznamObjednavekService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        _this.seznamObjednavekService = seznamObjednavekService;
        _this.typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;
        return _this;
    }
    SeznamObjednavekNastaveniComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    };
    SeznamObjednavekNastaveniComponent.prototype.odeslat = function () {
        var pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamObjednavekNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(1);
        this.seznamObjednavekService.aktualizovat();
    };
    SeznamObjednavekNastaveniComponent.prototype.inicializovatFormularovaData = function () {
        var pocetZaznamuNaStranku = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;
        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    };
    SeznamObjednavekNastaveniComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    SeznamObjednavekNastaveniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekNastaveniComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    };
    return SeznamObjednavekNastaveniComponent;
}(AbstraktniFormularComponent));
export { SeznamObjednavekNastaveniComponent };
