import {Component} from '@angular/core';
import {AbstraktniRazeniComponent} from '../../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {TypRazeni} from '../../../../../../../data/obecne/enum/typ-razeni.enum';
import {AtributRazeniObjednavekOperatora} from '../../../../../../../data/obecne/enum/atribut-razeni-objednavek-operatora.enum';

@Component({
    selector: 'app-seznam-objednavek-operatora-razeni',
    templateUrl: './seznam-objednavek-operatora-razeni.component.html',
    styleUrls: ['./seznam-objednavek-operatora-razeni.component.scss']
})
export class SeznamObjednavekOperatoraRazeniComponent extends AbstraktniRazeniComponent {

    public atributyRazeni = AtributRazeniObjednavekOperatora;

    public typyRazeni = TypRazeni;

    constructor(private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService, private seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService) {
        super(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService);
    }
}
