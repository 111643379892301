import { Adresa } from '../../data/organizace/adresa';
import { NastrojeFormulare } from './nastroje-formulare';
import { NastrojeObecne } from './nastroje-obecne';
import { NastrojeEnumu } from './nastroje-enumu';
import { Lokalizace } from '../lokalizace';
import { Stat } from '../../data/organizace/enum/stat.enum';
var NastrojeAdresy = /** @class */ (function () {
    function NastrojeAdresy() {
    }
    NastrojeAdresy.prevestNaRetezec = function (adresa, zobrazitTypAdresy) {
        if (zobrazitTypAdresy === void 0) { zobrazitTypAdresy = false; }
        if (adresa != null) {
            var retezecAdresy = adresa.ulice + ' ' + adresa.cisloPopisne;
            if (!NastrojeObecne.jeHodnotaPrazdna(adresa.cisloOrientacni)) {
                retezecAdresy += '/' + adresa.cisloOrientacni;
            }
            retezecAdresy += ', ' + adresa.psc + ' ' + adresa.obec;
            if (!NastrojeObecne.jeHodnotaPrazdna(adresa.castObce)) {
                retezecAdresy += ' - ' + adresa.castObce;
            }
            retezecAdresy += ', ' + Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech('stat', adresa.stat));
            if (zobrazitTypAdresy) {
                retezecAdresy += ' (' + Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech('typAdresy', adresa.typAdresy)) + ')';
            }
            return retezecAdresy;
        }
        return '';
    };
    NastrojeAdresy.pripravitAdresuZFormGroup = function (formGroup, typAdresy) {
        var adresa = new Adresa();
        adresa.ulice = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.ulice.value);
        adresa.cisloPopisne = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.cisloPopisne.value);
        adresa.cisloOrientacni = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.cisloOrientacni.value);
        adresa.obec = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.obec.value);
        adresa.castObce = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.castObce.value);
        adresa.psc = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.psc.value);
        adresa.stat = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.stat.value);
        adresa.typAdresy = typAdresy;
        return adresa;
    };
    NastrojeAdresy.nastavitVychoziHodnotuStatu = function (formGroup) {
        formGroup.controls.stat.setValue(Stat.CZ);
    };
    return NastrojeAdresy;
}());
export { NastrojeAdresy };
