import {Component, ElementRef, ViewChild} from '@angular/core';
import {NastrojeStrankovani} from '../../../../../../../../shared/nastroje/nastroje-strankovani';
import {NastrojeCisla} from '../../../../../../../../shared/nastroje/nastroje-cisla';
import {SeznamObjednavekNastaveniService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';

declare var $: any;

@Component({
  selector: 'app-seznam-objednavek-aktualni-stranka',
  templateUrl: './seznam-objednavek-aktualni-stranka.component.html',
  styleUrls: ['./seznam-objednavek-aktualni-stranka.component.scss']
})

export class SeznamObjednavekAktualniStrankaComponent {

    @ViewChild('vstupniPole', {static: false}) public vstupniPole: ElementRef;

    private maximalniPocetStranekProZobrazeni: number;

    constructor(private seznamObjednavekNastaveniService: SeznamObjednavekNastaveniService, private seznamObjednavekService: SeznamObjednavekService) {
    }

    public pripravitAktualniStranku(): number {
        return this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamObjednavek = this.seznamObjednavekService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    public nastavitStranku() {
        const element = $(this.vstupniPole.nativeElement);
        let hodnota = element.val();

        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }

        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (hodnota <= 0) {
            hodnota = 1;
        } else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }

        element.blur();
        element.val(hodnota);
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamObjednavekService.aktualizovat();
    }

    public pripravitMaximalniPocetStranekProZobrazeni() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }

        return this.maximalniPocetStranekProZobrazeni;
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    }
}
