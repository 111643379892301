import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SlevovyKupon} from '../../../data/e-shop/slevovy-kupon';
import {SlevovyKuponService} from '../../../service/e-shop/slevovy-kupon.service';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';

@Component({
    selector: 'app-detail-slevoveho-kuponu',
    templateUrl: './detail-slevoveho-kuponu.component.html',
    styleUrls: ['./detail-slevoveho-kuponu.component.scss']
})
export class DetailSlevovehoKuponuComponent implements OnInit {

    public slevovyKupon: SlevovyKupon;
    public zobrazitVrtitko = false;

    private nazevSlevovehoKuponu: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private autentizaceService: AutentizaceService,
        private slevovyKuponService: SlevovyKuponService
    ) {}

    ngOnInit(): void {
        this.zpracovatParametry();
    }

    public prejitNaStrankuUpravit(): void {
        this.router.navigate(['/slevove-kupony/upravit/' + this.nazevSlevovehoKuponu]);
    }

    public prejitZpet(): void {
        this.router.navigate(['/slevove-kupony']);
    }

    private zpracovatParametry(): void {
        this.route.params.subscribe(params => {
            this.nazevSlevovehoKuponu = params['nazev'];

            this.pripravitSlevovyKupon();
        });
    }

    private pripravitSlevovyKupon(): void {
        this.zobrazitVrtitko = true;

        this.slevovyKuponService.nacistSlevovyKupon(this.nazevSlevovehoKuponu)
            .toPromise()
            .then(odpoved => {
                this.slevovyKupon = odpoved;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }
}
