var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstraktniFormularovyPrvekComponent } from '../abstraktni-formularovy-prvek.component';
/**
 * Pouziti:
 *  <app-formular-input
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 *  [typ]="<typ vstupu>"
 * </app-formular-input>
 */
var FormularInputComponent = /** @class */ (function (_super) {
    __extends(FormularInputComponent, _super);
    function FormularInputComponent() {
        var _this = _super.call(this) || this;
        _this.typ = 'text';
        return _this;
    }
    FormularInputComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    return FormularInputComponent;
}(AbstraktniFormularovyPrvekComponent));
export { FormularInputComponent };
