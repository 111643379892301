import {FormGroup} from '@angular/forms';
import {NastrojeDataACasu} from '../../nastroje/nastroje-data-a-casu';
import {NastrojePorovnani} from '../../nastroje/nastroje-porovnani';
import {NastrojeValidace} from '../../nastroje/nastroje-validace';

export class ValidatoryRozsahu {

    private static readonly klicChybyRozsahu = 'rozsahPrvniHodnotaVetsiNezDruha';

    public static rozsah(nazevParametruOd: string, nazevParametruDo: string, typ: string): any {
        return (group: FormGroup) => {
            const prvekFormulareOd = group.controls[nazevParametruOd];
            const prvekFormulareDo = group.controls[nazevParametruDo];

            const hodnotaOd = prvekFormulareOd.value;
            const hodnotaDo = prvekFormulareDo.value;

            if (hodnotaOd && hodnotaDo) {
                if ('Date' === typ) {
                    const datumOd = NastrojeDataACasu.prevestRetezecNaDatum(hodnotaOd, NastrojeDataACasu.FORMAT_DATA);
                    const datumDo = NastrojeDataACasu.prevestRetezecNaDatum(hodnotaDo, NastrojeDataACasu.FORMAT_DATA);

                    if (NastrojePorovnani.porovnatDatum(datumOd, datumDo) === 1) {
                        NastrojeValidace.nastavitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    } else {
                        NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                } else if ('Number' === typ) {
                    if (+hodnotaOd > +hodnotaDo) {
                        NastrojeValidace.nastavitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    } else {
                        NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                }
            } else {
                NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
            }
        };
    }
}
