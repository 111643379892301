import {Injectable} from '@angular/core';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {Objednavka} from '../../data/e-shop/objednavka';
import {Observable} from 'rxjs';
import {prostredi} from '../../../prostredi/prostredi';
import {Platba} from '../../data/e-shop/platba';

@Injectable()
export class OnlinePlatbyService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService
    ) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopOnlinePlatby;
    }

    public vytvoritPlatbu(objednavka: Objednavka): Observable<any> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zadani-platby/', JSON.stringify(objednavka))
            .catch(this.zpracovatChybu);
    }

    public nacistStavPlatby(idPlatby: number): Observable<Platba> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/stav-platby/' + idPlatby)
            .catch(this.zpracovatChybu);
    }

    public nacistPosledniPlatbuPodleObjednavky(identifikatorObjednavky: string): Observable<Platba> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/nacist-posledni-platbu/' + identifikatorObjednavky)
            .catch(this.zpracovatChybu);
    }

    public zrusitOpakovanePlatby(idPlatby: number): Observable<Platba> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zruseni-opakovane-platby/' + idPlatby)
            .catch(this.zpracovatChybu);
    }
}
