import {Component} from '@angular/core';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-hlavicka',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-hlavicka.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-hlavicka.component.scss']
})
export class SeznamUzivateluSDodavatelemHlavickaComponent {

    constructor() {
    }
}
