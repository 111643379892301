import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Objednavka } from '../../../../data/e-shop/objednavka';
import { Stat } from '../../../../data/organizace/enum/stat.enum';
import { NastrojeObecne } from '../../../../shared/nastroje/nastroje-obecne';
import { NastrojeFakturacnichUdaju } from '../../../../shared/nastroje/nastroje-fakturacnich-udaju';
import { ZpusobVytvoreniObjednavky } from '../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
import { Notifikace, Uroven } from '../../../../shared/notifikace';
import { NastrojeAutentizace } from '../../../../shared/nastroje/nastroje-autentizace';
import { VytvoritObjednavkuService } from '../../../../service/e-shop/vytvorit-objednavku.service';
import { FakturacniUdajeService } from '../../../../service/e-shop/fakturacni-udaje.service';
var FakturacniUdajeInputComponent = /** @class */ (function () {
    function FakturacniUdajeInputComponent(formBuilder, fakturacniUdajeService, vytvoritObjednavkuService) {
        this.formBuilder = formBuilder;
        this.fakturacniUdajeService = fakturacniUdajeService;
        this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        this.odeslan = false;
        this.staty = Stat;
        this.zpusobyVytvoreniObjednavky = ZpusobVytvoreniObjednavky;
        this.zobrazitVrtitko = false;
    }
    FakturacniUdajeInputComponent.prototype.ngOnInit = function () {
        this.inicializovatExistujiDataProFormular();
        this.inicializovatFormularovaData();
        if (this.existujiDataProFormular) {
            NastrojeFakturacnichUdaju.pripravitFakturacniUdajeZObjednavky(this.formGroup, this.objednavka);
        }
    };
    FakturacniUdajeInputComponent.prototype.doplnitFakturacniUdajeZCDD = function () {
        this.formGroup.reset();
        this.pripravitFakturacniUdajeZCDDAdministrace(this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.OPERATOREM ?
            this.vytvoritObjednavkuService.objednavka.idUzivatele :
            NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage().informaceOUzivateli.id);
    };
    FakturacniUdajeInputComponent.prototype.inicializovatFormularovaData = function () {
        NastrojeFakturacnichUdaju.inicializovatFormularovaData(this.formGroup, this.vybranyZpusobVytvoreniObjednavky);
    };
    FakturacniUdajeInputComponent.prototype.pripravitFakturacniUdajeZCDDAdministrace = function (idUzivatele) {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.fakturacniUdajeService.nacistFakturacniUdaje(idUzivatele)
            .toPromise()
            .then(function (odpoved) {
            NastrojeFakturacnichUdaju.pripravitFakturacniUdajeZFakturacnichUdaju(_this.formGroup, odpoved);
            _this.zobrazitVrtitko = false;
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'fakturacniUdaje.informace.udajeZCDDDoplneny');
        }, function () { return _this.zobrazitVrtitko = false; });
    };
    FakturacniUdajeInputComponent.prototype.inicializovatExistujiDataProFormular = function () {
        this.existujiDataProFormular = !NastrojeObecne.jeHodnotaPrazdna(this.objednavka);
    };
    return FakturacniUdajeInputComponent;
}());
export { FakturacniUdajeInputComponent };
