import {TypPredplatneho} from '../../../e-shop/enum/predplatne.enum';
import {StavObjednavky} from '../../../e-shop/enum/stav-objednavky.enum';
import {Rozsah} from '../../../obecne/rozsah';

export class FiltrSeznamuObjednavekOperatora {
    identifikator: string;
    prihlasovaciJmeno: string;
    jmeno: string;
    prijmeni: string;
    nazevOrganizace: string;
    email: string;
    ic: string;
    predplatne: TypPredplatneho;
    nazevProduktu: string;
    stavObjednavky: StavObjednavky;
    datumVytvoreni: Rozsah;
    datumSplatnosti: Rozsah;
    prihlasovaciJmenoOperatora: string;
    jmenoOperatora: string;
    prijmeniOperatora: string;
}
