/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formular-output.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../nastroje/pipes/lokalizace.pipe";
import * as i3 from "./formular-output.component";
var styles_FormularOutputComponent = [i0.styles];
var RenderType_FormularOutputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormularOutputComponent, data: {} });
export { RenderType_FormularOutputComponent as RenderType_FormularOutputComponent };
export function View_FormularOutputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "info-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.popisek)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hodnota; _ck(_v, 7, 0, currVal_1); }); }
export function View_FormularOutputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formular-output", [], null, null, null, View_FormularOutputComponent_0, RenderType_FormularOutputComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormularOutputComponent, [], null, null)], null, null); }
var FormularOutputComponentNgFactory = i1.ɵccf("app-formular-output", i3.FormularOutputComponent, View_FormularOutputComponent_Host_0, { styl: "styl", popisek: "popisek", hodnota: "hodnota" }, {}, []);
export { FormularOutputComponentNgFactory as FormularOutputComponentNgFactory };
