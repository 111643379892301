/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-slevovych-kuponu-strankovani.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./seznam-slevovych-kuponu-strankovani.component";
import * as i5 from "../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service";
import * as i6 from "../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service";
var styles_SeznamSlevovychKuponuStrankovaniComponent = [i0.styles];
var RenderType_SeznamSlevovychKuponuStrankovaniComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamSlevovychKuponuStrankovaniComponent, data: {} });
export { RenderType_SeznamSlevovychKuponuStrankovaniComponent as RenderType_SeznamSlevovychKuponuStrankovaniComponent };
function View_SeznamSlevovychKuponuStrankovaniComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["class", "btn waves-effect waves-light"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.predchoziStranka() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "btn waves-effect waves-light"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dalsiStranka() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["class", "material-icons right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_right"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.jePrvniStranka() || (_co.pripravitCekovyPocetZaznamu() === undefined)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "tlacitko.predchozi")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.jePosledniStranka() || (_co.pripravitCekovyPocetZaznamu() === undefined)); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), "tlacitko.dalsi")); _ck(_v, 9, 0, currVal_3); }); }
export function View_SeznamSlevovychKuponuStrankovaniComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeznamSlevovychKuponuStrankovaniComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pripravitMaximalniPocetStranek() !== undefined); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SeznamSlevovychKuponuStrankovaniComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-slevovych-kuponu-strankovani", [], null, null, null, View_SeznamSlevovychKuponuStrankovaniComponent_0, RenderType_SeznamSlevovychKuponuStrankovaniComponent)), i1.ɵdid(1, 49152, null, 0, i4.SeznamSlevovychKuponuStrankovaniComponent, [i5.SeznamSlevovychKuponuNastaveniService, i6.SeznamSlevovychKuponuService], null, null)], null, null); }
var SeznamSlevovychKuponuStrankovaniComponentNgFactory = i1.ɵccf("app-seznam-slevovych-kuponu-strankovani", i4.SeznamSlevovychKuponuStrankovaniComponent, View_SeznamSlevovychKuponuStrankovaniComponent_Host_0, {}, {}, []);
export { SeznamSlevovychKuponuStrankovaniComponentNgFactory as SeznamSlevovychKuponuStrankovaniComponentNgFactory };
