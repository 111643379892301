import { NastrojeStrankovani } from '../../../../../../shared/nastroje/nastroje-strankovani';
import { SeznamSlevovychKuponuNastaveniService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import { SeznamSlevovychKuponuService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
var SeznamSlevovychKuponuStrankovaniComponent = /** @class */ (function () {
    function SeznamSlevovychKuponuStrankovaniComponent(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) {
        this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
    }
    SeznamSlevovychKuponuStrankovaniComponent.prototype.predchoziStranka = function () {
        var hodnota = this.pripravitHodnotu();
        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamSlevovychKuponuService.aktualizovat();
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.dalsiStranka = function () {
        var hodnota = this.pripravitHodnotu();
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamSlevovychKuponuService.aktualizovat();
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.jePrvniStranka = function () {
        return this.pripravitHodnotu() === 1;
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.jePosledniStranka = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.pripravitHodnotu = function () {
        return this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    };
    SeznamSlevovychKuponuStrankovaniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamSlevovychKuponu = this.seznamSlevovychKuponuService.seznam;
        var nastaveniZobrazeniSeznamuSlevovychKuponu = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu);
    };
    return SeznamSlevovychKuponuStrankovaniComponent;
}());
export { SeznamSlevovychKuponuStrankovaniComponent };
