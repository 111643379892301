var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { NastrojeJsonu } from '../../shared/nastroje/nastroje-jsonu';
import { prostredi } from '../../../prostredi/prostredi';
import { Polozka } from '../../data/obecne/polozka';
var SlevovyKuponService = /** @class */ (function (_super) {
    __extends(SlevovyKuponService, _super);
    function SlevovyKuponService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopSlevoveKupony;
        return _this;
    }
    SlevovyKuponService.prototype.nacistSlevovyKupon = function (nazevSlevovehoKuponu) {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/' + nazevSlevovehoKuponu)
            .catch(this.zpracovatChybu);
    };
    SlevovyKuponService.prototype.upravitPlatnostSlevovehoKuponu = function (nazevSlevovehoKuponu, platnost) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zmena-platnosti/' + nazevSlevovehoKuponu, NastrojeJsonu.stringifyRFC3339(platnost))
            .catch(this.zpracovatChybu);
    };
    SlevovyKuponService.prototype.vytvoritSlevovyKupon = function (kupon) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/novy', NastrojeJsonu.stringifyRFC3339(kupon))
            .catch(this.zpracovatChybu);
    };
    SlevovyKuponService.prototype.overitPlatnostSlevovehoKuponu = function (nazevSlevovehoKuponu, vybranyProdukt, vybranyTypPredplatneho) {
        var parametry = [
            new Polozka('nazevSlevovehoKuponu', nazevSlevovehoKuponu),
            new Polozka('vybranyProdukt', vybranyProdukt),
            new Polozka('vybranyTypPredplatneho', vybranyTypPredplatneho)
        ];
        return this.pozadavekSTokenem(TypPozadavku.GET, '/overeni-platnosti', null, parametry)
            .catch(this.zpracovatChybu);
    };
    return SlevovyKuponService;
}(AbstraktniService));
export { SlevovyKuponService };
