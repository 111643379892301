var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { prostredi } from '../../../../prostredi/prostredi';
import { AutentizaceService } from '../../bezpecnost/autentizace.service';
import { AbstraktniSeznamService } from '../abstraktni-seznam-service';
import { SeznamObjednavekOperatoraNastaveniService } from './seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatora } from '../../../data/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora';
import { TypPozadavku } from '../../obecne/abstraktni-service';
import { NastrojeJsonu } from '../../../shared/nastroje/nastroje-jsonu';
var SeznamObjednavekOperatoraService = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraService, _super);
    function SeznamObjednavekOperatoraService(http, autentizaceService, seznamObjednavekOperatoraNastaveniService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopSeznamObjednavek;
        return _this;
    }
    SeznamObjednavekOperatoraService.prototype.aktualizovat = function (odrolovatNaZacatekSeznamu) {
        var _this = this;
        if (odrolovatNaZacatekSeznamu === void 0) { odrolovatNaZacatekSeznamu = true; }
        this.seznam = new SeznamObjednavekOperatora();
        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-objednavek-operatora/', NastrojeJsonu.stringifyISO8601(this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(function (odpoved) {
            _this.seznam = odpoved;
        }).catch(this.zpracovatChybu);
        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    };
    return SeznamObjednavekOperatoraService;
}(AbstraktniSeznamService));
export { SeznamObjednavekOperatoraService };
