import { Router } from '@angular/router';
var SlevoveKuponyComponent = /** @class */ (function () {
    function SlevoveKuponyComponent(router) {
        this.router = router;
    }
    SlevoveKuponyComponent.prototype.presmerovatNaVytvoreniSlevovehoKuponu = function () {
        this.router.navigate(['/slevove-kupony/vytvorit']);
    };
    return SlevoveKuponyComponent;
}());
export { SlevoveKuponyComponent };
