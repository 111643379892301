import {Component, Input} from '@angular/core';
import {Objednavka} from '../../../../data/e-shop/objednavka';

@Component({
    selector: 'app-fakturacni-udaje-output',
    templateUrl: './fakturacni-udaje-output.component.html',
    styleUrls: ['./fakturacni-udaje-output.component.scss']
})
export class FakturacniUdajeOutputComponent {

    @Input() objednavka: Objednavka;
}
