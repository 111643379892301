/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "./obsah/seznam-objednavek-obsah.component.ngfactory";
import * as i4 from "./obsah/seznam-objednavek-obsah.component";
import * as i5 from "./seznam-objednavek.component";
import * as i6 from "../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni";
import * as i7 from "../../../../service/seznam/seznam-objednavek/seznam-objednavek.service";
var styles_SeznamObjednavekComponent = [i0.styles];
var RenderType_SeznamObjednavekComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekComponent, data: {} });
export { RenderType_SeznamObjednavekComponent as RenderType_SeznamObjednavekComponent };
export function View_SeznamObjednavekComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [["class", "info-title span-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container__content-body margin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-seznam-objednavek-obsah", [], null, null, null, i3.View_SeznamObjednavekObsahComponent_0, i3.RenderType_SeznamObjednavekObsahComponent)), i1.ɵdid(7, 49152, null, 0, i4.SeznamObjednavekObsahComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "objednavka.seznamObjednavek")); _ck(_v, 3, 0, currVal_0); }); }
export function View_SeznamObjednavekComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek", [], null, null, null, View_SeznamObjednavekComponent_0, RenderType_SeznamObjednavekComponent)), i1.ɵdid(1, 114688, null, 0, i5.SeznamObjednavekComponent, [i6.SeznamObjednavekNastaveniService, i7.SeznamObjednavekService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeznamObjednavekComponentNgFactory = i1.ɵccf("app-seznam-objednavek", i5.SeznamObjednavekComponent, View_SeznamObjednavekComponent_Host_0, {}, {}, []);
export { SeznamObjednavekComponentNgFactory as SeznamObjednavekComponentNgFactory };
