import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StavObjednavky} from '../../../data/e-shop/enum/stav-objednavky.enum';
import {Objednavka} from '../../../data/e-shop/objednavka';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {ObjednavkaService} from '../../../service/e-shop/objednavka.service';
import {saveAs} from 'file-saver';

@Component({
    selector: 'app-detail-objednavky',
    templateUrl: './detail-objednavky.component.html',
    styleUrls: ['./detail-objednavky.component.scss']
})
export class DetailObjednavkyComponent implements OnInit {

    public jePrihlasenCDDUzivatel: boolean;
    public objednavka: Objednavka;
    public zobrazitVrtitko = false;
    public zobrazitTabulkuFaktur = false;

    public zobrazitZruseniObjednavky = false;

    public zobrazitTlatickoZruseniObjednavky = false;

    private identifikatorObjednavky: string;

    private readonly blobNastaveni: any = {type: 'application/pdf'};

    constructor(
        private autentizaceService: AutentizaceService,
        private objednavkaService: ObjednavkaService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.inicializovatPromenne();
        this.zpracovatParametry();
    }

    public prejitZpet(): void {
        this.router.navigate(['/sekce-operatora']);
    }

    public prejitNaDetailPlatby() {
        this.router.navigate(['/e-shop/platba'], {queryParams: {id: this.objednavka.idPlatby}});
    }

    public prejitNaDetailPlatbyPodleObjednavky() {
        this.router.navigate(['/e-shop/platba'], {queryParams: {identifikatorObjednavky: this.objednavka.identifikator}});
    }

    public stahnoutZalohovouFakturu() {
        this.zobrazitVrtitko = true;
        this.objednavkaService.stahnoutZalohovouFakturu(this.objednavka.identifikator)
            .subscribe(data => {
                const blob = new Blob([data], this.blobNastaveni);
                saveAs(blob, this.objednavka.identifikator + '.pdf');
                this.zobrazitVrtitko = false;
            }, () => {
                this.zobrazitVrtitko = false;
            });
    }

    public prejitNaPlatbyAObjednavky() {
        this.router.navigate(['/e-shop/platby-a-objednavky/']);
    }

    private inicializovatPromenne(): void {
        this.jePrihlasenCDDUzivatel = this.autentizaceService.jePrihlasenyUzivatelCdd();
    }

    private zpracovatParametry(): void {
        this.route.params.subscribe(params => {
            this.identifikatorObjednavky = params['identifikatorObjednavky'];
            this.pripravitObjednavku();
        });
    }

    public pripravitObjednavku(): void {
        this.zobrazitVrtitko = true;

        this.objednavkaService.nacistObjednavku(this.identifikatorObjednavky)
            .toPromise()
            .then((odpoved) => {
                this.objednavka = odpoved;
                this.zobrazitTabulkuFaktur = !(this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_ZAKAZNIKEM || this.objednavka.stavObjednavky === StavObjednavky.CEKA_NA_ZAPLACENI);
                this.zobrazitZruseniObjednavky = this.objednavka.stavObjednavky === StavObjednavky.STORNOVANA;
                this.zobrazitTlatickoZruseniObjednavky = this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_ZAKAZNIKEM || this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_OPERATOREM || this.objednavka.stavObjednavky === StavObjednavky.CEKA_NA_ZAPLACENI;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }
}
