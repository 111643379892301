import { RadekSeznamu } from '../../../../../../shared/seznam/struktura/radek-seznamu';
import { SeznamObjednavekService } from '../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import { DatumPipe } from '../../../../../../shared/nastroje/pipes/datum.pipe';
import { VyctovyTypPipe } from '../../../../../../shared/nastroje/pipes/vyctovy-typ.pipe';
import { NastrojeObecne } from '../../../../../../shared/nastroje/nastroje-obecne';
import { HodnotaSeznamu } from '../../../../../../shared/seznam/struktura/hodnota-seznamu';
import { TypHodnotySeznamu } from '../../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import { TypPredplatneho } from '../../../../../../data/e-shop/enum/predplatne.enum';
import { NastrojeDataACasu } from '../../../../../../shared/nastroje/nastroje-data-a-casu';
import { StavObjednavky } from '../../../../../../data/e-shop/enum/stav-objednavky.enum';
import { DecimalPipe } from '@angular/common';
var SeznamObjednavekSeznamComponent = /** @class */ (function () {
    function SeznamObjednavekSeznamComponent(decimalPipe, datumPipe, seznamObjednavekService, vyctovyTypPipe) {
        this.decimalPipe = decimalPipe;
        this.datumPipe = datumPipe;
        this.seznamObjednavekService = seznamObjednavekService;
        this.vyctovyTypPipe = vyctovyTypPipe;
    }
    SeznamObjednavekSeznamComponent.prototype.pripravitCelkovyPocetZaznamu = function () {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    };
    SeznamObjednavekSeznamComponent.prototype.pripravitRadkySeznamuProZobrazeni = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamObjednavekService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }
        return this.radkySeznamuProZobrazeni;
    };
    SeznamObjednavekSeznamComponent.prototype.pripravitRadkySeznamu = function () {
        var radky = new Array();
        for (var _i = 0, _a = this.seznamObjednavekService.seznam.polozky; _i < _a.length; _i++) {
            var objednavka = _a[_i];
            radky.push(new RadekSeznamu(objednavka.identifikator, this.pripravitHodnotyRadkuSeznamu(objednavka), this.pripravitUrlDetailuObjednavky(objednavka.identifikator)));
        }
        return radky;
    };
    SeznamObjednavekSeznamComponent.prototype.pripravitHodnotyRadkuSeznamu = function (objednavka) {
        var hodnoty = new Array();
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, objednavka.nazevProduktu));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, this.vyctovyTypPipe.transform(objednavka.typPredplatneho, TypPredplatneho)));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, objednavka.identifikator, 'center_focus_weak'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumVytvoreni, NastrojeDataACasu.FORMAT_DATA), 'add'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumSplatnosti, NastrojeDataACasu.FORMAT_DATA), 'payment'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.vyctovyTypPipe.transform(objednavka.stavObjednavky, StavObjednavky), 'transform'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumPlatnostiPredplatnehoDo, NastrojeDataACasu.FORMAT_DATA, true), 'laptop_chromebook'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.decimalPipe.transform(objednavka.cenaSDph, '1.2-2', 'cs'), 'local_atm'));
        return hodnoty;
    };
    SeznamObjednavekSeznamComponent.prototype.pripravitUrlDetailuObjednavky = function (id) {
        return "e-shop/objednavka/" + id;
    };
    return SeznamObjednavekSeznamComponent;
}());
export { SeznamObjednavekSeznamComponent };
