import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Lokalizace} from '../../../../lokalizace';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

declare var $: any;

/**
 * <app-formular-select
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 *  [polozky]="<polozky selectu>"
 *  [prvniHodnotaNull]="<prvni_hodnota_null vychozi false>"
 *  [hodnotaKliceZObjektu]="<hodnota_klice_z_objektu default false>">
 * </app-form-select>
 */
@Component({
    selector: 'app-formular-select',
    templateUrl: './formular-select.component.html',
    styleUrls: ['./formular-select.component.scss']
})
export class FormularSelectComponent extends AbstraktniFormularovyPrvekComponent implements OnInit, AfterViewInit, OnChanges {

    private readonly nastaveniPozorovateleRoletky = {attributes: true};

    @ViewChild('roletka', {static: false}) roletka: ElementRef;

    @Input() polozky: any;

    /**
     * Definuje zda se ma v roletce zobrazit jako prvni hodnota "nezvolano" (null)
     */
    @Input() prvniHodnotaNull = false;

    /**
     * Definuje pokud jestli je klic hodnota brana z vyctoveho typu a nebo z objektu z poli klic a hodnota
     */
    @Input() hodnotaKliceZObjektu = false;

    /**
     * Definuje nazev klice objektu
     */
    @Input() nazevKliceObjektu = 'klic';

    /**
     * Definuje nazev hodnoty objektu
     */
    @Input() nazevHodnotyObjektu = 'hodnota';

    /**
     * Definuje zdali se maji hodnoty seradit podle prelozeneho popisku (pouze pro ciselniky (pole objektu), nikoliv pro enumy)
     */
    @Input() raditHodnotyPodleNazvu = true;

    /**
     * Definuje zdali je mozne vybirat vice polozek najednou
     */
    @Input() vicenasobnyVyberPolozek = false;

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.nastavitPosluchaceZmenyStavu();
        this.nastavitPozorovateleZmenyAtributu();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('polozky')) {
            this.seraditPolozkyDleNazvu();
            this.inicializovatRoletku();
        }
    }

    public seraditPolozkyDleNazvu() {
        if (this.raditHodnotyPodleNazvu && this.polozky instanceof Array) {
            this.polozky.sort((prvniPolozka, druhaPolozka) => {
                const prvniHodnota = this.pripravitPopisekPolozky(prvniPolozka);
                const druhaHodnota = this.pripravitPopisekPolozky(druhaPolozka);

                return prvniHodnota.localeCompare(druhaHodnota, 'cs');
            });
        }
    }

    private pripravitPopisekPolozky(polozka: any) {
        return Lokalizace.pripravitText(!this.hodnotaKliceZObjektu ? polozka.value : polozka[this.nazevHodnotyObjektu]);
    }

    private nastavitPosluchaceZmenyStavu() {
        this.ovladacFormulare.statusChanges.subscribe(() => {
            if (NastrojeObecne.jeHodnotaPrazdna(this.ovladacFormulare.value)) {
                $(this.roletka.nativeElement).val('');
            }

            $(this.roletka.nativeElement).formSelect();
        });
    }

    private nastavitPozorovateleZmenyAtributu() {
        const pozorovatel = new MutationObserver(this.zpracovatZmenyAtributuRoletky);
        pozorovatel.observe(this.roletka.nativeElement, this.nastaveniPozorovateleRoletky);
    }

    private zpracovatZmenyAtributuRoletky = function(listZmen) {
        for (const zmena of listZmen) {
            if (zmena.type === 'attributes') {
                const elementRoletky = $(zmena.target);
                const tridaRoletky = elementRoletky.attr('class');

                const kontejnerRoletky = elementRoletky.parent();
                const vychoziTridaKontejneruRoletky = 'select-wrapper';

                if (kontejnerRoletky.hasClass(vychoziTridaKontejneruRoletky)) {
                    kontejnerRoletky.attr('class', `${vychoziTridaKontejneruRoletky} ${tridaRoletky}`);
                }
            }
        }
    };

    private inicializovatRoletku() {
        $(document).ready(function() {
            $('select').formSelect();
        });
    }
}
