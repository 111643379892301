import { ZahranicniStatEu } from '../../data/organizace/enum/zahranicni-stat-eu.enum';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeDic = /** @class */ (function () {
    function NastrojeDic() {
    }
    NastrojeDic.jeNeplatceDph = function (dic) {
        if (!NastrojeObecne.jeHodnotaPrazdna(dic)) {
            var statZDic = dic.substr(0, 2);
            return ZahranicniStatEu[statZDic];
        }
        return false;
    };
    return NastrojeDic;
}());
export { NastrojeDic };
