import {Component} from '@angular/core';
import {RadekSeznamu} from '../../../../../shared/seznam/struktura/radek-seznamu';
import {DatumPipe} from '../../../../../shared/nastroje/pipes/datum.pipe';
import {NastrojeObecne} from '../../../../../shared/nastroje/nastroje-obecne';
import {HodnotaSeznamu} from '../../../../../shared/seznam/struktura/hodnota-seznamu';
import {TypHodnotySeznamu} from '../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import {NastrojeDataACasu} from '../../../../../shared/nastroje/nastroje-data-a-casu';
import {SeznamSlevovychKuponuService} from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import {PolozkaSeznamuSlevovychKuponu} from '../../../../../data/seznam/seznam-slevovych-kuponu/polozka-seznamu-slevovych-kuponu';
import {Lokalizace} from '../../../../../shared/lokalizace';
import {SlevaPipe} from '../../../../../shared/nastroje/pipes/sleva.pipe';

@Component({
    selector: 'app-seznam-slevovych-kuponu-seznam',
    templateUrl: './seznam-slevovych-kuponu-seznam.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-seznam.component.scss']
})
export class SeznamSlevovychKuponuSeznamComponent {

    private radkySeznamuProZobrazeni: Array<RadekSeznamu>;

    constructor(
        private datumPipe: DatumPipe,
        private slevaPipe: SlevaPipe,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
    }

    public pripravitCelkovyPocetZaznamu(): number {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    }

    public pripravitRadkySeznamuProZobrazeni(): Array<RadekSeznamu> {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }

        return this.radkySeznamuProZobrazeni;
    }

    private pripravitRadkySeznamu(): Array<RadekSeznamu> {
        const radky = new Array<RadekSeznamu>();

        for (const slevovyKupon of this.seznamSlevovychKuponuService.seznam.polozky) {
            radky.push(new RadekSeznamu(slevovyKupon.id, this.pripravitHodnotyRadkuSeznamu(slevovyKupon), this.pripravitUrlDetailuSlevovehoKuponu(slevovyKupon.nazev)));
        }

        return radky;
    }

    private pripravitHodnotyRadkuSeznamu(slevovyKupon: PolozkaSeznamuSlevovychKuponu): Array<HodnotaSeznamu> {
        const hodnoty = new Array<HodnotaSeznamu>();

        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, slevovyKupon.nazev));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, this.pripravitPlatnostSlevovehoKuponu(slevovyKupon)));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.slevaPipe.transform(slevovyKupon.sleva), 'local_offer'));

        return hodnoty;
    }

    private pripravitUrlDetailuSlevovehoKuponu(nazev: string): string {
        return `/slevove-kupony/detail/${nazev}`;
    }

    private pripravitPlatnostSlevovehoKuponu(slevovyKupon: PolozkaSeznamuSlevovychKuponu): string {
        return NastrojeObecne.formatovatRetezec('{0}: {1} - {2}', Lokalizace.pripravitText('slevovyKupon.platnost'), this.datumPipe.transform(slevovyKupon.datumPlatnostiOd, NastrojeDataACasu.FORMAT_DATA), this.datumPipe.transform(slevovyKupon.datumPlatnostiDo, NastrojeDataACasu.FORMAT_DATA));
    }
}
