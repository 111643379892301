import { PipeTransform } from '@angular/core';
import moment from 'moment';
var DatumPipe = /** @class */ (function () {
    function DatumPipe() {
    }
    DatumPipe.prototype.transform = function (value, format, zobrazitVUtc) {
        if (format === void 0) { format = ''; }
        if (zobrazitVUtc === void 0) { zobrazitVUtc = false; }
        moment.locale('cs'); // nastavuje cs-jazyk pro data, default je en
        if (!value || value === '') {
            return '';
        }
        return zobrazitVUtc ? moment.utc(value).format(format) : moment(value)
            .format(format);
    };
    return DatumPipe;
}());
export { DatumPipe };
