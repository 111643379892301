import {Component, HostListener, OnInit} from '@angular/core';
import {prostredi} from '../../../../prostredi/prostredi';
import {AbstraktniOpravneniComponent} from '../../../service/bezpecnost/abstraktni-opravneni.component';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {KeycloakService} from 'keycloak-angular';

@Component({
    selector: 'app-hlavni-menu',
    templateUrl: './hlavni-menu.component.html',
    styleUrls: ['./hlavni-menu.component.scss']
})
export class HlavniMenuComponent extends AbstraktniOpravneniComponent implements OnInit {

    public readonly urlFEN = prostredi.urlFEN;
    public readonly zakladniUrl = prostredi.zakladniUrl;

    public aktivniMobilniZobrazeni = false;
    public zobrazitMobilniZobrazeni = false;
    public zobrazitVrtitko = false;
    public prihlaseny = false;
    public prihlasenyAdministrator = false;
    public prihlasenyOperator = false;
    public jmenoAPrijmeniSpravceDatabazeCiOperatora;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.nastavitAktivniMobilniZobrazeni();
    }

    constructor(protected autentizaceService: AutentizaceService, private keycloakService: KeycloakService) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.nastavitAktivniMobilniZobrazeni();
        this.nastavitPromenne();
        this.nastavitCeleJmenoPrihlasenehoUzivatele();
    }

    public prepnoutNaMobilniZobrazeni(): void {
        this.zobrazitMobilniZobrazeni = !this.zobrazitMobilniZobrazeni;
    }

    private pripravitInformaceOPrihlasenemUzivateli(): void {
        this.zobrazitVrtitko = true;
        this.autentizaceService.pripravitInformaceOUzivateliAUlozitDoLocalStorage()
            .then(() => {
                this.zobrazitVrtitko = false;
            })
            .catch(() => this.zobrazitVrtitko = false);
    }

    public nacistJmenoAPrijmeniSpravceDatabazeCiOperatora(): void {
        this.zobrazitVrtitko = true;
        this.keycloakService.loadUserProfile()
            .then(data => {
                this.jmenoAPrijmeniSpravceDatabazeCiOperatora = data.firstName + ' ' + data.lastName;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private nastavitAktivniMobilniZobrazeni(): void {
        this.aktivniMobilniZobrazeni = window.innerWidth <= 900;
    }

    private nastavitPromenne(): void {
        this.prihlaseny = this.autentizaceService.jeUzivatelPrihlaseny();
        this.prihlasenyAdministrator = this.opravneniAdministraceJakoSpravceDatabaze();
        this.prihlasenyOperator = this.opravneniObsluhovatEshopJakoOperator();
    }

    private nastavitCeleJmenoPrihlasenehoUzivatele(): void {
        if (this.prihlaseny) {
            if (this.prihlasenyAdministrator || this.prihlasenyOperator) {
                this.nacistJmenoAPrijmeniSpravceDatabazeCiOperatora();
            } else {
                this.pripravitInformaceOPrihlasenemUzivateli();
            }
        }
    }
}
