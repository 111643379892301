<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'objednavka.detail.nadpis' | lokalizace }}
        </div>

        <app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>
        <app-zrusit-objednavku-modalni-panel [objednavka]="objednavka" (zruseno)="pripravitObjednavku()">></app-zrusit-objednavku-modalni-panel>

        <ng-container *ngIf="objednavka">
            <div class="container__content-body">
                <section class="info-container">
                    <div class="span-2">
                        <app-udaje-o-objednavce [objednavka]="objednavka"></app-udaje-o-objednavce>
                    </div>
                    <div class="span-2">
                        <app-fakturacni-udaje-output [objednavka]="objednavka"></app-fakturacni-udaje-output>
                    </div>
                    <div *ngIf="zobrazitTabulkuFaktur" class="span-2">
                        <app-tabulka-faktur-strankovana [identifikatorObjednavky]="objednavka.identifikator"></app-tabulka-faktur-strankovana>
                    </div>
                    <div *ngIf="zobrazitZruseniObjednavky" class="span-2">
                        <app-zruseni-objednavky [objednavka]="objednavka"></app-zruseni-objednavky>
                    </div>
                </section>
            </div>

            <div class="button-area button-area-responsive-vertically">
                <button *ngIf="!jePrihlasenCDDUzivatel"
                        type="submit" (click)="prejitZpet()"
                        class="btn btn-secondary waves-effect waves-light right">
                    <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                    <i class="material-icons left icon-flipped">forward</i>
                </button>
                <button *ngIf="!jePrihlasenCDDUzivatel && zobrazitTlatickoZruseniObjednavky"
                        type="button"
                        class="btn btn-secondary waves-effect waves-light right modal-trigger"
                        data-target="zruseni-objednavky-modalni-panel">
                    <b>{{ 'tlacitko.zrusitObjednavku' | lokalizace }}</b>
                </button>
                <div *ngIf="objednavka.zpusobPlatby == 'PLATBA_KARTOU' && objednavka.zdroj == 'ESHOP'">
                    <button *ngIf="objednavka.idPlatby"
                            type="button" (click)="prejitNaDetailPlatby()"
                            class="btn btn-secondary waves-effect waves-light right">
                        {{ 'tlacitko.detailPlatby' | lokalizace }}
                    </button>
                    <button *ngIf="!objednavka.idPlatby"
                            type="button" (click)="prejitNaDetailPlatbyPodleObjednavky()"
                            class="btn btn-secondary waves-effect waves-light right">
                        {{ 'tlacitko.detailPlatby' | lokalizace }}
                    </button>
                </div>
                <button *ngIf="objednavka.zpusobPlatby == 'BANKOVNI_PREVOD' && objednavka.zdroj == 'ESHOP'"
                        type="button" (click)="stahnoutZalohovouFakturu()"
                        class="btn btn-secondary waves-effect waves-light right">
                    {{ 'tlacitko.stahnoutObjednavku' | lokalizace }}
                </button>
                <button *ngIf="jePrihlasenCDDUzivatel"
                        type="button" (click)="prejitNaPlatbyAObjednavky()"
                        class="btn btn-secondary waves-effect waves-light right">
                    {{ 'tlacitko.prejitNaPlatbyAObjednavky' | lokalizace }}
                </button>
            </div>
        </ng-container>
    </div>
</main>
