import {Component, ElementRef, ViewChild} from '@angular/core';
import {SeznamSlevovychKuponuNastaveniService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';
import {NastrojeCisla} from '../../../../../../shared/nastroje/nastroje-cisla';

declare var $: any;

@Component({
    selector: 'app-seznam-slevovych-kuponu-aktualni-stranka',
    templateUrl: './seznam-slevovych-kuponu-aktualni-stranka.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-aktualni-stranka.component.scss']
})
export class SeznamSlevovychKuponuAktualniStrankaComponent {

    @ViewChild('vstupniPole', {static: false}) public vstupniPole: ElementRef;

    private maximalniPocetStranekProZobrazeni: number;

    constructor(
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
    }

    public pripravitAktualniStranku(): number {
        return this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamSlevovychKuponu = this.seznamSlevovychKuponuService.seznam;
        const nastaveniZobrazeniSeznamuSlevovychKuponu = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu);
    }

    public nastavitStranku(): void {
        const element = $(this.vstupniPole.nativeElement);
        let hodnota = element.val();

        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }

        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (hodnota <= 0) {
            hodnota = 1;
        } else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }

        element.blur();
        element.val(hodnota);
        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamSlevovychKuponuService.aktualizovat();
    }

    public pripravitMaximalniPocetStranekProZobrazeni(): number {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }

        return this.maximalniPocetStranekProZobrazeni;
    }

    public pripravitCekovyPocetZaznamu(): number {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    }
}
