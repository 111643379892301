<div [formGroup]="nadrazenaSkupinaFormulare" class="calendar-wrapper">
    <div class="input-field" [class.responsive-input-field]="zmensitRozestupy">
        <input id="{{idPrvku}}" type="text"
               [formControlName]="nazevOvladaceFormulare"
               [attr.disabled]="zneaktivnit() ? true : null"
               [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
               [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid"
               (change)="nastavitHodnotuOvladaceFormulare($event)">
        <label for="{{idPrvku}}" class="asterisk-placeholder" [class.active]="ovladacFormulare.value">{{popisek | lokalizace}}</label>
        <span *ngIf="!skrytPomocnyText" class="helper-text"
              [attr.data-error]="pripravitChybovouZpravu()"
              [attr.data-success]="''">
            {{pomocnyText | lokalizace}}
        </span>
    </div>
    <button #tlacitkoKalendare type="button" class="datepicker btn-flat"
            [attr.disabled]="zneaktivnit() ? true : null"
            (click)="otevritKalendarAVybratDatum()"
            (change)="nastavitHodnotuOvladaceFormulare($event)">
        <i class="tiny material-icons">date_range</i>
    </button>
</div>
