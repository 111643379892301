import {Injectable} from '@angular/core';
import {AbstraktniNastaveniSeznamuService} from '../abstraktni-nastaveni-seznamu-service';
import {TypRazeni} from '../../../data/obecne/enum/typ-razeni.enum';
import {AtributRazeniObjednavek} from '../../../data/obecne/enum/atribut-razeni-objednavek.enum';
import {NastaveniZobrazeniSeznamuObjednavek} from '../../../data/seznam/seznam-objednavek/filtr/nastaveni-zobrazeni-seznamu-objednavek';

@Injectable()
export class SeznamObjednavekNastaveniService extends AbstraktniNastaveniSeznamuService {
    protected readonly typAtributuRazeni = AtributRazeniObjednavek;
    protected readonly vychoziAtributRazeni = AtributRazeniObjednavek.DATUM_VYTVORENI;
    protected readonly typTypuRazeni = TypRazeni;
    protected readonly vychoziTypRazeni = TypRazeni.SESTUPNE;

    protected readonly nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-objednavek_strankovani-pocet-zaznamu-na-stranku';
    protected readonly nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-objednavek_strankovani-aktualni-stranka';
    protected readonly nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-objednavek_razeni-atribut-razeni';
    protected readonly nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-objednavek_razeni-typ-razeni';

    protected readonly nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuObjednavek();
}
