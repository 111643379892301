import moment from 'moment';
var NastrojeDataACasu = /** @class */ (function () {
    function NastrojeDataACasu() {
    }
    NastrojeDataACasu.prevestRetezecNaDatum = function (hodnota, vzor) {
        if (hodnota === undefined || hodnota === null) {
            return null;
        }
        else {
            return moment(hodnota, vzor).toDate();
        }
    };
    NastrojeDataACasu.prevestRetezecNaDatumDleISO8601 = function (datum) {
        return moment(datum).toISOString();
    };
    NastrojeDataACasu.prevestRetezecNaDatumDleRFC3339 = function (datum) {
        return moment(datum).format();
    };
    NastrojeDataACasu.nastavitCasZacatekDne = function (datum) {
        datum.setHours(0, 0, 0);
        return datum;
    };
    NastrojeDataACasu.nastavitCasKonecDne = function (datum) {
        datum.setHours(23, 59, 59);
        return datum;
    };
    NastrojeDataACasu.formatovatDatum = function (datum, vzor) {
        return moment(datum).format(vzor).toString();
    };
    NastrojeDataACasu.jeValidniDatum = function (datum) {
        return Object.prototype.toString.call(datum) === '[object Date]' && !isNaN(datum.getTime());
    };
    NastrojeDataACasu.pridatMesic = function (datum) {
        var pocetDnuVPristimMesici = new Date(datum.getFullYear(), datum.getMonth() + 2, 0).getDate();
        return new Date(datum.getFullYear(), datum.getMonth() + 1, datum.getDate() > pocetDnuVPristimMesici ? pocetDnuVPristimMesici : datum.getDate());
    };
    NastrojeDataACasu.pridatDny = function (datum, dny) {
        return datum.setDate(datum.getDate() + dny);
    };
    // k obecnemu pouziti
    NastrojeDataACasu.FORMAT_ROKU = 'YYYY';
    NastrojeDataACasu.FORMAT_DATA = 'DD. MM. YYYY';
    NastrojeDataACasu.FORMAT_CASU_PRESNOST_NA_MINUTY = 'HH:mm';
    NastrojeDataACasu.FORMAT_CASU_PRESNOST_NA_VTERINY = 'HH:mm:ss';
    NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_VTERINY = 'DD. MM. YYYY HH:mm:ss';
    NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY = 'DD. MM. YYYY HH:mm';
    // pouzivat pouze pro ucely formulare
    NastrojeDataACasu.FORMULAR_KALENDAR_FORMAT_DATA = 'dd. mm. yyyy';
    NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA = /^([1-9]|[0][1-9]|[1-9]\d)\.[ ]?([1-9]|[0][1-9]|[1-9]\d)\d?\.[ ]?[1-2]\d{3}$/;
    // pouzivat pouze pro ucely prevodu dat z/na JSON
    NastrojeDataACasu.NAHRAZOVAC_VSTUPNI_FORMAT_DATA = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    NastrojeDataACasu.NAHRAZOVAC_VYSTUPNI_FORMAT_DATA = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    NastrojeDataACasu.NAHRAZOVAC_FORMAT_OBJEKT = 'YYYY-MM-DD HH:mm:ss';
    return NastrojeDataACasu;
}());
export { NastrojeDataACasu };
