/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-operatora-razeni.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../shared/seznam/razeni/razeni.component.ngfactory";
import * as i3 from "../../../../../../../shared/seznam/razeni/razeni.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../../service/bezpecnost/autentizace.service";
import * as i6 from "./seznam-objednavek-operatora-razeni.component";
import * as i7 from "../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service";
import * as i8 from "../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service";
var styles_SeznamObjednavekOperatoraRazeniComponent = [i0.styles];
var RenderType_SeznamObjednavekOperatoraRazeniComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekOperatoraRazeniComponent, data: {} });
export { RenderType_SeznamObjednavekOperatoraRazeniComponent as RenderType_SeznamObjednavekOperatoraRazeniComponent };
export function View_SeznamObjednavekOperatoraRazeniComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-razeni", [], [[1, "idkomponentyformulare", 0]], [[null, "zmenaAtributuRazeni"], [null, "zmenaTypuRazeni"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keypress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } if (("zmenaAtributuRazeni" === en)) {
        var pd_1 = (_co.nastavitAtributRazeni($event) !== false);
        ad = (pd_1 && ad);
    } if (("zmenaTypuRazeni" === en)) {
        var pd_2 = (_co.nastavitTypRazeni($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_RazeniComponent_0, i2.RenderType_RazeniComponent)), i1.ɵdid(1, 114688, null, 0, i3.RazeniComponent, [i4.FormBuilder, i5.AutentizaceService], { atributyRazeni: [0, "atributyRazeni"], vychoziAtributRazeni: [1, "vychoziAtributRazeni"], typyRazeni: [2, "typyRazeni"], vychoziTypRazeni: [3, "vychoziTypRazeni"] }, { zmenaAtributuRazeni: "zmenaAtributuRazeni", zmenaTypuRazeni: "zmenaTypuRazeni" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.atributyRazeni; var currVal_2 = _co.pripravitVychoziAtributRazeni(); var currVal_3 = _co.typyRazeni; var currVal_4 = _co.pripravitVychoziTypRazeni(); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).pripravitIdKomponentyFormulare; _ck(_v, 0, 0, currVal_0); }); }
export function View_SeznamObjednavekOperatoraRazeniComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-razeni", [], null, null, null, View_SeznamObjednavekOperatoraRazeniComponent_0, RenderType_SeznamObjednavekOperatoraRazeniComponent)), i1.ɵdid(1, 49152, null, 0, i6.SeznamObjednavekOperatoraRazeniComponent, [i7.SeznamObjednavekOperatoraNastaveniService, i8.SeznamObjednavekOperatoraService], null, null)], null, null); }
var SeznamObjednavekOperatoraRazeniComponentNgFactory = i1.ɵccf("app-seznam-objednavek-operatora-razeni", i6.SeznamObjednavekOperatoraRazeniComponent, View_SeznamObjednavekOperatoraRazeniComponent_Host_0, {}, {}, []);
export { SeznamObjednavekOperatoraRazeniComponentNgFactory as SeznamObjednavekOperatoraRazeniComponentNgFactory };
