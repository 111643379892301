var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniRazeniComponent } from '../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
import { AtributRazeniSlevovychKuponu } from '../../../../../../data/obecne/enum/atribut-razeni-slevovych-kuponu.enum';
import { TypRazeni } from '../../../../../../data/obecne/enum/typ-razeni.enum';
import { SeznamSlevovychKuponuNastaveniService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import { SeznamSlevovychKuponuService } from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
var SeznamSlevovychKuponuRazeniComponent = /** @class */ (function (_super) {
    __extends(SeznamSlevovychKuponuRazeniComponent, _super);
    function SeznamSlevovychKuponuRazeniComponent(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) {
        var _this = _super.call(this, seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) || this;
        _this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        _this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
        _this.atributyRazeni = AtributRazeniSlevovychKuponu;
        _this.typyRazeni = TypRazeni;
        return _this;
    }
    return SeznamSlevovychKuponuRazeniComponent;
}(AbstraktniRazeniComponent));
export { SeznamSlevovychKuponuRazeniComponent };
