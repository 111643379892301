declare var $: any;

export class NastrojeTabu {

    public static inicializovatTaby() {
        $(document).ready(function() {
            $('ul.tabs').tabs();
        });
    }

    public static prejitNaKartu(idKarty: string) {
        $('.tab a').filter('a[href$="' + idKarty + '"]').click(function() {
            this.click();
        }).click();
    }
}
