<section class="intro">
	<div class="intro__headings">
		<h1 class="intro__heading-1 heading-1">
            <span class="first-letter">F</span>{{ 'domu.unkcni' | lokalizace }}<br/>
            {{ 'domu.elektronicky' | lokalizace }}<br/>
            {{ 'domu.nastroj' | lokalizace }}<br/>
            <span class="first-letter">E-</span>{{ 'domu.shop' | lokalizace }}
		</h1>

		<h2 class="intro__heading-2 heading-2">
			{{ 'domu.popisPlatformy' | lokalizace }}
		</h2>

		<img *hideIEVersion="['ie 9','ie 10','ie 11']" src="{{zakladniUrl}}assets/img/pracovnik.png" alt="{{ 'popisek.obrazek.pracovnik' | lokalizace }}" class="intro__image">
	</div>
	<div *showIEVersion="['ie 9','ie 10','ie 11']" class="intro__image-container">
		<img src="{{zakladniUrl}}assets/img/pracovnik.png" alt="{{ 'popisek.obrazek.pracovnik' | lokalizace }}" class="intro__image">
	</div>
</section>
