import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'datum'
})
export class DatumPipe implements PipeTransform {

    transform(value: any, format: string = '', zobrazitVUtc: boolean = false): string {
        moment.locale('cs'); // nastavuje cs-jazyk pro data, default je en
        if (!value || value === '') {
            return '';
        }

        return zobrazitVUtc ? moment.utc(value).format(format) : moment(value)
            .format(format);
    }
}
