import {TypPoctuZaznamuNaStranku} from '../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {LokalniUlozisteNastroje} from '../../shared/nastroje/lokalni-uloziste/lokalni-uloziste-nastroje';
import {NastrojeEnumu} from '../../shared/nastroje/nastroje-enumu';
import {NastrojeObecne} from '../../shared/nastroje/nastroje-obecne';
import {Razeni} from '../../shared/seznam/razeni/razeni';

export abstract class AbstraktniNastaveniSeznamuService {
    protected readonly vychoziPocetZaznamuNaStranku = TypPoctuZaznamuNaStranku.PET;
    protected readonly abstract typAtributuRazeni: any;
    protected readonly abstract vychoziAtributRazeni: any;
    protected readonly abstract typTypuRazeni;
    protected readonly abstract vychoziTypRazeni;

    protected readonly abstract nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti: string;
    protected readonly abstract nazevKliceAktualniStrankyVLokalnimUlozisti: string;
    protected readonly abstract nazevKliceAtributuRazeniVLokalnimUlozisti: string;
    protected readonly abstract nazevKliceTypuRazeniVLokalnimUlozisti: string;

    protected readonly abstract nastaveniZobrazeniSeznamu: any;

    public inicializovat() {
        this.inicializovatPocetZaznamuNaStranku();
        this.inicializovatAktualniStranku();
        this.inicializovatRazeni();
    }

    public inicializovatPocetZaznamuNaStranku() {
        const pocetZaznamuNaStrankuZUloziste = LokalniUlozisteNastroje.getPolozka(this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti);

        if (NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStrankuZUloziste) != null) {
            this.nastavitPocetZaznamuNaStranku(+pocetZaznamuNaStrankuZUloziste);
        } else {
            this.nastavitPocetZaznamuNaStranku(+this.vychoziPocetZaznamuNaStranku);
        }
    }

    private inicializovatAktualniStranku() {
        const aktualniStrankaZUloziste = LokalniUlozisteNastroje.getPolozka(this.nazevKliceAktualniStrankyVLokalnimUlozisti);
        const aktualniStranka = !NastrojeObecne.jeHodnotaPrazdna(aktualniStrankaZUloziste) ? +aktualniStrankaZUloziste : 1;

        this.nastavitAktualniStranku(aktualniStranka);
    }

    private inicializovatRazeni() {
        this.nastavitRazeni(this.pripravitAtributRazeni(), this.pripravitTypRazeni());
    }

    public nastavitRazeni(atributRazeni: any, typRazeni: any) {
        this.nastaveniZobrazeniSeznamu.razeni = new Razeni();

        this.nastavitAtributRazeni(atributRazeni);
        this.nastavitTypRazeni(typRazeni);
    }

    public nastavitPocetZaznamuNaStranku(hodnota: number) {
        this.nastaveniZobrazeniSeznamu.strankovani.pocetZaznamu = +hodnota;
        LokalniUlozisteNastroje.setPolozka(this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti, String(hodnota));
    }

    public nastavitAktualniStranku(hodnota: number) {
        this.nastaveniZobrazeniSeznamu.strankovani.stranka = +hodnota;
        LokalniUlozisteNastroje.setPolozka(this.nazevKliceAktualniStrankyVLokalnimUlozisti, String(hodnota));
    }

    public resetovatPoziciNaStrankach(): void {
        this.nastavitAktualniStranku(1);
    }

    public resetovatFiltr(): void {
        this.nastaveniZobrazeniSeznamu.filtr = new this.nastaveniZobrazeniSeznamu.filtr.constructor();
    }

    public pripravitAtributRazeni(): any {
        return this.pripravitHodnotu(this.typAtributuRazeni, this.nazevKliceAtributuRazeniVLokalnimUlozisti, this.vychoziAtributRazeni);
    }

    public pripravitTypRazeni(): any {
        return this.pripravitHodnotu(this.typTypuRazeni, this.nazevKliceTypuRazeniVLokalnimUlozisti, this.vychoziTypRazeni);
    }

    public pripravitHodnotu(typHodnoty: any, nazevKliceHodnotyVLokalnimUlozisti: string, vychoziHodnota: any): any {
        const polozka = LokalniUlozisteNastroje.getPolozka(nazevKliceHodnotyVLokalnimUlozisti);
        const typHodnotyZUloziste = typHodnoty[polozka];

        if (typHodnotyZUloziste !== undefined) {
            return typHodnotyZUloziste;
        } else {
            return vychoziHodnota;
        }
    }

    public nastavitAtributRazeni(atributRazeni: any) {
        this.nastavitHodnotu(this.typAtributuRazeni, atributRazeni, this.nazevKliceAtributuRazeniVLokalnimUlozisti, 'atribut');
    }

    public nastavitTypRazeni(typRazeni: any) {
        this.nastavitHodnotu(this.typTypuRazeni, typRazeni, this.nazevKliceTypuRazeniVLokalnimUlozisti, 'typRazeni');
    }

    public nastavitHodnotu(typHodnoty: any, hodnota: any, nazevKliceHodnotyVLokalnimUlozisti: string, nazevHodnotyRazeni: string) {
        const klic = NastrojeEnumu.pripravitKlicEnumuZHodnoty(typHodnoty, hodnota);

        this.nastaveniZobrazeniSeznamu.razeni[nazevHodnotyRazeni] = klic;

        LokalniUlozisteNastroje.setPolozka(nazevKliceHodnotyVLokalnimUlozisti, klic);
    }

    public getNastaveniZobrazeni(): any {
        return this.nastaveniZobrazeniSeznamu;
    }
}
