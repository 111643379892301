var NastrojeOdrolovani = /** @class */ (function () {
    function NastrojeOdrolovani() {
    }
    NastrojeOdrolovani.odrolovatNaIdElementuPriPresmerovani = function (router, idElementu) {
        var _this = this;
        router.events.subscribe(function () {
            _this.odrolovat(idElementu);
        });
    };
    NastrojeOdrolovani.odrolovat = function (idElementu) {
        document.getElementById(idElementu).scrollIntoView();
    };
    return NastrojeOdrolovani;
}());
export { NastrojeOdrolovani };
