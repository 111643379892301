import enJson from '../../assets/lokalizace/en.json';
import czJson from '../../assets/lokalizace/cz.json';
import { NastrojeObecne } from './nastroje/nastroje-obecne';
var Lokalizace = /** @class */ (function () {
    function Lokalizace() {
    }
    Lokalizace.pripravitText = function (klicTextu) {
        return this.data && this.data[klicTextu] ? this.data[klicTextu] : klicTextu;
    };
    Lokalizace.formatovatText = function (klicTextu) {
        var parametry = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parametry[_i - 1] = arguments[_i];
        }
        return NastrojeObecne.formatovatRetezec.apply(NastrojeObecne, [Lokalizace.pripravitText(klicTextu)].concat(parametry));
    };
    Lokalizace.vybratJazykovouMutaci = function (jazykovaMutace) {
        switch (jazykovaMutace) {
            case 'en':
                return enJson;
            case 'cs':
                return czJson;
            default:
                return czJson;
        }
    };
    Lokalizace.data = Lokalizace.vybratJazykovouMutaci(localStorage.getItem('jazykova-mutace'));
    return Lokalizace;
}());
export { Lokalizace };
