import {KeycloakService} from 'keycloak-angular';
import {prostredi} from '../../../prostredi/prostredi';
import {NastrojeObecne} from '../../shared/nastroje/nastroje-obecne';
import {LokalniUlozisteNastroje} from '../../shared/nastroje/lokalni-uloziste/lokalni-uloziste-nastroje';
import {AutentizaceService} from './autentizace.service';
import {NastrojeAutentizace} from '../../shared/nastroje/nastroje-autentizace';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise(async(resolve, reject) => {
            let nastaveniKeycloaku;

            const aktualniRealm = LokalniUlozisteNastroje.getPolozka(AutentizaceService.nazevKliceAktualnihoRealmu);

            if (!NastrojeObecne.jeHodnotaPrazdna(aktualniRealm) && aktualniRealm === prostredi.administratorRealm) {
                nastaveniKeycloaku = NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.cddKeycloakUrl, prostredi.administratorRealm, prostredi.administratorClientId, prostredi.administratorClientSecret);
            } else if (!NastrojeObecne.jeHodnotaPrazdna(aktualniRealm) && aktualniRealm === prostredi.eshopRealm) {
                nastaveniKeycloaku = NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.cddKeycloakUrl, prostredi.eshopRealm, prostredi.eshopClientId, prostredi.eshopClientSecret);
            } else {
                nastaveniKeycloaku = NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.cddKeycloakUrl, prostredi.cddRealm, prostredi.cddClientId, prostredi.cddClientSecret);
            }

            try {
                await keycloak.init(nastaveniKeycloaku);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
