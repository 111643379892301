/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-slevovych-kuponu-legenda.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "./seznam-slevovych-kuponu-legenda.component";
var styles_SeznamSlevovychKuponuLegendaComponent = [i0.styles];
var RenderType_SeznamSlevovychKuponuLegendaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamSlevovychKuponuLegendaComponent, data: {} });
export { RenderType_SeznamSlevovychKuponuLegendaComponent as RenderType_SeznamSlevovychKuponuLegendaComponent };
export function View_SeznamSlevovychKuponuLegendaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "legend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "legend__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["local_offer"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "slevovyKupon.slevaBezProcent")); _ck(_v, 6, 0, currVal_0); }); }
export function View_SeznamSlevovychKuponuLegendaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-slevovych-kuponu-legenda", [], null, null, null, View_SeznamSlevovychKuponuLegendaComponent_0, RenderType_SeznamSlevovychKuponuLegendaComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamSlevovychKuponuLegendaComponent, [], null, null)], null, null); }
var SeznamSlevovychKuponuLegendaComponentNgFactory = i1.ɵccf("app-seznam-slevovych-kuponu-legenda", i3.SeznamSlevovychKuponuLegendaComponent, View_SeznamSlevovychKuponuLegendaComponent_Host_0, {}, {}, []);
export { SeznamSlevovychKuponuLegendaComponentNgFactory as SeznamSlevovychKuponuLegendaComponentNgFactory };
