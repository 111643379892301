import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstraktniTypZobrazeniHlavnihoMenuComponent} from '../abstraktni-typ-zobrazeni-hlavniho-menu.component';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';

@Component({
    selector: 'app-mobilni-zobrazeni-hlavniho-menu',
    templateUrl: './mobilni-zobrazeni-hlavniho-menu.component.html',
    styleUrls: ['./mobilni-zobrazeni-hlavniho-menu.component.scss']
})
export class MobilniZobrazeniHlavnihoMenuComponent extends AbstraktniTypZobrazeniHlavnihoMenuComponent implements OnInit {

    @Input()
    zobrazit = false;

    @Output()
    zavrit = new EventEmitter();

    constructor(protected autentizaceService: AutentizaceService) {
        super(autentizaceService);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
