var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniNastaveniSeznamuService } from '../abstraktni-nastaveni-seznamu-service';
import { AtributRazeniSlevovychKuponu } from '../../../data/obecne/enum/atribut-razeni-slevovych-kuponu.enum';
import { TypRazeni } from '../../../data/obecne/enum/typ-razeni.enum';
import { NastaveniZobrazeniSeznamuSlevovychKuponu } from '../../../data/seznam/seznam-slevovych-kuponu/filtr/nastaveni-zobrazeni-seznamu-slevovych-kuponu';
var SeznamSlevovychKuponuNastaveniService = /** @class */ (function (_super) {
    __extends(SeznamSlevovychKuponuNastaveniService, _super);
    function SeznamSlevovychKuponuNastaveniService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typAtributuRazeni = AtributRazeniSlevovychKuponu;
        _this.vychoziAtributRazeni = AtributRazeniSlevovychKuponu.DATUM_PLATNOSTI_DO;
        _this.typTypuRazeni = TypRazeni;
        _this.vychoziTypRazeni = TypRazeni.SESTUPNE;
        _this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-slevovych-kuponu_strankovani-pocet-zaznamu-na-stranku';
        _this.nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-slevovych-kuponu_strankovani-aktualni-stranka';
        _this.nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-slevovych-kuponu_razeni-atribut-razeni';
        _this.nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-slevovych-kuponu_razeni-typ-razeni';
        _this.nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuSlevovychKuponu();
        return _this;
    }
    return SeznamSlevovychKuponuNastaveniService;
}(AbstraktniNastaveniSeznamuService));
export { SeznamSlevovychKuponuNastaveniService };
