<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'slevovyKupon.nadpis.detail' | lokalizace }}
        </div>

        <ng-container *ngIf="slevovyKupon">
            <app-slevovy-kupon-zakladni-udaje [slevovyKupon]="slevovyKupon"></app-slevovy-kupon-zakladni-udaje>
            <app-slevovy-kupon-tabulka-produktu [slevovyKupon]="slevovyKupon"></app-slevovy-kupon-tabulka-produktu>

            <div class="button-area">
                <button type="submit" (click)="prejitZpet()" class="btn btn-secondary waves-effect waves-light right">
                    <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                    <i class="material-icons left icon-flipped">forward</i>
                </button>

                <button type="submit" (click)="prejitNaStrankuUpravit()" class="btn btn-secondary waves-effect waves-light right">
                    <b>{{ 'tlacitko.upravit' | lokalizace }}</b>
                    <i class="material-icons right">edit</i>
                </button>
            </div>
        </ng-container>
    </div>
</main>
