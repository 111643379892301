import {Component} from '@angular/core';
import {AbstraktniRazeniComponent} from '../../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
import {SeznamObjednavekNastaveniService} from '../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import {TypRazeni} from '../../../../../../../data/obecne/enum/typ-razeni.enum';
import {AtributRazeniObjednavek} from '../../../../../../../data/obecne/enum/atribut-razeni-objednavek.enum';

@Component({
    selector: 'app-seznam-objednavek-razeni',
    templateUrl: './seznam-objednavek-razeni.component.html',
    styleUrls: ['./seznam-objednavek-razeni.component.scss']
})
export class SeznamObjednavekRazeniComponent extends AbstraktniRazeniComponent {

    public atributyRazeni = AtributRazeniObjednavek;

    public typyRazeni = TypRazeni;

    constructor(private seznamObjednavekNastaveniService: SeznamObjednavekNastaveniService, private seznamObjednavekService: SeznamObjednavekService) {
        super(seznamObjednavekNastaveniService, seznamObjednavekService);
    }
}
