var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AtributRazeniUzivateluSDodavatelem } from '../../../../../../data/obecne/enum/atribut-razeni-uzivatelu-s-dodavatelem.enum';
import { TypRazeni } from '../../../../../../data/obecne/enum/typ-razeni.enum';
import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { AbstraktniRazeniComponent } from '../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
var SeznamUzivateluSDodavatelemRazeniComponent = /** @class */ (function (_super) {
    __extends(SeznamUzivateluSDodavatelemRazeniComponent, _super);
    function SeznamUzivateluSDodavatelemRazeniComponent(seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) {
        var _this = _super.call(this, seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) || this;
        _this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        _this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
        _this.atributyRazeni = AtributRazeniUzivateluSDodavatelem;
        _this.typyRazeni = TypRazeni;
        return _this;
    }
    return SeznamUzivateluSDodavatelemRazeniComponent;
}(AbstraktniRazeniComponent));
export { SeznamUzivateluSDodavatelemRazeniComponent };
