import { EventEmitter } from '@angular/core';
import { ObjednavkaService } from './objednavka.service';
import { Objednavka } from '../../data/e-shop/objednavka';
import { NastrojeObecne } from '../../shared/nastroje/nastroje-obecne';
import { Cena } from '../../data/e-shop/cena';
import { NastrojeFormulare } from '../../shared/nastroje/nastroje-formulare';
import { FakturacniAdresa } from '../../data/e-shop/fakturacni-adresa';
import { NastrojeDic } from '../../shared/nastroje/nastroje-dic';
var VytvoritObjednavkuService = /** @class */ (function () {
    function VytvoritObjednavkuService(objednavkaService) {
        this.objednavkaService = objednavkaService;
        this.pripravitCenuProduktu = new EventEmitter();
    }
    VytvoritObjednavkuService.prototype.inicializovatObjednavku = function () {
        this.objednavka = new Objednavka();
    };
    VytvoritObjednavkuService.prototype.doplnitVybranyProdukt = function (produkt, predplatne, sleva, delkaPlatnostiPredplatneho) {
        this.objednavka.idProduktu = produkt.id;
        this.objednavka.identifikatorProduktu = produkt.identifikator;
        this.objednavka.ucetniIdentifikatorProduktu = produkt.ucetniIdentifikator;
        this.objednavka.nazevProduktu = produkt.nazev;
        this.objednavka.delkaPlatnostiPredplatneho = delkaPlatnostiPredplatneho;
        this.objednavka.typPredplatneho = predplatne;
        if (NastrojeObecne.jeHodnotaPrazdna(sleva)) {
            this.nastavitCenuObjednavky(produkt.cena);
        }
        else {
            this.nastavitCenuObjednavky(sleva);
        }
    };
    VytvoritObjednavkuService.prototype.doplnitZpusobPlatby = function (zpusobPlatby) {
        this.objednavka.zpusobPlatby = zpusobPlatby;
    };
    VytvoritObjednavkuService.prototype.doplnitSlevovyKupon = function (slevovyKupon) {
        this.objednavka.nazevSlevovehoKuponu = slevovyKupon.nazev;
        this.objednavka.slevaVProcentech = slevovyKupon.sleva;
    };
    VytvoritObjednavkuService.prototype.doplnitVybranehoUzivatele = function (radek) {
        this.objednavka.idUzivatele = radek.id;
        this.objednavka.prihlasovaciJmeno = radek.hodnoty[0].hodnota;
    };
    VytvoritObjednavkuService.prototype.jeUzivatelVybran = function () {
        return !NastrojeObecne.jeHodnotaPrazdna(this.objednavka.idUzivatele);
    };
    VytvoritObjednavkuService.prototype.doplnitFakturacniUdaje = function (formGroup) {
        this.objednavka.jmeno = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.krestniJmeno.value);
        this.objednavka.prijmeni = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.prijmeni.value);
        this.objednavka.email = formGroup.controls.email.value;
        this.objednavka.telefon = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.telefon.value);
        this.objednavka.nazevOrganizace = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.nazevOrganizace.value);
        this.objednavka.ic = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.ic.value);
        this.objednavka.dic = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.dic.value);
        this.objednavka.fakturacniAdresa = new FakturacniAdresa();
        this.objednavka.fakturacniAdresa.ulice = formGroup.controls.ulice.value;
        this.objednavka.fakturacniAdresa.cisloPopisne = formGroup.controls.cisloPopisne.value;
        this.objednavka.fakturacniAdresa.obec = formGroup.controls.obec.value;
        this.objednavka.fakturacniAdresa.psc = formGroup.controls.psc.value;
        this.objednavka.fakturacniAdresa.stat = formGroup.controls.stat.value;
    };
    VytvoritObjednavkuService.prototype.vytvoritObjednavku = function () {
        if (NastrojeDic.jeNeplatceDph(this.objednavka.dic)) {
            this.objednavka.cena.cenaSDph = this.objednavka.cena.cenaBezDph;
            this.objednavka.cena.koeficientDph = 0;
        }
        return this.objednavkaService.vytvoritObjednavku(this.objednavka)
            .toPromise();
    };
    VytvoritObjednavkuService.prototype.nastavitCenuObjednavky = function (cena) {
        this.objednavka.cena = new Cena();
        this.objednavka.cena.cenaBezDph = cena.cenaBezDph;
        this.objednavka.cena.cenaSDph = cena.cenaSDph;
        this.objednavka.cena.koeficientDph = cena.koeficientDph;
    };
    VytvoritObjednavkuService.prototype.vyvolatUdalostProPripravuCenyProduktu = function () {
        this.pripravitCenuProduktu.emit();
    };
    return VytvoritObjednavkuService;
}());
export { VytvoritObjednavkuService };
