<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<div class="info-container no-padding">
    <h3 class="info-title span-2">{{ 'slevovyKupon.nadpis.produkty' | lokalizace }}</h3>

    <table class="highlight span-2">
        <thead>
            <tr>
                <th>{{ 'slevovyKupon.nazev' | lokalizace }}</th>
                <th *ngIf="vytvorit">{{ 'slevovyKupon.produkty.tabulka.akce' | lokalizace }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="produkty.length > 0">
                <ng-container *ngIf="slevovyKupon.produkty.length === 0">
                    <tr>
                        <td class="value-column">{{ 'slevovyKupon.produkty.tabulka.zadnyProdukt' | lokalizace }}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="slevovyKupon.produkty.length > 0">
                    <tr *ngFor="let produkt of slevovyKupon.produkty; let i=index">
                        <td class="value-column">{{ pripravitNazevProduktu(produkt) }}</td>
                        <td *ngIf="vytvorit"><a (click)="smazatProdukt(i)">{{ 'tlacitko.smazat' | lokalizace }}</a></td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>

    <div class="button-area span-2" *ngIf="vytvorit">
        <a (click)="otevritModalniPanel()" class="btn waves-effect waves-light right" data-target="produkty-modalni-panel">
            {{ 'tlacitko.pridat' | lokalizace }}
        </a>
    </div>
</div>

<div #modalniPanel id="produkty-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'slevovyKupon.nadpis.pridatProdukt' | lokalizace }}
    </div>
    <div class="modal-content modal-body form-column-layout">
        <ng-container *ngIf="vytvorit">
            <app-formular-checkbox *ngFor="let produkt of produkty"
                                   class="span-6"
                                   [popisek]="produkt.nazev"
                                   [odeslan]="odeslan"
                                   [ovladacFormulare]="formGroup.controls[produkt.identifikator]"></app-formular-checkbox>
        </ng-container>
        <div class="span-12 buttons">
            <button type="submit" (click)="hromadneNastavitStavVsemProduktum(true)" class="btn btn-secondary">
                {{ 'tlacitko.vybratVse' | lokalizace }}
            </button>
            <button type="submit" (click)="hromadneNastavitStavVsemProduktum(false)" class="btn btn-secondary">
                {{ 'tlacitko.odebratVse' | lokalizace }}
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="zavritModalniPanel()" class="waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
        <button (click)="pridatProdukty()" class="waves-effect waves-light btn-flat">
            {{ 'tlacitko.ulozit' | lokalizace }}
        </button>
    </div>
</div>
