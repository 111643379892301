<section *ngIf="pripravitMaximalniPocetStranek() !== undefined" class="pagination">
    <button (click)="predchoziStranka()" [class.disabled]="jePrvniStranka() || pripravitCelkovyPocetZaznamu() === undefined" class="btn waves-effect waves-light">
        <i class="material-icons left">keyboard_arrow_left</i>
        <b>{{ 'tlacitko.predchozi' | lokalizace }}</b>
    </button>
    <button (click)="dalsiStranka()" [class.disabled]="jePosledniStranka() || pripravitCelkovyPocetZaznamu() === undefined" class="btn waves-effect waves-light">
        <b>{{ 'tlacitko.dalsi' | lokalizace }}</b>
        <i class="material-icons right">keyboard_arrow_right</i>
    </button>
</section>
