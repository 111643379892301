import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {NastrojeJsonu} from '../../shared/nastroje/nastroje-jsonu';
import {Objednavka} from '../../data/e-shop/objednavka';
import {prostredi} from '../../../prostredi/prostredi';
import {Cena} from '../../data/e-shop/cena';
import {ZruseniObjednavky} from '../../data/e-shop/zruseni-objednavky';

@Injectable()
export class ObjednavkaService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopDetailObjednavky;
    }

    public vytvoritObjednavku(objednavka: Objednavka): Observable<any> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/', NastrojeJsonu.stringify(objednavka))
            .catch(this.zpracovatChybu);
    }

    public zrusitObjednavku(zruseniObjednavky: ZruseniObjednavky): Observable<any> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zrusit-objednavku', NastrojeJsonu.stringify(zruseniObjednavky))
            .catch(this.zpracovatChybu);
    }

    public nacistObjednavku(identifikatorObjednavky: string): Observable<Objednavka> {
        return this.pozadavekSTokenem(TypPozadavku.GET, 'detail/' + identifikatorObjednavky)
            .catch(this.zpracovatChybu);
    }

    public stahnoutZalohovouFakturu(identifikator: string) {
        return this.pozadavekStahnoutSoubor('/stahnout-zalohovou-fakturu/' + identifikator, true)
            .catch(this.zpracovatChybu);
    }

    public pripravitSlevuZprechoduNaJinePredplatne(objednavka: Objednavka): Observable<Cena> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/sleva-z-prechodu', NastrojeJsonu.stringify(objednavka))
            .catch(this.zpracovatChybu);
    }
}
