import {Component, OnInit} from '@angular/core';
import {SeznamSlevovychKuponuNastaveniService} from '../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';

@Component({
    selector: 'app-seznam-slevovych-kuponu',
    templateUrl: './seznam-slevovych-kuponu.component.html',
    styleUrls: ['./seznam-slevovych-kuponu.component.scss']
})
export class SeznamSlevovychKuponuComponent implements OnInit {

    constructor(
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
    }

    ngOnInit(): void {
        this.seznamSlevovychKuponuNastaveniService.inicializovat();
        this.seznamSlevovychKuponuService.aktualizovat(false);
    }
}
