import {NgModule} from '@angular/core';
import {Lokalizace} from '../lokalizace';
import {PouzeCisliceDirective} from './directives/pouze-cislice.directive';
import {AdresaPipe} from './pipes/adresa.pipe';
import {CenaPipe} from './pipes/cena.pipe';
import {DatumPipe} from './pipes/datum.pipe';
import {KlicePipe} from './pipes/klice.pipe';
import {LokalizacePipe} from './pipes/lokalizace.pipe';
import {VelikostSouboruPipe} from './pipes/velikost-souboru.pipe';
import {VyctovyTypPipe} from './pipes/vyctovy-typ.pipe';
import {DecimalPipe} from '@angular/common';
import {SlevaPipe} from './pipes/sleva.pipe';

@NgModule({
    declarations: [
        LokalizacePipe,
        KlicePipe,
        AdresaPipe,
        CenaPipe,
        VelikostSouboruPipe,
        PouzeCisliceDirective,
        DatumPipe,
        VyctovyTypPipe,
        SlevaPipe
    ],
    exports: [
        LokalizacePipe,
        KlicePipe,
        AdresaPipe,
        CenaPipe,
        VelikostSouboruPipe,
        PouzeCisliceDirective,
        DatumPipe,
        VyctovyTypPipe,
        SlevaPipe
    ],
    providers: [
        DecimalPipe,
        Lokalizace,
        VyctovyTypPipe
    ]
})
export class NastrojeModule {
}
