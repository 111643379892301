import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {AbstraktniFiltrComponent} from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';
import {SeznamSlevovychKuponuService} from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import {TypUceluFormulare} from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeFiltrace} from '../../../../../shared/nastroje/nastroje-filtrace';
import {SeznamSlevovychKuponuNastaveniService} from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {FiltrSeznamuSlevovychKuponu} from '../../../../../data/seznam/seznam-slevovych-kuponu/filtr/filtr-seznamu-slevovych-kuponu';
import {ValidatoryRozsahu} from '../../../../../shared/formular/validator/validatory-rozsahu';

@Component({
    selector: 'app-seznam-slevovych-kuponu-filtr',
    templateUrl: './seznam-slevovych-kuponu-filtr.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-filtr.component.scss', './seznam-slevovych-kuponu-filtr.component.ie.scss']
})
export class SeznamSlevovychKuponuFiltrComponent extends AbstraktniFiltrComponent implements OnInit {

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
        super(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService, autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    }

    odeslat(): void {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuSlevovychKuponu(), this.formGroup));
    }

    private inicializovatFormularovaData(): void {
        this.formGroup.addControl('nazev', new FormControl('', []));
        this.formGroup.addControl('datumPlatnostiRozsahOd', new FormControl('', []));
        this.formGroup.addControl('datumPlatnostiRozsahDo', new FormControl('', []));

        this.formGroup.setValidators([
            ValidatoryRozsahu.rozsah('datumPlatnostiRozsahOd', 'datumPlatnostiRozsahDo', 'Date')
        ]);
    }
}
