import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { UcelSeznamu } from '../../../../../shared/seznam/enum/ucel-seznamu.enum';
import { Notifikace, Uroven } from '../../../../../shared/notifikace';
var ZvolitUzivateleComponent = /** @class */ (function () {
    function ZvolitUzivateleComponent(router, vytvoritObjednavkuService, seznamUzivateluSDodavatelemService, seznamUzivateluSDodavatelemNastaveniService) {
        this.router = router;
        this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
        this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        this.prejitNaKartu = new EventEmitter();
        this.ucelSeznamu = UcelSeznamu;
    }
    ZvolitUzivateleComponent.prototype.ngOnInit = function () {
        this.seznamUzivateluSDodavatelemNastaveniService.inicializovat();
        this.seznamUzivateluSDodavatelemService.aktualizovat(false);
    };
    ZvolitUzivateleComponent.prototype.odeslat = function () {
        if (this.vytvoritObjednavkuService.jeUzivatelVybran()) {
            this.nasledujiciKarta();
        }
        else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'formular.validace.nevybranyUzivatel');
        }
    };
    ZvolitUzivateleComponent.prototype.presmerovatPriZruseni = function () {
        this.router.navigate(['/sekce-operatora']);
    };
    ZvolitUzivateleComponent.prototype.nasledujiciKarta = function () {
        this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_PRODUKT);
    };
    return ZvolitUzivateleComponent;
}());
export { ZvolitUzivateleComponent };
