import {Router} from '@angular/router';

export class NastrojeOdrolovani {

    public static odrolovatNaIdElementuPriPresmerovani(router: Router, idElementu: string) {
        router.events.subscribe(() => {
            this.odrolovat(idElementu);
        });
    }

    public static odrolovat(idElementu: string): void {
        document.getElementById(idElementu).scrollIntoView();
    }
}
