import {Stat} from './enum/stat.enum';
import {TypAdresy} from '../dodavatel/enum/typ-adresy.enum';
import {Nuts} from './nuts';

export class Adresa {
    id?: number;
    ulice: string;
    obec: string;
    castObce?: string;
    cisloPopisne: string;
    cisloOrientacni?: string;
    psc: string;
    stat: Stat;
    typAdresy: TypAdresy;
    nuts: Nuts;
}
