var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
import { prostredi } from '../../../prostredi/prostredi';
var FakturaService = /** @class */ (function (_super) {
    __extends(FakturaService, _super);
    function FakturaService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopDetailFaktury;
        return _this;
    }
    FakturaService.prototype.nacistAktivniPredplatne = function () {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/aktivni-predplatne')
            .catch(this.zpracovatChybu);
    };
    FakturaService.prototype.nacistFaktury = function (identifikator) {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/seznam-faktur/' + identifikator)
            .catch(this.zpracovatChybu);
    };
    FakturaService.prototype.stahnoutFakturu = function (identifikator) {
        return this.pozadavekStahnoutSoubor('/stahnout-fakturu/' + identifikator, true)
            .catch(this.zpracovatChybu);
    };
    return FakturaService;
}(AbstraktniService));
export { FakturaService };
