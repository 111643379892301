var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { prostredi } from '../../../../../prostredi/prostredi';
import { InformaceOprihlasenemUzivateli } from '../../../../data/dodavatel/informace-oprihlasenem-uzivateli';
import { AbstraktniOpravneniComponent } from '../../../../service/bezpecnost/abstraktni-opravneni.component';
import { NastrojeLokalizace } from '../../../nastroje/nastroje-lokalizace';
var AbstraktniTypZobrazeniHlavnihoMenuComponent = /** @class */ (function (_super) {
    __extends(AbstraktniTypZobrazeniHlavnihoMenuComponent, _super);
    function AbstraktniTypZobrazeniHlavnihoMenuComponent(autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.autentizaceService = autentizaceService;
        _this.urlPODO = prostredi.urlPODO;
        _this.urlCDD = prostredi.urlCDD;
        _this.prihlaseny = false;
        _this.prihlasenyAdministrator = false;
        _this.prihlasenyOperator = false;
        return _this;
    }
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.ngOnInit = function () {
        this.nastavitVychoziJazykovouMutaci();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.prihlasit = function () {
        this.autentizaceService.prihlasitCddUzivatele();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.odhlasit = function () {
        this.autentizaceService.odhlasit();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.nastavitAnglickouJazykovouMutaci = function () {
        NastrojeLokalizace.nastavitAnglickouJazykovouMutaci();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.nastavitCeskouJazykovouMutaci = function () {
        NastrojeLokalizace.nastavitCeskouJazykovouMutaci();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.jeNastavenaVychoziJazykovaMutace = function () {
        return NastrojeLokalizace.jeNastavenaVychoziJazykovaMutace();
    };
    AbstraktniTypZobrazeniHlavnihoMenuComponent.prototype.nastavitVychoziJazykovouMutaci = function () {
        NastrojeLokalizace.nastavitVychoziJazykovouMutaci();
    };
    return AbstraktniTypZobrazeniHlavnihoMenuComponent;
}(AbstraktniOpravneniComponent));
export { AbstraktniTypZobrazeniHlavnihoMenuComponent };
