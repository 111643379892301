import { TypPredplatneho } from '../../data/e-shop/enum/predplatne.enum';
import { FakturacniAdresa } from '../../data/e-shop/fakturacni-adresa';
import { Objednavka } from '../../data/e-shop/objednavka';
import { Stat } from '../../data/organizace/enum/stat.enum';
import { NastrojeRetezce } from './nastroje-retezce';
var NastrojePrevoduObjednavky = /** @class */ (function () {
    function NastrojePrevoduObjednavky() {
    }
    NastrojePrevoduObjednavky.prevestPodoObjednavkyJsonDoObjednavky = function (podoOrderJsonInput) {
        var objednavka = new Objednavka();
        if (podoOrderJsonInput.user) {
            objednavka.jmeno = podoOrderJsonInput.user.firstName;
            objednavka.prijmeni = podoOrderJsonInput.user.lastName;
            objednavka.email = podoOrderJsonInput.user.email;
            objednavka.telefon = podoOrderJsonInput.user.phone ? NastrojeRetezce.odebratMezeryZRetezce(podoOrderJsonInput.user.phone) : podoOrderJsonInput.user.phone;
        }
        if (podoOrderJsonInput.company) {
            objednavka.nazevOrganizace = podoOrderJsonInput.company.name;
            objednavka.ic = podoOrderJsonInput.company.ico ? NastrojeRetezce.odebratMezeryZRetezce(podoOrderJsonInput.company.ico) : podoOrderJsonInput.company.ico;
            objednavka.dic = podoOrderJsonInput.company.dic;
            objednavka.fakturacniAdresa = new FakturacniAdresa();
            objednavka.fakturacniAdresa.ulice = podoOrderJsonInput.company.street;
            objednavka.fakturacniAdresa.cisloPopisne = podoOrderJsonInput.company.streetNumber;
            objednavka.fakturacniAdresa.obec = podoOrderJsonInput.company.city;
            objednavka.fakturacniAdresa.psc = podoOrderJsonInput.company.postalCode;
            objednavka.fakturacniAdresa.stat = Stat[podoOrderJsonInput.company.country];
        }
        if (podoOrderJsonInput.product) {
            objednavka.nazevProduktu = podoOrderJsonInput.product.name;
            if (podoOrderJsonInput.product.subscriptionType === 'ANNUAL') {
                objednavka.typPredplatneho = TypPredplatneho.ROCNI;
            }
            else if (podoOrderJsonInput.product.subscriptionType === 'RECURRING_MONTHLY') {
                objednavka.typPredplatneho = TypPredplatneho.MESICNI_S_OBNOVOU;
            }
        }
        return objednavka;
    };
    return NastrojePrevoduObjednavky;
}());
export { NastrojePrevoduObjednavky };
