import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeCisla {

    public static readonly VYCHOZI_ZAOKROUHLENI = 0;
    public static readonly VZOR_INTEGER = '^([1-9])(\\d|\\s)*$';
    public static readonly VZOR_DECIMAL = '^(?=.*[1-9])(\\s|\\d)*(\,(\\s|\\d)*)?$';
    public static readonly VZOR_DECIMAL_DOT_AND_COMMA = '^(?=.*[1-9])(\\s|\\d)*(?:[\,\.](\\s|\\d)*)?$';

    public static prevestNaCislo(vstup: any): number {
        if (vstup) {
            const hodnota = vstup.toString().replace(new RegExp('\\s', 'g'), '').replace(',', '.');

            return Number(hodnota);
        }

        return vstup;
    }

    public static formatovatCislo(hodnota: number, zaokrouhleni: number = this.VYCHOZI_ZAOKROUHLENI) {
        const formatCisla = require('format-number');
        const format = formatCisla({prefix: '', suffix: '', integerSeparator: ' ', decimal: ',', round: zaokrouhleni});

        return format(hodnota, {noSeparator: false});
    }

    public static formatovatCisloSeZarovnanimZprava(hodnota: number, zaokrouhleni: number = this.VYCHOZI_ZAOKROUHLENI) {
        const formatCisla = require('format-number');
        const format = formatCisla({
            prefix: '',
            suffix: '',
            integerSeparator: ' ',
            decimal: ',',
            round: zaokrouhleni,
            padRight: zaokrouhleni
        });

        return format(hodnota, {noSeparator: false});
    }

    public static formatovatCisloSOdebranimNadbytecnychNul(hodnota: number, zaokrouhleni: number = this.VYCHOZI_ZAOKROUHLENI) {
        const formatovaneCislo = this.formatovatCislo(hodnota, zaokrouhleni);
        return formatovaneCislo.indexOf(',') !== -1 ? formatovaneCislo.replace(/0+$/, '') : formatovaneCislo;
    }

    public static nasobitCisla(cislo1: number, cislo2: number): number {
        return cislo1 * cislo2;
    }

    public static delitCisla(cislo1: number, cislo2: number): number {
        return cislo2 === 0 ? 0 : cislo1 / cislo2;
    }

    public static secistCisla(cislo1: number, cislo2: number): number {
        return cislo1 ? cislo1 + cislo2 : cislo2;
    }

    public static jeCislo(hodnota: any): boolean {
        return !NastrojeObecne.jeHodnotaPrazdna(hodnota) && !isNaN(Number(hodnota));
    }
}
