import { LokalniUlozisteNastaveni } from './lokalni-uloziste/lokalni-uloziste-nastaveni';
import { NastrojeJsonu } from './nastroje-jsonu';
var NastrojeAutentizace = /** @class */ (function () {
    function NastrojeAutentizace() {
    }
    NastrojeAutentizace.ulozitInformaceOUzivateliDoLocalStorage = function (informaceOPrihlasenemUzivateli) {
        localStorage.setItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI, NastrojeJsonu.stringify(informaceOPrihlasenemUzivateli));
    };
    NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage = function () {
        return NastrojeJsonu.parse(localStorage.getItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI));
    };
    NastrojeAutentizace.odstranitInformaceOUzivateliZLocalStorage = function () {
        localStorage.removeItem(this.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI);
    };
    NastrojeAutentizace.pripravitNastaveniKeycloaku = function (keycloakUrl, realm, clientId, clientSecret) {
        return {
            initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false
            },
            config: {
                url: keycloakUrl,
                realm: realm,
                clientId: clientId,
                'credentials': {
                    'secret': clientSecret
                }
            },
            enableBearerInterceptor: false,
        };
    };
    NastrojeAutentizace.KLIC_DAT_UZIVATELE_V_LOKALNIM_ULOZISTI = LokalniUlozisteNastaveni.NAZEV_AKTUALNI_VERZE_PROSTREDI + LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE + 'informaceOPrihlasenemUzivateli';
    return NastrojeAutentizace;
}());
export { NastrojeAutentizace };
