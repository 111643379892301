import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-vrtitko',
    templateUrl: './vrtitko.component.html',
    styleUrls: ['./vrtitko.component.scss']
})
export class VrtitkoComponent {

    @Input() zobrazit = false;

    constructor() {
    }
}
