import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NastrojeOdrolovani} from './shared/nastroje/nastroje-odrolovani';
import {AutentizaceService} from './service/bezpecnost/autentizace.service';
import {NastrojeAutentizace} from './shared/nastroje/nastroje-autentizace';
import {OptimalizaceProVyhledavaceService} from './service/obecne/optimalizace-pro-vyhledavace.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    readonly idElementuProOdrolovani = 'zacatek-stranky';

    constructor(
        private router: Router,
        private autentizaceService: AutentizaceService,
        private optimalizaceProVyhledavaceService: OptimalizaceProVyhledavaceService
    ) {
    }

    ngOnInit() {
        this.optimalizaceProVyhledavaceService.optimalizovatStrankuProVyhledavace();
        this.odrolovatNaZacatekStranky();
        this.zkontrolovatExpiraciTokenuASmazatInformaceOUzivateliZLocalStorage();
    }

    private odrolovatNaZacatekStranky() {
        NastrojeOdrolovani.odrolovatNaIdElementuPriPresmerovani(this.router, this.idElementuProOdrolovani);
    }

    private zkontrolovatExpiraciTokenuASmazatInformaceOUzivateliZLocalStorage() {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            NastrojeAutentizace.odstranitInformaceOUzivateliZLocalStorage();
        }
    }
}
