import {AbstraktniService} from '../obecne/abstraktni-service';
import {NastrojeOdrolovani} from '../../shared/nastroje/nastroje-odrolovani';

export abstract class AbstraktniSeznamService extends AbstraktniService {

    protected readonly idElementuProOdrolovani = 'formular-razeni';

    public abstract seznam: any;

    public abstract aktualizovat(odrolovatNaZacatekSeznamu: boolean): void;

    protected odrolovatNaZacatekSeznamu() {
        NastrojeOdrolovani.odrolovat(this.idElementuProOdrolovani);
    }
}
