import { ElementRef, OnInit } from '@angular/core';
import { FakturaService } from '../../../service/e-shop/faktura.service';
import { NastrojeDataACasu } from '../../../shared/nastroje/nastroje-data-a-casu';
import { prostredi } from '../../../../prostredi/prostredi';
import { saveAs } from 'file-saver';
var TabulkaFakturStrankovanaComponent = /** @class */ (function () {
    function TabulkaFakturStrankovanaComponent(fakturaService) {
        this.fakturaService = fakturaService;
        this.formatData = NastrojeDataACasu.FORMAT_DATA;
        this.pocetZaznamuNaStranku = 5;
        this.zakladniUrl = prostredi.zakladniUrl;
        this.faktury = [];
        this.indexAktualniStranky = 1;
        this.jeStrankaPrvni = true;
        this.jeStrankaPosledni = false;
        this.zobrazeneFaktury = [];
        this.zobrazitVrtitko = false;
        this.blobNastaveni = { type: 'application/pdf' };
    }
    TabulkaFakturStrankovanaComponent.prototype.ngOnInit = function () {
        this.pripravitFaktury();
    };
    TabulkaFakturStrankovanaComponent.prototype.predchoziStranka = function () {
        this.indexAktualniStranky -= 1;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    };
    TabulkaFakturStrankovanaComponent.prototype.dalsiStranka = function () {
        this.indexAktualniStranky += 1;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    };
    TabulkaFakturStrankovanaComponent.prototype.nastavitStranku = function () {
        var element = $(this.vstupniPole.nativeElement);
        var hodnota = element.val();
        if (hodnota <= 0) {
            hodnota = 1;
        }
        else if (hodnota > this.maximalniPocetStranek) {
            hodnota = this.maximalniPocetStranek;
        }
        element.blur();
        element.val(hodnota);
        this.indexAktualniStranky = hodnota;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    };
    TabulkaFakturStrankovanaComponent.prototype.stahnoutFakturu = function (identifikator) {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.fakturaService.stahnoutFakturu(identifikator)
            .subscribe(function (data) {
            var blob = new Blob([data], _this.blobNastaveni);
            saveAs(blob, identifikator + '.pdf');
            _this.zobrazitVrtitko = false;
        }, function () {
            _this.zobrazitVrtitko = false;
        });
    };
    TabulkaFakturStrankovanaComponent.prototype.pripravitFaktury = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.fakturaService.nacistFaktury(this.identifikatorObjednavky)
            .toPromise()
            .then(function (odpoved) {
            _this.faktury = odpoved;
            _this.maximalniPocetStranek = Math.ceil(_this.faktury.length / _this.pocetZaznamuNaStranku);
            _this.pripravitZobrazeneFaktury();
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    TabulkaFakturStrankovanaComponent.prototype.pripravitZobrazeneFaktury = function () {
        var noveZobrazeneFaktury = [];
        var index = this.indexAktualniStranky * this.pocetZaznamuNaStranku - this.pocetZaznamuNaStranku;
        var posledniPolozka = this.indexAktualniStranky * this.pocetZaznamuNaStranku < this.faktury.length + 1 ? this.indexAktualniStranky * this.pocetZaznamuNaStranku : this.faktury.length;
        for (var i = index; i < posledniPolozka; i++) {
            noveZobrazeneFaktury.push(this.faktury[i]);
        }
        this.zobrazeneFaktury = noveZobrazeneFaktury;
    };
    TabulkaFakturStrankovanaComponent.prototype.pripravJeStrankaPrvni = function () {
        this.jeStrankaPrvni = this.indexAktualniStranky === 1;
    };
    TabulkaFakturStrankovanaComponent.prototype.pripravJeStrankaPosledni = function () {
        this.jeStrankaPosledni = this.indexAktualniStranky === this.maximalniPocetStranek;
    };
    return TabulkaFakturStrankovanaComponent;
}());
export { TabulkaFakturStrankovanaComponent };
