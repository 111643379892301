<ng-template [ngIf]="!vicenasobnyVyberPolozek">
    <div [formGroup]="nadrazenaSkupinaFormulare">
        <div class="input-field" [class.responsive-input-field]="zmensitRozestupy">
            <select #roletka
                    formControlName={{nazevOvladaceFormulare}}
                    [attr.disabled]="zneaktivnit() ? true : null"
                    [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
                    [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid">
                <option *ngIf="prvniHodnotaNull" value="" disabled selected>{{'formular.roletka.nezvoleno' | lokalizace}}</option>
                <ng-container *ngIf="!hodnotaKliceZObjektu">
                    <option *ngFor="let p of polozky | klice" [value]="p.key">{{p.value | vyctovyTyp: polozky}}</option>
                </ng-container>
                <ng-container *ngIf="hodnotaKliceZObjektu">
                    <option *ngFor="let p of polozky" [value]="p[nazevKliceObjektu]">{{p[nazevHodnotyObjektu] | lokalizace}}</option>
                </ng-container>
            </select>
            <label class="label asterisk-placeholder">{{popisek | lokalizace}}</label>
            <span *ngIf="!skrytPomocnyText" class="helper-text"
                  [attr.data-error]="pripravitChybovouZpravu()"
                  [attr.data-success]="''">
            {{pomocnyText | lokalizace}}
            </span>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="vicenasobnyVyberPolozek">
    <div [formGroup]="nadrazenaSkupinaFormulare">
        <div class="input-field">
            <select multiple #roletka
                    formControlName={{nazevOvladaceFormulare}}
                    [attr.disabled]="zneaktivnit() ? true : null"
                    [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
                    [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid">
                <option *ngIf="prvniHodnotaNull" value="" disabled selected>{{'formular.roletka.nezvoleno' | lokalizace}}</option>
                <ng-container *ngIf="!hodnotaKliceZObjektu">
                    <option *ngFor="let p of polozky | klice" [value]="p.key">{{p.value | vyctovyTyp: polozky}}</option>
                </ng-container>
                <ng-container *ngIf="hodnotaKliceZObjektu">
                    <option *ngFor="let p of polozky" [value]="p[nazevKliceObjektu]">{{p[nazevHodnotyObjektu] | lokalizace}}</option>
                </ng-container>
            </select>
            <label class="label asterisk-placeholder">{{popisek | lokalizace}}</label>
            <span *ngIf="!skrytPomocnyText" class="helper-text"
                  [attr.data-error]="pripravitChybovouZpravu()"
                  [attr.data-success]="''">
            {{pomocnyText | lokalizace}}
            </span>
        </div>
    </div>
</ng-template>
