import {Component, OnInit} from '@angular/core';
import {SeznamObjednavekNastaveniService} from '../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';

@Component({
    selector: 'app-seznam-objednavek',
    templateUrl: './seznam-objednavek.component.html',
    styleUrls: ['./seznam-objednavek.component.scss']
})
export class SeznamObjednavekComponent implements OnInit {

    constructor(private seznamObjednavekNastaveniService: SeznamObjednavekNastaveniService, private seznamObjednavekService: SeznamObjednavekService) {
    }

    ngOnInit() {
        this.seznamObjednavekNastaveniService.inicializovat();
        this.seznamObjednavekService.aktualizovat(false);
    }
}
