var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypUceluFormulare } from '../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AbstraktniOpravneniComponent } from '../../service/bezpecnost/abstraktni-opravneni.component';
import { AutentizaceService } from '../../service/bezpecnost/autentizace.service';
import { NastrojeFormulare } from '../nastroje/nastroje-formulare';
import { NastrojeObecne } from '../nastroje/nastroje-obecne';
import { Notifikace, Uroven } from '../notifikace';
import { isBoolean, isNumber } from 'util';
var AbstraktniFormularComponent = /** @class */ (function (_super) {
    __extends(AbstraktniFormularComponent, _super);
    function AbstraktniFormularComponent(autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.autentizaceService = autentizaceService;
        _this.klicVychoziChyboveHlasky = 'formular.chyba.formularObsahujeChyby';
        _this.odeslan = false;
        _this.zobrazitVrtitko = false;
        _this.idKomponentyFormulare = NastrojeObecne.vygenerovatNahodnyRetezec();
        return _this;
    }
    Object.defineProperty(AbstraktniFormularComponent.prototype, "pripravitIdKomponentyFormulare", {
        get: function () {
            return this.idKomponentyFormulare;
        },
        enumerable: true,
        configurable: true
    });
    AbstraktniFormularComponent.prototype.onKeyup = function (event) {
        var kod = event.which || event.keyCode;
        if (kod === 13 && this.jeElementTextovePole(event.target) && this.jeElementPrvkemTohotoFormulare(event.target) && !this.jeElementTextovePoleSAkci(event.target)) {
            this.onSubmit();
        }
    };
    AbstraktniFormularComponent.prototype.inicializovatFormular = function (typUceluFormulare) {
        this.formGroup = this.formBuilder.group([]);
        NastrojeFormulare.nastavitTypUceluFormulare(this.formGroup, typUceluFormulare);
    };
    AbstraktniFormularComponent.prototype.onSubmit = function () {
        this.odeslan = true;
        this.provestTrimNaVsechPrvcich();
        this.zpracovatHodnotyNeaktivnichPrvku();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) {
            this.prevestPrazdneHodnotyNaNedefinovane();
            this.odeslat();
        }
        else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, this.klicVychoziChyboveHlasky);
        }
    };
    AbstraktniFormularComponent.prototype.resetovatFormular = function () {
        NastrojeFormulare.resetovatFormularovaData(this.formGroup);
        this.odeslan = false;
    };
    AbstraktniFormularComponent.prototype.jeTypUceluFormulare = function (typUceluFormulare) {
        return NastrojeFormulare.pripravitTypUceluFormulare(this.formGroup) === typUceluFormulare;
    };
    AbstraktniFormularComponent.prototype.prevestPrazdneHodnotyNaNedefinovane = function () {
        var _this = this;
        Object.keys(this.formGroup.controls).forEach(function (key) {
            if (_this.formGroup.get(key).value === '') {
                NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(_this.formGroup.get(key), undefined);
            }
        });
    };
    AbstraktniFormularComponent.prototype.zpracovatHodnotyNeaktivnichPrvku = function () {
        var _this = this;
        if (this.jeTypUceluFormulare(TypUceluFormulare.VYTVORENI)) {
            Object.keys(this.formGroup.controls).forEach(function (key) {
                if (_this.formGroup.get(key).disabled) {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(_this.formGroup.get(key), undefined);
                }
            });
        }
    };
    AbstraktniFormularComponent.prototype.provestTrimNaVsechPrvcich = function () {
        var _this = this;
        Object.keys(this.formGroup.controls).forEach(function (key) {
            var prvek = _this.formGroup.get(key);
            if (!NastrojeObecne.jeHodnotaPrazdna(prvek.value)) {
                if (_this.lzePouzitTrimNaHodnotuPrvku(prvek.value)) {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(prvek, prvek.value.trim());
                }
                else {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(prvek, prvek.value);
                }
            }
        });
    };
    AbstraktniFormularComponent.prototype.lzePouzitTrimNaHodnotuPrvku = function (hodnota) {
        return !(hodnota instanceof Array || isBoolean(hodnota) || isNumber(hodnota));
    };
    AbstraktniFormularComponent.prototype.jeElementPrvkemTohotoFormulare = function (element) {
        return $(element).closest("[idkomponentyformulare=" + this.idKomponentyFormulare + "]").length;
    };
    AbstraktniFormularComponent.prototype.jeElementTextovePole = function (element) {
        return $(element).is('input');
    };
    AbstraktniFormularComponent.prototype.jeElementTextovePoleSAkci = function (element) {
        return $(element).closest('app-formular-input-s-akci').length > 0;
    };
    return AbstraktniFormularComponent;
}(AbstraktniOpravneniComponent));
export { AbstraktniFormularComponent };
