var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
var FakturacniUdajeService = /** @class */ (function (_super) {
    __extends(FakturacniUdajeService, _super);
    function FakturacniUdajeService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '/fakturacni-udaje';
        return _this;
    }
    FakturacniUdajeService.prototype.nacistFakturacniUdaje = function (idUzivatele) {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/uzivatel/' + idUzivatele)
            .catch(this.zpracovatChybu);
    };
    return FakturacniUdajeService;
}(AbstraktniService));
export { FakturacniUdajeService };
