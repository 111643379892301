<a href="{{ urlPODO }}" class="menu__link" routerLinkActive="menu__link-active">{{ 'hlavniMenu.portalDodavatele' | lokalizace }}</a>
<a href="{{ urlCDD }}" class="menu__link" routerLinkActive="menu__link-active">{{ 'hlavniMenu.cdd' | lokalizace }}</a>

<a *ngIf="!prihlaseny"
   class="menu__link"
   (click)="prihlasit()"><b>{{ 'tlacitko.prihlasit' | lokalizace }}</b></a>

<a *ngIf="prihlaseny && informaceOPrihlasenemUzivateli"
   class="menu__link dropdown-trigger"
   data-target="dropdown">
    {{ informaceOPrihlasenemUzivateli.informaceOUzivateli.krestniJmeno }} {{ informaceOPrihlasenemUzivateli.informaceOUzivateli.prijmeni }}
    <i class="material-icons dropdown-iconNextToItem">arrow_drop_down</i>
    {{ inicializovatRozbalovaciMenu() }}
</a>

<a *ngIf="prihlaseny && (prihlasenyAdministrator || prihlasenyOperator) && jmenoAPrijmeniSpravceDatabazeCiOperatora"
   class="menu__link dropdown-trigger"
   data-target="dropdown">
    {{ jmenoAPrijmeniSpravceDatabazeCiOperatora }}<i class="material-icons dropdown-iconNextToItem">arrow_drop_down</i>
    {{ inicializovatRozbalovaciMenu() }}
</a>

<ul id="dropdown" class="dropdown-content">
    <li *ngIf="prihlaseny && informaceOPrihlasenemUzivateli">
        <a href="{{ urlCDD + '/#/uzivatel/' + informaceOPrihlasenemUzivateli.informaceOUzivateli.id }}">{{ 'hlavniMenu.prihlasen.detailUzivatele' | lokalizace }}</a>
    </li>

    <li *ngIf="prihlaseny && !prihlasenyAdministrator && informaceOPrihlasenemUzivateli">
        <a routerLink="e-shop/platby-a-objednavky">{{ 'hlavniMenu.prihlasen.platbyAObjednavky' | lokalizace }}</a>
    </li>

    <li *ngIf="prihlaseny && prihlasenyOperator"><a
            routerLink="/sekce-operatora">{{ 'hlavniMenu.prihlasen.sekceOperatora' | lokalizace }}</a>
    </li>

    <li *ngIf="prihlaseny && prihlasenyOperator"><a
            routerLink="/slevove-kupony">{{ 'hlavniMenu.prihlasen.slevoveKupony' | lokalizace }}</a>
    </li>

    <div class="divider"></div>

    <li (click)="odhlasit()"><a>{{ 'tlacitko.odhlasit' | lokalizace }}</a></li>
</ul>

<a *ngIf="jeNastavenaVychoziJazykovaMutace()" class="menu__link" (click)="nastavitAnglickouJazykovouMutaci()">
    <b>{{ 'lokalizace.vyberJazyka.anglicky' | lokalizace }}</b>
</a>

<a *ngIf="!jeNastavenaVychoziJazykovaMutace()" class="menu__link" (click)="nastavitCeskouJazykovouMutaci()">
    <b>{{ 'lokalizace.vyberJazyka.cesky' | lokalizace }}</b>
</a>
