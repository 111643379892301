declare var $: any;
declare var M: any;

export class NastrojeWizardu {

	public static inicializovatWizard(element: HTMLElement) {
		M.Tabs.init(element);
	}

	public static prejitNaKartu(idKarty: string) {
		$('.tab a').filter('a[href$="' + idKarty + '"]').click(function() {
			this.click();
		}).click();
	}
}
