var NastrojeStrankovani = /** @class */ (function () {
    function NastrojeStrankovani() {
    }
    NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem = function (nastaveniZobrazeniSeznamuUzivateluSDodavatelem, seznamUzivateluSDodavatelem) {
        return Math.ceil(seznamUzivateluSDodavatelem.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuUzivateluSDodavatelem.strankovani.pocetZaznamu);
    };
    NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek = function (nastaveniZobrazeniSeznamuObjednavek, seznamObjednavek) {
        return Math.ceil(seznamObjednavek.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuObjednavek.strankovani.pocetZaznamu);
    };
    NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora = function (nastaveniZobrazeniSeznamuObjednavekOperatora, seznamObjednavekOperatora) {
        return Math.ceil(seznamObjednavekOperatora.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuObjednavekOperatora.strankovani.pocetZaznamu);
    };
    NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu = function (nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu) {
        return Math.ceil(seznamSlevovychKuponu.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuSlevovychKuponu.strankovani.pocetZaznamu);
    };
    return NastrojeStrankovani;
}());
export { NastrojeStrankovani };
