/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zruseni-objednavky.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "../../../shared/nastroje/pipes/datum.pipe";
import * as i4 from "../../../shared/formular/formularove-prvky/vystup/formular-output/formular-output.component.ngfactory";
import * as i5 from "../../../shared/formular/formularove-prvky/vystup/formular-output/formular-output.component";
import * as i6 from "./zruseni-objednavky.component";
var styles_ZruseniObjednavkyComponent = [i0.styles];
var RenderType_ZruseniObjednavkyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZruseniObjednavkyComponent, data: {} });
export { RenderType_ZruseniObjednavkyComponent as RenderType_ZruseniObjednavkyComponent };
export function View_ZruseniObjednavkyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), i1.ɵpid(0, i3.DatumPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "info-container no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [["class", "info-title span-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "info-group span-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-formular-output", [], null, null, null, i4.View_FormularOutputComponent_0, i4.RenderType_FormularOutputComponent)), i1.ɵdid(8, 49152, null, 0, i5.FormularOutputComponent, [], { popisek: [0, "popisek"], hodnota: [1, "hodnota"] }, null), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-formular-output", [], null, null, null, i4.View_FormularOutputComponent_0, i4.RenderType_FormularOutputComponent)), i1.ɵdid(11, 49152, null, 0, i5.FormularOutputComponent, [], { popisek: [0, "popisek"], hodnota: [1, "hodnota"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "objednavka.zruseniObjednavky.datumZruseniObjednavky"; var currVal_2 = i1.ɵunv(_v, 8, 1, _ck(_v, 9, 0, i1.ɵnov(_v, 1), _co.objednavka.zruseniObjednavky.datum, _co.formatDataACasu)); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = "objednavka.zruseniObjednavky.duvodZruseniObjednavky"; var currVal_4 = _co.objednavka.zruseniObjednavky.duvod; _ck(_v, 11, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "objednavka.zruseniObjednavky")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ZruseniObjednavkyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-zruseni-objednavky", [], null, null, null, View_ZruseniObjednavkyComponent_0, RenderType_ZruseniObjednavkyComponent)), i1.ɵdid(1, 114688, null, 0, i6.ZruseniObjednavkyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ZruseniObjednavkyComponentNgFactory = i1.ɵccf("app-zruseni-objednavky", i6.ZruseniObjednavkyComponent, View_ZruseniObjednavkyComponent_Host_0, { objednavka: "objednavka" }, {}, []);
export { ZruseniObjednavkyComponentNgFactory as ZruseniObjednavkyComponentNgFactory };
