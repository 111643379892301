import {Component, OnInit} from '@angular/core';
import {AutentizaceService} from '../../../../service/bezpecnost/autentizace.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-vytvorit-objednavku-operatora',
    templateUrl: './vytvorit-objednavku-operatora.component.html',
    styleUrls: ['./vytvorit-objednavku-operatora.component.scss']
})
export class VytvoritObjednavkuOperatoraComponent implements OnInit {

    constructor(
        protected autentizaceService: AutentizaceService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        } else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate([`/`]);
        }
    }
}
