import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {StavUzivatele} from '../../../../../data/dodavatel/enum/stav-uzivatele.enum';
import {TypUceluFormulare} from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {FiltrSeznamuUzivateluSDodavatelem} from '../../../../../data/seznam/seznam-uzivatelu-s-dodavatelem/filtr/filtr-seznamu-uzivatelu-s-dodavatelem';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {NastrojeCisla} from '../../../../../shared/nastroje/nastroje-cisla';
import {NastrojeFiltrace} from '../../../../../shared/nastroje/nastroje-filtrace';
import {AbstraktniFiltrComponent} from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-filtr',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-filtr.component.html',
    styleUrls: [
        './seznam-uzivatelu-s-dodavatelem-filtr.component.scss',
        './seznam-uzivatelu-s-dodavatelem-filtr.component.ie.scss'
    ]
})
export class SeznamUzivateluSDodavatelemFiltrComponent extends AbstraktniFiltrComponent implements OnInit {

    public stavUzivatele = StavUzivatele;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        protected seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService,
        protected seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService
    ) {
        super(seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService, autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    }

    odeslat(): void {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuUzivateluSDodavatelem(), this.formGroup));
    }

    private inicializovatFormularovaData() {
        this.formGroup.addControl('id', new FormControl('', [
            Validators.pattern(NastrojeCisla.VZOR_INTEGER)
        ]));

        this.formGroup.addControl('prihlasovaciJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('email', new FormControl('', [
            Validators.maxLength(100)
        ]));

        this.formGroup.addControl('krestniJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('prijmeni', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('stav', new FormControl('', []));

        this.formGroup.addControl('nazevOrganizacniJednotky', new FormControl('', [
            Validators.minLength(2),
            Validators.maxLength(255)
        ]));

        this.formGroup.addControl('ic', new FormControl('', [
            Validators.minLength(2),
            Validators.maxLength(20)
        ]));
    }
}
