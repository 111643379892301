/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-paticka.component.scss.shim.ngstyle";
import * as i1 from "./seznam-objednavek-paticka.component.ie.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./aktualni-stranka/seznam-objednavek-aktualni-stranka.component.ngfactory";
import * as i4 from "./aktualni-stranka/seznam-objednavek-aktualni-stranka.component";
import * as i5 from "../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni";
import * as i6 from "../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service";
import * as i7 from "./strankovani/seznam-objednavek-strankovani.component.ngfactory";
import * as i8 from "./strankovani/seznam-objednavek-strankovani.component";
import * as i9 from "./nastaveni/seznam-objednavek-nastaveni.component.ngfactory";
import * as i10 from "./nastaveni/seznam-objednavek-nastaveni.component";
import * as i11 from "@angular/forms";
import * as i12 from "../../../../../../../service/bezpecnost/autentizace.service";
import * as i13 from "./seznam-objednavek-paticka.component";
var styles_SeznamObjednavekPatickaComponent = [i0.styles, i1.styles];
var RenderType_SeznamObjednavekPatickaComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekPatickaComponent, data: {} });
export { RenderType_SeznamObjednavekPatickaComponent as RenderType_SeznamObjednavekPatickaComponent };
export function View_SeznamObjednavekPatickaComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "section", [["class", "detail-card"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "app-seznam-objednavek-aktualni-stranka", [], null, null, null, i3.View_SeznamObjednavekAktualniStrankaComponent_0, i3.RenderType_SeznamObjednavekAktualniStrankaComponent)), i2.ɵdid(2, 49152, null, 0, i4.SeznamObjednavekAktualniStrankaComponent, [i5.SeznamObjednavekNastaveniService, i6.SeznamObjednavekService], null, null), (_l()(), i2.ɵeld(3, 0, null, null, 1, "app-seznam-objednavek-strankovani", [], null, null, null, i7.View_SeznamObjednavekStrankovaniComponent_0, i7.RenderType_SeznamObjednavekStrankovaniComponent)), i2.ɵdid(4, 49152, null, 0, i8.SeznamObjednavekStrankovaniComponent, [i5.SeznamObjednavekNastaveniService, i6.SeznamObjednavekService], null, null), (_l()(), i2.ɵeld(5, 0, null, null, 1, "app-seznam-objednavek-nastaveni", [], [[1, "idkomponentyformulare", 0]], [[null, "keypress"]], function (_v, en, $event) { var ad = true; if (("keypress" === en)) {
        var pd_0 = (i2.ɵnov(_v, 6).onKeyup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_SeznamObjednavekNastaveniComponent_0, i9.RenderType_SeznamObjednavekNastaveniComponent)), i2.ɵdid(6, 114688, null, 0, i10.SeznamObjednavekNastaveniComponent, [i11.FormBuilder, i12.AutentizaceService, i5.SeznamObjednavekNastaveniService, i6.SeznamObjednavekService], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 6).pripravitIdKomponentyFormulare; _ck(_v, 5, 0, currVal_0); }); }
export function View_SeznamObjednavekPatickaComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-paticka", [], null, null, null, View_SeznamObjednavekPatickaComponent_0, RenderType_SeznamObjednavekPatickaComponent)), i2.ɵdid(1, 49152, null, 0, i13.SeznamObjednavekPatickaComponent, [], null, null)], null, null); }
var SeznamObjednavekPatickaComponentNgFactory = i2.ɵccf("app-seznam-objednavek-paticka", i13.SeznamObjednavekPatickaComponent, View_SeznamObjednavekPatickaComponent_Host_0, {}, {}, []);
export { SeznamObjednavekPatickaComponentNgFactory as SeznamObjednavekPatickaComponentNgFactory };
