import { OnInit } from '@angular/core';
import { prostredi } from '../../../../prostredi/prostredi';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { NastrojeUrl } from '../../../shared/nastroje/nastroje-url';
import { NastrojeObecne } from '../../../shared/nastroje/nastroje-obecne';
import { ActivatedRoute, Router } from '@angular/router';
import { OnlinePlatbyService } from '../../../service/e-shop/online-platby.service';
import { StavPlatby } from '../../../data/e-shop/enum/stav-platby.enum';
import { ZpusobPlatby } from '../../../data/e-shop/enum/zpusob-platby.enum';
var DetailPlatbyComponent = /** @class */ (function () {
    function DetailPlatbyComponent(autentizaceService, onlinePlatbyService, route, router) {
        this.autentizaceService = autentizaceService;
        this.onlinePlatbyService = onlinePlatbyService;
        this.route = route;
        this.router = router;
        this.zobrazitPotvrzeni = false;
        this.zobrazitVrtitko = false;
    }
    DetailPlatbyComponent.prototype.ngOnInit = function () {
        this.inicializovatPromenne();
        this.zpracovatParametrRetezceDotazuZUrl();
        this.pripravitStavPlatby();
    };
    DetailPlatbyComponent.prototype.prejitNaStrankuProfiluPODO = function () {
        NastrojeUrl.otevritOdkazVNoveZalozce(prostredi.urlPODO + '/profil');
    };
    DetailPlatbyComponent.prototype.prejitNaStrankuDetailuObjednavky = function () {
        if (NastrojeObecne.jeHodnotaPrazdna(this.platba.identifikatorObjednavky)) {
            this.router.navigate(['/e-shop/objednavka/' + this.identifikatorObjednavky]);
            return;
        }
        this.router.navigate(['/e-shop/objednavka/' + this.platba.identifikatorObjednavky]);
    };
    DetailPlatbyComponent.prototype.zavritModalniPanel = function () {
        this.zobrazitPotvrzeni = false;
    };
    DetailPlatbyComponent.prototype.inicializovatPromenne = function () {
        this.jePrihlasenCDDUzivatel = this.autentizaceService.jePrihlasenyUzivatelCdd();
    };
    DetailPlatbyComponent.prototype.zpracovatParametrRetezceDotazuZUrl = function () {
        var parametrIdPlatby = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'id');
        var parametrIdentifikatorObjednavky = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'identifikatorObjednavky');
        if (!NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby)) {
            this.idPlatby = Number(parametrIdPlatby);
        }
        else if (NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby) && !NastrojeObecne.jeHodnotaPrazdna(parametrIdentifikatorObjednavky)) {
            this.identifikatorObjednavky = parametrIdentifikatorObjednavky;
        }
        else {
            this.router.navigate(["/"]);
        }
    };
    DetailPlatbyComponent.prototype.pripravitStavPlatby = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        if (NastrojeObecne.jeHodnotaPrazdna(this.identifikatorObjednavky)) {
            this.onlinePlatbyService.nacistStavPlatby(this.idPlatby)
                .toPromise()
                .then(function (odpoved) {
                _this.platba = odpoved;
                if (_this.platba.zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU && _this.platba.stav === StavPlatby.VYTVORENA) {
                    _this.zobrazitPotvrzeni = true;
                }
                _this.zobrazitVrtitko = false;
            }).catch(function () { return _this.zobrazitVrtitko = false; });
        }
        else {
            this.onlinePlatbyService.nacistPosledniPlatbuPodleObjednavky(this.identifikatorObjednavky)
                .toPromise()
                .then(function (odpoved) {
                _this.platba = odpoved;
                if (_this.platba.zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU && _this.platba.stav === StavPlatby.VYTVORENA) {
                    _this.zobrazitPotvrzeni = true;
                }
                _this.zobrazitVrtitko = false;
            }).catch(function () { return _this.zobrazitVrtitko = false; });
        }
    };
    return DetailPlatbyComponent;
}());
export { DetailPlatbyComponent };
