var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { TypPoctuZaznamuNaStranku } from '../../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import { AbstraktniFormularComponent } from '../../../../../../../shared/formular/abstraktni-formular.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AutentizaceService } from '../../../../../../../service/bezpecnost/autentizace.service';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import { TypUceluFormulare } from '../../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeEnumu } from '../../../../../../../shared/nastroje/nastroje-enumu';
import { NastrojeModalnihoPanelu } from '../../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { NastrojeStrankovani } from '../../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamObjednavekOperatoraNastaveniComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraNastaveniComponent, _super);
    function SeznamObjednavekOperatoraNastaveniComponent(formBuilder, autentizaceService, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        _this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
        _this.typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;
        return _this;
    }
    SeznamObjednavekOperatoraNastaveniComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    };
    SeznamObjednavekOperatoraNastaveniComponent.prototype.odeslat = function () {
        var pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamObjednavekOperatoraNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(1);
        this.seznamObjednavekOperatoraService.aktualizovat();
    };
    SeznamObjednavekOperatoraNastaveniComponent.prototype.inicializovatFormularovaData = function () {
        var pocetZaznamuNaStranku = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;
        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    };
    SeznamObjednavekOperatoraNastaveniComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    SeznamObjednavekOperatoraNastaveniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekOperatoraNastaveniComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    };
    return SeznamObjednavekOperatoraNastaveniComponent;
}(AbstraktniFormularComponent));
export { SeznamObjednavekOperatoraNastaveniComponent };
