var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TypUceluFormulare } from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { AbstraktniFormularComponent } from '../../formular/abstraktni-formular.component';
import { NastrojeEnumu } from '../../nastroje/nastroje-enumu';
var RazeniComponent = /** @class */ (function (_super) {
    __extends(RazeniComponent, _super);
    function RazeniComponent(formBuilder, autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.zmenaAtributuRazeni = new EventEmitter();
        _this.zmenaTypuRazeni = new EventEmitter();
        return _this;
    }
    RazeniComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
    };
    RazeniComponent.prototype.odeslat = function () {
    };
    RazeniComponent.prototype.inicializovatFormularovaData = function () {
        var _this = this;
        this.formGroup.addControl('atributRazeni', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(this.atributyRazeni, this.vychoziAtributRazeni), []));
        this.formGroup.addControl('typRazeni', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(this.typyRazeni, this.vychoziTypRazeni), []));
        this.formGroup.controls.atributRazeni.valueChanges.subscribe(function () { return _this.oznamitZmenuAtributuRazeni(); });
        this.formGroup.controls.typRazeni.valueChanges.subscribe(function () { return _this.oznamitZmenuTypuRazeni(); });
    };
    RazeniComponent.prototype.oznamitZmenuAtributuRazeni = function () {
        this.zmenaAtributuRazeni.emit(this.atributyRazeni[String(this.formGroup.controls.atributRazeni.value)]);
    };
    RazeniComponent.prototype.oznamitZmenuTypuRazeni = function () {
        this.zmenaTypuRazeni.emit(this.typyRazeni[String(this.formGroup.controls.typRazeni.value)]);
    };
    return RazeniComponent;
}(AbstraktniFormularComponent));
export { RazeniComponent };
