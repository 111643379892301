import { Produkt } from './produkt';
import { TypPredplatneho } from './enum/predplatne.enum';

export class InformaceOVybranemTarifu {
    produkt: Produkt;
    vybranePredplatne: TypPredplatneho;
    delkaPlatnostiPredplatneho: number;

      constructor(
        produkt?: Produkt,
        vybranePredplatne?: TypPredplatneho,
        delkaPlatnostiPredplatneho?: string | number
    ) {
        this.produkt = produkt;
        this.vybranePredplatne = vybranePredplatne;
        this.delkaPlatnostiPredplatneho = +delkaPlatnostiPredplatneho;
    }
}
