import {prostredi} from '../../../prostredi/prostredi';
import {IdProduktu} from '../../data/e-shop/enum/id-produktu.enum';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';

export class NastrojeGoogleAnalytics {

    public static readonly addToCart = 'add_to_cart';
    public static readonly beginCheckout = 'begin_checkout';
    public static readonly checkoutProgress = 'checkout_progress';
    public static readonly purchase = 'purchase';
    public static readonly removeFromCart = 'remove_from_cart';
    public static readonly setCheckoutOption = 'set_checkout_option';

    public static readonly zasilatDataDoGA = prostredi.verze === 'Produkční';

    public static pripravitGAIdProduktu(id: string, typPredplatneho: TypPredplatneho): string {
        const zakladIdProduktu = id.trim().toUpperCase().replace(" ","_");

        switch (typPredplatneho) {
            case TypPredplatneho.MESICNI_S_OBNOVOU:
                return zakladIdProduktu + '_' + 'MONTH';
            case TypPredplatneho.ROCNI:
                return zakladIdProduktu + '_' + 'YEAR';
            default:
                return zakladIdProduktu;
        }
    }

    public static pripravitGANazevProduktu(id: string, typPredplatneho: TypPredplatneho): string {
        const zakladNazvuProduktu = id.trim().toUpperCase();

        switch (typPredplatneho) {
            case TypPredplatneho.MESICNI_S_OBNOVOU:
                return zakladNazvuProduktu + " " + 'měsíční';
            case TypPredplatneho.ROCNI:
                return zakladNazvuProduktu + " " + 'roční';
            default:
                return zakladNazvuProduktu;
        }
    }

    public static pripravitGAPoradiProduktuVSeznamu(id: string): number {
        switch (id) {
            case IdProduktu.PROFI:
                return 1;
            case IdProduktu.PREMIUM:
                return 2;
            default:
                return 0;
        }
    }
}
