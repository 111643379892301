<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'platbyAObjednavky.nadpis' | lokalizace }}
        </div>
        <div class="container__content-body">
            <app-tabulka-aktivnich-predplatnych></app-tabulka-aktivnich-predplatnych>
            <app-seznam-objednavek></app-seznam-objednavek>
        </div>
    </div>
</main>
