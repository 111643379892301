import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { Lokalizace } from '../../../../../shared/lokalizace';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { Mena } from '../../../../../data/obecne/enum/mena.enum';
import { NastrojeDic } from '../../../../../shared/nastroje/nastroje-dic';
import { NastrojeObjednavky } from '../../../../../shared/nastroje/nastroje-objednavky';
import { ObjednavkaService } from '../../../../../service/e-shop/objednavka.service';
import { TypPredplatneho } from "../../../../../data/e-shop/enum/predplatne.enum";

@Component({
    selector: 'app-rekapitulace-objednavky-zvoleneho-produktu',
    templateUrl: './rekapitulace-objednavky-zvoleneho-produktu.component.html',
    styleUrls: ['./rekapitulace-objednavky-zvoleneho-produktu.component.scss']
})
export class RekapitulaceObjednavkyZvolenohoProduktuComponent extends AbstraktniFormularComponent implements OnInit, OnDestroy {

    @Output() prejitNaKartu = new EventEmitter<string>();

    public readonly mena = Mena;

    public objednavka: Objednavka;
    public textPotvrzovaciHlaskyVytvoreneObjednavky: string;
    public zobrazitPotvrzeni = false;

    private posluchacPrijetiCenyProduktu: Subscription;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private router: Router,
        private vytvoritObjednavkuService: VytvoritObjednavkuService,
        private objednavkaService: ObjednavkaService
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatPromenne();
        this.zaregistrovatPosluchaceCenyProduktu();
    }

    ngOnDestroy(): void {
        this.posluchacPrijetiCenyProduktu.unsubscribe();
    }

    public odeslat(): void {
        this.zobrazitVrtitko = true;

        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.poznamka.value)) {
            this.vytvoritObjednavkuService.objednavka.poznamka = this.formGroup.controls.poznamka.value;
        }

        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.poznamkaNaFakturu.value)) {
            this.vytvoritObjednavkuService.objednavka.poznamkaNaFakturu = this.formGroup.controls.poznamkaNaFakturu.value;
        }

        this.vytvoritObjednavkuService.vytvoritObjednavku()
            .then((vysledek) => {
                this.textPotvrzovaciHlaskyVytvoreneObjednavky = Lokalizace.formatovatText('objednavka.potvrzeni.text.operator', vysledek.identifikator);
                this.zobrazitPotvrzeni = true;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    public presmerovatNaSekciOperatora(): void {
        this.router.navigate(['/sekce-operatora']);
    }

    public predchoziKarta(): void {
        this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
    }

    public jeNeplatceDph(): boolean {
        if (this.vytvoritObjednavkuService.objednavka) {
            return NastrojeDic.jeNeplatceDph(this.vytvoritObjednavkuService.objednavka.dic);
        }
    }

    public pripravitTextTarifuNaMiru() {
        if (this.objednavka.typPredplatneho === TypPredplatneho.NA_MIRU) {
            return Lokalizace.formatovatText("objednavka.rekapitulace.dodatecneInfo.text.dobaPlatnostiTarifuNaMiru", this.objednavka.delkaPlatnostiPredplatneho)
        }

        return Lokalizace.pripravitText("objednavka.rekapitulace.dodatecneInfo.text.dobaPlatnosti")
    }

    private inicializovatFormularovaData(): void {
        this.formGroup.addControl('poznamka', new FormControl('', [Validators.maxLength(500)]));
        this.formGroup.addControl('poznamkaNaFakturu', new FormControl('', [Validators.maxLength(500)]));
    }

    private inicializovatPromenne(): void {
        this.objednavka = this.vytvoritObjednavkuService.objednavka;
    }

    private zaregistrovatPosluchaceCenyProduktu(): void {
        if (this.objednavka.typPredplatneho !== TypPredplatneho.NA_MIRU) {
            this.posluchacPrijetiCenyProduktu = this.vytvoritObjednavkuService.pripravitCenuProduktu.subscribe(() => this.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu());
        }
    }

    private zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu(): void {
        this.zobrazitVrtitko = true;

        const idUzivatele: number = this.objednavka.idUzivatele;
        const dataProVypocetSlevy: Objednavka = NastrojeObjednavky.pripravitDataObjednavkyProVypocetSlevy(idUzivatele, this.objednavka.typPredplatneho, this.objednavka.dic, this.objednavka.idProduktu, this.objednavka.identifikatorProduktu);
        dataProVypocetSlevy.cena = this.objednavka.cena;

        this.objednavkaService.pripravitSlevuZprechoduNaJinePredplatne(dataProVypocetSlevy)
            .toPromise()
            .then(cena => {
                if (!NastrojeObecne.jeHodnotaPrazdna(cena)) {
                    this.objednavka.cena = cena;
                    this.vytvoritObjednavkuService.nastavitCenuObjednavky(cena);
                }

                this.zobrazitVrtitko = false;
            })
            .catch(() => this.zobrazitVrtitko = false);
    }
}
