/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vrtitko.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./vrtitko.component";
var styles_VrtitkoComponent = [i0.styles];
var RenderType_VrtitkoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VrtitkoComponent, data: {} });
export { RenderType_VrtitkoComponent as RenderType_VrtitkoComponent };
export function View_VrtitkoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "preloader-container"]], [[2, "preloader-displayed", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "preloader-wrapper big active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "spinner-layer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "circle-clipper left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "gap-patch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "circle-clipper right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zobrazit; _ck(_v, 0, 0, currVal_0); }); }
export function View_VrtitkoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vrtitko", [], null, null, null, View_VrtitkoComponent_0, RenderType_VrtitkoComponent)), i1.ɵdid(1, 49152, null, 0, i2.VrtitkoComponent, [], null, null)], null, null); }
var VrtitkoComponentNgFactory = i1.ɵccf("app-vrtitko", i2.VrtitkoComponent, View_VrtitkoComponent_Host_0, { zobrazit: "zobrazit" }, {}, []);
export { VrtitkoComponentNgFactory as VrtitkoComponentNgFactory };
