/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./presmerovani-na-podo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/layout/vrtitko/vrtitko.component.ngfactory";
import * as i3 from "../../../shared/layout/vrtitko/vrtitko.component";
import * as i4 from "./presmerovani-na-podo.component";
import * as i5 from "../../../service/e-shop/objednavka.service";
import * as i6 from "../../../service/e-shop/online-platby.service";
import * as i7 from "@angular/router";
var styles_PresmerovaniNaPODO = [i0.styles];
var RenderType_PresmerovaniNaPODO = i1.ɵcrt({ encapsulation: 0, styles: styles_PresmerovaniNaPODO, data: {} });
export { RenderType_PresmerovaniNaPODO as RenderType_PresmerovaniNaPODO };
export function View_PresmerovaniNaPODO_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vrtitko", [], null, null, null, i2.View_VrtitkoComponent_0, i2.RenderType_VrtitkoComponent)), i1.ɵdid(1, 49152, null, 0, i3.VrtitkoComponent, [], { zobrazit: [0, "zobrazit"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "redirect-to-podo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "redirect-to-podo-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prob\u00EDh\u00E1 p\u0159esm\u011Brov\u00E1n\u00ED zp\u011Bt na port\u00E1l PODO ..."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zobrazitVrtitko; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PresmerovaniNaPODO_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-presmerovani-na-podo", [], null, null, null, View_PresmerovaniNaPODO_0, RenderType_PresmerovaniNaPODO)), i1.ɵdid(1, 114688, null, 0, i4.PresmerovaniNaPODO, [i5.ObjednavkaService, i6.OnlinePlatbyService, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PresmerovaniNaPODONgFactory = i1.ɵccf("app-presmerovani-na-podo", i4.PresmerovaniNaPODO, View_PresmerovaniNaPODO_Host_0, {}, {}, []);
export { PresmerovaniNaPODONgFactory as PresmerovaniNaPODONgFactory };
