var NastrojeLokalizace = /** @class */ (function () {
    function NastrojeLokalizace() {
    }
    NastrojeLokalizace.nastavitVychoziJazykovouMutaci = function () {
        if (!this.jeNastavenaJazykovaMutace()) {
            localStorage.setItem('jazykova-mutace', 'cs');
        }
    };
    NastrojeLokalizace.vratitNastavenouJazykovouMutaci = function () {
        if (this.jeNastavenaJazykovaMutace()) {
            return localStorage.getItem('jazykova-mutace');
        }
        else {
            this.nastavitVychoziJazykovouMutaci();
            return localStorage.getItem('jazykova-mutace');
        }
    };
    NastrojeLokalizace.nastavitAnglickouJazykovouMutaci = function () {
        localStorage.setItem('jazykova-mutace', 'en');
        location.reload(false);
    };
    NastrojeLokalizace.nastavitCeskouJazykovouMutaci = function () {
        localStorage.setItem('jazykova-mutace', 'cs');
        location.reload(false);
    };
    NastrojeLokalizace.jeNastavenaVychoziJazykovaMutace = function () {
        return localStorage.getItem('jazykova-mutace') === 'cs';
    };
    NastrojeLokalizace.jeNastavenaJazykovaMutace = function () {
        return localStorage.getItem('jazykova-mutace') === 'cs' || localStorage.getItem('jazykova-mutace') === 'en';
    };
    return NastrojeLokalizace;
}());
export { NastrojeLokalizace };
