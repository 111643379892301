import {Component} from '@angular/core';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-paticka',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-paticka.component.html',
    styleUrls: [
        './seznam-uzivatelu-s-dodavatelem-paticka.component.scss',
        './seznam-uzivatelu-s-dodavatelem-paticka.component.ie.scss'
    ]
})
export class SeznamUzivateluSDodavatelemPatickaComponent {

    constructor() {
    }
}
