import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Objednavka } from '../../../../data/e-shop/objednavka';
import { Stat } from '../../../../data/organizace/enum/stat.enum';
import { NastrojeObecne } from '../../../../shared/nastroje/nastroje-obecne';
import { NastrojeFakturacnichUdaju } from '../../../../shared/nastroje/nastroje-fakturacnich-udaju';
import { ZpusobVytvoreniObjednavky } from '../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
import { Notifikace, Uroven } from '../../../../shared/notifikace';
import { NastrojeAutentizace } from '../../../../shared/nastroje/nastroje-autentizace';
import { VytvoritObjednavkuService } from '../../../../service/e-shop/vytvorit-objednavku.service';
import { FakturacniUdajeService } from '../../../../service/e-shop/fakturacni-udaje.service';

@Component({
    selector: 'app-fakturacni-udaje-input',
    templateUrl: './fakturacni-udaje-input.component.html',
    styleUrls: ['./fakturacni-udaje-input.component.scss']
})
export class FakturacniUdajeInputComponent implements OnInit {

    @Input() vybranyZpusobVytvoreniObjednavky: ZpusobVytvoreniObjednavky;
    @Input() objednavka: Objednavka;
    @Input() formGroup: FormGroup;
    @Input() odeslan: Boolean = false;

    public readonly staty = Stat;
    public readonly zpusobyVytvoreniObjednavky = ZpusobVytvoreniObjednavky;

    public existujiDataProFormular: boolean;
    public zobrazitVrtitko = false;

    constructor(
        protected formBuilder: FormBuilder,
        private fakturacniUdajeService: FakturacniUdajeService,
        private vytvoritObjednavkuService: VytvoritObjednavkuService
    ) {
    }

    ngOnInit() {
        this.inicializovatExistujiDataProFormular();
        this.inicializovatFormularovaData();

        if (this.existujiDataProFormular) {
            NastrojeFakturacnichUdaju.pripravitFakturacniUdajeZObjednavky(this.formGroup, this.objednavka);
        }
    }

    public doplnitFakturacniUdajeZCDD(): void {
        this.formGroup.reset();

        this.pripravitFakturacniUdajeZCDDAdministrace(this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.OPERATOREM ?
            this.vytvoritObjednavkuService.objednavka.idUzivatele :
            NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage().informaceOUzivateli.id);
    }

    private inicializovatFormularovaData(): void {
        NastrojeFakturacnichUdaju.inicializovatFormularovaData(this.formGroup, this.vybranyZpusobVytvoreniObjednavky);
    }

    private pripravitFakturacniUdajeZCDDAdministrace(idUzivatele) {
        this.zobrazitVrtitko = true;

        this.fakturacniUdajeService.nacistFakturacniUdaje(idUzivatele)
            .toPromise()
            .then((odpoved) => {
                NastrojeFakturacnichUdaju.pripravitFakturacniUdajeZFakturacnichUdaju(this.formGroup, odpoved);
                this.zobrazitVrtitko = false;
                Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'fakturacniUdaje.informace.udajeZCDDDoplneny');
            }, () => this.zobrazitVrtitko = false);
    }

    private inicializovatExistujiDataProFormular() {
        this.existujiDataProFormular = !NastrojeObecne.jeHodnotaPrazdna(this.objednavka);
    }
}
