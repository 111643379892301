import { PipeTransform } from '@angular/core';
import { Lokalizace } from '../../lokalizace';
var LokalizacePipe = /** @class */ (function () {
    function LokalizacePipe() {
    }
    LokalizacePipe.prototype.transform = function (klicTextu) {
        return Lokalizace.pripravitText(klicTextu);
    };
    return LokalizacePipe;
}());
export { LokalizacePipe };
