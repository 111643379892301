<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'slevoveKupony.nadpis' | lokalizace }}
        </div>

        <div class="container__content-body">
            <section class="info-container">
                <div class="span-2">
                    <div class="info-container no-padding">
                        <h3 class="info-title span-2">{{ 'slevovyKupon.nadpis' | lokalizace }}</h3>
                    </div>
                    <button type="button" class="btn btn-secondary waves-effect waves-light" (click)="presmerovatNaVytvoreniSlevovehoKuponu()">
                        {{ 'tlaticko.vytvoritSlevovyKupon' | lokalizace }}
                    </button>
                </div>
            </section>

            <app-seznam-slevovych-kuponu></app-seznam-slevovych-kuponu>
        </div>
    </div>
</main>
