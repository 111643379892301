import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TypHodnotySeznamu } from './enum/typ-hodnoty-seznamu';
import { NastrojeObecne } from '../nastroje/nastroje-obecne';
import { UcelSeznamu } from './enum/ucel-seznamu.enum';
var SeznamComponent = /** @class */ (function () {
    function SeznamComponent(router) {
        this.router = router;
        this.ucelSeznamu = UcelSeznamu.PROKLIK_NA_DETAIL;
        this.vybranRadek = new EventEmitter();
        this.zastupnyRetezecPrazdneHodnoty = '---';
    }
    SeznamComponent.prototype.pripravitPrimarniHodnotuRadku = function (radek) {
        return this.pripravitHodnotu(radek, TypHodnotySeznamu.PRIMARNI);
    };
    SeznamComponent.prototype.pripravitSekundarniHodnotuRadku = function (radek) {
        return this.pripravitHodnotu(radek, TypHodnotySeznamu.SEKUNDARNI);
    };
    SeznamComponent.prototype.pripravitDoplnujiciHodnotyRadku = function (radek) {
        return this.pripravitHodnoty(radek, TypHodnotySeznamu.DOPLNUJICI);
    };
    SeznamComponent.prototype.pripravitSpecialniHodnotyRadku = function (radek) {
        return this.pripravitHodnoty(radek, TypHodnotySeznamu.SPECIALNI);
    };
    SeznamComponent.prototype.jeHodnotaPrazdna = function (hodnota) {
        return NastrojeObecne.jeHodnotaPrazdna(hodnota);
    };
    SeznamComponent.prototype.pripravitHodnotuProZobrazeni = function (hodnota) {
        return this.jeHodnotaPrazdna(hodnota) ? this.zastupnyRetezecPrazdneHodnoty : hodnota;
    };
    SeznamComponent.prototype.pripravitHodnotu = function (radek, typHodnoty) {
        return this.pripravitHodnoty(radek, typHodnoty)[0];
    };
    SeznamComponent.prototype.pripravitHodnoty = function (radek, typHodnoty) {
        var hodnoty = Array();
        for (var _i = 0, _a = radek.hodnoty; _i < _a.length; _i++) {
            var hodnota = _a[_i];
            if (hodnota.typHodnoty === typHodnoty) {
                hodnoty.push(hodnota);
            }
        }
        return hodnoty;
    };
    SeznamComponent.prototype.zpracovatKliknutiNaRadku = function (radek) {
        if (this.ucelSeznamu === UcelSeznamu.PROKLIK_NA_DETAIL) {
            if (!NastrojeObecne.jeHodnotaPrazdna(radek.url)) {
                this.router.navigate([radek.url]);
            }
        }
        else if (this.ucelSeznamu === UcelSeznamu.VYBER_RADKY) {
            this.vybranRadek.emit(radek);
        }
    };
    return SeznamComponent;
}());
export { SeznamComponent };
