import { Lokalizace } from '../lokalizace';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeOptimalizaceProVyhledavace = /** @class */ (function () {
    function NastrojeOptimalizaceProVyhledavace() {
    }
    NastrojeOptimalizaceProVyhledavace.pridatTitulek = function (titulek, title) {
        var prefixTitulku = titulek ? Lokalizace.pripravitText(titulek) : null;
        var suffixTitulku = Lokalizace.pripravitText('metadata.suffixTitulku');
        title.setTitle(prefixTitulku ? NastrojeObecne.formatovatRetezec(this.vzorTitulku, prefixTitulku, suffixTitulku) : suffixTitulku);
    };
    NastrojeOptimalizaceProVyhledavace.pridatPovoleniProIndexaciRoboty = function (metadata) {
        this.pridatMetadata('robots', 'index,follow', metadata);
    };
    NastrojeOptimalizaceProVyhledavace.pridatPopis = function (popis, metadata) {
        this.pridatMetadata('description', popis, metadata);
    };
    NastrojeOptimalizaceProVyhledavace.pridatKlicovaSlova = function (klicovaSlova, metadata) {
        this.pridatMetadata('keywords', klicovaSlova, metadata);
    };
    NastrojeOptimalizaceProVyhledavace.pridatAutora = function (metadata) {
        this.pridatMetadata('author', 'metadata.autor', metadata);
    };
    NastrojeOptimalizaceProVyhledavace.pridatMetadata = function (tag, hodnota, metadata) {
        if (hodnota && tag && metadata) {
            metadata.updateTag({
                name: tag,
                content: Lokalizace.pripravitText(hodnota)
            });
        }
    };
    NastrojeOptimalizaceProVyhledavace.vzorTitulku = '{0} - {1}';
    return NastrojeOptimalizaceProVyhledavace;
}());
export { NastrojeOptimalizaceProVyhledavace };
