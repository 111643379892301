import { OnInit } from '@angular/core';
import { Objednavka } from '../../../data/e-shop/objednavka';
import { NastrojeDataACasu } from '../../../shared/nastroje/nastroje-data-a-casu';
var ZruseniObjednavkyComponent = /** @class */ (function () {
    function ZruseniObjednavkyComponent() {
        this.formatDataACasu = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;
    }
    ZruseniObjednavkyComponent.prototype.ngOnInit = function () {
    };
    return ZruseniObjednavkyComponent;
}());
export { ZruseniObjednavkyComponent };
