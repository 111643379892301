import { ElementRef, OnInit } from '@angular/core';
import { Platba } from '../../../data/e-shop/platba';
import { NastrojeUrl } from '../../../shared/nastroje/nastroje-url';
import { ZpusobPlatby } from '../../../data/e-shop/enum/zpusob-platby.enum';
import { StavPlatby } from '../../../data/e-shop/enum/stav-platby.enum';
import { Lokalizace } from '../../../shared/lokalizace';
import { StavOpakovanePlatby } from '../../../data/e-shop/enum/stav-opakovane-platby.enum';
import { NastrojeDataACasu } from '../../../shared/nastroje/nastroje-data-a-casu';
import { OnlinePlatbyService } from '../../../service/e-shop/online-platby.service';
import { Notifikace, Uroven } from '../../../shared/notifikace';
import { prostredi } from '../../../../prostredi/prostredi';
import { NastrojeModalnihoPanelu } from '../../../shared/nastroje/nastroje-modalniho-panelu';
import { TypPredplatneho } from '../../../data/e-shop/enum/predplatne.enum';
var UdajeOPlatbeComponent = /** @class */ (function () {
    function UdajeOPlatbeComponent(onlinePlatbyService) {
        this.onlinePlatbyService = onlinePlatbyService;
        this.formatData = NastrojeDataACasu.FORMAT_DATA;
        this.formatDataACasuNaMinuty = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;
        this.stavyOpakovanePlatby = StavOpakovanePlatby;
        this.stavyPlatby = StavPlatby;
        this.upresnujiTextODaniKCastce = Lokalizace.pripravitText('cena.vcetneDPH');
        this.zpusobyPlatby = ZpusobPlatby;
        this.zobrazitPotvrzeni = false;
        this.zobrazitVrtitko = false;
        this.platbyAObjednavkyUrl = prostredi.url + '/#/e-shop/platby-a-objednavky';
    }
    UdajeOPlatbeComponent.prototype.ngOnInit = function () {
        this.inicializovatModalniPanel();
    };
    UdajeOPlatbeComponent.prototype.otevritPlatebniBranu = function () {
        NastrojeUrl.otevritOdkaz(this.platba.urlPlatebniBrany);
    };
    UdajeOPlatbeComponent.prototype.potvrditZruseniOpakovaniPlatby = function () {
        this.zobrazitPotvrzeni = true;
    };
    UdajeOPlatbeComponent.prototype.skrytModalniPanel = function () {
        this.zobrazitPotvrzeni = false;
    };
    UdajeOPlatbeComponent.prototype.zrusitOpakovaniPlatby = function () {
        var _this = this;
        this.zobrazitPotvrzeni = false;
        this.zobrazitVrtitko = true;
        this.onlinePlatbyService.zrusitOpakovanePlatby(this.platba.idPlatby)
            .toPromise()
            .then(function () {
            _this.zobrazitVrtitko = false;
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'platba.udajeKOpakovani.zrusit.uspech');
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    UdajeOPlatbeComponent.prototype.zobrazitZrusitOpakovaniPlatby = function () {
        return this.platba.nastaveniOpakovanePlatby.stavOpakovanePlatby === StavOpakovanePlatby.ZAHAJENA || (this.platba.nastaveniOpakovanePlatby.stavOpakovanePlatby === StavOpakovanePlatby.VYZADANA && this.platba.stav === StavPlatby.ZAPLACENA);
    };
    UdajeOPlatbeComponent.prototype.zobrazitInformaceOOpakovaniPlateb = function () {
        return this.platba.typPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU && this.platba.nastaveniOpakovanePlatby && this.platba.stav === StavPlatby.ZAPLACENA;
    };
    UdajeOPlatbeComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    return UdajeOPlatbeComponent;
}());
export { UdajeOPlatbeComponent };
