export class NastrojeLokalizace {

    public static nastavitVychoziJazykovouMutaci() {
        if (!this.jeNastavenaJazykovaMutace()) {
            localStorage.setItem('jazykova-mutace', 'cs');
        }
    }

    public static vratitNastavenouJazykovouMutaci(): string {
        if (this.jeNastavenaJazykovaMutace()) {
            return localStorage.getItem('jazykova-mutace');
        } else {
            this.nastavitVychoziJazykovouMutaci();
            return localStorage.getItem('jazykova-mutace');
        }
    }

    public static nastavitAnglickouJazykovouMutaci() {
        localStorage.setItem('jazykova-mutace', 'en');
        location.reload(false);
    }

    public static nastavitCeskouJazykovouMutaci() {
        localStorage.setItem('jazykova-mutace', 'cs');
        location.reload(false);
    }

    public static jeNastavenaVychoziJazykovaMutace(): boolean {
        return localStorage.getItem('jazykova-mutace') === 'cs';
    }

    private static jeNastavenaJazykovaMutace(): boolean {
        return localStorage.getItem('jazykova-mutace') === 'cs' || localStorage.getItem('jazykova-mutace') === 'en';
    }
}
