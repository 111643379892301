import {Pipe, PipeTransform} from '@angular/core';
import {Lokalizace} from '../../lokalizace';

@Pipe({
    name: 'lokalizace'
})
export class LokalizacePipe implements PipeTransform {

    constructor() {
    }

    transform(klicTextu: any): any {
        return Lokalizace.pripravitText(klicTextu);
    }

}
