<nav class="menu-mobile" [ngClass]="{'hidden': !zobrazit, 'displayed': zobrazit}">
    <a href="{{ urlPODO }}" class="menu-mobile__link">
        {{ 'hlavniMenu.portalDodavatele' | lokalizace }}
    </a>

    <a href="{{ urlCDD }}" class="menu-mobile__link">
        {{ 'hlavniMenu.cdd' | lokalizace }}
    </a>

    <div class="menu-mobile__separator"></div>

    <div *ngIf="prihlaseny && informaceOPrihlasenemUzivateli" class="menu-mobile__label">
        {{ informaceOPrihlasenemUzivateli.informaceOUzivateli.krestniJmeno }} {{ informaceOPrihlasenemUzivateli.informaceOUzivateli.prijmeni }}
    </div>

    <div *ngIf="prihlaseny && (prihlasenyAdministrator || prihlasenyOperator) && jmenoAPrijmeniSpravceDatabazeCiOperatora" class="menu-mobile__label">
        {{ jmenoAPrijmeniSpravceDatabazeCiOperatora }}
    </div>

    <a *ngIf="!prihlaseny" class="menu-mobile__link" (click)="prihlasit()">
        <b>{{'tlacitko.prihlasit' | lokalizace }}</b>
    </a>

    <a *ngIf="prihlaseny && informaceOPrihlasenemUzivateli" class="menu-mobile__link" href="{{ urlCDD + '/#/uzivatel/' + informaceOPrihlasenemUzivateli.informaceOUzivateli.id }}">
        {{ 'hlavniMenu.prihlasen.detailUzivatele' | lokalizace }}
    </a>

    <a *ngIf="prihlaseny && !prihlasenyAdministrator && informaceOPrihlasenemUzivateli"
       class="menu-mobile__link" routerLink="e-shop/platby-a-objednavky" routerLinkActive="menu-mobile__link-active" (click)="zavrit.emit()">
       {{ 'hlavniMenu.prihlasen.platbyAObjednavky' | lokalizace }}
    </a>

    <a *ngIf="prihlaseny && prihlasenyOperator"
       class="menu-mobile__link" routerLink="/sekce-operatora" routerLinkActive="menu-mobile__link-active" (click)="zavrit.emit()">
       {{ 'hlavniMenu.prihlasen.sekceOperatora' | lokalizace }}
    </a>

    <a *ngIf="prihlaseny && prihlasenyOperator"
       class="menu-mobile__link" routerLink="/slevove-kupony" routerLinkActive="menu-mobile__link-active" (click)="zavrit.emit()">
        {{ 'hlavniMenu.prihlasen.slevoveKupony' | lokalizace }}
    </a>

    <a *ngIf="prihlaseny"
       class="menu-mobile__link" (click)="odhlasit()">
        {{'tlacitko.odhlasit' | lokalizace }}
    </a>

    <div class="menu-mobile__separator"></div>

    <a *ngIf="jeNastavenaVychoziJazykovaMutace()"
       class="menu-mobile__link" (click)="nastavitAnglickouJazykovouMutaci()">
        {{'lokalizace.vyberJazyka.anglicky' | lokalizace }}
    </a>

    <a *ngIf="!jeNastavenaVychoziJazykovaMutace()"
       class="menu-mobile__link" (click)="nastavitCeskouJazykovouMutaci()">
        {{'lokalizace.vyberJazyka.cesky' | lokalizace }}
    </a>
</nav>

<div class="overlay" [ngClass]="{'hidden': !zobrazit}" (click)="zavrit.emit()"></div>
