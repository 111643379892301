<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<form [formGroup]="formGroup" class="form-column-layout">
    <h3 *ngIf="vybranyZpusobVytvoreniObjednavky === zpusobyVytvoreniObjednavky.OPERATOREM"
        class="container-subheading span-12">{{ 'fakturacniUdaje.nadpis' | lokalizace }}</h3>

    <div class="button-area span-12">
        <button type="button" (click)="doplnitFakturacniUdajeZCDD()"
                class="btn btn-secondary waves-effect waves-light right">
            {{ 'tlacitko.doplnitZCDD' | lokalizace }}
        </button>
    </div>

    <app-formular-input
            class="span-12"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.nazevSpolecnosti'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.nazevOrganizace"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.ico'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.ic"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.dic'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.dic"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.jmeno'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.krestniJmeno"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.prijmeni'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.prijmeni"></app-formular-input>

    <app-formular-input
            class="span-8"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.ulice'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.ulice"></app-formular-input>

    <app-formular-input
            class="span-4"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.cisloPopisne'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.cisloPopisne"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.obec'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.obec"></app-formular-input>

    <app-formular-input
            class="span-2"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.psc'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.psc"></app-formular-input>

    <app-formular-select
            class="span-4"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.stat'"
            [polozky]="staty"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.stat"
            [prvniHodnotaNull]="false"
            [hodnotaKliceZObjektu]="false"></app-formular-select>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.email'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.email"></app-formular-input>

    <app-formular-input
            class="span-6"
            [zmensitRozestupy]="true"
            [popisek]="'fakturacniUdaje.telefon'"
            [odeslan]="odeslan"
            [ovladacFormulare]="formGroup.controls.telefon"></app-formular-input>
</form>
