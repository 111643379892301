<div id="zacatek-stranky"></div>

<ng-container *showIEVersion="['ie 11']">
    <div class="main__outdated">
        {{ 'chyba.zastaralyProhlizec' | lokalizace }}
    </div>
</ng-container>

<ng-container *hideIEVersion="['ie 9','ie 10']">
    <app-hlavni-menu></app-hlavni-menu>

    <router-outlet></router-outlet>

    <app-paticka></app-paticka>
</ng-container>

<ng-container *showIEVersion="['ie 9','ie 10']">
    <main class="main">
        <img src="/assets/img/loga/FEN-logo.png" alt="{{ 'popisek.obrazek.fen' | lokalizace }}">

        <div class="main__not-available">
            {{ 'chyba.nepodporovanyProhlizec' | lokalizace }}
        </div>
    </main>
</ng-container>
