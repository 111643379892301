import { Routes } from '@angular/router';
import { PresmerovaniNaPODO } from './e-shop/platba/presmerovani-na-podo/presmerovani-na-podo.component';
import { DomuComponent } from './obecne/domu/domu.component';
import { VytvoritObjednavkuUzivateleComponent } from './e-shop/objednavka/vytvorit/vytvorit-objednavku-uzivatele/vytvorit-objednavku-uzivatele.component';
import { VytvoritObjednavkuOperatoraComponent } from './e-shop/objednavka/vytvorit/vytvorit-objednavku-operatora/vytvorit-objednavku-operatora.component';
import { SekceOperatoraComponent } from './e-shop/sekce-operatora/sekce-operatora.component';
import { OperatorComponent } from './obecne/operator/operator.component';
import { PlatbyAObjednavkyComponent } from './e-shop/platby-a-objednavky/platby-a-objednavky.component';
import { DetailPlatbyComponent } from './e-shop/platba/detail/detail-platby.component';
import { DetailObjednavkyComponent } from './e-shop/objednavka/detail/detail-objednavky.component';
import { SlevoveKuponyComponent } from './e-shop/slevove-kupony/slevove-kupony.component';
import { DetailSlevovehoKuponuComponent } from './e-shop/slevove-kupony/detail/detail-slevoveho-kuponu.component';
import { UpravitSlevovyKuponComponent } from './e-shop/slevove-kupony/upravit/upravit-slevovy-kupon.component';
import { VytvoritSlevovyKuponComponent } from './e-shop/slevove-kupony/vytvorit/vytvorit-slevovy-kupon.component';
var ɵ0 = {
    popis: 'domu.popis',
    klicovaSlova: 'domu.klicovaSlova'
}, ɵ1 = {
    titulek: 'vytvoritObjednavkuUzivatele.titulek',
}, ɵ2 = {
    titulek: 'vytvoritObjednavkuOperatora.titulek',
}, ɵ3 = {
    titulek: 'platbyAObjednavky.titulek',
}, ɵ4 = {
    titulek: 'detailObjednavky.titulek',
}, ɵ5 = {
    titulek: 'detailPlatby.titulek',
}, ɵ6 = {
    titulek: 'presmerovaniNaPODO.titulek',
}, ɵ7 = {
    titulek: 'operator.titulek',
}, ɵ8 = {
    titulek: 'sekceOperatora.titulek',
}, ɵ9 = {
    titulek: 'slevoveKupony.titulek'
}, ɵ10 = {
    titulek: 'detailSlevovehoKuponu.titulek'
}, ɵ11 = {
    titulek: 'upravitSlevovyKupon.titulek'
}, ɵ12 = {
    titulek: 'vytvoritSlevovyKupon.titulek'
};
var routes = [
    {
        path: '',
        data: ɵ0,
        component: DomuComponent
    },
    {
        path: 'e-shop/objednavka-uzivatele',
        data: ɵ1,
        component: VytvoritObjednavkuUzivateleComponent
    },
    {
        path: 'e-shop/objednavka-operatora',
        data: ɵ2,
        component: VytvoritObjednavkuOperatoraComponent
    },
    {
        path: 'e-shop/platby-a-objednavky',
        data: ɵ3,
        component: PlatbyAObjednavkyComponent
    },
    {
        path: 'e-shop/objednavka/:identifikatorObjednavky',
        data: ɵ4,
        component: DetailObjednavkyComponent
    },
    {
        path: 'e-shop/platba',
        data: ɵ5,
        component: DetailPlatbyComponent
    },
    {
        path: 'e-shop/presmerovani-na-podo',
        data: ɵ6,
        component: PresmerovaniNaPODO
    },
    {
        path: 'operator',
        data: ɵ7,
        component: OperatorComponent
    },
    {
        path: 'sekce-operatora',
        data: ɵ8,
        component: SekceOperatoraComponent
    },
    {
        path: 'slevove-kupony',
        data: ɵ9,
        component: SlevoveKuponyComponent
    },
    {
        path: 'slevove-kupony/detail/:nazev',
        data: ɵ10,
        component: DetailSlevovehoKuponuComponent
    },
    {
        path: 'slevove-kupony/upravit/:nazev',
        data: ɵ11,
        component: UpravitSlevovyKuponComponent
    },
    {
        path: 'slevove-kupony/vytvorit',
        data: ɵ12,
        component: VytvoritSlevovyKuponComponent
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
