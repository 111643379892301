import {Component} from '@angular/core';
import {AtributRazeniUzivateluSDodavatelem} from '../../../../../../data/obecne/enum/atribut-razeni-uzivatelu-s-dodavatelem.enum';
import {TypRazeni} from '../../../../../../data/obecne/enum/typ-razeni.enum';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {AbstraktniRazeniComponent} from '../../../../../../shared/seznam/razeni/abstraktni-razeni.component';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-razeni',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-razeni.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-razeni.component.scss']
})
export class SeznamUzivateluSDodavatelemRazeniComponent extends AbstraktniRazeniComponent {

    public atributyRazeni = AtributRazeniUzivateluSDodavatelem;

    public typyRazeni = TypRazeni;

    constructor(private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService, private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService) {
        super(seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService);
    }
}
