var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { prostredi } from '../../../../../../prostredi/prostredi';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { PoradoveCisloKrokuObjednavky } from '../../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import { TypPredplatneho } from '../../../../../data/e-shop/enum/predplatne.enum';
import { ZpusobPlatby } from '../../../../../data/e-shop/enum/zpusob-platby.enum';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { Mena } from '../../../../../data/obecne/enum/mena.enum';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { ObjednavkaService } from '../../../../../service/e-shop/objednavka.service';
import { OnlinePlatbyService } from '../../../../../service/e-shop/online-platby.service';
import { ProduktService } from '../../../../../service/e-shop/produkt.service';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { GoogleAnalyticsTrackingService } from '../../../../../service/obecne/google-analytics-tracking.service';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { NastrojeDataACasu } from '../../../../../shared/nastroje/nastroje-data-a-casu';
import { NastrojeModalnihoPanelu } from '../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { NastrojeUrl } from '../../../../../shared/nastroje/nastroje-url';
import { Notifikace, Uroven } from '../../../../../shared/notifikace';
import { SlevovyKuponService } from '../../../../../service/e-shop/slevovy-kupon.service';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { NastrojeFormulare } from '../../../../../shared/nastroje/nastroje-formulare';
import { NastrojeDic } from '../../../../../shared/nastroje/nastroje-dic';
import { NastrojeObjednavky } from '../../../../../shared/nastroje/nastroje-objednavky';
import { NastrojeAutentizace } from '../../../../../shared/nastroje/nastroje-autentizace';
import { PredplatneService } from '../../../../../service/e-shop/predplatne.service';
import { StavPredplatneho } from '../../../../../data/e-shop/enum/stav-predplatneho.enum';
var RekapitulaceObjednavkySVyberemComponent = /** @class */ (function (_super) {
    __extends(RekapitulaceObjednavkySVyberemComponent, _super);
    function RekapitulaceObjednavkySVyberemComponent(formBuilder, autentizaceService, router, vytvoritObjednavkuService, objednavkuService, onlinePlatbyService, produktService, slevovyKuponService, googleAnalyticsTrackingService, predplatneService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.router = router;
        _this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        _this.objednavkuService = objednavkuService;
        _this.onlinePlatbyService = onlinePlatbyService;
        _this.produktService = produktService;
        _this.slevovyKuponService = slevovyKuponService;
        _this.googleAnalyticsTrackingService = googleAnalyticsTrackingService;
        _this.predplatneService = predplatneService;
        _this.prejitNaKartu = new EventEmitter();
        _this.datumPristiPlatby = NastrojeDataACasu.pridatMesic(new Date());
        _this.formatData = NastrojeDataACasu.FORMAT_DATA;
        _this.mena = Mena;
        _this.platbyAObjednavkyUrl = prostredi.url + '/#/e-shop/platby-a-objednavky';
        _this.typPredplatneho = TypPredplatneho;
        _this.urlPODO = prostredi.urlPODO;
        _this.zpusobPlatby = ZpusobPlatby;
        _this.zakladniUrl = prostredi.zakladniUrl;
        _this.dokoncitObjednavkuTextTlacitka = 'tlacitko.zaplatit';
        _this.vybranyZpusobPlatby = _this.zpusobPlatby.PLATBA_KARTOU;
        _this.zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
        _this.jmenoAPrijmeni = '';
        return _this;
    }
    RekapitulaceObjednavkySVyberemComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
        this.pripravitProdukt();
        this.zaregistrovatPosluchaceCenyProduktu();
        this.nacistAktivniPredplatneUzivatele();
        if (!NastrojeObecne.jeHodnotaPrazdna(this.aktualniFakturacniUdajeObjednavky.jmeno) && !NastrojeObecne.jeHodnotaPrazdna(this.aktualniFakturacniUdajeObjednavky.prijmeni)) {
            this.jmenoAPrijmeni = this.aktualniFakturacniUdajeObjednavky.jmeno + ' ' + this.aktualniFakturacniUdajeObjednavky.prijmeni;
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.ngOnDestroy = function () {
        this.posluchacPrijetiCenyProduktu.unsubscribe();
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.odeslat = function () {
        var _this = this;
        if (!NastrojeObecne.jeHodnotaPrazdna(this.getPrvekFormulare('nazevSlevovehoKuponu').value) && NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon)) {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.zadanySlevovyKuponNebylUplatnen');
            return;
        }
        if (this.jeNutneZrusitOpakovanePlatby()) {
            this.otevritModalniPanelZruseniOnlinePlateb();
            return;
        }
        this.zobrazitVrtitko = true;
        this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranyTypPredplatneho);
        this.vytvoritObjednavkuService.doplnitZpusobPlatby(this.vybranyZpusobPlatby);
        if (!NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon)) {
            this.vytvoritObjednavkuService.doplnitSlevovyKupon(this.slevovyKupon);
        }
        this.vytvoritObjednavkuService.vytvoritObjednavku()
            .then(function (vysledek) {
            _this.objednavkuService.nacistObjednavku(vysledek.identifikator)
                .toPromise()
                .then(function (obj) {
                _this.googleAnalyticsTrackingService.evidovatPurchase(obj);
                _this.googleAnalyticsTrackingService.evidovatCheckoutProgress(obj, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
                _this.googleAnalyticsTrackingService.evidovatCheckoutOption(obj, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
                // TODO osetrit asynchronim voláním na googleAnalyticsTrackingService evidence
                setTimeout(function () {
                    _this.dokoncitObjednavku(obj);
                }, 4000);
            });
        }).catch(function () {
            _this.zobrazitVrtitko = false;
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.nelzeVytvoritPlatbu');
        });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.presmerovatPriZruseni = function () {
        this.googleAnalyticsTrackingService.evidovatZruseni(this.puvodniObjednavka);
        this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.puvodniObjednavka, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
        this.router.navigate(['/']);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.predchoziKarta = function () {
        this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.puvodniObjednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
        this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zmenenVybranyZpusobUhrady = function (target) {
        switch (target.value) {
            case 'platbaKartouRocni':
                this.upravitDleZvolenehoZpusobuUhrady(ZpusobPlatby.PLATBA_KARTOU, 'tlacitko.zaplatit');
                break;
            case 'platbaPrevodemRocni':
                this.upravitDleZvolenehoZpusobuUhrady(ZpusobPlatby.BANKOVNI_PREVOD, 'tlacitko.odeslatObjednavku');
                break;
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.otevritModalniPanel = function () {
        NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zavritModalniPanel = function () {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.otevritModalniPanelZruseniOnlinePlateb = function () {
        this.zobrazitModalniPanelProZruseniOpakovanychPlateb = true;
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zavritModalniPanelZruseniOnlinePlateb = function () {
        this.zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.jeNeplatceDph = function () {
        if (this.vytvoritObjednavkuService.objednavka) {
            return NastrojeDic.jeNeplatceDph(this.vytvoritObjednavkuService.objednavka.dic);
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.uplatnitSlevovyKupon = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.slevovyKuponService.overitPlatnostSlevovehoKuponu(this.getPrvekFormulare('nazevSlevovehoKuponu').value, this.vybranyProdukt.identifikator, this.vybranyTypPredplatneho)
            .toPromise()
            .then(function (odpoved) {
            _this.slevovyKupon = odpoved;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.odebratSlevovyKupon = function () {
        this.slevovyKupon = null;
        NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(this.getPrvekFormulare('nazevSlevovehoKuponu'), '');
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.pripravitCenuVybranehoProduktuBezDph = function () {
        return NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon) ?
            this.vybranyProdukt.cena.cenaBezDph :
            (this.vybranyProdukt.cena.cenaBezDph - this.pripravitVysiSlevyZVybranehoProduktu());
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.pripravitCenuVybranehoProduktuSDph = function () {
        return NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon) ?
            this.vybranyProdukt.cena.cenaSDph :
            (this.pripravitCenuVybranehoProduktuBezDph() + (this.pripravitCenuVybranehoProduktuBezDph() * this.vybranyProdukt.cena.koeficientDph));
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.pripravitVysiSlevyZVybranehoProduktu = function () {
        return this.vybranyProdukt.cena.cenaBezDph * (this.slevovyKupon.sleva / 100);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('zpusobPlatby', new FormControl('', []));
        this.formGroup.addControl('nazevSlevovehoKuponu', new FormControl('', []));
        this.formGroup.addControl('souhlasSObchodnimiPodminkami', new FormControl('', [Validators.required,
            Validators.requiredTrue]));
        if (this.vybranyTypPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU) {
            this.formGroup.addControl('souhlasSOpakovanouPlatbou', new FormControl('', [Validators.required,
                Validators.requiredTrue]));
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.pripravitProdukt = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.produktService.nacistProdukt(this.puvodniObjednavka.nazevProduktu, this.vybranyTypPredplatneho)
            .subscribe(function (data) {
            _this.vybranyProdukt = data;
            _this.inicializovatPromenne();
            _this.zobrazitVrtitko = false;
        }, function () { return _this.zobrazitVrtitko = false; });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.nacistAktivniPredplatneUzivatele = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.predplatneService.nacistAktivniPredplatne()
            .toPromise()
            .then(function (odpoved) {
            _this.aktivniPredplatne = odpoved;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.jeNutneZrusitOpakovanePlatby = function () {
        for (var i = 0; i < this.aktivniPredplatne.length; i++) {
            if (this.aktivniPredplatne[i].typPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU && this.aktivniPredplatne[i].stavPredplatneho === StavPredplatneho.AKTIVNI && this.aktivniPredplatne[i].identifikatorProduktu === 'premium' && this.vybranyTypPredplatneho === TypPredplatneho.ROCNI && this.vybranyProdukt.identifikator === 'profi') {
                this.idPlatby = this.aktivniPredplatne[i].idPlatby;
                return true;
            }
        }
        return false;
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zrusitOpakovaniPlatby = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.onlinePlatbyService.zrusitOpakovanePlatby(this.idPlatby)
            .toPromise()
            .then(function () {
            _this.zobrazitVrtitko = false;
            _this.zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
            _this.nacistAktivniPredplatneUzivatele();
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'platba.udajeKOpakovani.zrusit.uspech');
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.inicializovatPromenne = function () {
        switch (this.vybranyTypPredplatneho) {
            case (TypPredplatneho.MESICNI_S_OBNOVOU):
                this.moznostPlatbyKartouValue = 'platbaKartouMesicne';
                break;
            case (TypPredplatneho.ROCNI):
                this.moznostPlatbyKartouValue = 'platbaKartouRocni';
                this.moznostBankovniPrevodValue = 'platbaPrevodemRocni';
                break;
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zaregistrovatPosluchaceCenyProduktu = function () {
        var _this = this;
        this.posluchacPrijetiCenyProduktu = this.vytvoritObjednavkuService.pripravitCenuProduktu.subscribe(function () { return _this.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu(); });
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.upravitDleZvolenehoZpusobuUhrady = function (zpusobPlatby, textTlacitka) {
        this.vybranyZpusobPlatby = zpusobPlatby;
        this.dokoncitObjednavkuTextTlacitka = textTlacitka;
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.dokoncitObjednavku = function (obj) {
        var _this = this;
        if (this.vybranyZpusobPlatby === this.zpusobPlatby.PLATBA_KARTOU) {
            this.onlinePlatbyService.vytvoritPlatbu(obj)
                .toPromise()
                .then(function (odpoved) {
                _this.zobrazitVrtitko = false;
                NastrojeUrl.otevritOdkaz(odpoved.urlPlatebniBrany);
            })
                .catch(function () { return Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.nelzeVytvoritObjednavku'); });
        }
        else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.USPECH, 'uspech.objednavkaOdeslana');
            this.router.navigate(['/e-shop/platby-a-objednavky']);
        }
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.getPrvekFormulare = function (nazev) {
        return this.formGroup.controls[nazev];
    };
    RekapitulaceObjednavkySVyberemComponent.prototype.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        var idUzivatele = NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage().informaceOUzivateli.id;
        var dataProVypocetSlevy = NastrojeObjednavky.pripravitDataObjednavkyProVypocetSlevy(idUzivatele, this.vybranyTypPredplatneho, this.vytvoritObjednavkuService.objednavka.dic, this.vybranyProdukt.id, this.vybranyProdukt.identifikator, this.slevovyKupon);
        this.objednavkuService.pripravitSlevuZprechoduNaJinePredplatne(dataProVypocetSlevy)
            .toPromise()
            .then(function (cena) {
            if (!NastrojeObecne.jeHodnotaPrazdna(cena)) {
                _this.vybranyProdukt.cena = cena;
            }
            _this.zobrazitVrtitko = false;
        })
            .catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return RekapitulaceObjednavkySVyberemComponent;
}(AbstraktniFormularComponent));
export { RekapitulaceObjednavkySVyberemComponent };
