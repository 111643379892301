var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { Lokalizace } from '../../../../../shared/lokalizace';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { Mena } from '../../../../../data/obecne/enum/mena.enum';
import { NastrojeDic } from '../../../../../shared/nastroje/nastroje-dic';
import { NastrojeObjednavky } from '../../../../../shared/nastroje/nastroje-objednavky';
import { ObjednavkaService } from '../../../../../service/e-shop/objednavka.service';
import { TypPredplatneho } from "../../../../../data/e-shop/enum/predplatne.enum";
var RekapitulaceObjednavkyZvolenohoProduktuComponent = /** @class */ (function (_super) {
    __extends(RekapitulaceObjednavkyZvolenohoProduktuComponent, _super);
    function RekapitulaceObjednavkyZvolenohoProduktuComponent(formBuilder, autentizaceService, router, vytvoritObjednavkuService, objednavkaService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.router = router;
        _this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        _this.objednavkaService = objednavkaService;
        _this.prejitNaKartu = new EventEmitter();
        _this.mena = Mena;
        _this.zobrazitPotvrzeni = false;
        return _this;
    }
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatPromenne();
        this.zaregistrovatPosluchaceCenyProduktu();
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.ngOnDestroy = function () {
        this.posluchacPrijetiCenyProduktu.unsubscribe();
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.odeslat = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.poznamka.value)) {
            this.vytvoritObjednavkuService.objednavka.poznamka = this.formGroup.controls.poznamka.value;
        }
        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.poznamkaNaFakturu.value)) {
            this.vytvoritObjednavkuService.objednavka.poznamkaNaFakturu = this.formGroup.controls.poznamkaNaFakturu.value;
        }
        this.vytvoritObjednavkuService.vytvoritObjednavku()
            .then(function (vysledek) {
            _this.textPotvrzovaciHlaskyVytvoreneObjednavky = Lokalizace.formatovatText('objednavka.potvrzeni.text.operator', vysledek.identifikator);
            _this.zobrazitPotvrzeni = true;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.presmerovatNaSekciOperatora = function () {
        this.router.navigate(['/sekce-operatora']);
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.predchoziKarta = function () {
        this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.jeNeplatceDph = function () {
        if (this.vytvoritObjednavkuService.objednavka) {
            return NastrojeDic.jeNeplatceDph(this.vytvoritObjednavkuService.objednavka.dic);
        }
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.pripravitTextTarifuNaMiru = function () {
        if (this.objednavka.typPredplatneho === TypPredplatneho.NA_MIRU) {
            return Lokalizace.formatovatText("objednavka.rekapitulace.dodatecneInfo.text.dobaPlatnostiTarifuNaMiru", this.objednavka.delkaPlatnostiPredplatneho);
        }
        return Lokalizace.pripravitText("objednavka.rekapitulace.dodatecneInfo.text.dobaPlatnosti");
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('poznamka', new FormControl('', [Validators.maxLength(500)]));
        this.formGroup.addControl('poznamkaNaFakturu', new FormControl('', [Validators.maxLength(500)]));
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.inicializovatPromenne = function () {
        this.objednavka = this.vytvoritObjednavkuService.objednavka;
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.zaregistrovatPosluchaceCenyProduktu = function () {
        var _this = this;
        if (this.objednavka.typPredplatneho !== TypPredplatneho.NA_MIRU) {
            this.posluchacPrijetiCenyProduktu = this.vytvoritObjednavkuService.pripravitCenuProduktu.subscribe(function () { return _this.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu(); });
        }
    };
    RekapitulaceObjednavkyZvolenohoProduktuComponent.prototype.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        var idUzivatele = this.objednavka.idUzivatele;
        var dataProVypocetSlevy = NastrojeObjednavky.pripravitDataObjednavkyProVypocetSlevy(idUzivatele, this.objednavka.typPredplatneho, this.objednavka.dic, this.objednavka.idProduktu, this.objednavka.identifikatorProduktu);
        dataProVypocetSlevy.cena = this.objednavka.cena;
        this.objednavkaService.pripravitSlevuZprechoduNaJinePredplatne(dataProVypocetSlevy)
            .toPromise()
            .then(function (cena) {
            if (!NastrojeObecne.jeHodnotaPrazdna(cena)) {
                _this.objednavka.cena = cena;
                _this.vytvoritObjednavkuService.nastavitCenuObjednavky(cena);
            }
            _this.zobrazitVrtitko = false;
        })
            .catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return RekapitulaceObjednavkyZvolenohoProduktuComponent;
}(AbstraktniFormularComponent));
export { RekapitulaceObjednavkyZvolenohoProduktuComponent };
