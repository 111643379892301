import {Component} from '@angular/core';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-strankovani',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-strankovani.component.html',
    styleUrls: [
        './seznam-uzivatelu-s-dodavatelem-strankovani.component.scss',
        './seznam-uzivatelu-s-dodavatelem-strankovani.component.ie.scss'
    ]
})
export class SeznamUzivateluSDodavatelemStrankovaniComponent {

    constructor(private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService, private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService) {
    }

    public predchoziStranka() {
        const hodnota = this.pripravitHodnotu();
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    }

    public dalsiStranka() {
        const hodnota = this.pripravitHodnotu();
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    }

    public jePrvniStranka() {
        return this.pripravitHodnotu() === 1;
    }

    public jePosledniStranka() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    }

    private pripravitHodnotu() {
        return this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    }

    public pripravitMaximalniPocetStranek() {
        const seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        const nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    }
}
