<div class="filter-container">
    <form [formGroup]="formGroup" class="form-column-layout" autocomplete="off">
        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.nazevOrganizace'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.nazevOrganizace"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.ic'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.ic"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.email'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.email"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.prihlasovaciJmeno'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.prihlasovaciJmeno"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.jmeno'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.jmeno"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.prijmeni'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.prijmeni"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.prihlasovaciJmenoOperatora'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.prihlasovaciJmenoOperatora"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.jmenoOperatora'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.jmenoOperatora"></app-formular-input>

        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.prijmeniOperatora'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.prijmeniOperatora"></app-formular-input>
    </form>
</div>
