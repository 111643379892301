var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { AbstraktniFormularComponent } from '../../../../../../shared/formular/abstraktni-formular.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AutentizaceService } from '../../../../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { TypPredplatneho } from '../../../../../../data/e-shop/enum/predplatne.enum';
import { NastrojeModalnihoPanelu } from '../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { Lokalizace } from '../../../../../../shared/lokalizace';
import { NastrojeEnumu } from '../../../../../../shared/nastroje/nastroje-enumu';
import { Polozka } from '../../../../../../data/obecne/polozka';
import { InformaceOVybranemTarifu } from '../../../../../../data/e-shop/informace-o-vybranem-tarifu';
import { NastrojeObecne } from '../../../../../../shared/nastroje/nastroje-obecne';
import { NastrojeCisla } from '../../../../../../shared/nastroje/nastroje-cisla';
import { NastrojeFormulare } from '../../../../../../shared/nastroje/nastroje-formulare';
var ZvolitProduktModalniPanelComponent = /** @class */ (function (_super) {
    __extends(ZvolitProduktModalniPanelComponent, _super);
    function ZvolitProduktModalniPanelComponent(formBuilder, autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.pridatVybranyTarif = new EventEmitter();
        _this.dostupnePredplatne = [];
        return _this;
    }
    ZvolitProduktModalniPanelComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatModalniPanel();
        this.inicializovatFormularovaData();
    };
    ZvolitProduktModalniPanelComponent.prototype.odeslat = function () {
        if (this.pripravitPredplatnePridanehoProduktu() !== TypPredplatneho.NA_MIRU) {
            NastrojeFormulare.resetovatPrvekBezVyvolaniUdalosti(this.formGroup.controls.delkaPlatnostiPredplatneho);
            this.formGroup.controls.delkaPlatnostiPredplatneho.markAsUntouched();
            this.odeslan = false;
        }
        this.pridatVybranyTarif.emit(this.pripravitDataZModalu());
        this.zavritModalniPanel();
    };
    ZvolitProduktModalniPanelComponent.prototype.povolenoZobrazeniVlastniDelkyPredplatneho = function () {
        return !NastrojeObecne.jeHodnotaPrazdna(this.vybranyProdukt) && this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.NA_MIRU);
    };
    ZvolitProduktModalniPanelComponent.prototype.pripravitDataZModalu = function () {
        var vybranePredplatne = this.pripravitPredplatnePridanehoProduktu();
        if (vybranePredplatne === TypPredplatneho.NA_MIRU) {
            return new InformaceOVybranemTarifu(this.vybranyProdukt, this.pripravitPredplatnePridanehoProduktu(), this.formGroup.controls.delkaPlatnostiPredplatneho.value);
        }
        else {
            return new InformaceOVybranemTarifu(this.vybranyProdukt, this.pripravitPredplatnePridanehoProduktu());
        }
    };
    ZvolitProduktModalniPanelComponent.prototype.pripravitPredplatnePridanehoProduktu = function () {
        if (this.jeViceroDostupnychPredplatnych && !this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.ROCNI)) {
            return this.formGroup.controls.predplatne.value;
        }
        else {
            return this.automatickyVybranePredplatne;
        }
    };
    ZvolitProduktModalniPanelComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('produkt', new FormControl('', [Validators.required]));
        this.formGroup.addControl('delkaPlatnostiPredplatneho', new FormControl('', [
            Validators.required,
            Validators.min(1),
            Validators.max(100),
            Validators.pattern(NastrojeCisla.VZOR_INTEGER)
        ]));
        this.formGroup.addControl('predplatne', new FormControl({
            value: '',
            disabled: true
        }, [Validators.required]));
        this.inicializovatPosluchaceZmenHodnot();
    };
    ZvolitProduktModalniPanelComponent.prototype.inicializovatPosluchaceZmenHodnot = function () {
        var _this = this;
        this.formGroup.controls.produkt.valueChanges.subscribe(function (nazevVybranehoProduktu) {
            _this.aktualizovatFormularPodleVybranehoProduktu(nazevVybranehoProduktu);
        });
    };
    ZvolitProduktModalniPanelComponent.prototype.aktualizovatFormularPodleVybranehoProduktu = function (nazevVybranehoProduktu) {
        this.vybranyProdukt = this.produkty.find(function (produkt) { return produkt.nazev === nazevVybranehoProduktu; });
        this.jeViceroDostupnychPredplatnych = this.vybranyProdukt.dostupnePredplatne.length > 1;
        if (this.jeViceroDostupnychPredplatnych) {
            if (this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.ROCNI)) {
                this.automatickyVybranePredplatne = TypPredplatneho.ROCNI;
            }
            else {
                this.pripravitFormularovaDataProPredplatneProduktu();
            }
        }
        else {
            this.automatickyVybranePredplatne = this.vybranyProdukt.dostupnePredplatne[0];
        }
        if (this.vybranyProdukt.dostupnePredplatne[0] !== TypPredplatneho.NA_MIRU) {
            NastrojeFormulare.resetovatPrvekBezVyvolaniUdalosti(this.formGroup.controls.delkaPlatnostiPredplatneho);
            this.formGroup.controls.delkaPlatnostiPredplatneho.markAsUntouched();
            this.formGroup.controls.delkaPlatnostiPredplatneho.markAsDirty();
            this.formGroup.controls.delkaPlatnostiPredplatneho.setErrors(null);
        }
    };
    ZvolitProduktModalniPanelComponent.prototype.pripravitFormularovaDataProPredplatneProduktu = function () {
        var _this = this;
        this.dostupnePredplatne = [];
        this.formGroup.controls.predplatne.reset();
        this.vybranyProdukt.dostupnePredplatne.forEach(function (typ) { return _this.dostupnePredplatne.push(new Polozka(typ, Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech('Predplatne', typ)))); });
        this.formGroup.controls.predplatne.enable();
    };
    ZvolitProduktModalniPanelComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    ZvolitProduktModalniPanelComponent.prototype.zavritModalniPanel = function () {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    };
    return ZvolitProduktModalniPanelComponent;
}(AbstraktniFormularComponent));
export { ZvolitProduktModalniPanelComponent };
