import { OnInit } from '@angular/core';
import { prostredi } from '../../../prostredi/prostredi';
var DomuComponent = /** @class */ (function () {
    function DomuComponent() {
        this.zakladniUrl = prostredi.zakladniUrl;
    }
    DomuComponent.prototype.ngOnInit = function () {
    };
    return DomuComponent;
}());
export { DomuComponent };
