import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstraktniFormularComponent} from '../../../shared/formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {TypUceluFormulare} from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {SlevovyKupon} from '../../../data/e-shop/slevovy-kupon';
import {SlevovyKuponService} from '../../../service/e-shop/slevovy-kupon.service';
import {NastrojeSlevovehoKuponu} from '../../../shared/nastroje/nastroje-slevoveho-kuponu';
import {NastrojeJsonu} from '../../../shared/nastroje/nastroje-jsonu';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';

@Component({
    selector: 'app-upravit-slevovy-kupon',
    templateUrl: './upravit-slevovy-kupon.component.html',
    styleUrls: ['./upravit-slevovy-kupon.component.scss']
})
export class UpravitSlevovyKuponComponent extends AbstraktniFormularComponent implements OnInit {

    public slevovyKupon: SlevovyKupon;

    private nazevSlevovehoKuponu: string;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private route: ActivatedRoute,
        private router: Router,
        private slevovyKuponService: SlevovyKuponService
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.UPRAVA);
        this.zpracovatParametry();
    }

    public odeslat(): void {
        this.zobrazitVrtitko = true;

        this.slevovyKuponService.upravitPlatnostSlevovehoKuponu(this.nazevSlevovehoKuponu, NastrojeSlevovehoKuponu.pripravitPlatnostSlevovehoKuponuZFormulare(this.formGroup))
            .toPromise()
            .then(() => {
                this.zobrazitVrtitko = false;

                this.prejitZpet();
            }).catch(() => this.zobrazitVrtitko = false);
    }

    public prejitZpet(): void {
        this.router.navigate(['/slevove-kupony/detail/' + this.nazevSlevovehoKuponu]);
    }

    private zpracovatParametry(): void {
        this.route.params.subscribe(params => {
            this.nazevSlevovehoKuponu = params['nazev'];

            this.pripravitSlevovyKupon();
        });
    }

    private pripravitSlevovyKupon(): void {
        this.zobrazitVrtitko = true;

        this.slevovyKuponService.nacistSlevovyKupon(this.nazevSlevovehoKuponu)
            .toPromise()
            .then(odpoved => {
                this.slevovyKupon = odpoved;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }
}
