import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypUceluFormulare } from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { Lokalizace } from '../../../lokalizace';
import { NastrojeFormulare } from '../../../nastroje/nastroje-formulare';
import { NastrojeObecne } from '../../../nastroje/nastroje-obecne';
import { NastrojeValidace } from '../../../nastroje/nastroje-validace';
import { Notifikace, Uroven } from '../../../notifikace';
var AbstraktniFormularovyPrvekComponent = /** @class */ (function () {
    function AbstraktniFormularovyPrvekComponent() {
        this.klicChyboveHlaskyKontrolyTypuFormulare = 'formular.chyba.typFormulareNeniNastaven';
        /**
         * Pro pripady kdy chceme pomocny text skryt uplne
         */
        this.skrytPomocnyText = false;
        /**
         * Priznak urcujici, zda se uzivatel pokusil formular odeslat
         */
        this.odeslan = false;
        /**
         * Pokud je pomocnym textem napr. spravny format hodnoty, zobrazi se pomocny text na konci chybove hlasky v zavorce
         */
        this.pomocnyTextSoucastiChyby = false;
        /**
         * Urcuje zda se ma vzdy skryt formularovy prvek, pokud je neaktivni, ma prioritu pred "vzdySkrytNeaktivniPrazdnouHodnotu"
         */
        this.vzdySkrytNeaktivniHodnotu = false;
        /**
         * Urcuje zda se ma vzdy skryt formularovy prvek, pokud je neaktivni a zaroven je jeho hodnota prazdna
         */
        this.vzdySkrytNeaktivniPrazdnouHodnotu = true;
        /**
         * Urcuje zda se ma pouzit trida responsive-input-field, ktera responzivne zmensuje odsazeni jednotlivych poli
         */
        this.zmensitRozestupy = false;
    }
    Object.defineProperty(AbstraktniFormularovyPrvekComponent.prototype, "pripravitPodminkuViditelnosti", {
        get: function () {
            return this.skryt();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstraktniFormularovyPrvekComponent.prototype, "pripravitPovinnostHodnoty", {
        get: function () {
            return this.jeHodnotaPovinna();
        },
        enumerable: true,
        configurable: true
    });
    AbstraktniFormularovyPrvekComponent.prototype.ngOnInit = function () {
        this.nadrazenaSkupinaFormulare = this.pripravitNadrazenouSkupinuFormulare();
        this.nazevOvladaceFormulare = this.pripravitNazevOvladaceFormulare();
        this.idPrvku = this.pripravitIdPrvku();
        this.zkontrolovatTypUceluFormulare();
        this.nastavitPosluchaceZmenyHodnoty();
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitChybovouZpravu = function () {
        if (this.zobrazitChybovouHlaskuPrkuFormulare()) {
            var validacniZprava = this.pripravitValidacniZpravu();
            var vyslednyTextValidacniZpravy = Lokalizace.formatovatText.apply(Lokalizace, [validacniZprava.klic].concat(validacniZprava.hodnoty));
            return vyslednyTextValidacniZpravy + (this.pomocnyTextSoucastiChyby ? " (" + Lokalizace.pripravitText(this.pomocnyText) + ")" : '');
        }
        return null;
    };
    AbstraktniFormularovyPrvekComponent.prototype.skryt = function () {
        return this.ovladacFormulare.disabled && (this.jeTypUceluFormulare(TypUceluFormulare.VYTVORENI) || this.vzdySkrytNeaktivniHodnotu || (this.vzdySkrytNeaktivniPrazdnouHodnotu && NastrojeObecne.jeHodnotaPrazdna(this.ovladacFormulare.value)));
    };
    AbstraktniFormularovyPrvekComponent.prototype.zneaktivnit = function () {
        return this.ovladacFormulare.disabled && this.jeTypUceluFormulare(TypUceluFormulare.UPRAVA);
    };
    AbstraktniFormularovyPrvekComponent.prototype.zobrazitStavPrvkuFormulare = function () {
        return !this.ovladacFormulare.disabled && ((this.ovladacFormulare.dirty && this.ovladacFormulare.touched) || (this.odeslan && this.ovladacFormulare.invalid));
    };
    AbstraktniFormularovyPrvekComponent.prototype.zobrazitChybovouHlaskuPrkuFormulare = function () {
        return this.zobrazitStavPrvkuFormulare() && this.ovladacFormulare.invalid;
    };
    AbstraktniFormularovyPrvekComponent.prototype.jeHodnotaPovinna = function () {
        return NastrojeValidace.jePouzitValidatorPovinneHodnoty(this.ovladacFormulare);
    };
    AbstraktniFormularovyPrvekComponent.prototype.jePouzitValidator = function (nazevValidatoru) {
        return NastrojeValidace.jePouzitValidator(this.ovladacFormulare, nazevValidatoru);
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitValidacniZpravu = function () {
        return NastrojeValidace.pripravitChybovouZpravu(this.ovladacFormulare);
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitNadrazenouSkupinuFormulare = function () {
        return this.ovladacFormulare['_parent'];
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitNazevOvladaceFormulare = function () {
        for (var _i = 0, _a = Object.keys(this.nadrazenaSkupinaFormulare.controls); _i < _a.length; _i++) {
            var nazevOvladaceFormulare = _a[_i];
            if (this.ovladacFormulare === this.nadrazenaSkupinaFormulare.controls[nazevOvladaceFormulare]) {
                return nazevOvladaceFormulare;
            }
        }
        return null;
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitIdPrvku = function () {
        return NastrojeObecne.vygenerovatNahodnyRetezec();
    };
    AbstraktniFormularovyPrvekComponent.prototype.nastavitPosluchaceZmenyHodnoty = function () {
        var _this = this;
        this.ovladacFormulare.valueChanges.subscribe(function () {
            _this.ovladacFormulare.markAsTouched();
            _this.ovladacFormulare.markAsDirty();
        });
    };
    AbstraktniFormularovyPrvekComponent.prototype.jeTypUceluFormulare = function (typUceluFormulare) {
        return this.pripravitTypUceluFormulare() === typUceluFormulare;
    };
    AbstraktniFormularovyPrvekComponent.prototype.pripravitTypUceluFormulare = function () {
        return NastrojeFormulare.pripravitTypUceluFormulare(this.nadrazenaSkupinaFormulare);
    };
    AbstraktniFormularovyPrvekComponent.prototype.zkontrolovatTypUceluFormulare = function () {
        if (NastrojeObecne.jeHodnotaPrazdna(this.pripravitTypUceluFormulare())) {
            Notifikace.zobrazitZpravu(Uroven.CHYBA, Lokalizace.formatovatText(this.klicChyboveHlaskyKontrolyTypuFormulare, this.nazevOvladaceFormulare));
        }
    };
    return AbstraktniFormularovyPrvekComponent;
}());
export { AbstraktniFormularovyPrvekComponent };
