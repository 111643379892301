var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniService, TypPozadavku } from './abstraktni-service';
import { HttpClient } from '@angular/common/http';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
var VerzeBackenduService = /** @class */ (function (_super) {
    __extends(VerzeBackenduService, _super);
    function VerzeBackenduService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '/verze';
        return _this;
    }
    VerzeBackenduService.prototype.pripravitCisloVerzeBackendu = function () {
        return this.pozadavekBezTokenu(TypPozadavku.GET)
            .catch(this.zpracovatChybu);
    };
    return VerzeBackenduService;
}(AbstraktniService));
export { VerzeBackenduService };
