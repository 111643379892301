import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NastrojeModalnihoPanelu } from '../../nastroje/nastroje-modalniho-panelu';
var TextovyModalniPanelComponent = /** @class */ (function () {
    function TextovyModalniPanelComponent() {
        this.souhlas = new EventEmitter();
        this.nesouhlas = new EventEmitter();
        this.nazevNeakcnihoTlacitka = 'tlacitko.zavrit';
    }
    TextovyModalniPanelComponent.prototype.ngOnInit = function () {
        this.inicializovatModalniPanel();
    };
    TextovyModalniPanelComponent.prototype.otevritModalniPanel = function () {
        NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
    };
    TextovyModalniPanelComponent.prototype.zavritModalniPanel = function () {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    };
    TextovyModalniPanelComponent.prototype.oznamitSouhlas = function () {
        this.souhlas.emit(this.idPrvku);
    };
    TextovyModalniPanelComponent.prototype.oznamitNesouhlas = function () {
        this.nesouhlas.emit(this.idPrvku);
    };
    TextovyModalniPanelComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    return TextovyModalniPanelComponent;
}());
export { TextovyModalniPanelComponent };
