<main class="container">
    <div class="container__content">
        <div class="container__header">
            {{ 'sekceOperatora.nadpis' | lokalizace }}
        </div>

        <div>
            <section class="info-container">
                <div class="span-2">
                    <div class="info-container no-padding">
                        <h3 class="info-title span-2">{{ 'sekceOperatora.objednavky' | lokalizace }}</h3>
                    </div>

                    <div class="button-area-reverse">
                        <button type="button" (click)="presmerovatNaVytvoritObjednavkuOperatorem()"
                                class="btn btn-secondary waves-effect waves-light right">
                            {{ 'tlacitko.vytvoritObjednavku' | lokalizace }}
                        </button>
                    </div>
                </div>
            </section>
        </div>
        <app-seznam-objednavek-operatora></app-seznam-objednavek-operatora>
    </div>
</main>
