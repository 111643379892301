import {Input, OnInit} from '@angular/core';
import {prostredi} from '../../../../../prostredi/prostredi';
import {AutentizaceService} from '../../../../service/bezpecnost/autentizace.service';
import {InformaceOprihlasenemUzivateli} from '../../../../data/dodavatel/informace-oprihlasenem-uzivateli';
import {AbstraktniOpravneniComponent} from '../../../../service/bezpecnost/abstraktni-opravneni.component';
import {NastrojeLokalizace} from '../../../nastroje/nastroje-lokalizace';

export abstract class AbstraktniTypZobrazeniHlavnihoMenuComponent extends AbstraktniOpravneniComponent implements OnInit {

    public readonly urlPODO = prostredi.urlPODO;
    public readonly urlCDD = prostredi.urlCDD;

    @Input()
    prihlaseny = false;

    @Input()
    prihlasenyAdministrator = false;

    @Input()
    prihlasenyOperator = false;

    @Input()
    informaceOPrihlasenemUzivateli: InformaceOprihlasenemUzivateli;

    @Input()
    jmenoAPrijmeniSpravceDatabazeCiOperatora: string;

    protected constructor(protected autentizaceService: AutentizaceService) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.nastavitVychoziJazykovouMutaci();
    }

    public prihlasit(): void {
        this.autentizaceService.prihlasitCddUzivatele();
    }

    public odhlasit(): void {
        this.autentizaceService.odhlasit();
    }

    public nastavitAnglickouJazykovouMutaci() {
        NastrojeLokalizace.nastavitAnglickouJazykovouMutaci();
    }

    public nastavitCeskouJazykovouMutaci() {
        NastrojeLokalizace.nastavitCeskouJazykovouMutaci();
    }

    public jeNastavenaVychoziJazykovaMutace(): boolean {
        return NastrojeLokalizace.jeNastavenaVychoziJazykovaMutace();
    }

    private nastavitVychoziJazykovouMutaci(): void {
        NastrojeLokalizace.nastavitVychoziJazykovouMutaci();
    }
}
