var AbstraktniRazeniComponent = /** @class */ (function () {
    function AbstraktniRazeniComponent(abstraktniNastaveniSeznamuService, seznamService) {
        this.abstraktniNastaveniSeznamuService = abstraktniNastaveniSeznamuService;
        this.seznamService = seznamService;
    }
    AbstraktniRazeniComponent.prototype.pripravitVychoziAtributRazeni = function () {
        return this.abstraktniNastaveniSeznamuService.pripravitAtributRazeni();
    };
    AbstraktniRazeniComponent.prototype.pripravitVychoziTypRazeni = function () {
        return this.abstraktniNastaveniSeznamuService.pripravitTypRazeni();
    };
    AbstraktniRazeniComponent.prototype.nastavitAtributRazeni = function (atribut) {
        this.abstraktniNastaveniSeznamuService.nastavitAtributRazeni(atribut);
        this.resetovatPoziciNaStrankachAAktualizovat();
    };
    AbstraktniRazeniComponent.prototype.nastavitTypRazeni = function (typRazeni) {
        this.abstraktniNastaveniSeznamuService.nastavitTypRazeni(typRazeni);
        this.resetovatPoziciNaStrankachAAktualizovat();
    };
    AbstraktniRazeniComponent.prototype.resetovatPoziciNaStrankachAAktualizovat = function () {
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.seznamService.aktualizovat(true);
    };
    return AbstraktniRazeniComponent;
}());
export { AbstraktniRazeniComponent };
