import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeObecne} from '../nastroje-obecne';

@Pipe({
    name: 'sleva'
})
export class SlevaPipe implements PipeTransform {

    transform(hodnota: number): any {
        return NastrojeObecne.formatovatRetezec('{0}%', hodnota);
    }
}
