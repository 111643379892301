import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NastrojeIdentifikacnichUdaju } from './nastroje-identifikacnich-udaju';
import { NastrojeKontaktnichUdaju } from './nastroje-kontaktnich-udaju';
import { NastrojeAdresy } from './nastroje-adresy';
import { FakturacniUdaje } from '../../data/e-shop/fakturacni-udaje';
import { Objednavka } from '../../data/e-shop/objednavka';
import { ZpusobVytvoreniObjednavky } from "../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum";

export class NastrojeFakturacnichUdaju {

    public static inicializovatFormularovaData(formGroup: FormGroup, zpusobVytvoreniObjednavky: ZpusobVytvoreniObjednavky): void {
        if (zpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.OPERATOREM) {
            formGroup.addControl('krestniJmeno', new FormControl('', [
                Validators.maxLength(50)
            ]));

            formGroup.addControl('prijmeni', new FormControl('', [
                Validators.maxLength(50)
            ]));
        } else {
            formGroup.addControl('krestniJmeno', new FormControl('', [
                Validators.required,
                Validators.maxLength(50)
            ]));

            formGroup.addControl('prijmeni', new FormControl('', [
                Validators.required,
                Validators.maxLength(50)
            ]));
        }

        formGroup.addControl('nazevOrganizace', new FormControl('', [
            Validators.maxLength(255)
        ]));

        formGroup.addControl('ic', new FormControl('', [
            Validators.minLength(8),
            Validators.maxLength(8),
            Validators.pattern(NastrojeIdentifikacnichUdaju.VZOR_IC)
        ]));

        formGroup.addControl('dic', new FormControl('', [
            Validators.maxLength(14),
            Validators.pattern(NastrojeIdentifikacnichUdaju.VZOR_DIC)
        ]));

        formGroup.addControl('ulice', new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]));

        formGroup.addControl('obec', new FormControl('', [
            Validators.required,
            Validators.maxLength(100)
        ]));

        formGroup.addControl('cisloPopisne', new FormControl('', [
            Validators.required,
            Validators.maxLength(10)
        ]));

        formGroup.addControl('psc', new FormControl('', [
            Validators.required,
            Validators.maxLength(10)
        ]));

        formGroup.addControl('stat', new FormControl('', [
            Validators.required
        ]));

        formGroup.addControl('email', new FormControl('', [
            Validators.required,
            Validators.pattern(NastrojeKontaktnichUdaju.VZOR_EMAIL)
        ]));

        formGroup.addControl('telefon', new FormControl('', [
            Validators.required,
            Validators.pattern(NastrojeKontaktnichUdaju.VZOR_TELEFONNIHO_CISLA)
        ]));

        NastrojeAdresy.nastavitVychoziHodnotuStatu(formGroup);

        formGroup.controls.ic.valueChanges.subscribe(ic => {
            NastrojeFakturacnichUdaju.aktualizovatValidatoryFormulare(formGroup, !!ic);
        });
    }

    public static pripravitFakturacniUdajeZObjednavky(formGroup: FormGroup, objednavka: Objednavka): void {
        formGroup.controls.nazevOrganizace.setValue(objednavka.nazevOrganizace);
        formGroup.controls.ic.setValue(objednavka.ic);
        formGroup.controls.dic.setValue(objednavka.dic);
        formGroup.controls.krestniJmeno.setValue(objednavka.jmeno);
        formGroup.controls.prijmeni.setValue(objednavka.prijmeni);
        formGroup.controls.ulice.setValue(objednavka.fakturacniAdresa.ulice);
        formGroup.controls.obec.setValue(objednavka.fakturacniAdresa.obec);
        formGroup.controls.cisloPopisne.setValue(objednavka.fakturacniAdresa.cisloPopisne);
        formGroup.controls.psc.setValue(objednavka.fakturacniAdresa.psc);
        formGroup.controls.stat.setValue(objednavka.fakturacniAdresa.stat);
        formGroup.controls.email.setValue(objednavka.email);
        formGroup.controls.telefon.setValue(objednavka.telefon);
    }

    public static pripravitFakturacniUdajeZFakturacnichUdaju(formGroup: FormGroup, fakturacniUdaje: FakturacniUdaje): void {
        formGroup.controls.nazevOrganizace.setValue(fakturacniUdaje.nazevSpolecnosti);
        formGroup.controls.ic.setValue(fakturacniUdaje.ic);
        formGroup.controls.dic.setValue(fakturacniUdaje.dic);
        formGroup.controls.krestniJmeno.setValue(fakturacniUdaje.krestniJmeno);
        formGroup.controls.prijmeni.setValue(fakturacniUdaje.prijmeni);
        formGroup.controls.ulice.setValue(fakturacniUdaje.ulice);
        formGroup.controls.obec.setValue(fakturacniUdaje.obec);
        formGroup.controls.cisloPopisne.setValue(fakturacniUdaje.cisloPopisne);
        formGroup.controls.psc.setValue(fakturacniUdaje.psc);
        formGroup.controls.stat.setValue(fakturacniUdaje.stat);
        formGroup.controls.email.setValue(fakturacniUdaje.email);
        formGroup.controls.telefon.setValue(fakturacniUdaje.telefon);

        formGroup.markAsPristine();
        formGroup.markAsUntouched();
    }

    private static aktualizovatValidatoryFormulare(formGroup: FormGroup, vyplneneIC: boolean): void {
        if (vyplneneIC) {
            formGroup.controls.nazevOrganizace.setValidators([
                Validators.required
            ]);
        } else {
            formGroup.controls.nazevOrganizace.clearValidators();
        }

        formGroup.controls.nazevOrganizace.updateValueAndValidity();
    }
}
