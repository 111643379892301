import {Injectable} from '@angular/core';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {prostredi} from '../../../prostredi/prostredi';
import {Observable} from 'rxjs';
import {Predplatne} from '../../data/e-shop/predplatne';

@Injectable()
export class PredplatneService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService
    ) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopPredplatne;
    }

    public nacistAktivniPredplatne(): Observable<Predplatne[]> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/aktivni-predplatne')
            .catch(this.zpracovatChybu);
    }
}
