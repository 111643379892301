import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {TypUceluFormulare} from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {AbstraktniFormularComponent} from '../../formular/abstraktni-formular.component';
import {NastrojeEnumu} from '../../nastroje/nastroje-enumu';

@Component({
    selector: 'app-razeni',
    templateUrl: './razeni.component.html',
    styleUrls: ['./razeni.component.scss']
})
export class RazeniComponent extends AbstraktniFormularComponent implements OnInit {

    @Input() atributyRazeni: any;

    @Input() vychoziAtributRazeni: any;

    @Input() typyRazeni: any;

    @Input() vychoziTypRazeni: any;

    @Output() zmenaAtributuRazeni = new EventEmitter<any>();

    @Output() zmenaTypuRazeni = new EventEmitter<any>();

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService
    ) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
    }

    odeslat(): void {
    }

    private inicializovatFormularovaData() {
        this.formGroup.addControl('atributRazeni', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(this.atributyRazeni, this.vychoziAtributRazeni), []));
        this.formGroup.addControl('typRazeni', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(this.typyRazeni, this.vychoziTypRazeni), []));

        this.formGroup.controls.atributRazeni.valueChanges.subscribe(() => this.oznamitZmenuAtributuRazeni());
        this.formGroup.controls.typRazeni.valueChanges.subscribe(() => this.oznamitZmenuTypuRazeni());
    }

    private oznamitZmenuAtributuRazeni() {
        this.zmenaAtributuRazeni.emit(this.atributyRazeni[String(this.formGroup.controls.atributRazeni.value)]);
    }

    private oznamitZmenuTypuRazeni() {
        this.zmenaTypuRazeni.emit(this.typyRazeni[String(this.formGroup.controls.typRazeni.value)]);
    }
}
