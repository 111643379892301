export enum StavPlatby {
    VYTVORENA = 'VYTVORENA',
    PLATEBNI_METODA_VYBRANA = 'PLATEBNI_METODA_VYBRANA',
    ZAPLACENA = 'ZAPLACENA',
    AUTORIZOVANA = 'AUTORIZOVANA',
    ZRUSENA = 'ZRUSENA',
    VYPRSELA_PLATNOST = 'VYPRSELA_PLATNOST',
    REFUNDOVANA = 'REFUNDOVANA',
    CASTECNE_REFUNDOVANA = 'CASTECNE_REFUNDOVANA'
}
