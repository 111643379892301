import {FormGroup} from '@angular/forms';
import {TypHodnotyRozsahu} from '../../data/obecne/enum/typ-hodnoty-rozsahu.enum';
import {Rozsah} from '../../data/obecne/rozsah';
import {NastrojeCisla} from './nastroje-cisla';
import {NastrojeDataACasu} from './nastroje-data-a-casu';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeFiltrace {

    public static pripravitFiltr(filtr: any, formGroup: FormGroup): any {
        for (const nazevPrvkuFormulare of Object.keys(formGroup.controls)) {
            const hodnotaPrvkuFormulare = formGroup.controls[nazevPrvkuFormulare].value;

            if (!NastrojeObecne.jeHodnotaPrazdna(hodnotaPrvkuFormulare)) {
                const pouzitySuffixRozsahu = this.pripravitSuffixRozsahu(nazevPrvkuFormulare);

                if (pouzitySuffixRozsahu !== null) {
                    const nazevRozsahu = nazevPrvkuFormulare.slice(0, nazevPrvkuFormulare.length - pouzitySuffixRozsahu.length);
                    const suffixRozsahu = pouzitySuffixRozsahu.charAt(0).toLowerCase() + pouzitySuffixRozsahu.slice(1);

                    if (filtr[nazevRozsahu] === undefined) {
                        filtr[nazevRozsahu] = new Rozsah();
                    }

                    filtr[nazevRozsahu][suffixRozsahu] = this.pripravitHodnotuRozsahu(hodnotaPrvkuFormulare, suffixRozsahu);
                } else {
                    filtr[nazevPrvkuFormulare] = hodnotaPrvkuFormulare;
                }
            }
        }

        return filtr;
    }

    public static nastavitVychoziHodnoty(formGroup: FormGroup, objektNastaveniFiltru: any) {
        const nazvyPouzitychFiltracnichAtributu = Object.keys(objektNastaveniFiltru);

        for (let i = 0; i < nazvyPouzitychFiltracnichAtributu.length; i++) {
            const nazevAtributu = nazvyPouzitychFiltracnichAtributu[i];
            const hodnota = objektNastaveniFiltru[nazevAtributu];

            if (hodnota instanceof Rozsah) {
                const hodnotaOd = this.pripravitVychoziHodnotu(hodnota.od);
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu + TypHodnotyRozsahu.OD, hodnotaOd);

                const hodnotaDo = this.pripravitVychoziHodnotu(hodnota.do);
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu + TypHodnotyRozsahu.DO, hodnotaDo);
            } else {
                this.nastavitVychoziHodnotu(formGroup, nazevAtributu, this.pripravitVychoziHodnotu(hodnota));
            }
        }
    }

    private static pripravitSuffixRozsahu(nazevHodnoty: string): string {
        for (const typHodnotyRozsahu of Object.keys(TypHodnotyRozsahu)) {
            const testovanySuffix = TypHodnotyRozsahu[typHodnotyRozsahu];

            if (nazevHodnoty.endsWith(testovanySuffix)) {
                return testovanySuffix;
            }
        }

        return null;
    }

    private static pripravitHodnotuRozsahu(hodnota: string, suffix: string) {
        if (new RegExp(NastrojeCisla.VZOR_DECIMAL).test(hodnota)) {
            return NastrojeCisla.prevestNaCislo(hodnota);
        } else if (new RegExp(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA).test(hodnota)) {
            const datum = NastrojeDataACasu.prevestRetezecNaDatum(hodnota, NastrojeDataACasu.FORMAT_DATA);

            if (NastrojeDataACasu.jeValidniDatum(datum)) {
                return suffix === 'od' || suffix === 'rozsahOd' ? NastrojeDataACasu.nastavitCasZacatekDne(datum) : NastrojeDataACasu.nastavitCasKonecDne(datum);
            }
        }

        return hodnota;
    }

    private static nastavitVychoziHodnotu(formGroup: FormGroup, nazevAtributu: string, hodnota: string) {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota)) {
            formGroup.controls[nazevAtributu].setValue(hodnota);
            formGroup.controls[nazevAtributu].markAsTouched();
            formGroup.controls[nazevAtributu].markAsDirty();
        }
    }

    private static pripravitVychoziHodnotu(hodnota: any): string {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota) && hodnota instanceof Date) {
            return NastrojeDataACasu.formatovatDatum(hodnota, NastrojeDataACasu.FORMAT_DATA);
        }
        return hodnota;
    }
}
