import { OnInit } from '@angular/core';
import { SeznamObjednavekNastaveniService } from '../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import { SeznamObjednavekService } from '../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
var SeznamObjednavekComponent = /** @class */ (function () {
    function SeznamObjednavekComponent(seznamObjednavekNastaveniService, seznamObjednavekService) {
        this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        this.seznamObjednavekService = seznamObjednavekService;
    }
    SeznamObjednavekComponent.prototype.ngOnInit = function () {
        this.seznamObjednavekNastaveniService.inicializovat();
        this.seznamObjednavekService.aktualizovat(false);
    };
    return SeznamObjednavekComponent;
}());
export { SeznamObjednavekComponent };
