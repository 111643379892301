import {Notifikace, Uroven} from '../notifikace';

export class NastrojeRestu {

    private static readonly klicVychoziChyboveZpravy = 'chyba.problemPriZpracovaniPozadavku';

    public static zpracovatChybu(error: Response | any): Promise<any> {
        if (error.error && error.error.length > 1) {
            error.error.forEach(chyba => this.zobrazitChybu(chyba.klic, chyba.parametry));
        } else {
            this.zobrazitChybu(error.error.klic, error.error.parametry);
        }

        return Promise.reject(error.message || error);
    }

    private static zobrazitChybu(klicTextu: string, parametry: any[]): void {
        Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, klicTextu || this.klicVychoziChyboveZpravy, ...parametry);
    }
}
