var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { Lokalizace } from '../../../../lokalizace';
import { NastrojeDataACasu } from '../../../../nastroje/nastroje-data-a-casu';
import { NastrojeObecne } from '../../../../nastroje/nastroje-obecne';
import { AbstraktniFormularovyPrvekComponent } from '../abstraktni-formularovy-prvek.component';
/**
 * Pouziti:
 *  <app-formular-kalendar
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 * </app-formular-kalendar>
 */
var FormularKalendarComponent = /** @class */ (function (_super) {
    __extends(FormularKalendarComponent, _super);
    function FormularKalendarComponent() {
        return _super.call(this) || this;
    }
    FormularKalendarComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.inicializovatKalendar();
    };
    FormularKalendarComponent.prototype.nastavitHodnotuOvladaceFormulare = function (event) {
        var hodnota = $(event.target).val();
        this.ovladacFormulare.setValue(hodnota);
    };
    FormularKalendarComponent.prototype.otevritKalendarAVybratDatum = function () {
        this.otevritKalendar();
        if (NastrojeObecne.jeHodnotaPrazdna(this.ovladacFormulare.value)) {
            this.vybratDatumKalendare(NastrojeDataACasu.nastavitCasZacatekDne(new Date()));
        }
        else {
            this.vybratDatumKalendare(NastrojeDataACasu.prevestRetezecNaDatum(this.ovladacFormulare.value, NastrojeDataACasu.FORMAT_DATA));
        }
    };
    FormularKalendarComponent.prototype.otevritKalendar = function () {
        var instanceKalendare = this.pripravitInstanciKalendare();
        if (!instanceKalendare.isOpen) {
            instanceKalendare.open();
        }
    };
    FormularKalendarComponent.prototype.vybratDatumKalendare = function (datum) {
        var instanceKalendare = this.pripravitInstanciKalendare();
        instanceKalendare.setDate(datum);
    };
    FormularKalendarComponent.prototype.inicializovatKalendar = function () {
        var nazvyMesicu = this.pripravitNazvyMesicu();
        var nazvyDnu = this.pripravitNazvyDnu();
        $(document).ready(function () {
            $('.datepicker').datepicker({
                format: NastrojeDataACasu.FORMULAR_KALENDAR_FORMAT_DATA,
                firstDay: 1,
                yearRange: 100,
                i18n: {
                    cancel: Lokalizace.pripravitText('zavrit'),
                    done: Lokalizace.pripravitText('potvrdit'),
                    clear: Lokalizace.pripravitText('odstranit'),
                    months: nazvyMesicu,
                    monthsShort: nazvyMesicu,
                    weekdaysShort: nazvyDnu,
                    weekdaysAbbrev: nazvyDnu,
                },
                showClearBtn: true,
                onOpen: function () {
                    this.options.onSelect(this);
                },
                onSelect: function (context) {
                    var kalendar = context instanceof Date ? this : context;
                    var elementTextuData = $(kalendar.dateTextEl);
                    if (!(context instanceof Date) && elementTextuData.next().length === 0) {
                        var formatovacData = require('dateformat');
                        var formatData = kalendar.options.format;
                        elementTextuData.parent().append(elementTextuData.clone());
                        elementTextuData.next().text(formatovacData(new Date(), formatData));
                        elementTextuData.prev().hide();
                        elementTextuData.hide();
                    }
                    else if (context instanceof Date) {
                        elementTextuData.next().text(kalendar.toString());
                    }
                }
            });
        });
    };
    FormularKalendarComponent.prototype.pripravitNazvyDnu = function () {
        return [
            Lokalizace.pripravitText('ne'),
            Lokalizace.pripravitText('po'),
            Lokalizace.pripravitText('ut'),
            Lokalizace.pripravitText('st'),
            Lokalizace.pripravitText('ct'),
            Lokalizace.pripravitText('pa'),
            Lokalizace.pripravitText('so')
        ];
    };
    FormularKalendarComponent.prototype.pripravitNazvyMesicu = function () {
        return [
            Lokalizace.pripravitText('leden'),
            Lokalizace.pripravitText('unor'),
            Lokalizace.pripravitText('brezen'),
            Lokalizace.pripravitText('duben'),
            Lokalizace.pripravitText('kveten'),
            Lokalizace.pripravitText('cerven'),
            Lokalizace.pripravitText('cervenec'),
            Lokalizace.pripravitText('srpen'),
            Lokalizace.pripravitText('zari'),
            Lokalizace.pripravitText('rijen'),
            Lokalizace.pripravitText('listopad'),
            Lokalizace.pripravitText('prosinec')
        ];
    };
    FormularKalendarComponent.prototype.pripravitInstanciKalendare = function () {
        var element = this.tlacitkoKalendare.nativeElement;
        return M.Datepicker.getInstance(element);
    };
    return FormularKalendarComponent;
}(AbstraktniFormularovyPrvekComponent));
export { FormularKalendarComponent };
