import { NastrojeGoogleAnalytics } from '../../shared/nastroje/nastroje-google-analytics';
import { NastrojeObecne } from '../../shared/nastroje/nastroje-obecne';
var GoogleAnalyticsTrackingService = /** @class */ (function () {
    function GoogleAnalyticsTrackingService() {
        this.nazevSeznamu = 'Ceník';
        this.pocetProduktu = 1;
    }
    GoogleAnalyticsTrackingService.prototype.pridatConfig = function (userId) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('config', 'G-5G52QV1Z8L', {
                'user_id': userId,
                'send_page_view': false
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatPridaniZboziDoKosiku = function (objednavka) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.addToCart, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatZapocetiCheckoutu = function (objednavka) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.beginCheckout, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatCheckoutProgress = function (objednavka, poradoveCisloKrokuObjednavky) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.checkoutProgress, {
                "checkout_step": poradoveCisloKrokuObjednavky,
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatCheckoutOption = function (objednavka, poradoveCisloKrokuObjednavky) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.setCheckoutOption, {
                'checkout_step': poradoveCisloKrokuObjednavky,
                'checkout_option': 'Způsob platby',
                'value': objednavka.zpusobPlatby
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatPurchase = function (objednavka) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            var dan = objednavka.cena.cenaSDph - objednavka.cena.cenaBezDph;
            var eventContent = {
                'transaction_id': objednavka.identifikator,
                'affiliation': 'FEN eShop/PoDo',
                'value': objednavka.cena.cenaSDph,
                'currency': 'CZK',
                'tax': dan < 0 ? 0 : dan,
                'shipping': 0,
                'items': [
                    this.pripravitItem(objednavka)
                ]
            };
            gtag('event', NastrojeGoogleAnalytics.purchase, eventContent);
        }
    };
    GoogleAnalyticsTrackingService.prototype.evidovatZruseni = function (objednavka) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.removeFromCart, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    };
    GoogleAnalyticsTrackingService.prototype.pripravitItem = function (objednavka) {
        return {
            'id': NastrojeGoogleAnalytics.pripravitGAIdProduktu(objednavka.nazevProduktu, objednavka.typPredplatneho),
            'name': NastrojeGoogleAnalytics.pripravitGANazevProduktu(objednavka.nazevProduktu, objednavka.typPredplatneho),
            'list_name': this.nazevSeznamu,
            'list_position': NastrojeGoogleAnalytics.pripravitGAPoradiProduktuVSeznamu(objednavka.nazevProduktu),
            'quantity': this.pocetProduktu,
            'price': NastrojeObecne.jeHodnotaPrazdna(objednavka.cena) ? '' : objednavka.cena.cenaSDph
        };
    };
    return GoogleAnalyticsTrackingService;
}());
export { GoogleAnalyticsTrackingService };
