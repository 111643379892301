import {AbstraktniNastaveniSeznamuService} from '../../../service/seznam/abstraktni-nastaveni-seznamu-service';
import {AbstraktniSeznamService} from '../../../service/seznam/abstraktni-seznam-service';
import {AbstraktniFormularComponent} from '../../formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {NastrojeFiltrace} from '../../nastroje/nastroje-filtrace';

export abstract class AbstraktniFiltrComponent extends AbstraktniFormularComponent {

    protected constructor(
        protected abstraktniNastaveniSeznamuService: AbstraktniNastaveniSeznamuService,
        protected abstraktniSeznamService: AbstraktniSeznamService,
        protected autentizaceService: AutentizaceService
    ) {
        super(autentizaceService);
    }

    protected vyhledat(filtr: any): void {
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.abstraktniNastaveniSeznamuService.getNastaveniZobrazeni().filtr = filtr;
        this.abstraktniSeznamService.aktualizovat(false);
    }

    protected nastavitVychoziHodnoty(): void {
        NastrojeFiltrace.nastavitVychoziHodnoty(this.formGroup, this.abstraktniNastaveniSeznamuService.getNastaveniZobrazeni().filtr);
    }

    public resetovatFiltr(): void {
        this.resetovatFormular();
        this.abstraktniNastaveniSeznamuService.resetovatFiltr();
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.abstraktniSeznamService.aktualizovat(false);
    }
}
