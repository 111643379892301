<div class="info-container no-padding">
    <h3 class="info-title span-2">{{ 'objednavka.fakturacniUdaje' | lokalizace }}</h3>

    <div class="info-group span-2">
        <app-formular-output *ngIf="objednavka.nazevOrganizace" [popisek]="'fakturacniUdaje.nazevSpolecnosti'" [hodnota]="objednavka.nazevOrganizace"></app-formular-output>
        <app-formular-output [popisek]="'fakturacniUdaje.jmeno'" [hodnota]="objednavka.jmeno + ' ' + objednavka.prijmeni"></app-formular-output>
        <app-formular-output [popisek]="'fakturacniUdaje.adresa'" [hodnota]="objednavka.fakturacniAdresa | adresaPipe"></app-formular-output>
    </div>

    <div class="info-group">
        <app-formular-output [popisek]="'fakturacniUdaje.email'" [hodnota]="objednavka.email"></app-formular-output>
        <app-formular-output *ngIf="objednavka.ic || objednavka.dic" [popisek]="'fakturacniUdaje.ico'" [hodnota]="objednavka.ic"></app-formular-output>
    </div>

    <div class="info-group">
        <app-formular-output [popisek]="'fakturacniUdaje.telefon'" [hodnota]="objednavka.telefon"></app-formular-output>
        <app-formular-output *ngIf="objednavka.ic || objednavka.dic" [popisek]="'fakturacniUdaje.dic'" [hodnota]="objednavka.dic"></app-formular-output>
    </div>
</div>
