import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {FormBuilder, FormControl, Validators} from '@angular/forms';

import {ZruseniObjednavky} from '../../../../data/e-shop/zruseni-objednavky';
import {AbstraktniFormularComponent} from '../../../../shared/formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../../service/bezpecnost/autentizace.service';
import {TypUceluFormulare} from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeModalnihoPanelu} from '../../../../shared/nastroje/nastroje-modalniho-panelu';
import {Objednavka} from '../../../../data/e-shop/objednavka';
import {ObjednavkaService} from '../../../../service/e-shop/objednavka.service';

@Component({
    selector: 'app-zrusit-objednavku-modalni-panel',
    templateUrl: './zrusit-objednavku-modalni-panel.component.html',
    styleUrls: ['./zrusit-objednavku-modalni-panel.component.scss']
})
export class ZrusitObjednavkuModalniPanelComponent extends AbstraktniFormularComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    @Input() objednavka: Objednavka;

    @Output() zruseno = new EventEmitter<String>();

    private duvodZruseni: string;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private objednavkaService: ObjednavkaService
    ) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatModalniPanel();
        this.inicializovatFormularovaData();
    }

    odeslat() {
        this.zobrazitVrtitko = true;
        this.objednavkaService.zrusitObjednavku(this.pripravitDataZModalu()).toPromise().then(() => {
            this.zobrazitVrtitko = false;
            this.zruseno.emit();
        }).catch(() => this.zobrazitVrtitko = false);


        this.zavritModalniPanel();
    }

    private pripravitDataZModalu(): ZruseniObjednavky {
        return new ZruseniObjednavky(this.objednavka.identifikator, this.duvodZruseni);
    }


    private inicializovatFormularovaData() {
        this.formGroup.addControl('duvodZruseni', new FormControl('', [
            Validators.required,
            Validators.maxLength(1000)
        ]));

        this.inicializovatPosluchaceZmenHodnot();
    }

    private inicializovatPosluchaceZmenHodnot() {
        this.formGroup.controls.duvodZruseni.valueChanges.subscribe(duvodZruseni => {
            this.duvodZruseni = duvodZruseni;
        });
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    private zavritModalniPanel() {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    }
}
