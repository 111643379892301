import { OnInit } from '@angular/core';
import { TypPredplatneho } from '../../../data/e-shop/enum/predplatne.enum';
import { NastrojeDataACasu } from '../../../shared/nastroje/nastroje-data-a-casu';
import { Router } from '@angular/router';
import { NastrojeObecne } from '../../../shared/nastroje/nastroje-obecne';
import { ZpusobPlatby } from '../../../data/e-shop/enum/zpusob-platby.enum';
import { PredplatneService } from '../../../service/e-shop/predplatne.service';
var TabulkaAktivnichPredplatnychComponent = /** @class */ (function () {
    function TabulkaAktivnichPredplatnychComponent(predplatneService, router) {
        this.predplatneService = predplatneService;
        this.router = router;
        this.formatData = NastrojeDataACasu.FORMAT_DATA;
        this.predplatne = TypPredplatneho;
        this.zpusobPlatby = ZpusobPlatby;
        this.zobrazitVrtitko = false;
    }
    TabulkaAktivnichPredplatnychComponent.prototype.ngOnInit = function () {
        this.pripravitAktivniPredplatne();
    };
    TabulkaAktivnichPredplatnychComponent.prototype.prejitNaDetailPlatby = function (idPlatby, zpusobPlatby) {
        if (!NastrojeObecne.jeHodnotaPrazdna(idPlatby) && zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU) {
            this.router.navigate(['/e-shop/platba'], { queryParams: { id: idPlatby } });
        }
    };
    TabulkaAktivnichPredplatnychComponent.prototype.pripravitAktivniPredplatne = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.predplatneService.nacistAktivniPredplatne()
            .toPromise()
            .then(function (odpoved) {
            _this.aktivniPredplatne = odpoved;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return TabulkaAktivnichPredplatnychComponent;
}());
export { TabulkaAktivnichPredplatnychComponent };
