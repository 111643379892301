import { prostredi } from './../../../../prostredi/prostredi';
var LokalniUlozisteNastaveni = /** @class */ (function () {
    function LokalniUlozisteNastaveni() {
    }
    LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE = '_';
    LokalniUlozisteNastaveni.SUFIX_NAZVU_KLICE_TOKENU = 'token';
    LokalniUlozisteNastaveni.PREFIX_NAZVU_DOCASNEHO_KLICE = 'Tmp';
    LokalniUlozisteNastaveni.NAZEV_AKTUALNI_VERZE_PROSTREDI = prostredi.verze;
    LokalniUlozisteNastaveni.NAZVY_VSECH_VERZI_PROSTREDI = ['Lokální', 'Testovací', 'Produkční'];
    return LokalniUlozisteNastaveni;
}());
export { LokalniUlozisteNastaveni };
