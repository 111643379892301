import {HostBinding, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TypUceluFormulare} from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {Lokalizace} from '../../../lokalizace';
import {NastrojeFormulare} from '../../../nastroje/nastroje-formulare';
import {NastrojeObecne} from '../../../nastroje/nastroje-obecne';
import {NastrojeValidace} from '../../../nastroje/nastroje-validace';
import {Notifikace, Uroven} from '../../../notifikace';
import {ValidacniZprava} from '../../validator/validacni-zprava';

export class AbstraktniFormularovyPrvekComponent implements OnInit {

    private readonly klicChyboveHlaskyKontrolyTypuFormulare = 'formular.chyba.typFormulareNeniNastaven';

    @HostBinding('hidden') get pripravitPodminkuViditelnosti(): any {
        return this.skryt();
    }

    @HostBinding('class.required-form-field') get pripravitPovinnostHodnoty(): boolean {
        return this.jeHodnotaPovinna();
    }

    /**
     * Ovladac formulare (FormControl) reprezentuje datovou strukturu formulare
     */
    @Input() ovladacFormulare: FormControl;

    /**
     * Popisek prvku formulare
     */
    @Input() popisek: string;

    /**
     * Text zobrazeny pod prvkem formulare
     */
    @Input() pomocnyText: string;

    /**
     * Pro pripady kdy chceme pomocny text skryt uplne
     */
    @Input() skrytPomocnyText = false;

    /**
     * Priznak urcujici, zda se uzivatel pokusil formular odeslat
     */
    @Input() odeslan = false;

    /**
     * Pokud je pomocnym textem napr. spravny format hodnoty, zobrazi se pomocny text na konci chybove hlasky v zavorce
     */
    @Input() pomocnyTextSoucastiChyby = false;

    /**
     * Urcuje zda se ma vzdy skryt formularovy prvek, pokud je neaktivni, ma prioritu pred "vzdySkrytNeaktivniPrazdnouHodnotu"
     */
    @Input() vzdySkrytNeaktivniHodnotu = false;

    /**
     * Urcuje zda se ma vzdy skryt formularovy prvek, pokud je neaktivni a zaroven je jeho hodnota prazdna
     */
    @Input() vzdySkrytNeaktivniPrazdnouHodnotu = true;

    /**
     * Urcuje zda se ma pouzit trida responsive-input-field, ktera responzivne zmensuje odsazeni jednotlivych poli
     */
    @Input() zmensitRozestupy = false;

    /**
     * Pro spravne fungovani formularovych prvku zobrazenych pomoci materialize
     * je nutne definovat vazbu mezi popiskem prvku a samotnym vstupnim prvkem pomoci id elementu.
     * Toto id generujeme automaticky, abychom ho nemuseli zadavat rucne.
     */
    public idPrvku: string;

    /**
     * Nazev pod kterym je prvek uveden ve skupine formulare.
     */
    public nazevOvladaceFormulare: string;

    /**
     * Kazdy formularovy prvek musi byt umisten do struktury:
     * <div [formGroup]=parentFormGroup></div>
     * Vyjimkou jsou prvky umistene primo do <form> tagu formulare
     */
    public nadrazenaSkupinaFormulare: FormGroup;

    ngOnInit() {
        this.nadrazenaSkupinaFormulare = this.pripravitNadrazenouSkupinuFormulare();
        this.nazevOvladaceFormulare = this.pripravitNazevOvladaceFormulare();
        this.idPrvku = this.pripravitIdPrvku();
        this.zkontrolovatTypUceluFormulare();
        this.nastavitPosluchaceZmenyHodnoty();
    }

    public pripravitChybovouZpravu(): string {
        if (this.zobrazitChybovouHlaskuPrkuFormulare()) {
            const validacniZprava = this.pripravitValidacniZpravu();
            const vyslednyTextValidacniZpravy = Lokalizace.formatovatText(validacniZprava.klic, ...validacniZprava.hodnoty);

            return vyslednyTextValidacniZpravy + (this.pomocnyTextSoucastiChyby ? ` (${Lokalizace.pripravitText(this.pomocnyText)})` : '');
        }

        return null;
    }

    public skryt(): boolean {
        return this.ovladacFormulare.disabled && (this.jeTypUceluFormulare(TypUceluFormulare.VYTVORENI) || this.vzdySkrytNeaktivniHodnotu || (this.vzdySkrytNeaktivniPrazdnouHodnotu && NastrojeObecne.jeHodnotaPrazdna(this.ovladacFormulare.value)));
    }

    public zneaktivnit(): boolean {
        return this.ovladacFormulare.disabled && this.jeTypUceluFormulare(TypUceluFormulare.UPRAVA);
    }

    public zobrazitStavPrvkuFormulare(): boolean {
        return !this.ovladacFormulare.disabled && ((this.ovladacFormulare.dirty && this.ovladacFormulare.touched) || (this.odeslan && this.ovladacFormulare.invalid));
    }

    public zobrazitChybovouHlaskuPrkuFormulare(): boolean {
        return this.zobrazitStavPrvkuFormulare() && this.ovladacFormulare.invalid;
    }

    public jeHodnotaPovinna(): boolean {
        return NastrojeValidace.jePouzitValidatorPovinneHodnoty(this.ovladacFormulare);
    }

    public jePouzitValidator(nazevValidatoru: string): boolean {
        return NastrojeValidace.jePouzitValidator(this.ovladacFormulare, nazevValidatoru);
    }

    private pripravitValidacniZpravu(): ValidacniZprava {
        return NastrojeValidace.pripravitChybovouZpravu(this.ovladacFormulare);
    }

    private pripravitNadrazenouSkupinuFormulare(): FormGroup {
        return this.ovladacFormulare['_parent'];
    }

    private pripravitNazevOvladaceFormulare(): string {
        for (const nazevOvladaceFormulare of Object.keys(this.nadrazenaSkupinaFormulare.controls)) {
            if (this.ovladacFormulare === this.nadrazenaSkupinaFormulare.controls[nazevOvladaceFormulare]) {
                return nazevOvladaceFormulare;
            }
        }

        return null;
    }

    private pripravitIdPrvku(): string {
        return NastrojeObecne.vygenerovatNahodnyRetezec();
    }

    private nastavitPosluchaceZmenyHodnoty() {
        this.ovladacFormulare.valueChanges.subscribe(() => {
            this.ovladacFormulare.markAsTouched();
            this.ovladacFormulare.markAsDirty();
        });
    }

    private jeTypUceluFormulare(typUceluFormulare: TypUceluFormulare): boolean {
        return this.pripravitTypUceluFormulare() === typUceluFormulare;
    }

    private pripravitTypUceluFormulare() {
        return NastrojeFormulare.pripravitTypUceluFormulare(this.nadrazenaSkupinaFormulare);
    }

    private zkontrolovatTypUceluFormulare() {
        if (NastrojeObecne.jeHodnotaPrazdna(this.pripravitTypUceluFormulare())) {
            Notifikace.zobrazitZpravu(Uroven.CHYBA, Lokalizace.formatovatText(this.klicChyboveHlaskyKontrolyTypuFormulare, this.nazevOvladaceFormulare));
        }
    }
}
