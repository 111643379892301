import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TypPoctuZaznamuNaStranku} from '../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {AutentizaceService} from '../../../../../../service/bezpecnost/autentizace.service';
import {TypUceluFormulare} from '../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeEnumu} from '../../../../../../shared/nastroje/nastroje-enumu';
import {NastrojeModalnihoPanelu} from '../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';
import {SeznamSlevovychKuponuNastaveniService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import {AbstraktniFormularComponent} from '../../../../../../shared/formular/abstraktni-formular.component';

@Component({
    selector: 'app-seznam-slevovych-kuponu-nastaveni',
    templateUrl: './seznam-slevovych-kuponu-nastaveni.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-nastaveni.component.scss']
})
export class SeznamSlevovychKuponuNastaveniComponent extends AbstraktniFormularComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    public typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    }

    odeslat(): void {
        const pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;

        this.seznamSlevovychKuponuNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(1);
        this.seznamSlevovychKuponuService.aktualizovat();
    }

    private inicializovatFormularovaData(): void {
        const pocetZaznamuNaStranku = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;

        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    }

    private inicializovatModalniPanel(): void {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamSlevovychKuponu = this.seznamSlevovychKuponuService.seznam;
        const nastaveniZobrazeniSeznamuSlevovychKuponu = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu);
    }

    public pripravitCekovyPocetZaznamu(): number {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    }
}
