import { OnInit } from '@angular/core';
import { SeznamSlevovychKuponuNastaveniService } from '../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import { SeznamSlevovychKuponuService } from '../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
var SeznamSlevovychKuponuComponent = /** @class */ (function () {
    function SeznamSlevovychKuponuComponent(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) {
        this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
    }
    SeznamSlevovychKuponuComponent.prototype.ngOnInit = function () {
        this.seznamSlevovychKuponuNastaveniService.inicializovat();
        this.seznamSlevovychKuponuService.aktualizovat(false);
    };
    return SeznamSlevovychKuponuComponent;
}());
export { SeznamSlevovychKuponuComponent };
