import { SeznamObjednavekNastaveniService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import { SeznamObjednavekService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import { NastrojeStrankovani } from '../../../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamObjednavekStrankovaniComponent = /** @class */ (function () {
    function SeznamObjednavekStrankovaniComponent(seznamObjednavekNastaveniService, seznamObjednavekService) {
        this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        this.seznamObjednavekService = seznamObjednavekService;
    }
    SeznamObjednavekStrankovaniComponent.prototype.predchoziStranka = function () {
        var hodnota = this.pripravitHodnotu();
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamObjednavekService.aktualizovat();
    };
    SeznamObjednavekStrankovaniComponent.prototype.dalsiStranka = function () {
        var hodnota = this.pripravitHodnotu();
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamObjednavekService.aktualizovat();
    };
    SeznamObjednavekStrankovaniComponent.prototype.jePrvniStranka = function () {
        return this.pripravitHodnotu() === 1;
    };
    SeznamObjednavekStrankovaniComponent.prototype.jePosledniStranka = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    };
    SeznamObjednavekStrankovaniComponent.prototype.pripravitCelkovyPocetZaznamu = function () {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    };
    SeznamObjednavekStrankovaniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekStrankovaniComponent.prototype.pripravitHodnotu = function () {
        return this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    return SeznamObjednavekStrankovaniComponent;
}());
export { SeznamObjednavekStrankovaniComponent };
