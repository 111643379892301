import {InformaceOprihlasenemUzivateli} from '../../data/dodavatel/informace-oprihlasenem-uzivateli';
import {AutentizaceService} from './autentizace.service';
import {Uzivatel} from '../../data/dodavatel/uzivatel';
import {NastrojeAutentizace} from '../../shared/nastroje/nastroje-autentizace';

export abstract class AbstraktniOpravneniComponent {

    protected constructor(protected autentizaceService: AutentizaceService) {
        if (autentizaceService.jeUzivatelPrihlaseny() && !this.getInformaceOPrihlasenemUzivateli() && !this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze() && !this.autentizaceService.jePrihlasenyUzivatelOperator()) {
            this.autentizaceService.aktualizovatInformaceOUzivateliDoLocalStorage();
        }
    }

    public getInformaceOPrihlasenemUzivateli(): InformaceOprihlasenemUzivateli {
        return NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage();
    }

    public opravneniAdministraceJakoSpravceDatabaze(): boolean {
        return this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze();
    }

    public opravneniObsluhovatEshopJakoOperator(): boolean {
        return this.autentizaceService.jePrihlasenyUzivatelOperator();
    }

    private jeUzivatelPrihlaseny(uzivatel: Uzivatel): boolean {
        return this.getInformaceOPrihlasenemUzivateli().informaceOUzivateli.id === uzivatel.id;
    }
}

