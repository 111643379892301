import { ElementRef } from '@angular/core';
import { NastrojeStrankovani } from '../../../../../../../shared/nastroje/nastroje-strankovani';
import { NastrojeCisla } from '../../../../../../../shared/nastroje/nastroje-cisla';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
var SeznamObjednavekOperatoraAktualniStrankaComponent = /** @class */ (function () {
    function SeznamObjednavekOperatoraAktualniStrankaComponent(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
    }
    SeznamObjednavekOperatoraAktualniStrankaComponent.prototype.pripravitAktualniStranku = function () {
        return this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamObjednavekOperatoraAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekOperatoraAktualniStrankaComponent.prototype.nastavitStranku = function () {
        var element = $(this.vstupniPole.nativeElement);
        var hodnota = element.val();
        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (hodnota <= 0) {
            hodnota = 1;
        }
        else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }
        element.blur();
        element.val(hodnota);
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamObjednavekOperatoraService.aktualizovat();
    };
    SeznamObjednavekOperatoraAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranekProZobrazeni = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }
        return this.maximalniPocetStranekProZobrazeni;
    };
    SeznamObjednavekOperatoraAktualniStrankaComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    };
    return SeznamObjednavekOperatoraAktualniStrankaComponent;
}());
export { SeznamObjednavekOperatoraAktualniStrankaComponent };
