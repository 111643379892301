/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-hlavicka.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./razeni/seznam-objednavek-razeni.component.ngfactory";
import * as i3 from "./razeni/seznam-objednavek-razeni.component";
import * as i4 from "../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni";
import * as i5 from "../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service";
import * as i6 from "./legenda/seznam-objednavek-legenda.component.ngfactory";
import * as i7 from "./legenda/seznam-objednavek-legenda.component";
import * as i8 from "./seznam-objednavek-hlavicka.component";
var styles_SeznamObjednavekHlavickaComponent = [i0.styles];
var RenderType_SeznamObjednavekHlavickaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekHlavickaComponent, data: {} });
export { RenderType_SeznamObjednavekHlavickaComponent as RenderType_SeznamObjednavekHlavickaComponent };
export function View_SeznamObjednavekHlavickaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "detail-card header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-seznam-objednavek-razeni", [], null, null, null, i2.View_SeznamObjednavekRazeniComponent_0, i2.RenderType_SeznamObjednavekRazeniComponent)), i1.ɵdid(2, 49152, null, 0, i3.SeznamObjednavekRazeniComponent, [i4.SeznamObjednavekNastaveniService, i5.SeznamObjednavekService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-seznam-objednavek-legenda", [], null, null, null, i6.View_SeznamObjednavekLegendaComponent_0, i6.RenderType_SeznamObjednavekLegendaComponent)), i1.ɵdid(4, 49152, null, 0, i7.SeznamObjednavekLegendaComponent, [], null, null)], null, null); }
export function View_SeznamObjednavekHlavickaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-hlavicka", [], null, null, null, View_SeznamObjednavekHlavickaComponent_0, RenderType_SeznamObjednavekHlavickaComponent)), i1.ɵdid(1, 49152, null, 0, i8.SeznamObjednavekHlavickaComponent, [], null, null)], null, null); }
var SeznamObjednavekHlavickaComponentNgFactory = i1.ɵccf("app-seznam-objednavek-hlavicka", i8.SeznamObjednavekHlavickaComponent, View_SeznamObjednavekHlavickaComponent_Host_0, {}, {}, []);
export { SeznamObjednavekHlavickaComponentNgFactory as SeznamObjednavekHlavickaComponentNgFactory };
