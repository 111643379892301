import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PresmerovaniNaPODO} from './e-shop/platba/presmerovani-na-podo/presmerovani-na-podo.component';
import {DomuComponent} from './obecne/domu/domu.component';
import {AppAuthGuardService} from './service/bezpecnost/app-auth-guard.service';
import {VytvoritObjednavkuUzivateleComponent} from './e-shop/objednavka/vytvorit/vytvorit-objednavku-uzivatele/vytvorit-objednavku-uzivatele.component';
import {VytvoritObjednavkuOperatoraComponent} from './e-shop/objednavka/vytvorit/vytvorit-objednavku-operatora/vytvorit-objednavku-operatora.component';
import {SekceOperatoraComponent} from './e-shop/sekce-operatora/sekce-operatora.component';
import {OperatorComponent} from './obecne/operator/operator.component';
import {PlatbyAObjednavkyComponent} from './e-shop/platby-a-objednavky/platby-a-objednavky.component';
import {DetailPlatbyComponent} from './e-shop/platba/detail/detail-platby.component';
import {DetailObjednavkyComponent} from './e-shop/objednavka/detail/detail-objednavky.component';
import {SlevoveKuponyComponent} from './e-shop/slevove-kupony/slevove-kupony.component';
import {DetailSlevovehoKuponuComponent} from './e-shop/slevove-kupony/detail/detail-slevoveho-kuponu.component';
import {UpravitSlevovyKuponComponent} from './e-shop/slevove-kupony/upravit/upravit-slevovy-kupon.component';
import {VytvoritSlevovyKuponComponent} from './e-shop/slevove-kupony/vytvorit/vytvorit-slevovy-kupon.component';

const routes: Routes = [
    {
        path: '',
        data: {
            popis: 'domu.popis',
            klicovaSlova: 'domu.klicovaSlova'
        },
        component: DomuComponent
    },
    {
        path: 'e-shop/objednavka-uzivatele',
        data: {
            titulek: 'vytvoritObjednavkuUzivatele.titulek',
        },
        component: VytvoritObjednavkuUzivateleComponent
    },
    {
        path: 'e-shop/objednavka-operatora',
        data: {
            titulek: 'vytvoritObjednavkuOperatora.titulek',
        },
        component: VytvoritObjednavkuOperatoraComponent
    },
    {
        path: 'e-shop/platby-a-objednavky',
        data: {
            titulek: 'platbyAObjednavky.titulek',
        },
        component: PlatbyAObjednavkyComponent
    },
    {
        path: 'e-shop/objednavka/:identifikatorObjednavky',
        data: {
            titulek: 'detailObjednavky.titulek',
        },
        component: DetailObjednavkyComponent
    },
    {
        path: 'e-shop/platba',
        data: {
            titulek: 'detailPlatby.titulek',
        },
        component: DetailPlatbyComponent
    },
    {
        path: 'e-shop/presmerovani-na-podo',
        data: {
            titulek: 'presmerovaniNaPODO.titulek',
        },
        component: PresmerovaniNaPODO
    },
    {
        path: 'operator',
        data: {
            titulek: 'operator.titulek',
        },
        component: OperatorComponent
    },
    {
        path: 'sekce-operatora',
        data: {
            titulek: 'sekceOperatora.titulek',
        },
        component: SekceOperatoraComponent
    },
    {
        path: 'slevove-kupony',
        data: {
            titulek: 'slevoveKupony.titulek'
        },
        component: SlevoveKuponyComponent
    },
    {
        path: 'slevove-kupony/detail/:nazev',
        data: {
            titulek: 'detailSlevovehoKuponu.titulek'
        },
        component: DetailSlevovehoKuponuComponent
    },
    {
        path: 'slevove-kupony/upravit/:nazev',
        data: {
            titulek: 'upravitSlevovyKupon.titulek'
        },
        component: UpravitSlevovyKuponComponent
    },
    {
        path: 'slevove-kupony/vytvorit',
        data: {
            titulek: 'vytvoritSlevovyKupon.titulek'
        },
        component: VytvoritSlevovyKuponComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AppAuthGuardService]
})
export class AppRoutingModule { }
