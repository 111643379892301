import { Notifikace, Uroven } from '../notifikace';
var NastrojeRestu = /** @class */ (function () {
    function NastrojeRestu() {
    }
    NastrojeRestu.zpracovatChybu = function (error) {
        var _this = this;
        if (error.error && error.error.length > 1) {
            error.error.forEach(function (chyba) { return _this.zobrazitChybu(chyba.klic, chyba.parametry); });
        }
        else {
            this.zobrazitChybu(error.error.klic, error.error.parametry);
        }
        return Promise.reject(error.message || error);
    };
    NastrojeRestu.zobrazitChybu = function (klicTextu, parametry) {
        Notifikace.zobrazitLokalizovanouZpravu.apply(Notifikace, [Uroven.CHYBA, klicTextu || this.klicVychoziChyboveZpravy].concat(parametry));
    };
    NastrojeRestu.klicVychoziChyboveZpravy = 'chyba.problemPriZpracovaniPozadavku';
    return NastrojeRestu;
}());
export { NastrojeRestu };
