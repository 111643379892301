import { NastrojeObecne } from './nastroje-obecne';
var NastrojeUrl = /** @class */ (function () {
    function NastrojeUrl() {
    }
    NastrojeUrl.otevritOdkaz = function (url) {
        window.location.href = url;
    };
    NastrojeUrl.otevritOdkazVNoveZalozce = function (url) {
        window.open(url, '_blank');
    };
    NastrojeUrl.prejitZpet = function () {
        window.history.back();
    };
    NastrojeUrl.ziskatParametrRetezceDotazuZUrl = function (route, klicParametru) {
        return route.snapshot.queryParamMap.get(klicParametru);
    };
    NastrojeUrl.pripravitUrl = function (korenoveUrl, cesta, parametry) {
        return NastrojeObecne.formatovatRetezec('{0}{1}{2}', korenoveUrl, this.pripravitCestu(cesta), this.pripravitRetezecUrlParametru(parametry));
    };
    NastrojeUrl.pripravitCestu = function (cesta) {
        return NastrojeObecne.jeHodnotaPrazdna(cesta) ? '' : String(String(cesta).startsWith('/') ? cesta : '/' + cesta);
    };
    NastrojeUrl.pripravitRetezecUrlParametru = function (parametry) {
        var retezec = '';
        if (parametry) {
            for (var _i = 0, parametry_1 = parametry; _i < parametry_1.length; _i++) {
                var polozka = parametry_1[_i];
                var oddelovac = retezec.length === 0 ? '?' : '&';
                retezec += NastrojeObecne.formatovatRetezec('{0}{1}={2}', oddelovac, polozka.klic, polozka.hodnota);
            }
        }
        return retezec;
    };
    return NastrojeUrl;
}());
export { NastrojeUrl };
