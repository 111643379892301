import { Lokalizace } from './lokalizace';
var Notifikace = /** @class */ (function () {
    function Notifikace() {
    }
    Notifikace.zobrazitZpravu = function (uroven, text) {
        M.toast({
            displayLength: Math.max(text.length * this.pocetMilisekundNaZnakTextu, this.minimalniDobaZobrazeniZpravy),
            classes: this.pripravitBarvu(uroven),
            html: "<div>" + text + "</div><i class=\"material-icons\">" + this.pripravitIkonu(uroven) + "</i>"
        });
    };
    Notifikace.zobrazitLokalizovanouZpravu = function (uroven, klicTextu) {
        var parametry = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            parametry[_i - 2] = arguments[_i];
        }
        var text = Lokalizace.formatovatText.apply(Lokalizace, [klicTextu.toString()].concat(parametry));
        this.zobrazitZpravu(uroven, text);
    };
    Notifikace.pripravitBarvu = function (uroven) {
        switch (+uroven) {
            case Uroven.USPECH:
                return 'green';
            case Uroven.INFORMACE:
                return 'blue';
            case Uroven.VAROVANI:
                return 'orange';
            case Uroven.CHYBA:
                return 'red';
            default:
                return '#3276B1';
        }
    };
    Notifikace.pripravitIkonu = function (uroven) {
        switch (+uroven) {
            case Uroven.USPECH:
                return 'check_circle';
            case Uroven.INFORMACE:
                return 'info';
            case Uroven.VAROVANI:
                return 'warning';
            case Uroven.CHYBA:
                return 'cancel';
            default:
                return 'info';
        }
    };
    Notifikace.pocetMilisekundNaZnakTextu = 80;
    Notifikace.minimalniDobaZobrazeniZpravy = 3000;
    return Notifikace;
}());
export { Notifikace };
export var Uroven;
(function (Uroven) {
    Uroven[Uroven["USPECH"] = 0] = "USPECH";
    Uroven[Uroven["INFORMACE"] = 1] = "INFORMACE";
    Uroven[Uroven["VAROVANI"] = 2] = "VAROVANI";
    Uroven[Uroven["CHYBA"] = 3] = "CHYBA";
})(Uroven || (Uroven = {}));
