import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstraktniFiltrComponent} from '../../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import {AutentizaceService} from '../../../../../../service/bezpecnost/autentizace.service';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';

@Component({
    selector: 'app-seznam-objednavek-operatora-filtr-zakladni',
    templateUrl: './seznam-objednavek-operatora-filtr-zakladni.component.html',
    styleUrls: ['./seznam-objednavek-operatora-filtr-zakladni.component.scss']
})
export class SeznamObjednavekOperatoraFiltrZakladniComponent extends AbstraktniFiltrComponent implements OnInit {

    @Input() public formGroup: FormGroup;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        protected seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService,
        protected seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService
    ) {
        super(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormularovaData();
    }

    odeslat(): void {
    }

    private inicializovatFormularovaData() {
        this.formGroup.addControl('nazevOrganizace', new FormControl('', [
            Validators.maxLength(255)
        ]));
        this.formGroup.addControl('ic', new FormControl('', [
            Validators.maxLength(20)
        ]));
        this.formGroup.addControl('email', new FormControl('', [
            Validators.maxLength(100)
        ]));
        this.formGroup.addControl('prihlasovaciJmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('jmeno', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('prijmeni', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('prihlasovaciJmenoOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('jmenoOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));

        this.formGroup.addControl('prijmeniOperatora', new FormControl('', [
            Validators.maxLength(50)
        ]));
    }
}
