<div [formGroup]="nadrazenaSkupinaFormulare">
    <div class="input-field" [class.responsive-input-field]="zmensitRozestupy">
        <input id="{{idPrvku}}" type="{{typ}}" autocomplete="off"
               [formControlName]="nazevOvladaceFormulare"
               [attr.disabled]="zneaktivnit() ? true : null"
               [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
               [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid">
        <label for="{{idPrvku}}" class="asterisk-placeholder" [class.active]="ovladacFormulare.value">{{popisek | lokalizace}}</label>
        <span *ngIf="!skrytPomocnyText" class="helper-text"
              [attr.data-error]="pripravitChybovouZpravu()"
              [attr.data-success]="''">
            {{pomocnyText | lokalizace}}
        </span>
    </div>
</div>
