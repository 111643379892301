import {HostBinding, HostListener, Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TypUceluFormulare} from '../../data/obecne/enum/typ-ucelu-formulare.enum';
import {AbstraktniOpravneniComponent} from '../../service/bezpecnost/abstraktni-opravneni.component';
import {AutentizaceService} from '../../service/bezpecnost/autentizace.service';
import {NastrojeFormulare} from '../nastroje/nastroje-formulare';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';
import {Notifikace, Uroven} from '../notifikace';
import {isBoolean, isNumber} from 'util';

declare var $: any;

@Injectable()
export abstract class AbstraktniFormularComponent extends AbstraktniOpravneniComponent {

    private readonly klicVychoziChyboveHlasky = 'formular.chyba.formularObsahujeChyby';

    protected abstract formBuilder: FormBuilder;
    public odeslan: Boolean = false;
    public formGroup: FormGroup;
    public zobrazitVrtitko: Boolean = false;
    private idKomponentyFormulare = NastrojeObecne.vygenerovatNahodnyRetezec();

    @HostBinding('attr.idkomponentyformulare') get pripravitIdKomponentyFormulare(): any {
        return this.idKomponentyFormulare;
    }

    @HostListener('keypress', ['$event']) onKeyup(event) {
        const kod = event.which || event.keyCode;

        if (kod === 13 && this.jeElementTextovePole(event.target) && this.jeElementPrvkemTohotoFormulare(event.target) && !this.jeElementTextovePoleSAkci(event.target)) {
            this.onSubmit();
        }
    }

    constructor(protected autentizaceService: AutentizaceService) {
        super(autentizaceService);
    }

    public inicializovatFormular(typUceluFormulare: TypUceluFormulare) {
        this.formGroup = this.formBuilder.group([]);
        NastrojeFormulare.nastavitTypUceluFormulare(this.formGroup, typUceluFormulare);
    }

    public onSubmit() {
        this.odeslan = true;

        this.provestTrimNaVsechPrvcich();
        this.zpracovatHodnotyNeaktivnichPrvku();
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {
            this.prevestPrazdneHodnotyNaNedefinovane();
            this.odeslat();
        } else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, this.klicVychoziChyboveHlasky);
        }
    }

    public resetovatFormular() {
        NastrojeFormulare.resetovatFormularovaData(this.formGroup);
        this.odeslan = false;
    }

    public jeTypUceluFormulare(typUceluFormulare: TypUceluFormulare) {
        return NastrojeFormulare.pripravitTypUceluFormulare(this.formGroup) === typUceluFormulare;
    }

    private prevestPrazdneHodnotyNaNedefinovane() {
        Object.keys(this.formGroup.controls).forEach(key => {
            if (this.formGroup.get(key).value === '') {
                NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(this.formGroup.get(key), undefined);
            }
        });
    }

    private zpracovatHodnotyNeaktivnichPrvku() {
        if (this.jeTypUceluFormulare(TypUceluFormulare.VYTVORENI)) {
            Object.keys(this.formGroup.controls).forEach(key => {
                if (this.formGroup.get(key).disabled) {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(this.formGroup.get(key), undefined);
                }
            });
        }
    }

    private provestTrimNaVsechPrvcich() {
        Object.keys(this.formGroup.controls).forEach(key => {
            const prvek = this.formGroup.get(key);

            if (!NastrojeObecne.jeHodnotaPrazdna(prvek.value)) {
                if (this.lzePouzitTrimNaHodnotuPrvku(prvek.value)) {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(prvek, prvek.value.trim());
                } else {
                    NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(prvek, prvek.value);
                }
            }
        });
    }

    private lzePouzitTrimNaHodnotuPrvku(hodnota: any): boolean {
        return !(hodnota instanceof Array || isBoolean(hodnota) || isNumber(hodnota));
    }

    private jeElementPrvkemTohotoFormulare(element: Element): boolean {
        return $(element).closest(`[idkomponentyformulare=${this.idKomponentyFormulare}]`).length;
    }

    private jeElementTextovePole(element: Element): boolean {
        return $(element).is('input');
    }

    private jeElementTextovePoleSAkci(element: Element): boolean {
        return $(element).closest('app-formular-input-s-akci').length > 0;
    }

    abstract odeslat(): void;
}
