import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlevovyKuponService } from '../../../service/e-shop/slevovy-kupon.service';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
var DetailSlevovehoKuponuComponent = /** @class */ (function () {
    function DetailSlevovehoKuponuComponent(route, router, autentizaceService, slevovyKuponService) {
        this.route = route;
        this.router = router;
        this.autentizaceService = autentizaceService;
        this.slevovyKuponService = slevovyKuponService;
        this.zobrazitVrtitko = false;
    }
    DetailSlevovehoKuponuComponent.prototype.ngOnInit = function () {
        this.zpracovatParametry();
    };
    DetailSlevovehoKuponuComponent.prototype.prejitNaStrankuUpravit = function () {
        this.router.navigate(['/slevove-kupony/upravit/' + this.nazevSlevovehoKuponu]);
    };
    DetailSlevovehoKuponuComponent.prototype.prejitZpet = function () {
        this.router.navigate(['/slevove-kupony']);
    };
    DetailSlevovehoKuponuComponent.prototype.zpracovatParametry = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.nazevSlevovehoKuponu = params['nazev'];
            _this.pripravitSlevovyKupon();
        });
    };
    DetailSlevovehoKuponuComponent.prototype.pripravitSlevovyKupon = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.slevovyKuponService.nacistSlevovyKupon(this.nazevSlevovehoKuponu)
            .toPromise()
            .then(function (odpoved) {
            _this.slevovyKupon = odpoved;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return DetailSlevovehoKuponuComponent;
}());
export { DetailSlevovehoKuponuComponent };
