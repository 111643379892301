import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {FakturacniUdaje} from '../../data/e-shop/fakturacni-udaje';
import {Observable} from 'rxjs';

@Injectable()
export class FakturacniUdajeService extends AbstraktniService {

    protected resourceClassUri = '/fakturacni-udaje';

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService
    ) {
        super(http, autentizaceService);
    }

    public nacistFakturacniUdaje(idUzivatele: number): Observable<FakturacniUdaje> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/uzivatel/' + idUzivatele)
            .catch(this.zpracovatChybu);
    }
}
