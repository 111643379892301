export enum Mena {
    CZK = 'CZK',
    EUR = 'EUR',
    HUF = 'HUF',
    DKK = 'DKK',
    SEK = 'SEK',
    HRK = 'HRK',
    BGN = 'BGN',
    GBP = 'GBP',
    PLN = 'PLN',
    RON = 'RON',
    LTL = 'LTL',
    CAD = 'CAD',
    USD = 'USD',
    RSD = 'RSD',
    CHF = 'CHF'
}
