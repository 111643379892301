import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Produkt} from '../../../../../../data/e-shop/produkt';
import {AbstraktniFormularComponent} from '../../../../../../shared/formular/abstraktni-formular.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AutentizaceService} from '../../../../../../service/bezpecnost/autentizace.service';
import {TypUceluFormulare} from '../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {TypPredplatneho} from '../../../../../../data/e-shop/enum/predplatne.enum';
import {NastrojeModalnihoPanelu} from '../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import {Lokalizace} from '../../../../../../shared/lokalizace';
import {NastrojeEnumu} from '../../../../../../shared/nastroje/nastroje-enumu';
import {Polozka} from '../../../../../../data/obecne/polozka';
import {InformaceOVybranemTarifu} from '../../../../../../data/e-shop/informace-o-vybranem-tarifu';
import {NastrojeObecne} from '../../../../../../shared/nastroje/nastroje-obecne';
import {NastrojeCisla} from '../../../../../../shared/nastroje/nastroje-cisla';
import {NastrojeFormulare} from '../../../../../../shared/nastroje/nastroje-formulare';

@Component({
	selector: 'app-zvolit-produkt-modalni-panel',
	templateUrl: './zvolit-produkt-modalni-panel.component.html',
	styleUrls: ['./zvolit-produkt-modalni-panel.component.scss']
})
export class ZvolitProduktModalniPanelComponent extends AbstraktniFormularComponent implements OnInit {

	@ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

	@Input() produkty: Produkt[];
	@Output() pridatVybranyTarif = new EventEmitter<InformaceOVybranemTarifu>();

	public dostupnePredplatne: Polozka[] = [];

	private automatickyVybranePredplatne: TypPredplatneho;
	private vybranyProdukt: Produkt;
	private jeViceroDostupnychPredplatnych: boolean;

	constructor(
		protected formBuilder: FormBuilder,
		protected autentizaceService: AutentizaceService
	) {
		super(autentizaceService);
	}

	ngOnInit() {
		this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
		this.inicializovatModalniPanel();
		this.inicializovatFormularovaData();
	}

	odeslat() {
		if (this.pripravitPredplatnePridanehoProduktu() !== TypPredplatneho.NA_MIRU) {
			NastrojeFormulare.resetovatPrvekBezVyvolaniUdalosti(this.formGroup.controls.delkaPlatnostiPredplatneho);
			this.formGroup.controls.delkaPlatnostiPredplatneho.markAsUntouched();
			this.odeslan = false;
		}

		this.pridatVybranyTarif.emit(this.pripravitDataZModalu());
		this.zavritModalniPanel();
	}

	public povolenoZobrazeniVlastniDelkyPredplatneho(): boolean {
		return !NastrojeObecne.jeHodnotaPrazdna(this.vybranyProdukt) && this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.NA_MIRU);
	}

	private pripravitDataZModalu(): InformaceOVybranemTarifu {
		const vybranePredplatne = this.pripravitPredplatnePridanehoProduktu();

		if (vybranePredplatne === TypPredplatneho.NA_MIRU) {
			return new InformaceOVybranemTarifu(this.vybranyProdukt, this.pripravitPredplatnePridanehoProduktu(), this.formGroup.controls.delkaPlatnostiPredplatneho.value);
		} else {
			return new InformaceOVybranemTarifu(this.vybranyProdukt, this.pripravitPredplatnePridanehoProduktu());
		}
	}

	private pripravitPredplatnePridanehoProduktu(): TypPredplatneho {
		if (this.jeViceroDostupnychPredplatnych && !this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.ROCNI)) {
			return this.formGroup.controls.predplatne.value;
		} else {
			return this.automatickyVybranePredplatne;
		}
	}

	private inicializovatFormularovaData() {
		this.formGroup.addControl('produkt', new FormControl('', [Validators.required]));

		this.formGroup.addControl('delkaPlatnostiPredplatneho', new FormControl('', [
			Validators.required,
			Validators.min(1),
			Validators.max(100),
			Validators.pattern(NastrojeCisla.VZOR_INTEGER)
		]));

		this.formGroup.addControl('predplatne', new FormControl({
			value: '',
			disabled: true
		}, [Validators.required]));

		this.inicializovatPosluchaceZmenHodnot();
	}

	private inicializovatPosluchaceZmenHodnot() {
		this.formGroup.controls.produkt.valueChanges.subscribe(nazevVybranehoProduktu => {
			this.aktualizovatFormularPodleVybranehoProduktu(nazevVybranehoProduktu);
		});
	}

	private aktualizovatFormularPodleVybranehoProduktu(nazevVybranehoProduktu: string): void {
		this.vybranyProdukt = this.produkty.find(produkt => produkt.nazev === nazevVybranehoProduktu);
		this.jeViceroDostupnychPredplatnych = this.vybranyProdukt.dostupnePredplatne.length > 1;

		if (this.jeViceroDostupnychPredplatnych) {
			if (this.vybranyProdukt.dostupnePredplatne.includes(TypPredplatneho.ROCNI)) {
				this.automatickyVybranePredplatne = TypPredplatneho.ROCNI;
			} else {
				this.pripravitFormularovaDataProPredplatneProduktu();
			}
		} else {
			this.automatickyVybranePredplatne = this.vybranyProdukt.dostupnePredplatne[0];
		}

		if (this.vybranyProdukt.dostupnePredplatne[0] !== TypPredplatneho.NA_MIRU) {
			NastrojeFormulare.resetovatPrvekBezVyvolaniUdalosti(this.formGroup.controls.delkaPlatnostiPredplatneho);

			this.formGroup.controls.delkaPlatnostiPredplatneho.markAsUntouched();
			this.formGroup.controls.delkaPlatnostiPredplatneho.markAsDirty();
			this.formGroup.controls.delkaPlatnostiPredplatneho.setErrors(null);
		}
	}

	private pripravitFormularovaDataProPredplatneProduktu() {
		this.dostupnePredplatne = [];

		this.formGroup.controls.predplatne.reset();
		this.vybranyProdukt.dostupnePredplatne.forEach(typ => this.dostupnePredplatne.push(new Polozka(typ, Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech('Predplatne', typ)))));
		this.formGroup.controls.predplatne.enable();
	}

	private inicializovatModalniPanel() {
		NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
	}

	private zavritModalniPanel() {
		NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
	}
}
