import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {prostredi} from '../../../../prostredi/prostredi';
import {AutentizaceService} from '../../bezpecnost/autentizace.service';
import {AbstraktniSeznamService} from '../abstraktni-seznam-service';
import {SeznamObjednavekOperatoraNastaveniService} from './seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatora} from '../../../data/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora';
import {TypPozadavku} from '../../obecne/abstraktni-service';
import {NastrojeJsonu} from '../../../shared/nastroje/nastroje-jsonu';

@Injectable()
export class SeznamObjednavekOperatoraService extends AbstraktniSeznamService {

    protected resourceClassUri = '';

    public seznam: SeznamObjednavekOperatora;

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService,
        private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService
    ) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopSeznamObjednavek;
    }

    public aktualizovat(odrolovatNaZacatekSeznamu: boolean = true): void {
        this.seznam = new SeznamObjednavekOperatora();

        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-objednavek-operatora/', NastrojeJsonu.stringifyISO8601(this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(odpoved => {
                this.seznam = odpoved;
            }).catch(this.zpracovatChybu);

        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    }
}
