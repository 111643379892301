import {Component} from '@angular/core';
import {AbstraktniVystupFormularovyPrvekComponent} from '../abstraktni-vystup-formularovy-prvek.component';

/**
 * Pouziti:
 *  <app-formular-output
 *  [popisek]="'Popisek'"
 *  [hodnota]="hodnota"
 * </app-formular-output>
 */
@Component({
    selector: 'app-formular-output',
    templateUrl: './formular-output.component.html',
    styleUrls: ['./formular-output.component.scss']
})
export class FormularOutputComponent extends AbstraktniVystupFormularovyPrvekComponent {

    constructor() {
        super();
    }
}
