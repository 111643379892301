import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {AbstraktniFormularModule} from '../shared/formular/abstraktni-formular-module';
import {NastrojeModule} from '../shared/nastroje/nastroje.module';
import {AdresaPipe} from '../shared/nastroje/pipes/adresa.pipe';
import {SeznamModule} from '../shared/seznam/seznam.module';
import {TextovyModalniPanelModule} from '../shared/modalni-panel/textovy-modalni-panel/textovy-modalni-panel.module';
import {SeznamUzivateluSDodavatelemService} from '../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {SeznamUzivateluSDodavatelemObsahComponent} from './uzivatel/seznam-s-dodavatelem/obsah/seznam-uzivatelu-s-dodavatelem-obsah.component';
import {SeznamUzivateluSDodavatelemSeznamComponent} from './uzivatel/seznam-s-dodavatelem/obsah/seznam/seznam-uzivatelu-s-dodavatelem-seznam.component';
import {SeznamUzivateluSDodavatelemHlavickaComponent} from './uzivatel/seznam-s-dodavatelem/obsah/hlavicka/seznam-uzivatelu-s-dodavatelem-hlavicka.component';
import {SeznamUzivateluSDodavatelemRazeniComponent} from './uzivatel/seznam-s-dodavatelem/obsah/hlavicka/razeni/seznam-uzivatelu-s-dodavatelem-razeni.component';
import {SeznamUzivateluSDodavatelemLegendaComponent} from './uzivatel/seznam-s-dodavatelem/obsah/hlavicka/legenda/seznam-uzivatelu-s-dodavatelem-legenda.component';
import {VrtitkoModule} from '../shared/layout/vrtitko/vrtitko.module';
import {RouterModule} from '@angular/router';
import {SeznamUzivateluSDodavatelemFiltrComponent} from './uzivatel/seznam-s-dodavatelem/obsah/filtr/seznam-uzivatelu-s-dodavatelem-filtr.component';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemPatickaComponent} from './uzivatel/seznam-s-dodavatelem/obsah/paticka/seznam-uzivatelu-s-dodavatelem-paticka.component';
import {SeznamUzivateluSDodavatelemAktualniStrankaComponent} from './uzivatel/seznam-s-dodavatelem/obsah/paticka/aktualni-stranka/seznam-uzivatelu-s-dodavatelem-aktualni-stranka.component';
import {SeznamUzivateluSDodavatelemStrankovaniComponent} from './uzivatel/seznam-s-dodavatelem/obsah/paticka/strankovani/seznam-uzivatelu-s-dodavatelem-strankovani.component';
import {SeznamUzivateluSDodavatelemNastaveniComponent} from './uzivatel/seznam-s-dodavatelem/obsah/paticka/nastaveni/seznam-uzivatelu-s-dodavatelem-nastaveni.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AbstraktniFormularModule,
        NastrojeModule,
        SeznamModule,
        TextovyModalniPanelModule,
        VrtitkoModule,
        RouterModule
    ],
    declarations: [
        SeznamUzivateluSDodavatelemObsahComponent,
        SeznamUzivateluSDodavatelemSeznamComponent,
        SeznamUzivateluSDodavatelemHlavickaComponent,
        SeznamUzivateluSDodavatelemRazeniComponent,
        SeznamUzivateluSDodavatelemLegendaComponent,
        SeznamUzivateluSDodavatelemFiltrComponent,
        SeznamUzivateluSDodavatelemPatickaComponent,
        SeznamUzivateluSDodavatelemAktualniStrankaComponent,
        SeznamUzivateluSDodavatelemStrankovaniComponent,
        SeznamUzivateluSDodavatelemNastaveniComponent
    ],
    providers: [
        AdresaPipe,
        SeznamUzivateluSDodavatelemNastaveniService,
        SeznamUzivateluSDodavatelemService
    ],
    exports: [
        SeznamUzivateluSDodavatelemFiltrComponent,
        SeznamUzivateluSDodavatelemHlavickaComponent,
        SeznamUzivateluSDodavatelemSeznamComponent,
        SeznamUzivateluSDodavatelemPatickaComponent,
        SeznamUzivateluSDodavatelemAktualniStrankaComponent,
        SeznamUzivateluSDodavatelemStrankovaniComponent,
        SeznamUzivateluSDodavatelemNastaveniComponent,
        SeznamUzivateluSDodavatelemObsahComponent,
        SeznamUzivateluSDodavatelemSeznamComponent,
        SeznamUzivateluSDodavatelemHlavickaComponent,
        SeznamUzivateluSDodavatelemRazeniComponent,
        SeznamUzivateluSDodavatelemLegendaComponent
    ]
})
export class OrganizacniStrukturaModule {
}
