import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {prostredi} from '../../../../prostredi/prostredi';
import {StavPlatby} from '../../../data/e-shop/enum/stav-platby.enum';
import {Objednavka} from '../../../data/e-shop/objednavka';
import {Platba} from '../../../data/e-shop/platba';
import {ObjednavkaService} from '../../../service/e-shop/objednavka.service';
import {OnlinePlatbyService} from '../../../service/e-shop/online-platby.service';
import {NastrojeObecne} from '../../../shared/nastroje/nastroje-obecne';
import {NastrojeUrl} from '../../../shared/nastroje/nastroje-url';

@Component({
    selector: 'app-presmerovani-na-podo',
    templateUrl: './presmerovani-na-podo.component.html',
    styleUrls: ['./presmerovani-na-podo.component.scss']
})
export class PresmerovaniNaPODO implements OnInit {

    public objednavka: Objednavka;
    public platba: Platba;
    public zobrazitVrtitko = false;

    private idPlatby: number;

    constructor(
        private objednavkaService: ObjednavkaService,
        private onlinePlatbyService: OnlinePlatbyService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.zpracovatParametrRetezceDotazuZUrl();
        this.pripravitStavPlatby();
    }

    private zpracovatParametrRetezceDotazuZUrl(): void {
        const parametrIdPlatby = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'id');

        if (!NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby)) {
            this.idPlatby = Number(parametrIdPlatby);
        } else {
            this.router.navigate([`/`]);
        }
    }

    private pripravitStavPlatby(): void {
        this.zobrazitVrtitko = true;

        this.onlinePlatbyService.nacistStavPlatby(this.idPlatby)
            .toPromise()
            .then((odpoved) => {
                this.platba = odpoved;
                this.pripravitObjednavku();
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private pripravitObjednavku(): void {
        this.objednavkaService.nacistObjednavku(this.platba.identifikatorObjednavky)
            .toPromise()
            .then((odpoved) => {
                this.objednavka = odpoved;
                this.presmerovatNaPODO();
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private presmerovatNaPODO() {
        NastrojeUrl.otevritOdkaz(this.platba.stav === StavPlatby.ZAPLACENA ? prostredi.urlPODO + '/payment/success/' + this.objednavka.identifikatorProduktu : prostredi.urlPODO + '/payment/failure');
    }
}
