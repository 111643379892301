import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NastrojeOptimalizaceProVyhledavace } from '../../shared/nastroje/nastroje-optimalizace-pro-vyhledavace';
import { prostredi } from '../../../prostredi/prostredi';
var OptimalizaceProVyhledavaceService = /** @class */ (function () {
    function OptimalizaceProVyhledavaceService(activatedRoute, router, title, metadata) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.title = title;
        this.metadata = metadata;
    }
    OptimalizaceProVyhledavaceService.prototype.optimalizovatStrankuProVyhledavace = function () {
        this.pridatStatickeInformaceDoHlavickyStranky();
        this.pridatDynamickeInformaceDoHlavickyStranky();
    };
    OptimalizaceProVyhledavaceService.prototype.pridatTitulek = function (titulek) {
        NastrojeOptimalizaceProVyhledavace.pridatTitulek(titulek, this.title);
    };
    OptimalizaceProVyhledavaceService.prototype.pridatStatickeInformaceDoHlavickyStranky = function () {
        NastrojeOptimalizaceProVyhledavace.pridatAutora(this.metadata);
        NastrojeOptimalizaceProVyhledavace.pridatPovoleniProIndexaciRoboty(this.metadata);
    };
    OptimalizaceProVyhledavaceService.prototype.pridatDynamickeInformaceDoHlavickyStranky = function () {
        var _this = this;
        this.router.events.subscribe(function (udalost) {
            if (udalost instanceof NavigationEnd) {
                var prvek = _this.activatedRoute.firstChild;
                while (prvek) {
                    if (prvek.firstChild) {
                        prvek = prvek.firstChild;
                    }
                    else {
                        _this.zpracovatData(prvek);
                        _this.zaznamenatZobrazeniStranky(udalost.urlAfterRedirects);
                        return;
                    }
                }
            }
        });
    };
    OptimalizaceProVyhledavaceService.prototype.zpracovatData = function (prvek) {
        if (prvek.snapshot.data) {
            this.pridatTitulek(prvek.snapshot.data['titulek']);
            NastrojeOptimalizaceProVyhledavace.pridatPopis(prvek.snapshot.data['popis'], this.metadata);
            NastrojeOptimalizaceProVyhledavace.pridatKlicovaSlova(prvek.snapshot.data['klicovaSlova'], this.metadata);
        }
    };
    OptimalizaceProVyhledavaceService.prototype.zaznamenatZobrazeniStranky = function (url) {
        if (prostredi.verze === 'Produkční') {
            window.ga('set', 'page', url);
            window.ga('send', 'pageview');
        }
    };
    return OptimalizaceProVyhledavaceService;
}());
export { OptimalizaceProVyhledavaceService };
