export class NastrojeRetezce {

    public static prevestUpperSnakeCaseNaLowerCamelCase(retezec: string): string {
        return retezec.toLowerCase().replace(/_(\w)/g, (m => m[1].toUpperCase()));
    }

    public static prevestUpperCamelCaseNaLowerCamelCase(retezec: string): string {
        return retezec.charAt(0).toLowerCase() + retezec.slice(1);
    }

    public static odstranitDiakritiku(retezec: string) {
        return retezec.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    public static odebratMezeryZRetezce(retezec: string): string {
        return retezec.replace(/\s/g, '');
    }
}
