import { NastrojeObecne } from './nastroje-obecne';
var NastrojeCisla = /** @class */ (function () {
    function NastrojeCisla() {
    }
    NastrojeCisla.prevestNaCislo = function (vstup) {
        if (vstup) {
            var hodnota = vstup.toString().replace(new RegExp('\\s', 'g'), '').replace(',', '.');
            return Number(hodnota);
        }
        return vstup;
    };
    NastrojeCisla.formatovatCislo = function (hodnota, zaokrouhleni) {
        if (zaokrouhleni === void 0) { zaokrouhleni = this.VYCHOZI_ZAOKROUHLENI; }
        var formatCisla = require('format-number');
        var format = formatCisla({ prefix: '', suffix: '', integerSeparator: ' ', decimal: ',', round: zaokrouhleni });
        return format(hodnota, { noSeparator: false });
    };
    NastrojeCisla.formatovatCisloSeZarovnanimZprava = function (hodnota, zaokrouhleni) {
        if (zaokrouhleni === void 0) { zaokrouhleni = this.VYCHOZI_ZAOKROUHLENI; }
        var formatCisla = require('format-number');
        var format = formatCisla({
            prefix: '',
            suffix: '',
            integerSeparator: ' ',
            decimal: ',',
            round: zaokrouhleni,
            padRight: zaokrouhleni
        });
        return format(hodnota, { noSeparator: false });
    };
    NastrojeCisla.formatovatCisloSOdebranimNadbytecnychNul = function (hodnota, zaokrouhleni) {
        if (zaokrouhleni === void 0) { zaokrouhleni = this.VYCHOZI_ZAOKROUHLENI; }
        var formatovaneCislo = this.formatovatCislo(hodnota, zaokrouhleni);
        return formatovaneCislo.indexOf(',') !== -1 ? formatovaneCislo.replace(/0+$/, '') : formatovaneCislo;
    };
    NastrojeCisla.nasobitCisla = function (cislo1, cislo2) {
        return cislo1 * cislo2;
    };
    NastrojeCisla.delitCisla = function (cislo1, cislo2) {
        return cislo2 === 0 ? 0 : cislo1 / cislo2;
    };
    NastrojeCisla.secistCisla = function (cislo1, cislo2) {
        return cislo1 ? cislo1 + cislo2 : cislo2;
    };
    NastrojeCisla.jeCislo = function (hodnota) {
        return !NastrojeObecne.jeHodnotaPrazdna(hodnota) && !isNaN(Number(hodnota));
    };
    NastrojeCisla.VYCHOZI_ZAOKROUHLENI = 0;
    NastrojeCisla.VZOR_INTEGER = '^([1-9])(\\d|\\s)*$';
    NastrojeCisla.VZOR_DECIMAL = '^(?=.*[1-9])(\\s|\\d)*(\,(\\s|\\d)*)?$';
    NastrojeCisla.VZOR_DECIMAL_DOT_AND_COMMA = '^(?=.*[1-9])(\\s|\\d)*(?:[\,\.](\\s|\\d)*)?$';
    return NastrojeCisla;
}());
export { NastrojeCisla };
