import {Component, OnInit} from '@angular/core';
import {AbstraktniTypZobrazeniHlavnihoMenuComponent} from '../abstraktni-typ-zobrazeni-hlavniho-menu.component';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';

declare var $: any;

@Component({
    selector: 'app-standardni-zobrazeni-hlavniho-menu',
    templateUrl: './standardni-zobrazeni-hlavniho-menu.component.html',
    styleUrls: ['./standardni-zobrazeni-hlavniho-menu.component.scss']
})
export class StandardniZobrazeniHlavnihoMenuComponent extends AbstraktniTypZobrazeniHlavnihoMenuComponent implements OnInit {

    constructor(protected autentizaceService: AutentizaceService) {
        super(autentizaceService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.inicializovatRozbalovaciMenu();
    }

    public inicializovatRozbalovaciMenu(): void {
        const neinicializovanaRozbalovaciMenu = $('.dropdown-trigger:not([initialized])');
        neinicializovanaRozbalovaciMenu.dropdown();
        neinicializovanaRozbalovaciMenu.attr('initialized', true);
    }
}
