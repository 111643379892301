import {Component, OnInit} from '@angular/core';
import {prostredi} from '../../../prostredi/prostredi';

@Component({
    selector: 'app-domu',
    templateUrl: './domu.component.html',
    styleUrls: ['./domu.component.scss']
})
export class DomuComponent implements OnInit {

    public zakladniUrl = prostredi.zakladniUrl;

    constructor() {
    }

    ngOnInit() {
    }
}
