import { LokalniUlozisteNastaveni } from './lokalni-uloziste-nastaveni';
import { NastrojeObecne } from '../nastroje-obecne';
import { NastrojeAutentizace } from '../nastroje-autentizace';
var LokalniUlozisteNastroje = /** @class */ (function () {
    function LokalniUlozisteNastroje() {
    }
    LokalniUlozisteNastroje.getPolozka = function (klic) {
        return localStorage.getItem(this.pripravitKlicPolozky(klic));
    };
    LokalniUlozisteNastroje.setPolozka = function (klic, hodnota) {
        localStorage.setItem(this.pripravitKlicPolozky(klic), hodnota);
    };
    LokalniUlozisteNastroje.odstranitPolozku = function (klic) {
        localStorage.removeItem(this.pripravitKlicPolozky(klic));
    };
    LokalniUlozisteNastroje.setDocasnaPolozka = function (klic, hodnota) {
        localStorage.setItem(this.pripravitKlicDocasnePolozky(klic), hodnota);
    };
    LokalniUlozisteNastroje.getDocasnaPolozka = function (klic) {
        return localStorage.getItem(this.pripravitKlicDocasnePolozky(klic));
    };
    LokalniUlozisteNastroje.odstranitDocasnePolozky = function () {
        var klice = this.pripravitKliceDocasnychPolozek();
        for (var i = 0; i < klice.length; i++) {
            localStorage.removeItem(klice[i]);
        }
    };
    LokalniUlozisteNastroje.pripravitKlicePolozek = function () {
        var klice = [];
        for (var i = 0; i < localStorage.length; ++i) {
            var klic = localStorage.key(i);
            if (!NastrojeObecne.jeHodnotaPrazdna(klic)) {
                klice.push(klic.replace(this.pripravitPrefixKlicePolozky(), ''));
            }
        }
        return klice;
    };
    LokalniUlozisteNastroje.pripravitKlicPolozky = function (klic) {
        return this.pripravitPrefixKlicePolozky() + klic;
    };
    LokalniUlozisteNastroje.pripravitPrefixKlicePolozky = function () {
        var separator = LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE;
        var uzivatel = NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage();
        var idUzivatele = NastrojeObecne.jeHodnotaPrazdna(uzivatel) ? this.vychoziIdentifikatorUzivatele : uzivatel.informaceOUzivateli.id;
        return LokalniUlozisteNastaveni.NAZEV_AKTUALNI_VERZE_PROSTREDI + separator + idUzivatele + separator;
    };
    LokalniUlozisteNastroje.pripravitKlicDocasnePolozky = function (klic) {
        return LokalniUlozisteNastaveni.PREFIX_NAZVU_DOCASNEHO_KLICE + LokalniUlozisteNastaveni.ODDELOVAC_HODNOT_NAZVU_KLICE + klic;
    };
    LokalniUlozisteNastroje.pripravitKliceDocasnychPolozek = function () {
        var klice = [];
        for (var i = 0; i < localStorage.length; i++) {
            var klic = localStorage.key(i);
            if (!NastrojeObecne.jeHodnotaPrazdna(klic) && this.jeDocasnaPolozkaUloziste(klic)) {
                klice.push(klic);
            }
        }
        return klice;
    };
    LokalniUlozisteNastroje.jeDocasnaPolozkaUloziste = function (klic) {
        for (var i = 0; i < LokalniUlozisteNastaveni.NAZVY_VSECH_VERZI_PROSTREDI.length; i++) {
            if (klic.startsWith(LokalniUlozisteNastaveni.NAZVY_VSECH_VERZI_PROSTREDI[i])) {
                return false;
            }
        }
        return true;
    };
    LokalniUlozisteNastroje.vychoziIdentifikatorUzivatele = 'neprihlaseny';
    return LokalniUlozisteNastroje;
}());
export { LokalniUlozisteNastroje };
