<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<div class="frame">
    <div class="recapitulation-layout">
        <div class="recapitulation-item-1">
            <article>
                <div class="chapter-text">
                    <h3 class="info-title">{{ 'objednavka.rekapitulace.dodatecneInfo.nadpis' | lokalizace }}</h3>
                    <p>{{ 'objednavka.rekapitulace.dodatecneInfo.text.postup' | lokalizace }}</p>
                    <p>{{ pripravitTextTarifuNaMiru() }}</p>
                </div>

                <div>
                    <h3 class="info-title">{{ 'objednavka.poznamka.nadpis' | lokalizace }}</h3>
                    <app-formular-textove-pole class="span-12"
                                               [popisek]="'objednavka.poznamka.text'"
                                               [odeslan]="odeslan"
                                               [ovladacFormulare]="formGroup.controls.poznamka"></app-formular-textove-pole>
                </div>

                <div>
                    <h3 class="info-title">{{ 'objednavka.poznamkaNaFakturu.nadpis' | lokalizace }}</h3>
                    <app-formular-textove-pole class="span-12"
                                               [popisek]="'objednavka.poznamkaNaFakturu.text'"
                                               [odeslan]="odeslan"
                                               [ovladacFormulare]="formGroup.controls.poznamkaNaFakturu"></app-formular-textove-pole>
                </div>
            </article>
        </div>

        <div class="recapitulation-item-2">
            <h3 class="info-title summary-title">{{ 'objednavka.souhrn' | lokalizace }}</h3>

            <div class="summary-container">
                <div>
                    <p class="summary-subtitle">{{ 'objednavka.produkt' | lokalizace }}</p>
                    <div class="summary-content-container summary-content-container__product">
                        <p class="summary-product-name">1x {{ objednavka.nazevProduktu }}</p>
                        <p class="summary-product-subscription">{{ objednavka.typPredplatneho | vyctovyTyp: 'Predplatne' }}</p>
                        <p class="summary-product-price">
                            <span *ngIf="jeNeplatceDph()">{{ objednavka.cena?.cenaBezDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</span>
                            <span *ngIf="!jeNeplatceDph()">{{ objednavka.cena?.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</span>
                        </p>
                    </div>
                </div>
                <div>
                    <p class="summary-subtitle">{{ 'objednavka.cena.celkova' | lokalizace }}</p>
                    <div class="summary-content-container summary-content-container__price">
                        <div class="summary-price-row summary-price-row__first">
                            <p>{{ 'objednavka.cena.bezDPH' | lokalizace }}</p>
                            <p>{{ objednavka.cena?.cenaBezDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</p>
                        </div>
                        <div class="summary-price-row">
                            <p>{{ 'objednavka.cena.kUhrade' | lokalizace }}</p>
                            <p *ngIf="jeNeplatceDph()" class="summary-price-final">{{ objednavka.cena?.cenaBezDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</p>
                            <p *ngIf="!jeNeplatceDph()" class="summary-price-final">{{ objednavka.cena?.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</p>
                        </div>
                    </div>

                    <div *ngIf="jeNeplatceDph()">
                        <br/>
                        <p>
                            <b>{{ 'objednavka.fakturacniUdaje.fakturaceZahranicnihoDodavateleVEU' | lokalizace }}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container__content-footer">
            <button type="button" (click)="presmerovatNaSekciOperatora()" class="btn btn-secondary">
                <b>{{ 'tlacitko.zrusit' | lokalizace }}</b>
                <i class="material-icons right">clear</i>
            </button>
            <button type="button" (click)="predchoziKarta()" class="btn btn-secondary">
                <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                <i class="material-icons right">arrow_back</i>
            </button>
            <button type="submit" (click)="onSubmit()" class="btn btn-primary">
                {{ 'tlacitko.vytvoritObjednavku' | lokalizace }}
            </button>
        </div>
    </div>
</div>

<app-textovy-modalni-panel (souhlas)="presmerovatNaSekciOperatora()"
                           [zobrazit]="zobrazitPotvrzeni"
                           [idPrvku]="'potvrzeni-modalni-panel'"
                           [nadpis]="'objednavka.potvrzeni.nadpis'"
                           [text]="textPotvrzovaciHlaskyVytvoreneObjednavky"></app-textovy-modalni-panel>
