import { NastrojeFormulare } from './nastroje-formulare';
import { NastrojeObecne } from './nastroje-obecne';
var NastrojeTabulkyProduktu = /** @class */ (function () {
    function NastrojeTabulkyProduktu() {
    }
    NastrojeTabulkyProduktu.aktualizovatVybraneProduktyVeFormulari = function (formGroup, vybraneProdukty) {
        Object.keys(formGroup.controls).forEach(function (klic) { return NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(formGroup.controls[klic], vybraneProdukty.includes(klic)); });
    };
    NastrojeTabulkyProduktu.hromadneNastavitStavVsemProduktum = function (formGroup, stav) {
        Object.keys(formGroup.controls).forEach(function (klic) { return NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(formGroup.controls[klic], stav); });
    };
    NastrojeTabulkyProduktu.pripravitKolekciVybranychProduktu = function (formGroup) {
        var vybraneProdukty = [];
        Object.keys(formGroup.controls).forEach(function (klic) {
            if (!NastrojeObecne.jeHodnotaPrazdna(formGroup.controls[klic].value) && formGroup.controls[klic].value) {
                vybraneProdukty.push(klic);
            }
        });
        return vybraneProdukty;
    };
    return NastrojeTabulkyProduktu;
}());
export { NastrojeTabulkyProduktu };
