import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeAdresy} from '../nastroje-adresy';

@Pipe({
    name: 'adresaPipe'
})
export class AdresaPipe implements PipeTransform {

    transform (hodnota: any, zobrazitTypAdresy?: boolean): any {
        if (hodnota != null) {
            return NastrojeAdresy.prevestNaRetezec(hodnota, zobrazitTypAdresy);
        }

        return '';
    }
}
