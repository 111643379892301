<div [formGroup]="nadrazenaSkupinaFormulare">
    <div class="input-field" [class.responsive-input-field]="zmensitRozestupy">
        <div class="input-filed__wrap">
            <input id="{{idPrvku}}" type="{{typ}}" autocomplete="off"
                   [formControlName]="nazevOvladaceFormulare"
                   [attr.disabled]="zneaktivnit() ? true : null"
                   [class.valid]="zobrazitStavPrvkuFormulare() && !ovladacFormulare.invalid"
                   [class.invalid]="zobrazitStavPrvkuFormulare() && ovladacFormulare.invalid">
            <button *ngIf="nazevSekundarnihoTlacitka || ikonkaSekundarnihoTlacitka" class="btn btn-secondary" [disabled]="!ovladacFormulare.value" (click)="vyvolatSekundarniAkci()">
                <i *ngIf="ikonkaSekundarnihoTlacitka" [class.left]="ikonkaSekundarnihoTlacitka && nazevSekundarnihoTlacitka" class="material-icons">{{ ikonkaSekundarnihoTlacitka }}</i>
                <b *ngIf="nazevSekundarnihoTlacitka">{{ nazevSekundarnihoTlacitka | lokalizace }}</b>
            </button>
            <button class="btn btn-primary" [disabled]="!ovladacFormulare.value" (click)="vyvolatPrimarniAkci()">
                <i *ngIf="ikonkaPrimarnihoTlacitka" [class.left]="ikonkaPrimarnihoTlacitka && nazevPrimarnihoTlacitka" class="material-icons">{{ ikonkaPrimarnihoTlacitka }}</i>
                <b *ngIf="nazevPrimarnihoTlacitka">{{ nazevPrimarnihoTlacitka | lokalizace }}</b>
            </button>
        </div>
        <label for="{{idPrvku}}" class="asterisk-placeholder" [class.active]="ovladacFormulare.value">{{popisek | lokalizace}}</label>
        <span *ngIf="!skrytPomocnyText" class="helper-text"
              [attr.data-error]="pripravitChybovouZpravu()"
              [attr.data-success]="''">
            {{pomocnyText | lokalizace}}
        </span>
    </div>
</div>
