import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import { NastrojeStrankovani } from '../../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamObjednavekOperatoraStrankovaniComponent = /** @class */ (function () {
    function SeznamObjednavekOperatoraStrankovaniComponent(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
    }
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.predchoziStranka = function () {
        var hodnota = this.pripravitHodnotu();
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamObjednavekOperatoraService.aktualizovat();
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.dalsiStranka = function () {
        var hodnota = this.pripravitHodnotu();
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamObjednavekOperatoraService.aktualizovat();
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.jePrvniStranka = function () {
        return this.pripravitHodnotu() === 1;
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.jePosledniStranka = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.pripravitCelkovyPocetZaznamu = function () {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekOperatoraStrankovaniComponent.prototype.pripravitHodnotu = function () {
        return this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    return SeznamObjednavekOperatoraStrankovaniComponent;
}());
export { SeznamObjednavekOperatoraStrankovaniComponent };
