var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TypPoctuZaznamuNaStranku } from '../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import { TypUceluFormulare } from '../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../../../../service/bezpecnost/autentizace.service';
import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { AbstraktniFormularComponent } from '../../../../../../shared/formular/abstraktni-formular.component';
import { NastrojeEnumu } from '../../../../../../shared/nastroje/nastroje-enumu';
import { NastrojeModalnihoPanelu } from '../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { NastrojeStrankovani } from '../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamUzivateluSDodavatelemNastaveniComponent = /** @class */ (function (_super) {
    __extends(SeznamUzivateluSDodavatelemNastaveniComponent, _super);
    function SeznamUzivateluSDodavatelemNastaveniComponent(formBuilder, autentizaceService, seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        _this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
        _this.typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;
        return _this;
    }
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    };
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.odeslat = function () {
        var pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(1);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    };
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.inicializovatFormularovaData = function () {
        var pocetZaznamuNaStranku = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;
        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    };
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        var nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    };
    SeznamUzivateluSDodavatelemNastaveniComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    };
    return SeznamUzivateluSDodavatelemNastaveniComponent;
}(AbstraktniFormularComponent));
export { SeznamUzivateluSDodavatelemNastaveniComponent };
