import {Component, OnInit} from '@angular/core';
import {Predplatne} from '../../../data/e-shop/predplatne';
import {TypPredplatneho} from '../../../data/e-shop/enum/predplatne.enum';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';
import {Router} from '@angular/router';
import {NastrojeObecne} from '../../../shared/nastroje/nastroje-obecne';
import {ZpusobPlatby} from '../../../data/e-shop/enum/zpusob-platby.enum';
import {PredplatneService} from '../../../service/e-shop/predplatne.service';

@Component({
    selector: 'app-tabulka-aktivnich-predplatnych',
    templateUrl: './tabulka-aktivnich-predplatnych.component.html',
    styleUrls: ['./tabulka-aktivnich-predplatnych.component.scss']
})
export class TabulkaAktivnichPredplatnychComponent implements OnInit {

    public readonly formatData = NastrojeDataACasu.FORMAT_DATA;
    public readonly predplatne = TypPredplatneho;
    public readonly zpusobPlatby = ZpusobPlatby;

    public aktivniPredplatne: Predplatne[];
    public zobrazitVrtitko = false;

    constructor(private predplatneService: PredplatneService, private router: Router) {
    }

    ngOnInit() {
        this.pripravitAktivniPredplatne();
    }

    public prejitNaDetailPlatby(idPlatby: number, zpusobPlatby: ZpusobPlatby) {
        if (!NastrojeObecne.jeHodnotaPrazdna(idPlatby) && zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU) {
            this.router.navigate(['/e-shop/platba'], {queryParams: {id: idPlatby}});
        }
    }

    private pripravitAktivniPredplatne(): void {
        this.zobrazitVrtitko = true;

        this.predplatneService.nacistAktivniPredplatne()
            .toPromise()
            .then((odpoved) => {
                this.aktivniPredplatne = odpoved;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }
}
