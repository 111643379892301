import {Injectable} from '@angular/core';
import {AbstraktniNastaveniSeznamuService} from '../abstraktni-nastaveni-seznamu-service';
import {AtributRazeniSlevovychKuponu} from '../../../data/obecne/enum/atribut-razeni-slevovych-kuponu.enum';
import {TypRazeni} from '../../../data/obecne/enum/typ-razeni.enum';
import {NastaveniZobrazeniSeznamuSlevovychKuponu} from '../../../data/seznam/seznam-slevovych-kuponu/filtr/nastaveni-zobrazeni-seznamu-slevovych-kuponu';

@Injectable()
export class SeznamSlevovychKuponuNastaveniService extends AbstraktniNastaveniSeznamuService {
    protected readonly typAtributuRazeni = AtributRazeniSlevovychKuponu;
    protected readonly vychoziAtributRazeni = AtributRazeniSlevovychKuponu.DATUM_PLATNOSTI_DO;
    protected readonly typTypuRazeni = TypRazeni;
    protected readonly vychoziTypRazeni = TypRazeni.SESTUPNE;

    protected readonly nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-slevovych-kuponu_strankovani-pocet-zaznamu-na-stranku';
    protected readonly nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-slevovych-kuponu_strankovani-aktualni-stranka';
    protected readonly nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-slevovych-kuponu_razeni-atribut-razeni';
    protected readonly nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-slevovych-kuponu_razeni-typ-razeni';

    protected readonly nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuSlevovychKuponu();
}
