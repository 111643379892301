<nav class="menu">
	<div class="menu__logos">
		<a href="{{urlFEN}}" class="menu__logo-fen">
			<img src="{{zakladniUrl}}assets/img/loga/FEN-logo.png" alt="{{ 'popisek.obrazek.fen' | lokalizace }}" class="menu__logo">
		</a>

		<a routerLink="/" class="menu__logo-eshop">
			<div>
				<p><span class="first-letter">{{ 'hlavniMenu.eshop.1' | lokalizace }}</span>{{ 'hlavniMenu.eshop.2' | lokalizace }}</p>
			</div>
		</a>
	</div>

    <ng-container *ngIf="!aktivniMobilniZobrazeni; then standardniZobrazeni else mobilniZobrazeni"></ng-container>

    <ng-template #standardniZobrazeni>
        <app-standardni-zobrazeni-hlavniho-menu class="menu__links" [prihlaseny]="prihlaseny"
                                                [prihlasenyAdministrator]="prihlasenyAdministrator"
                                                [prihlasenyOperator]="prihlasenyOperator"
                                                [informaceOPrihlasenemUzivateli]="getInformaceOPrihlasenemUzivateli()"
                                                [jmenoAPrijmeniSpravceDatabazeCiOperatora]="jmenoAPrijmeniSpravceDatabazeCiOperatora"></app-standardni-zobrazeni-hlavniho-menu>
    </ng-template>

    <ng-template #mobilniZobrazeni>
        <img src="{{zakladniUrl}}assets/img/ikony/menu-ikona.png"
             alt="{{ 'popisek.obrazek.menu' | lokalizace }}"
             class="menu__mobile-button"
             (click)="prepnoutNaMobilniZobrazeni()">
    </ng-template>
</nav>

<app-mobilni-zobrazeni-hlavniho-menu [zobrazit]="zobrazitMobilniZobrazeni" [prihlaseny]="prihlaseny"
                                     [prihlasenyAdministrator]="prihlasenyAdministrator"
                                     [prihlasenyOperator]="prihlasenyOperator"
                                     [informaceOPrihlasenemUzivateli]="getInformaceOPrihlasenemUzivateli()"
                                     [jmenoAPrijmeniSpravceDatabazeCiOperatora]="jmenoAPrijmeniSpravceDatabazeCiOperatora"
                                     (zavrit)="prepnoutNaMobilniZobrazeni()"></app-mobilni-zobrazeni-hlavniho-menu>

<app-vrtitko [zobrazit]=zobrazitVrtitko></app-vrtitko>
