export const prostredi = {
    production: true,

    verze: 'Produkční',
    zakladniUrl: '/',

    url: 'https://eshop.fen.cz',
    urlFEN: 'https://fen.cz',
    urlCDD: 'https://cdd.fen.cz',
    urlPODO: 'https://podo.fen.cz',

    urlBackendEshopDetailObjednavky: 'https://api.eshop.fen.cz/fen-objednavky-detail-objednavky',
    urlBackendEshopDetailFaktury: 'https://api.eshop.fen.cz/fen-faktury-detail-faktury',
    urlBackendEshopSeznamObjednavek: 'https://api.eshop.fen.cz/fen-objednavky-seznam-objednavek',
    urlBackendEshopOnlinePlatby: 'https://api.eshop.fen.cz/fen-onlineplatby',
    urlBackendEshopProdukty: 'https://api.eshop.fen.cz/fen-produkty-detail-produktu',
    urlBackendEshopPredplatne: 'https://api.eshop.fen.cz/fen-predplatne',
    urlBackendEshopSlevoveKupony: 'https://api.eshop.fen.cz/fen-slevove-kupony',

    cddAdministraceBackendUrl: 'https://api.cddadmin.fen.cz/cdd-administrace',
    cddKeycloakUrl: 'https://auth.fen.cz/auth',

    cddRealm: 'CDD',
    cddClientId: 'administrace-cdd',
    cddClientSecret: '6d2457f8-85c0-48cd-a9e9-0c0f01a45f27',

    administratorRealm: 'administrator',
    administratorClientId: 'cdd-administrace',
    administratorClientSecret: 'f0a9c185-8c56-4b4d-9e1e-b803a6e9e8cb',

    eshopRealm: 'eshop',
    eshopClientId: 'administrace-eshopu',
    eshopClientSecret: '0799b9fa-fa27-4d52-812b-b1e5b646817b',

    hlavicky: {
        'Content-Type': 'application/json'
    },
    hlavickyBackend: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
    },
    hlavickyBackendSGetOverride: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'X-HTTP-Method-Override': 'GET'
    },
};
