import {Injectable} from '@angular/core';
import {prostredi} from '../../../prostredi/prostredi';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import {InformaceOprihlasenemUzivateli} from '../../data/dodavatel/informace-oprihlasenem-uzivateli';
import {NastrojeJsonu} from '../../shared/nastroje/nastroje-jsonu';
import {NastrojeRestu} from '../../shared/nastroje/nastroje-restu';
import {NastrojeAutentizace} from '../../shared/nastroje/nastroje-autentizace';
import {LokalniUlozisteNastroje} from '../../shared/nastroje/lokalni-uloziste/lokalni-uloziste-nastroje';
import {NastrojeLokalizace} from '../../shared/nastroje/nastroje-lokalizace';

@Injectable()
export class AutentizaceService {
    public static readonly nazevKliceAktualnihoRealmu = 'aktualniRealm';
    private hlavicky: HttpHeaders;

    constructor(private http: HttpClient, private keycloakService: KeycloakService) {
        this.hlavicky = new HttpHeaders();
    }

    public vlozitTokenDoHlavicky(hlavicka?: HttpHeaders): HttpHeaders {
        if (!hlavicka) {
            hlavicka = new HttpHeaders();
        }
        hlavicka = hlavicka.append('Authorization', 'Bearer ' + this.keycloakService.getKeycloakInstance().token);
        return hlavicka;
    }

    public pripravitInformaceOUzivateliAUlozitDoLocalStorage(): Promise<any> {
        if (NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage() != null) {
            return Promise.resolve();
        }

        return this.aktualizovatInformaceOUzivateliDoLocalStorage();
    }

    public aktualizovatInformaceOUzivateliDoLocalStorage(): Promise<any> {
        return this.http.get(prostredi.cddAdministraceBackendUrl + '/prihlasenyUzivatel', {headers: this.vlozitTokenDoHlavicky()})
            .toPromise()
            .then(vysledek => {
                const info = NastrojeJsonu.stringifyAndParse(vysledek);
                NastrojeAutentizace.ulozitInformaceOUzivateliDoLocalStorage(info);
                return info as InformaceOprihlasenemUzivateli;
            })
            .catch(NastrojeRestu.zpracovatChybu);
    }

    public prihlasitCddUzivatele(): void {
        this.prihlasit(prostredi.cddRealm, prostredi.cddClientId, prostredi.cddClientSecret);
    }

    public prihlasitAdministratora(): void {
        this.prihlasit(prostredi.administratorRealm, prostredi.administratorClientId, prostredi.administratorClientSecret);
    }

    public prihlasitOperatora(): void {
        this.prihlasit(prostredi.eshopRealm, prostredi.eshopClientId, prostredi.eshopClientSecret);
    }

    public odhlasit(): void {
        LokalniUlozisteNastroje.odstranitPolozku(AutentizaceService.nazevKliceAktualnihoRealmu);
        this.keycloakService.logout(prostredi.url);
    }

    public jeUzivatelPrihlaseny(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated && !this.keycloakService.getKeycloakInstance().isTokenExpired();
    }

    public jePrihlasenyUzivatelCdd(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.cddRealm;
    }

    public jePrihlasenyUzivatelSpravceDatabaze(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.administratorRealm;
    }

    public jePrihlasenyUzivatelOperator(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.eshopRealm;
    }

    public vratitRealmAktualnePrihlasenehoUzivatele(): string {
        return this.keycloakService.getKeycloakInstance().realm;
    }

    private prihlasit(nazevRealmu: string, clientId: string, clientSecret: string): void {
        NastrojeAutentizace.odstranitInformaceOUzivateliZLocalStorage();
        LokalniUlozisteNastroje.setPolozka(AutentizaceService.nazevKliceAktualnihoRealmu, nazevRealmu);
        this.keycloakService.init(NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.cddKeycloakUrl, nazevRealmu, clientId, clientSecret));

        this.keycloakService.login({kcLocale: NastrojeLokalizace.vratitNastavenouJazykovouMutaci()});
    }
}
