import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
    selector: 'app-formular-input-s-akci',
    templateUrl: './formular-input-s-akci.component.html',
    styleUrls: ['./formular-input-s-akci.component.scss']
})
export class FormularInputSAkciComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

    @Input() typ = 'text';

    @Input() nazevPrimarnihoTlacitka: string;
    @Input() ikonkaPrimarnihoTlacitka: string;

    @Input() nazevSekundarnihoTlacitka: string;
    @Input() ikonkaSekundarnihoTlacitka: string;

    @Output() primarniAkce = new EventEmitter();
    @Output() sekundarniAkce = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public vyvolatPrimarniAkci(): void {
        this.primarniAkce.emit();
    }

    public vyvolatSekundarniAkci(): void {
        this.sekundarniAkce.emit();
    }
}
