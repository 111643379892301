import { compare } from 'fast-json-patch';
import { NastrojeDataACasu } from './nastroje-data-a-casu';
var NastrojeJsonu = /** @class */ (function () {
    function NastrojeJsonu() {
    }
    NastrojeJsonu.stringifyAndParse = function (object) {
        return JSON.parse(this.stringify(object), nahrazovacVstupu);
    };
    NastrojeJsonu.parse = function (json) {
        return JSON.parse(json);
    };
    NastrojeJsonu.stringify = function (object) {
        return JSON.stringify(object, nahrazovacVystupu);
    };
    NastrojeJsonu.stringifyRFC3339 = function (object) {
        return JSON.stringify(object, nahrazovacVystupuRFC3339);
    };
    NastrojeJsonu.stringifyISO8601 = function (object) {
        return JSON.stringify(object, nahrazovacVstupuISO8601);
    };
    NastrojeJsonu.vytvoritJsonPatch = function (puvodniObjekt, novyObjekt) {
        return JSON.stringify(compare(puvodniObjekt, novyObjekt), nahrazovacVystupu);
    };
    return NastrojeJsonu;
}());
export { NastrojeJsonu };
function nahrazovacVstupu(klic, hodnota) {
    if (typeof hodnota === 'string' && NastrojeDataACasu.NAHRAZOVAC_VSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatum(hodnota, NastrojeDataACasu.NAHRAZOVAC_FORMAT_OBJEKT);
    }
    return hodnota;
}
function nahrazovacVstupuISO8601(klic, hodnota) {
    if (typeof hodnota === 'string' && NastrojeDataACasu.NAHRAZOVAC_VSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatumDleISO8601(hodnota);
    }
    return hodnota;
}
function nahrazovacVystupu(klic, hodnota) {
    if (NastrojeDataACasu.NAHRAZOVAC_VYSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.formatovatDatum(new Date(Date.parse(hodnota)), NastrojeDataACasu.NAHRAZOVAC_FORMAT_OBJEKT);
    }
    return hodnota;
}
function nahrazovacVystupuRFC3339(klic, hodnota) {
    if (NastrojeDataACasu.NAHRAZOVAC_VYSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatumDleRFC3339(new Date(Date.parse(hodnota)));
    }
    return hodnota;
}
