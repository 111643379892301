import { RadekSeznamu } from '../../../../../shared/seznam/struktura/radek-seznamu';
import { DatumPipe } from '../../../../../shared/nastroje/pipes/datum.pipe';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { HodnotaSeznamu } from '../../../../../shared/seznam/struktura/hodnota-seznamu';
import { TypHodnotySeznamu } from '../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import { NastrojeDataACasu } from '../../../../../shared/nastroje/nastroje-data-a-casu';
import { SeznamSlevovychKuponuService } from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import { Lokalizace } from '../../../../../shared/lokalizace';
import { SlevaPipe } from '../../../../../shared/nastroje/pipes/sleva.pipe';
var SeznamSlevovychKuponuSeznamComponent = /** @class */ (function () {
    function SeznamSlevovychKuponuSeznamComponent(datumPipe, slevaPipe, seznamSlevovychKuponuService) {
        this.datumPipe = datumPipe;
        this.slevaPipe = slevaPipe;
        this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
    }
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitCelkovyPocetZaznamu = function () {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    };
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitRadkySeznamuProZobrazeni = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }
        return this.radkySeznamuProZobrazeni;
    };
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitRadkySeznamu = function () {
        var radky = new Array();
        for (var _i = 0, _a = this.seznamSlevovychKuponuService.seznam.polozky; _i < _a.length; _i++) {
            var slevovyKupon = _a[_i];
            radky.push(new RadekSeznamu(slevovyKupon.id, this.pripravitHodnotyRadkuSeznamu(slevovyKupon), this.pripravitUrlDetailuSlevovehoKuponu(slevovyKupon.nazev)));
        }
        return radky;
    };
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitHodnotyRadkuSeznamu = function (slevovyKupon) {
        var hodnoty = new Array();
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, slevovyKupon.nazev));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, this.pripravitPlatnostSlevovehoKuponu(slevovyKupon)));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.slevaPipe.transform(slevovyKupon.sleva), 'local_offer'));
        return hodnoty;
    };
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitUrlDetailuSlevovehoKuponu = function (nazev) {
        return "/slevove-kupony/detail/" + nazev;
    };
    SeznamSlevovychKuponuSeznamComponent.prototype.pripravitPlatnostSlevovehoKuponu = function (slevovyKupon) {
        return NastrojeObecne.formatovatRetezec('{0}: {1} - {2}', Lokalizace.pripravitText('slevovyKupon.platnost'), this.datumPipe.transform(slevovyKupon.datumPlatnostiOd, NastrojeDataACasu.FORMAT_DATA), this.datumPipe.transform(slevovyKupon.datumPlatnostiDo, NastrojeDataACasu.FORMAT_DATA));
    };
    return SeznamSlevovychKuponuSeznamComponent;
}());
export { SeznamSlevovychKuponuSeznamComponent };
