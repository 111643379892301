import { SeznamObjednavekOperatoraService } from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import { RadekSeznamu } from '../../../../../../shared/seznam/struktura/radek-seznamu';
import { HodnotaSeznamu } from '../../../../../../shared/seznam/struktura/hodnota-seznamu';
import { TypHodnotySeznamu } from '../../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import { DatumPipe } from '../../../../../../shared/nastroje/pipes/datum.pipe';
import { VyctovyTypPipe } from '../../../../../../shared/nastroje/pipes/vyctovy-typ.pipe';
import { NastrojeObecne } from '../../../../../../shared/nastroje/nastroje-obecne';
import { StavObjednavky } from '../../../../../../data/e-shop/enum/stav-objednavky.enum';
import { NastrojeDataACasu } from '../../../../../../shared/nastroje/nastroje-data-a-casu';
import { Lokalizace } from '../../../../../../shared/lokalizace';
var SeznamObjednavekOperatoraSeznamComponent = /** @class */ (function () {
    function SeznamObjednavekOperatoraSeznamComponent(seznamObjednavekOperatoraService, datumPipe, vyctovyTypPipe) {
        this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
        this.datumPipe = datumPipe;
        this.vyctovyTypPipe = vyctovyTypPipe;
    }
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitCelkovyPocetZaznamu = function () {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    };
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitRadkySeznamuProZobrazeni = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }
        return this.radkySeznamuProZobrazeni;
    };
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitRadkySeznamu = function () {
        var radky = new Array();
        for (var _i = 0, _a = this.seznamObjednavekOperatoraService.seznam.polozky; _i < _a.length; _i++) {
            var objednavka = _a[_i];
            radky.push(new RadekSeznamu(objednavka.identifikator, this.pripravitHodnotyRadkuSeznamu(objednavka), this.pripravitUrlDetailuObjednavky(objednavka.identifikator)));
        }
        return radky;
    };
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitHodnotyRadkuSeznamu = function (objednavka) {
        var hodnoty = new Array();
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, objednavka.nazevProduktu));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, this.pripravitSekundarniHodnotuRadkuSeznamu(objednavka)));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, objednavka.identifikator, 'center_focus_weak'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumVytvoreni, NastrojeDataACasu.FORMAT_DATA), 'add'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumSplatnosti, NastrojeDataACasu.FORMAT_DATA), 'payment'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.vyctovyTypPipe.transform(objednavka.stavObjednavky, StavObjednavky), 'transform'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumPlatnostiPredplatnehoDo, NastrojeDataACasu.FORMAT_DATA, true), 'laptop_chromebook'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, objednavka.prihlasovaciJmeno, 'person_outline'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, objednavka.prihlasovaciJmenoOperatora, 'headset_mic'));
        return hodnoty;
    };
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitUrlDetailuObjednavky = function (id) {
        return "e-shop/objednavka/" + id;
    };
    SeznamObjednavekOperatoraSeznamComponent.prototype.pripravitSekundarniHodnotuRadkuSeznamu = function (objednavka) {
        var vysledek = [];
        if (objednavka.nazevOrganizace) {
            vysledek.push(objednavka.nazevOrganizace);
        }
        if (objednavka.ic) {
            vysledek.push(Lokalizace.pripravitText('objednavka.ic') + ': ' + objednavka.ic);
        }
        if (objednavka.jmeno || objednavka.prijmeni) {
            vysledek.push(objednavka.jmeno + ' ' + objednavka.prijmeni);
        }
        if (objednavka.email) {
            vysledek.push(objednavka.email);
        }
        return vysledek.join(', ').toString();
    };
    return SeznamObjednavekOperatoraSeznamComponent;
}());
export { SeznamObjednavekOperatoraSeznamComponent };
