var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Lokalizace } from '../../../../lokalizace';
import { NastrojeObecne } from '../../../../nastroje/nastroje-obecne';
import { AbstraktniFormularovyPrvekComponent } from '../abstraktni-formularovy-prvek.component';
/**
 * <app-formular-select
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 *  [polozky]="<polozky selectu>"
 *  [prvniHodnotaNull]="<prvni_hodnota_null vychozi false>"
 *  [hodnotaKliceZObjektu]="<hodnota_klice_z_objektu default false>">
 * </app-form-select>
 */
var FormularSelectComponent = /** @class */ (function (_super) {
    __extends(FormularSelectComponent, _super);
    function FormularSelectComponent() {
        var _this = _super.call(this) || this;
        _this.nastaveniPozorovateleRoletky = { attributes: true };
        /**
         * Definuje zda se ma v roletce zobrazit jako prvni hodnota "nezvolano" (null)
         */
        _this.prvniHodnotaNull = false;
        /**
         * Definuje pokud jestli je klic hodnota brana z vyctoveho typu a nebo z objektu z poli klic a hodnota
         */
        _this.hodnotaKliceZObjektu = false;
        /**
         * Definuje nazev klice objektu
         */
        _this.nazevKliceObjektu = 'klic';
        /**
         * Definuje nazev hodnoty objektu
         */
        _this.nazevHodnotyObjektu = 'hodnota';
        /**
         * Definuje zdali se maji hodnoty seradit podle prelozeneho popisku (pouze pro ciselniky (pole objektu), nikoliv pro enumy)
         */
        _this.raditHodnotyPodleNazvu = true;
        /**
         * Definuje zdali je mozne vybirat vice polozek najednou
         */
        _this.vicenasobnyVyberPolozek = false;
        _this.zpracovatZmenyAtributuRoletky = function (listZmen) {
            for (var _i = 0, listZmen_1 = listZmen; _i < listZmen_1.length; _i++) {
                var zmena = listZmen_1[_i];
                if (zmena.type === 'attributes') {
                    var elementRoletky = $(zmena.target);
                    var tridaRoletky = elementRoletky.attr('class');
                    var kontejnerRoletky = elementRoletky.parent();
                    var vychoziTridaKontejneruRoletky = 'select-wrapper';
                    if (kontejnerRoletky.hasClass(vychoziTridaKontejneruRoletky)) {
                        kontejnerRoletky.attr('class', vychoziTridaKontejneruRoletky + " " + tridaRoletky);
                    }
                }
            }
        };
        return _this;
    }
    FormularSelectComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    FormularSelectComponent.prototype.ngAfterViewInit = function () {
        this.nastavitPosluchaceZmenyStavu();
        this.nastavitPozorovateleZmenyAtributu();
    };
    FormularSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('polozky')) {
            this.seraditPolozkyDleNazvu();
            this.inicializovatRoletku();
        }
    };
    FormularSelectComponent.prototype.seraditPolozkyDleNazvu = function () {
        var _this = this;
        if (this.raditHodnotyPodleNazvu && this.polozky instanceof Array) {
            this.polozky.sort(function (prvniPolozka, druhaPolozka) {
                var prvniHodnota = _this.pripravitPopisekPolozky(prvniPolozka);
                var druhaHodnota = _this.pripravitPopisekPolozky(druhaPolozka);
                return prvniHodnota.localeCompare(druhaHodnota, 'cs');
            });
        }
    };
    FormularSelectComponent.prototype.pripravitPopisekPolozky = function (polozka) {
        return Lokalizace.pripravitText(!this.hodnotaKliceZObjektu ? polozka.value : polozka[this.nazevHodnotyObjektu]);
    };
    FormularSelectComponent.prototype.nastavitPosluchaceZmenyStavu = function () {
        var _this = this;
        this.ovladacFormulare.statusChanges.subscribe(function () {
            if (NastrojeObecne.jeHodnotaPrazdna(_this.ovladacFormulare.value)) {
                $(_this.roletka.nativeElement).val('');
            }
            $(_this.roletka.nativeElement).formSelect();
        });
    };
    FormularSelectComponent.prototype.nastavitPozorovateleZmenyAtributu = function () {
        var pozorovatel = new MutationObserver(this.zpracovatZmenyAtributuRoletky);
        pozorovatel.observe(this.roletka.nativeElement, this.nastaveniPozorovateleRoletky);
    };
    FormularSelectComponent.prototype.inicializovatRoletku = function () {
        $(document).ready(function () {
            $('select').formSelect();
        });
    };
    return FormularSelectComponent;
}(AbstraktniFormularovyPrvekComponent));
export { FormularSelectComponent };
