import {Injectable} from '@angular/core';
import {AbstraktniNastaveniSeznamuService} from '../abstraktni-nastaveni-seznamu-service';
import {TypRazeni} from '../../../data/obecne/enum/typ-razeni.enum';
import {AtributRazeniObjednavekOperatora} from '../../../data/obecne/enum/atribut-razeni-objednavek-operatora.enum';
import {NastaveniZobrazeniSeznamuObjednavekOperatora} from '../../../data/seznam/seznam-objednavek-operatora/filtr/nastaveni-zobrazeni-seznamu-objednavek-operatora';
import {TypFiltru} from '../../../data/obecne/enum/typ-filtru.enum';

@Injectable()
export class SeznamObjednavekOperatoraNastaveniService extends AbstraktniNastaveniSeznamuService {
    protected readonly typAtributuRazeni = AtributRazeniObjednavekOperatora;
    protected readonly vychoziAtributRazeni = AtributRazeniObjednavekOperatora.DATUM_VYTVORENI;
    protected readonly typTypuRazeni = TypRazeni;
    protected readonly vychoziTypRazeni = TypRazeni.SESTUPNE;

    protected readonly nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-objednavek-operatora_strankovani-pocet-zaznamu-na-stranku';
    protected readonly nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-objednavek-operatora_strankovani-aktualni-stranka';
    protected readonly nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-objednavek-operatora_razeni-atribut-razeni';
    protected readonly nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-objednavek-operatora_razeni-typ-razeni';

    protected readonly nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuObjednavekOperatora();

    private typFiltru: TypFiltru;

    public getTypFiltru(): TypFiltru {
        return this.typFiltru;
    }

    public setTypFiltru(typFiltru: TypFiltru) {
        this.typFiltru = typFiltru;
    }
}
