<div class="frame">
    <h3 class="info-title">{{ 'objednavka.produkt' | lokalizace }}</h3>

    <app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

    <ng-container class="span-2" *ngIf="!vybranyProdukt">
        <div class="button-area-reverse">
            <button type="button"
                    class="btn btn-secondary waves-effect waves-light right modal-trigger"
                    data-target="volba-produktu-modalni-panel">
                <b>{{ 'tlacitko.pridatProdukt' | lokalizace }}</b>
                <i class="material-icons left">{{ vybranyProdukt ? 'edit' : 'add' }}</i>
            </button>
        </div>
    </ng-container>

    <ng-container *ngIf="vybranyProdukt">
        <div class="product-container span-2">
            <div class="list__item clickable modal-trigger" data-target="volba-produktu-modalni-panel">
                <div class="title-container">
                    <p class="title">{{ pripravitTextProduktu() }}</p>

                    <p class="product-price">
                        <span *ngIf="vybranePredplatne !== 'NA_MIRU'">{{ vybranyProdukt.cena?.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }} <span>{{ 'cena.vcetneDPH' | lokalizace }}</span></span>
                        <span  *ngIf="vybranePredplatne === 'NA_MIRU'">{{ vybranyProdukt.cena?.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }} <span>{{ 'cena.vcetneDPHZaMesic' | lokalizace }}</span></span>
                        <span *ngIf="vybranePredplatne === predplatne.MESICNI_S_OBNOVOU">{{ 'objednavka.cena.mesicne' | lokalizace }}</span>
                    </p>
                </div>

                <div class="second-title">{{ vybranePredplatne | vyctovyTyp: 'Predplatne' }}</div>
                <div class="subtitle">{{ vybranyProdukt.popis }}</div>
            </div>

            <form [formGroup]="formGroup" class="form-column-layout">
                <app-formular-input
                        class="reverse-span-4"
                        [popisek]="'objednavka.celkovaCenaSDPH.popisek'"
                        [odeslan]="odeslan"
                        [ovladacFormulare]="formGroup.controls.cena"></app-formular-input>
            </form>
        </div>
    </ng-container>

    <div>
        <div class="container__content-footer">
            <button type="button" (click)="presmerovatPriZruseni()"
                    class="btn btn-secondary">
                <b>{{ 'tlacitko.zrusit' | lokalizace }}</b>
                <i class="material-icons right">clear</i>
            </button>
            <button type="button" (click)="predchoziKarta()"
                    class="btn btn-secondary">
                <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                <i class="material-icons right">arrow_back</i>
            </button>
            <button type="submit" (click)="onSubmit()"
                    class="btn btn-primary">
                <b>{{ 'tlacitko.pokracovat' | lokalizace }}</b>
                <i class="material-icons right">arrow_forward</i>
            </button>
        </div>
    </div>
</div>

<app-zvolit-produkt-modalni-panel [produkty]="produkty"
                                  (pridatVybranyTarif)="vlozitVybranyProdukt($event)"></app-zvolit-produkt-modalni-panel>
