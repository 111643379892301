<ng-container *ngIf="!vytvorit && !editovat">
    <div class="info-container no-padding">
        <h3 class="info-title span-2">{{ 'slevovyKupon.nadpis.zakladniUdaje' | lokalizace }}</h3>

        <div class="info-group">
            <app-formular-output [popisek]="'slevovyKupon.nazev'" [hodnota]="slevovyKupon.nazev"></app-formular-output>
            <app-formular-output [popisek]="'slevovyKupon.platnostOd'" [hodnota]="slevovyKupon.platnost.rozsahOd | datum: formatData"></app-formular-output>
        </div>

        <div class="info-group">
            <app-formular-output [popisek]="'slevovyKupon.slevaBezProcent'" [hodnota]="slevovyKupon.sleva | sleva"></app-formular-output>
            <app-formular-output [popisek]="'slevovyKupon.platnostDo'" [hodnota]="slevovyKupon.platnost.rozsahDo | datum: formatData"></app-formular-output>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="vytvorit || editovat">
    <h3 class="info-title span-2">{{ 'slevovyKupon.nadpis.zakladniUdaje' | lokalizace }}</h3>

    <form [formGroup]="formGroup" class="form-column-layout">
        <app-formular-input class="span-3"
                            [popisek]="'slevovyKupon.nazev'"
                            [odeslan]="odeslan"
                            [ovladacFormulare]="formGroup.controls.nazev"></app-formular-input>

        <app-formular-input class="span-3"
                            [popisek]="'slevovyKupon.slevaSProcenty'"
                            [odeslan]="odeslan"
                            [ovladacFormulare]="formGroup.controls.sleva"></app-formular-input>

        <app-formular-kalendar class="span-3"
                               [popisek]="'slevovyKupon.platnostOd'"
                               [odeslan]="odeslan"
                               [ovladacFormulare]="formGroup.controls.platnostRozsahOd"></app-formular-kalendar>

        <app-formular-kalendar class="span-3"
                               [popisek]="'slevovyKupon.platnostDo'"
                               [odeslan]="odeslan"
                               [ovladacFormulare]="formGroup.controls.platnostRozsahDo"></app-formular-kalendar>
    </form>
</ng-container>
