/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-strankovani.component.scss.shim.ngstyle";
import * as i1 from "./seznam-objednavek-strankovani.component.ie.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../../../../../../shared/nastroje/pipes/lokalizace.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./seznam-objednavek-strankovani.component";
import * as i6 from "../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni";
import * as i7 from "../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service";
var styles_SeznamObjednavekStrankovaniComponent = [i0.styles, i1.styles];
var RenderType_SeznamObjednavekStrankovaniComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekStrankovaniComponent, data: {} });
export { RenderType_SeznamObjednavekStrankovaniComponent as RenderType_SeznamObjednavekStrankovaniComponent };
function View_SeznamObjednavekStrankovaniComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "section", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 5, "button", [["class", "btn waves-effect waves-light"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.predchoziStranka() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons left"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i2.ɵeld(4, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), i2.ɵppd(6, 1), (_l()(), i2.ɵeld(7, 0, null, null, 5, "button", [["class", "btn waves-effect waves-light"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dalsiStranka() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i2.ɵted(9, null, ["", ""])), i2.ɵppd(10, 1), (_l()(), i2.ɵeld(11, 0, null, null, 1, "i", [["class", "material-icons right"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["keyboard_arrow_right"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.jePrvniStranka() || (_co.pripravitCelkovyPocetZaznamu() === undefined)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i2.ɵnov(_v.parent, 0), "tlacitko.predchozi")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.jePosledniStranka() || (_co.pripravitCelkovyPocetZaznamu() === undefined)); _ck(_v, 7, 0, currVal_2); var currVal_3 = i2.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i2.ɵnov(_v.parent, 0), "tlacitko.dalsi")); _ck(_v, 9, 0, currVal_3); }); }
export function View_SeznamObjednavekStrankovaniComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i3.LokalizacePipe, []), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SeznamObjednavekStrankovaniComponent_1)), i2.ɵdid(2, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pripravitMaximalniPocetStranek() !== undefined); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SeznamObjednavekStrankovaniComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-strankovani", [], null, null, null, View_SeznamObjednavekStrankovaniComponent_0, RenderType_SeznamObjednavekStrankovaniComponent)), i2.ɵdid(1, 49152, null, 0, i5.SeznamObjednavekStrankovaniComponent, [i6.SeznamObjednavekNastaveniService, i7.SeznamObjednavekService], null, null)], null, null); }
var SeznamObjednavekStrankovaniComponentNgFactory = i2.ɵccf("app-seznam-objednavek-strankovani", i5.SeznamObjednavekStrankovaniComponent, View_SeznamObjednavekStrankovaniComponent_Host_0, {}, {}, []);
export { SeznamObjednavekStrankovaniComponentNgFactory as SeznamObjednavekStrankovaniComponentNgFactory };
