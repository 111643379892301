import { ElementRef, OnInit } from '@angular/core';
import { Objednavka } from '../../../data/e-shop/objednavka';
import { TypPredplatneho } from '../../../data/e-shop/enum/predplatne.enum';
import { NastrojeDataACasu } from '../../../shared/nastroje/nastroje-data-a-casu';
import { Mena } from '../../../data/obecne/enum/mena.enum';
import { prostredi } from '../../../../prostredi/prostredi';
import { NastrojeModalnihoPanelu } from '../../../shared/nastroje/nastroje-modalniho-panelu';
var UdajeOObjednavceComponent = /** @class */ (function () {
    function UdajeOObjednavceComponent() {
        this.formatData = NastrojeDataACasu.FORMAT_DATA;
        this.formatDataACasu = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;
        this.mena = Mena;
        this.predplatne = TypPredplatneho;
        this.platbyAObjednavkyUrl = prostredi.url + '/#/e-shop/platby-a-objednavky';
    }
    UdajeOObjednavceComponent.prototype.ngOnInit = function () {
        this.inicializovatModalniPanel();
    };
    UdajeOObjednavceComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    return UdajeOObjednavceComponent;
}());
export { UdajeOObjednavceComponent };
