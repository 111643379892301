<div class="legend">
    <div class="legend__item">
        <i class="material-icons">center_focus_weak</i>
        <label>{{ 'objednavka.id' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">add</i>
        <label>{{ 'objednavka.datumVytvoreni' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">payment</i>
        <label>{{ 'objednavka.datumSplatnosti' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">transform</i>
        <label>{{ 'objednavka.stav' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">laptop_chromebook</i>
        <label>{{ 'objednavka.datumPlatnostiPredplatnehoDo' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">local_atm</i>
        <label>{{ 'objednavka.celkovaCenaSDPH.popisek' | lokalizace }}</label>
    </div>
</div>
