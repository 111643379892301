import { NastrojeDataACasu } from '../../nastroje/nastroje-data-a-casu';
import { NastrojePorovnani } from '../../nastroje/nastroje-porovnani';
import { NastrojeValidace } from '../../nastroje/nastroje-validace';
var ValidatoryRozsahu = /** @class */ (function () {
    function ValidatoryRozsahu() {
    }
    ValidatoryRozsahu.rozsah = function (nazevParametruOd, nazevParametruDo, typ) {
        return function (group) {
            var prvekFormulareOd = group.controls[nazevParametruOd];
            var prvekFormulareDo = group.controls[nazevParametruDo];
            var hodnotaOd = prvekFormulareOd.value;
            var hodnotaDo = prvekFormulareDo.value;
            if (hodnotaOd && hodnotaDo) {
                if ('Date' === typ) {
                    var datumOd = NastrojeDataACasu.prevestRetezecNaDatum(hodnotaOd, NastrojeDataACasu.FORMAT_DATA);
                    var datumDo = NastrojeDataACasu.prevestRetezecNaDatum(hodnotaDo, NastrojeDataACasu.FORMAT_DATA);
                    if (NastrojePorovnani.porovnatDatum(datumOd, datumDo) === 1) {
                        NastrojeValidace.nastavitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                    else {
                        NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                }
                else if ('Number' === typ) {
                    if (+hodnotaOd > +hodnotaDo) {
                        NastrojeValidace.nastavitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                    else {
                        NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
                    }
                }
            }
            else {
                NastrojeValidace.odstranitChybu(prvekFormulareOd, ValidatoryRozsahu.klicChybyRozsahu);
            }
        };
    };
    ValidatoryRozsahu.klicChybyRozsahu = 'rozsahPrvniHodnotaVetsiNezDruha';
    return ValidatoryRozsahu;
}());
export { ValidatoryRozsahu };
