import {compare} from 'fast-json-patch';
import {NastrojeDataACasu} from './nastroje-data-a-casu';

export class NastrojeJsonu {

    public static stringifyAndParse(object: any): any {
        return JSON.parse(this.stringify(object), nahrazovacVstupu);
    }

    public static parse(json: string): any {
        return JSON.parse(json);
    }

    public static stringify(object: any) {
        return JSON.stringify(object, nahrazovacVystupu);
    }

    public static stringifyRFC3339(object: any) {
        return JSON.stringify(object, nahrazovacVystupuRFC3339);
    }

    public static stringifyISO8601(object: any) {
        return JSON.stringify(object, nahrazovacVstupuISO8601);
    }

    public static vytvoritJsonPatch<T>(puvodniObjekt: T, novyObjekt: T): string {
        return JSON.stringify(compare(puvodniObjekt, novyObjekt), nahrazovacVystupu);
    }
}

function nahrazovacVstupu(klic, hodnota) {
    if (typeof hodnota === 'string' && NastrojeDataACasu.NAHRAZOVAC_VSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatum(hodnota, NastrojeDataACasu.NAHRAZOVAC_FORMAT_OBJEKT);
    }

    return hodnota;
}

function nahrazovacVstupuISO8601(klic, hodnota) {
    if (typeof hodnota === 'string' && NastrojeDataACasu.NAHRAZOVAC_VSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatumDleISO8601(hodnota);
    }

    return hodnota;
}

function nahrazovacVystupu(klic, hodnota) {
    if (NastrojeDataACasu.NAHRAZOVAC_VYSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.formatovatDatum(new Date(Date.parse(hodnota)), NastrojeDataACasu.NAHRAZOVAC_FORMAT_OBJEKT);
    }

    return hodnota;
}

function nahrazovacVystupuRFC3339(klic, hodnota) {
    if (NastrojeDataACasu.NAHRAZOVAC_VYSTUPNI_FORMAT_DATA.test(hodnota)) {
        return NastrojeDataACasu.prevestRetezecNaDatumDleRFC3339(new Date(Date.parse(hodnota)));
    }

    return hodnota;
}
