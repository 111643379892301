import { ElementRef, OnInit } from '@angular/core';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { NastrojeTabu } from '../../../../../shared/nastroje/nastroje-tabu';
import { NastrojeOdrolovani } from '../../../../../shared/nastroje/nastroje-odrolovani';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { ZpusobVytvoreniObjednavky } from '../../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
var VytvoritObjednavkuOperatoraWizardComponent = /** @class */ (function () {
    function VytvoritObjednavkuOperatoraWizardComponent(vytvoritObjednavkuService) {
        this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        this.krokObjednavky = KrokObjednavky;
        this.zpusobVytvoreniObjednavky = ZpusobVytvoreniObjednavky;
        this.jeWizardInicializovan = false;
    }
    VytvoritObjednavkuOperatoraWizardComponent.prototype.ngOnInit = function () {
        this.vytvoritObjednavkuService.inicializovatObjednavku();
        this.inicializovatWizard();
    };
    VytvoritObjednavkuOperatoraWizardComponent.prototype.prejitNaKartu = function (idKarty) {
        NastrojeTabu.prejitNaKartu(idKarty);
        NastrojeOdrolovani.odrolovat('zacatek-stranky');
    };
    VytvoritObjednavkuOperatoraWizardComponent.prototype.inicializovatWizard = function () {
        NastrojeTabu.inicializovatTaby();
        this.jeWizardInicializovan = true;
    };
    return VytvoritObjednavkuOperatoraWizardComponent;
}());
export { VytvoritObjednavkuOperatoraWizardComponent };
