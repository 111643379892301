var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { prostredi } from '../../../../prostredi/prostredi';
import { AutentizaceService } from '../../bezpecnost/autentizace.service';
import { AbstraktniSeznamService } from '../abstraktni-seznam-service';
import { SeznamObjednavekNastaveniService } from './seznam-objednavek-nastaveni';
import { SeznamObjednavek } from '../../../data/seznam/seznam-objednavek/seznam-objednavek';
import { TypPozadavku } from '../../obecne/abstraktni-service';
import { NastrojeJsonu } from '../../../shared/nastroje/nastroje-jsonu';
var SeznamObjednavekService = /** @class */ (function (_super) {
    __extends(SeznamObjednavekService, _super);
    function SeznamObjednavekService(http, autentizaceService, seznamObjednavekNastaveniService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopSeznamObjednavek;
        return _this;
    }
    SeznamObjednavekService.prototype.aktualizovat = function (odrolovatNaZacatekSeznamu) {
        var _this = this;
        if (odrolovatNaZacatekSeznamu === void 0) { odrolovatNaZacatekSeznamu = true; }
        this.seznam = new SeznamObjednavek();
        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-objednavek-uzivatele/', NastrojeJsonu.stringifyISO8601(this.seznamObjednavekNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(function (odpoved) {
            _this.seznam = odpoved;
        }).catch(this.zpracovatChybu);
        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    };
    return SeznamObjednavekService;
}(AbstraktniSeznamService));
export { SeznamObjednavekService };
