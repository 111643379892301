import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'klice'
})
export class KlicePipe implements PipeTransform {

    transform(hodnota: any): any {
        const klice = [];

        for (const clenEnumu of Object.keys(hodnota)) {
            klice.push({
                key: clenEnumu.toString(),
                value: hodnota[clenEnumu.toString()]
            });
        }

        return klice;
    }
}
