import { OnInit } from '@angular/core';
import { AutentizaceService } from '../../../../service/bezpecnost/autentizace.service';
import { Router } from '@angular/router';
var VytvoritObjednavkuOperatoraComponent = /** @class */ (function () {
    function VytvoritObjednavkuOperatoraComponent(autentizaceService, router) {
        this.autentizaceService = autentizaceService;
        this.router = router;
    }
    VytvoritObjednavkuOperatoraComponent.prototype.ngOnInit = function () {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        }
        else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate(["/"]);
        }
    };
    return VytvoritObjednavkuOperatoraComponent;
}());
export { VytvoritObjednavkuOperatoraComponent };
