import { ElementRef } from '@angular/core';
import { SeznamUzivateluSDodavatelemNastaveniService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import { SeznamUzivateluSDodavatelemService } from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { NastrojeCisla } from '../../../../../../shared/nastroje/nastroje-cisla';
import { NastrojeStrankovani } from '../../../../../../shared/nastroje/nastroje-strankovani';
var SeznamUzivateluSDodavatelemAktualniStrankaComponent = /** @class */ (function () {
    function SeznamUzivateluSDodavatelemAktualniStrankaComponent(seznamUzivateluSDodavatelemNastaveniService, seznamUzivateluSDodavatelemService) {
        this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
    }
    SeznamUzivateluSDodavatelemAktualniStrankaComponent.prototype.pripravitAktualniStranku = function () {
        return this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamUzivateluSDodavatelemAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        var nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    };
    SeznamUzivateluSDodavatelemAktualniStrankaComponent.prototype.nastavitStranku = function () {
        var element = $(this.vstupniPole.nativeElement);
        var hodnota = element.val();
        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (hodnota <= 0) {
            hodnota = 1;
        }
        else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }
        element.blur();
        element.val(hodnota);
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    };
    SeznamUzivateluSDodavatelemAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranekProZobrazeni = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }
        return this.maximalniPocetStranekProZobrazeni;
    };
    SeznamUzivateluSDodavatelemAktualniStrankaComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    };
    return SeznamUzivateluSDodavatelemAktualniStrankaComponent;
}());
export { SeznamUzivateluSDodavatelemAktualniStrankaComponent };
