import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {KrokObjednavky} from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import {VytvoritObjednavkuService} from '../../../../../service/e-shop/vytvorit-objednavku.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {UcelSeznamu} from '../../../../../shared/seznam/enum/ucel-seznamu.enum';
import {Notifikace, Uroven} from '../../../../../shared/notifikace';

@Component({
    selector: 'app-zvolit-uzivatele',
    templateUrl: './zvolit-uzivatele.component.html',
    styleUrls: ['./zvolit-uzivatele.component.scss']
})
export class ZvolitUzivateleComponent implements OnInit {

    @Output() prejitNaKartu = new EventEmitter<string>();

    public readonly ucelSeznamu = UcelSeznamu;

    constructor(
        private router: Router,
        private vytvoritObjednavkuService: VytvoritObjednavkuService,
        private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService,
        private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService
    ) {
    }

    ngOnInit() {
        this.seznamUzivateluSDodavatelemNastaveniService.inicializovat();
        this.seznamUzivateluSDodavatelemService.aktualizovat(false);
    }

    odeslat() {
        if (this.vytvoritObjednavkuService.jeUzivatelVybran()) {
            this.nasledujiciKarta();
        } else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'formular.validace.nevybranyUzivatel');
        }
    }

    public presmerovatPriZruseni() {
        this.router.navigate(['/sekce-operatora']);
    }

    private nasledujiciKarta() {
        this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_PRODUKT);
    }
}
