import {Input} from '@angular/core';

export class AbstraktniVystupFormularovyPrvekComponent {

    @Input() styl: string;

    @Input() popisek: string;

    @Input() hodnota: any;

    public idPrvku: string;
}
