import enJson from '../../assets/lokalizace/en.json';
import czJson from '../../assets/lokalizace/cz.json';

import {NastrojeObecne} from './nastroje/nastroje-obecne';

export class Lokalizace {

    public static data = Lokalizace.vybratJazykovouMutaci(localStorage.getItem('jazykova-mutace'));

    constructor() {
    }

    public static pripravitText(klicTextu: string): string {
        return this.data && this.data[klicTextu] ? this.data[klicTextu] : klicTextu;
    }

    public static formatovatText(klicTextu: string, ...parametry: any[]): string {
        return NastrojeObecne.formatovatRetezec(Lokalizace.pripravitText(klicTextu), ...parametry);
    }

    public static vybratJazykovouMutaci(jazykovaMutace: string): Object {
        switch (jazykovaMutace) {
            case 'en':
                return (<any>enJson);
            case 'cs':
                return (<any>czJson);
            default:
                return (<any>czJson);
        }
    }
}
