import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Platba} from '../../../data/e-shop/platba';
import {NastrojeUrl} from '../../../shared/nastroje/nastroje-url';
import {ZpusobPlatby} from '../../../data/e-shop/enum/zpusob-platby.enum';
import {StavPlatby} from '../../../data/e-shop/enum/stav-platby.enum';
import {Lokalizace} from '../../../shared/lokalizace';
import {StavOpakovanePlatby} from '../../../data/e-shop/enum/stav-opakovane-platby.enum';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';
import {OnlinePlatbyService} from '../../../service/e-shop/online-platby.service';
import {Notifikace, Uroven} from '../../../shared/notifikace';
import {prostredi} from '../../../../prostredi/prostredi';
import {NastrojeModalnihoPanelu} from '../../../shared/nastroje/nastroje-modalniho-panelu';
import {TypPredplatneho} from '../../../data/e-shop/enum/predplatne.enum';

@Component({
    selector: 'app-udaje-o-platbe',
    templateUrl: './udaje-o-platbe.component.html',
    styleUrls: ['./udaje-o-platbe.component.scss']
})
export class UdajeOPlatbeComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    @Input() platba: Platba;

    public readonly formatData = NastrojeDataACasu.FORMAT_DATA;
    public readonly formatDataACasuNaMinuty = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;
    public readonly stavyOpakovanePlatby = StavOpakovanePlatby;
    public readonly stavyPlatby = StavPlatby;
    public readonly upresnujiTextODaniKCastce = Lokalizace.pripravitText('cena.vcetneDPH');
    public readonly zpusobyPlatby = ZpusobPlatby;

    public zobrazitPotvrzeni = false;
    public zobrazitVrtitko = false;

    public readonly platbyAObjednavkyUrl: string = prostredi.url + '/#/e-shop/platby-a-objednavky';


    constructor(private onlinePlatbyService: OnlinePlatbyService) {
    }

    ngOnInit() {
        this.inicializovatModalniPanel();
    }

    public otevritPlatebniBranu(): void {
        NastrojeUrl.otevritOdkaz(this.platba.urlPlatebniBrany);
    }

    public potvrditZruseniOpakovaniPlatby(): void {
        this.zobrazitPotvrzeni = true;
    }

    public skrytModalniPanel() {
        this.zobrazitPotvrzeni = false;
    }

    public zrusitOpakovaniPlatby(): void {
        this.zobrazitPotvrzeni = false;
        this.zobrazitVrtitko = true;

        this.onlinePlatbyService.zrusitOpakovanePlatby(this.platba.idPlatby)
            .toPromise()
            .then(() => {
                this.zobrazitVrtitko = false;
                Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'platba.udajeKOpakovani.zrusit.uspech');
            }).catch(() => this.zobrazitVrtitko = false);
    }

    public zobrazitZrusitOpakovaniPlatby(): boolean {
        return this.platba.nastaveniOpakovanePlatby.stavOpakovanePlatby === StavOpakovanePlatby.ZAHAJENA || (this.platba.nastaveniOpakovanePlatby.stavOpakovanePlatby === StavOpakovanePlatby.VYZADANA && this.platba.stav === StavPlatby.ZAPLACENA);
    }

    public zobrazitInformaceOOpakovaniPlateb(): boolean {
        return this.platba.typPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU && this.platba.nastaveniOpakovanePlatby && this.platba.stav === StavPlatby.ZAPLACENA;
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }
}
