import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutentizaceService } from '../../service/bezpecnost/autentizace.service';
var OperatorComponent = /** @class */ (function () {
    function OperatorComponent(autentizaceService, router) {
        this.autentizaceService = autentizaceService;
        this.router = router;
    }
    OperatorComponent.prototype.ngOnInit = function () {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        }
        else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate(["/"]);
        }
    };
    OperatorComponent.prototype.presmerovatNaSekciOperatora = function () {
        this.router.navigate(['/sekce-operatora']);
    };
    OperatorComponent.prototype.presmerovatNaSekciSlevoveKupony = function () {
        this.router.navigate(['/slevove-kupony']);
    };
    return OperatorComponent;
}());
export { OperatorComponent };
