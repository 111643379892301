import { NastrojeJsonu } from './nastroje-jsonu';
var NastrojeObecne = /** @class */ (function () {
    function NastrojeObecne() {
    }
    NastrojeObecne.jeHodnotaPrazdna = function (hodnota) {
        return hodnota === null || hodnota === undefined || hodnota === '';
    };
    NastrojeObecne.formatovatRetezec = function (hodnota) {
        var parametry = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parametry[_i - 1] = arguments[_i];
        }
        return hodnota.replace(/{(\d+)}/g, function (prvek, poradi) {
            return typeof parametry[poradi] !== 'undefined' ? parametry[poradi] : prvek;
        });
    };
    NastrojeObecne.vygenerovatNahodnyRetezec = function (pozadovanaDelka) {
        if (pozadovanaDelka === void 0) { pozadovanaDelka = 32; }
        var povoleneZnaky = 'abcdefghijklmnopqrstuvwxyz'; // Pouze pismena, jelikoz prvni znak hodnoty html atributu nemuze byt cislo
        var vysledek = '';
        for (var i = 0; i < pozadovanaDelka; i++) {
            vysledek += povoleneZnaky.charAt(Math.floor(Math.random() * povoleneZnaky.length));
        }
        return vysledek;
    };
    NastrojeObecne.vytvoritHlubokouKopii = function (data) {
        return NastrojeJsonu.parse(NastrojeJsonu.stringify(data));
    };
    return NastrojeObecne;
}());
export { NastrojeObecne };
