import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

declare var $: any;
declare var M: any;

/**
 * Usage:
 * <app-formular-textove-pole
 *  [popisek]="'Popisek'"
 *  [odeslan]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>">
 * </app-formular-textove-pole>
 */
@Component({
    selector: 'app-formular-textove-pole',
    templateUrl: './formular-textove-pole.component.html',
    styleUrls: ['./formular-textove-pole.component.scss']
})
export class FormularTextovePoleComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

    @ViewChild('textovePole', {static: false}) textovePole: ElementRef;

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.nastavitPosluchaceZmenyHodnotyTextovehoPole();
    }

    private nastavitPosluchaceZmenyHodnotyTextovehoPole() {
        this.ovladacFormulare.valueChanges.subscribe(() => {
            M.textareaAutoResize($(this.textovePole.nativeElement));
        });
    }
}
