import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

/**
 * Pouziti:
 *  <app-formular-input
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 *  [typ]="<typ vstupu>"
 * </app-formular-input>
 */
@Component({
    selector: 'app-formular-input',
    templateUrl: './formular-input.component.html',
    styleUrls: ['./formular-input.component.scss']
})
export class FormularInputComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

    @Input() typ = 'text';

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
