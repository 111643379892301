var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { prostredi } from '../../../prostredi/prostredi';
import { NastrojeRestu } from '../../shared/nastroje/nastroje-restu';
import { NastrojeUrl } from '../../shared/nastroje/nastroje-url';
var AbstraktniService = /** @class */ (function () {
    function AbstraktniService(http, autentizaceService) {
        this.http = http;
        this.autentizaceService = autentizaceService;
        this.url = prostredi.cddAdministraceBackendUrl;
        this.hlavicky = new HttpHeaders(prostredi.hlavickyBackend);
        this.hlavickySOverride = new HttpHeaders(prostredi.hlavickyBackendSGetOverride);
    }
    AbstraktniService.prototype.pozadavekSTokenem = function (typPozadavku, cesta, teloPozadavku, parametry) {
        return this.pozadavek(typPozadavku, true, false, teloPozadavku, cesta, parametry);
    };
    AbstraktniService.prototype.pozadavekBezTokenu = function (typPozadavku, cesta, teloPozadavku, parametry) {
        return this.pozadavek(typPozadavku, false, false, teloPozadavku, cesta, parametry);
    };
    AbstraktniService.prototype.pozadavekStahnoutSoubor = function (cesta, vlozitTokenDoHlavicky) {
        if (this.jeVyzadovanaAutentizace(vlozitTokenDoHlavicky)) {
            return this.presmerovatNaPrihlasovaciStranku();
        }
        return this.http.get(NastrojeUrl.pripravitUrl(this.url + this.resourceClassUri, cesta), { 'headers': vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky() : null, 'responseType': 'blob' });
    };
    AbstraktniService.prototype.pozadavek = function (typPozadavku, vlozitTokenDoHlavicky, hlavickySOverride, teloPozadavku, cesta, parametry) {
        if (this.jeVyzadovanaAutentizace(vlozitTokenDoHlavicky)) {
            return this.presmerovatNaPrihlasovaciStranku();
        }
        var urlPozadavku = NastrojeUrl.pripravitUrl(this.url + this.resourceClassUri, cesta, parametry);
        return this.pripravitPozadavek(typPozadavku, urlPozadavku, vlozitTokenDoHlavicky, hlavickySOverride, teloPozadavku);
    };
    AbstraktniService.prototype.pripravitPozadavek = function (typPozadavku, urlPozadavku, vlozitTokenDoHlavicky, hlavickySOverride, teloPozadavku) {
        var hlavicky = this.pripravitHlavicky(vlozitTokenDoHlavicky, hlavickySOverride);
        switch (typPozadavku) {
            case TypPozadavku.GET:
                return this.http.get(urlPozadavku, hlavicky);
            case TypPozadavku.HEAD:
                return this.http.head(urlPozadavku, hlavicky);
            case TypPozadavku.PATCH:
                return this.http.patch(urlPozadavku, teloPozadavku, hlavicky);
            case TypPozadavku.POST:
                return this.http.post(urlPozadavku, teloPozadavku, hlavicky);
            case TypPozadavku.PUT:
                return this.http.put(urlPozadavku, teloPozadavku, hlavicky);
            default:
                return null;
        }
    };
    AbstraktniService.prototype.pripravitHlavicky = function (vlozitTokenDoHlavicky, hlavickySOverride) {
        if (hlavickySOverride === void 0) { hlavickySOverride = false; }
        return hlavickySOverride ? this.pripravitHlavickySOverride(vlozitTokenDoHlavicky) : this.pripravitHlavickyBezOverride(vlozitTokenDoHlavicky);
    };
    AbstraktniService.prototype.pripravitHlavickyBezOverride = function (vlozitTokenDoHlavicky) {
        if (vlozitTokenDoHlavicky === void 0) { vlozitTokenDoHlavicky = false; }
        return {
            headers: vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky(this.hlavicky) : this.hlavicky
        };
    };
    AbstraktniService.prototype.pripravitHlavickySOverride = function (vlozitTokenDoHlavicky) {
        if (vlozitTokenDoHlavicky === void 0) { vlozitTokenDoHlavicky = false; }
        return {
            headers: vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky(this.hlavickySOverride) : this.hlavickySOverride,
            observe: 'response',
            responseType: 'json'
        };
    };
    AbstraktniService.prototype.jeVyzadovanaAutentizace = function (vlozitTokenDoHlavicky) {
        return vlozitTokenDoHlavicky && !this.autentizaceService.jeUzivatelPrihlaseny();
    };
    AbstraktniService.prototype.presmerovatNaPrihlasovaciStranku = function () {
        var _this = this;
        return Observable.fromPromise(new Promise(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze()) {
                    this.autentizaceService.prihlasitAdministratora();
                }
                else if (this.autentizaceService.jePrihlasenyUzivatelOperator()) {
                    this.autentizaceService.prihlasitOperatora();
                }
                else {
                    this.autentizaceService.prihlasitCddUzivatele();
                }
                return [2 /*return*/];
            });
        }); }));
    };
    AbstraktniService.prototype.zpracovatChybu = function (error) {
        return NastrojeRestu.zpracovatChybu(error);
    };
    return AbstraktniService;
}());
export { AbstraktniService };
export var TypPozadavku;
(function (TypPozadavku) {
    TypPozadavku[TypPozadavku["GET"] = 0] = "GET";
    TypPozadavku[TypPozadavku["HEAD"] = 1] = "HEAD";
    TypPozadavku[TypPozadavku["PATCH"] = 2] = "PATCH";
    TypPozadavku[TypPozadavku["POST"] = 3] = "POST";
    TypPozadavku[TypPozadavku["PUT"] = 4] = "PUT";
})(TypPozadavku || (TypPozadavku = {}));
