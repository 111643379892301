import {Injectable} from '@angular/core';
import {Verze} from '../../data/obecne/verze';
import {AbstraktniService, TypPozadavku} from './abstraktni-service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';

@Injectable()
export class VerzeBackenduService extends AbstraktniService {

    protected resourceClassUri = '/verze';

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService) {
        super(http, autentizaceService);
    }

    public pripravitCisloVerzeBackendu(): Observable<Verze> {
        return this.pozadavekBezTokenu(TypPozadavku.GET)
            .catch(this.zpracovatChybu);
    }
}
