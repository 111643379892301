var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { SeznamUzivateluSDodavatelem } from '../../../data/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem';
import { NastrojeJsonu } from '../../../shared/nastroje/nastroje-jsonu';
import { AutentizaceService } from '../../bezpecnost/autentizace.service';
import { TypPozadavku } from '../../obecne/abstraktni-service';
import { AbstraktniSeznamService } from '../abstraktni-seznam-service';
import { SeznamUzivateluSDodavatelemNastaveniService } from './seznam-uzivatelu-s-dodavatelem-nastaveni.service';
var SeznamUzivateluSDodavatelemService = /** @class */ (function (_super) {
    __extends(SeznamUzivateluSDodavatelemService, _super);
    function SeznamUzivateluSDodavatelemService(http, autentizaceService, seznamUzivateluSDodavatelemNastaveniService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.seznamUzivateluSDodavatelemNastaveniService = seznamUzivateluSDodavatelemNastaveniService;
        _this.resourceClassUri = '/uzivatele';
        return _this;
    }
    SeznamUzivateluSDodavatelemService.prototype.aktualizovat = function (odrolovatNaZacatekSeznamu) {
        var _this = this;
        if (odrolovatNaZacatekSeznamu === void 0) { odrolovatNaZacatekSeznamu = true; }
        this.seznam = new SeznamUzivateluSDodavatelem();
        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-s-dodavatelem', NastrojeJsonu.stringify(this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(function (odpoved) {
            _this.seznam = odpoved;
        }).catch(this.zpracovatChybu);
        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    };
    return SeznamUzivateluSDodavatelemService;
}(AbstraktniSeznamService));
export { SeznamUzivateluSDodavatelemService };
