import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Lokalizace} from '../../../../lokalizace';
import {NastrojeDataACasu} from '../../../../nastroje/nastroje-data-a-casu';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

declare var $: any;
declare var M: any;

/**
 * Pouziti:
 *  <app-formular-kalendar
 *  [popisek]="'Popisek'"
 *  [odeslano]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>"
 *  [pomocnyText]="<pomocny_text>"
 *  [pomocnyTextSoucastiChyby]="<pomocnyTextSoucastiChyby>"
 * </app-formular-kalendar>
 */
@Component({
    selector: 'app-formular-kalendar',
    templateUrl: './formular-kalendar.component.html',
    styleUrls: ['./formular-kalendar.component.scss', './formular-kalendar.component.ie.scss']
})
export class FormularKalendarComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

    @ViewChild('tlacitkoKalendare', {static: false}) tlacitkoKalendare: ElementRef;

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.inicializovatKalendar();
    }

    public nastavitHodnotuOvladaceFormulare(event) {
        const hodnota = $(event.target).val();

        this.ovladacFormulare.setValue(hodnota);
    }

    public otevritKalendarAVybratDatum() {
        this.otevritKalendar();

        if (NastrojeObecne.jeHodnotaPrazdna(this.ovladacFormulare.value)) {
            this.vybratDatumKalendare(NastrojeDataACasu.nastavitCasZacatekDne(new Date()));
        } else {
            this.vybratDatumKalendare(NastrojeDataACasu.prevestRetezecNaDatum(this.ovladacFormulare.value, NastrojeDataACasu.FORMAT_DATA));
        }
    }

    public otevritKalendar() {
        const instanceKalendare = this.pripravitInstanciKalendare();

        if (!instanceKalendare.isOpen) {
            instanceKalendare.open();
        }
    }

    private vybratDatumKalendare(datum: Date) {
        const instanceKalendare = this.pripravitInstanciKalendare();

        instanceKalendare.setDate(datum);
    }

    private inicializovatKalendar() {
        const nazvyMesicu = this.pripravitNazvyMesicu();
        const nazvyDnu = this.pripravitNazvyDnu();

        $(document).ready(function () {
            $('.datepicker').datepicker({
                format: NastrojeDataACasu.FORMULAR_KALENDAR_FORMAT_DATA,
                firstDay: 1,
                yearRange: 100,
                i18n: {
                    cancel: Lokalizace.pripravitText('zavrit'),
                    done: Lokalizace.pripravitText('potvrdit'),
                    clear: Lokalizace.pripravitText('odstranit'),
                    months: nazvyMesicu,
                    monthsShort: nazvyMesicu,
                    weekdaysShort: nazvyDnu,
                    weekdaysAbbrev: nazvyDnu,
                },
                showClearBtn: true,
                onOpen: function () {
                    this.options.onSelect(this);
                },
                onSelect: function (context) {
                    const kalendar = context instanceof Date ? this : context;
                    const elementTextuData = $(kalendar.dateTextEl);

                    if (!(context instanceof Date) && elementTextuData.next().length === 0) {
                        const formatovacData = require('dateformat');
                        const formatData = kalendar.options.format;

                        elementTextuData.parent().append(elementTextuData.clone());
                        elementTextuData.next().text(formatovacData(new Date(), formatData));

                        elementTextuData.prev().hide();
                        elementTextuData.hide();
                    } else if (context instanceof Date) {
                        elementTextuData.next().text(kalendar.toString());
                    }
                }
            });
        });
    }

    private pripravitNazvyDnu(): string[] {
        return [
            Lokalizace.pripravitText('ne'),
            Lokalizace.pripravitText('po'),
            Lokalizace.pripravitText('ut'),
            Lokalizace.pripravitText('st'),
            Lokalizace.pripravitText('ct'),
            Lokalizace.pripravitText('pa'),
            Lokalizace.pripravitText('so')
        ];
    }

    private pripravitNazvyMesicu(): string[] {
        return [
            Lokalizace.pripravitText('leden'),
            Lokalizace.pripravitText('unor'),
            Lokalizace.pripravitText('brezen'),
            Lokalizace.pripravitText('duben'),
            Lokalizace.pripravitText('kveten'),
            Lokalizace.pripravitText('cerven'),
            Lokalizace.pripravitText('cervenec'),
            Lokalizace.pripravitText('srpen'),
            Lokalizace.pripravitText('zari'),
            Lokalizace.pripravitText('rijen'),
            Lokalizace.pripravitText('listopad'),
            Lokalizace.pripravitText('prosinec')
        ];
    }

    private pripravitInstanciKalendare() {
        const element = this.tlacitkoKalendare.nativeElement;

        return M.Datepicker.getInstance(element);
    }
}
