import {Injectable} from '@angular/core';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';
import {Polozka} from '../../data/obecne/polozka';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {Observable} from 'rxjs';
import {Produkt} from '../../data/e-shop/produkt';
import {prostredi} from '../../../prostredi/prostredi';

@Injectable()
export class ProduktService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService
    ) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopProdukty;
    }

    public nacistProdukt(nazevProduktu: string, vybranyTypPredplatneho: TypPredplatneho): Observable<Produkt> {
        const parametry = [new Polozka('id', nazevProduktu), new Polozka('typPredplatneho', vybranyTypPredplatneho.toString().toLocaleLowerCase())];

        return this.pozadavekSTokenem(TypPozadavku.GET, 'detail', null, parametry)
            .catch(this.zpracovatChybu);
    }

    public nacistSeznamProduktu(): Observable<Produkt[]> {
        return this.pozadavekSTokenem(TypPozadavku.GET, 'seznam')
            .catch(this.zpracovatChybu);
    }
}
