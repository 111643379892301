var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { AbstraktniFormularovyPrvekComponent } from '../abstraktni-formularovy-prvek.component';
var FormularInputSAkciComponent = /** @class */ (function (_super) {
    __extends(FormularInputSAkciComponent, _super);
    function FormularInputSAkciComponent() {
        var _this = _super.call(this) || this;
        _this.typ = 'text';
        _this.primarniAkce = new EventEmitter();
        _this.sekundarniAkce = new EventEmitter();
        return _this;
    }
    FormularInputSAkciComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    FormularInputSAkciComponent.prototype.vyvolatPrimarniAkci = function () {
        this.primarniAkce.emit();
    };
    FormularInputSAkciComponent.prototype.vyvolatSekundarniAkci = function () {
        this.sekundarniAkce.emit();
    };
    return FormularInputSAkciComponent;
}(AbstraktniFormularovyPrvekComponent));
export { FormularInputSAkciComponent };
