import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstraktniFormularComponent} from '../../../../../../../../shared/formular/abstraktni-formular.component';
import {TypPoctuZaznamuNaStranku} from '../../../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AutentizaceService} from '../../../../../../../../service/bezpecnost/autentizace.service';
import {SeznamObjednavekNastaveniService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import {SeznamObjednavekService} from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import {TypUceluFormulare} from '../../../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeEnumu} from '../../../../../../../../shared/nastroje/nastroje-enumu';
import {NastrojeModalnihoPanelu} from '../../../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import {NastrojeStrankovani} from '../../../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-objednavek-nastaveni',
    templateUrl: './seznam-objednavek-nastaveni.component.html',
    styleUrls: ['./seznam-objednavek-nastaveni.component.scss']
})
export class SeznamObjednavekNastaveniComponent extends AbstraktniFormularComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    public typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private seznamObjednavekNastaveniService: SeznamObjednavekNastaveniService,
        private seznamObjednavekService: SeznamObjednavekService
    ) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    }

    odeslat(): void {
        const pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamObjednavekNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(1);
        this.seznamObjednavekService.aktualizovat();
    }

    private inicializovatFormularovaData() {
        const pocetZaznamuNaStranku = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;

        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    public pripravitMaximalniPocetStranek() {
        const seznamObjednavek = this.seznamObjednavekService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    }
}
