import {Component, ElementRef, ViewChild} from '@angular/core';
import {NastrojeStrankovani} from '../../../../../../../shared/nastroje/nastroje-strankovani';
import {NastrojeCisla} from '../../../../../../../shared/nastroje/nastroje-cisla';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';

declare var $: any;

@Component({
    selector: 'app-seznam-objednavek-operatora-aktualni-stranka',
    templateUrl: './seznam-objednavek-operatora-aktualni-stranka.component.html',
    styleUrls: ['./seznam-objednavek-operatora-aktualni-stranka.component.scss']
})
export class SeznamObjednavekOperatoraAktualniStrankaComponent {

    @ViewChild('vstupniPole', {static: false}) public vstupniPole: ElementRef;

    private maximalniPocetStranekProZobrazeni: number;

    constructor(private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService, private seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService) {
    }

    public pripravitAktualniStranku(): number {
        return this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    public nastavitStranku() {
        const element = $(this.vstupniPole.nativeElement);
        let hodnota = element.val();

        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }

        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (hodnota <= 0) {
            hodnota = 1;
        } else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }

        element.blur();
        element.val(hodnota);
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamObjednavekOperatoraService.aktualizovat();
    }

    public pripravitMaximalniPocetStranekProZobrazeni() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }

        return this.maximalniPocetStranekProZobrazeni;
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    }

}
