var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstraktniFiltrComponent } from '../../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { SeznamObjednavekOperatoraService } from '../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AutentizaceService } from '../../../../../../service/bezpecnost/autentizace.service';
import { NastrojeDataACasu } from '../../../../../../shared/nastroje/nastroje-data-a-casu';
import { ValidatoryRozsahu } from '../../../../../../shared/formular/validator/validatory-rozsahu';
import { StavObjednavky } from '../../../../../../data/e-shop/enum/stav-objednavky.enum';
import { TypPredplatneho } from '../../../../../../data/e-shop/enum/predplatne.enum';
var SeznamObjednavekOperatoraFiltrRozsireneComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraFiltrRozsireneComponent, _super);
    function SeznamObjednavekOperatoraFiltrRozsireneComponent(formBuilder, autentizaceService, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        var _this = _super.call(this, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        _this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
        _this.stavObjednavky = StavObjednavky;
        _this.predplatne = TypPredplatneho;
        return _this;
    }
    SeznamObjednavekOperatoraFiltrRozsireneComponent.prototype.ngOnInit = function () {
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    };
    SeznamObjednavekOperatoraFiltrRozsireneComponent.prototype.odeslat = function () {
    };
    SeznamObjednavekOperatoraFiltrRozsireneComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('identifikator', new FormControl('', [
            Validators.maxLength(20)
        ]));
        this.formGroup.addControl('nazevProduktu', new FormControl('', [
            Validators.maxLength(255)
        ]));
        this.formGroup.addControl('datumVytvoreniRozsahOd', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));
        this.formGroup.addControl('datumVytvoreniRozsahDo', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));
        this.formGroup.addControl('predplatne', new FormControl('', []));
        this.formGroup.addControl('datumSplatnostiRozsahOd', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));
        this.formGroup.addControl('datumSplatnostiRozsahDo', new FormControl('', [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA)
        ]));
        this.formGroup.addControl('stavObjednavky', new FormControl('', []));
        this.formGroup.setValidators([
            ValidatoryRozsahu.rozsah('datumVytvoreniRozsahOd', 'datumVytvoreniRozsahDo', 'Date'),
            ValidatoryRozsahu.rozsah('datumSplatnostiRozsahOd', 'datumSplatnostiRozsahDo', 'Date')
        ]);
    };
    return SeznamObjednavekOperatoraFiltrRozsireneComponent;
}(AbstraktniFiltrComponent));
export { SeznamObjednavekOperatoraFiltrRozsireneComponent };
