import { prostredi } from '../../../prostredi/prostredi';
import { IdProduktu } from '../../data/e-shop/enum/id-produktu.enum';
import { TypPredplatneho } from '../../data/e-shop/enum/predplatne.enum';
var NastrojeGoogleAnalytics = /** @class */ (function () {
    function NastrojeGoogleAnalytics() {
    }
    NastrojeGoogleAnalytics.pripravitGAIdProduktu = function (id, typPredplatneho) {
        var zakladIdProduktu = id.trim().toUpperCase().replace(" ", "_");
        switch (typPredplatneho) {
            case TypPredplatneho.MESICNI_S_OBNOVOU:
                return zakladIdProduktu + '_' + 'MONTH';
            case TypPredplatneho.ROCNI:
                return zakladIdProduktu + '_' + 'YEAR';
            default:
                return zakladIdProduktu;
        }
    };
    NastrojeGoogleAnalytics.pripravitGANazevProduktu = function (id, typPredplatneho) {
        var zakladNazvuProduktu = id.trim().toUpperCase();
        switch (typPredplatneho) {
            case TypPredplatneho.MESICNI_S_OBNOVOU:
                return zakladNazvuProduktu + " " + 'měsíční';
            case TypPredplatneho.ROCNI:
                return zakladNazvuProduktu + " " + 'roční';
            default:
                return zakladNazvuProduktu;
        }
    };
    NastrojeGoogleAnalytics.pripravitGAPoradiProduktuVSeznamu = function (id) {
        switch (id) {
            case IdProduktu.PROFI:
                return 1;
            case IdProduktu.PREMIUM:
                return 2;
            default:
                return 0;
        }
    };
    NastrojeGoogleAnalytics.addToCart = 'add_to_cart';
    NastrojeGoogleAnalytics.beginCheckout = 'begin_checkout';
    NastrojeGoogleAnalytics.checkoutProgress = 'checkout_progress';
    NastrojeGoogleAnalytics.purchase = 'purchase';
    NastrojeGoogleAnalytics.removeFromCart = 'remove_from_cart';
    NastrojeGoogleAnalytics.setCheckoutOption = 'set_checkout_option';
    NastrojeGoogleAnalytics.zasilatDataDoGA = prostredi.verze === 'Produkční';
    return NastrojeGoogleAnalytics;
}());
export { NastrojeGoogleAnalytics };
