import { NastrojeAutentizace } from '../../shared/nastroje/nastroje-autentizace';
var AbstraktniOpravneniComponent = /** @class */ (function () {
    function AbstraktniOpravneniComponent(autentizaceService) {
        this.autentizaceService = autentizaceService;
        if (autentizaceService.jeUzivatelPrihlaseny() && !this.getInformaceOPrihlasenemUzivateli() && !this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze() && !this.autentizaceService.jePrihlasenyUzivatelOperator()) {
            this.autentizaceService.aktualizovatInformaceOUzivateliDoLocalStorage();
        }
    }
    AbstraktniOpravneniComponent.prototype.getInformaceOPrihlasenemUzivateli = function () {
        return NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage();
    };
    AbstraktniOpravneniComponent.prototype.opravneniAdministraceJakoSpravceDatabaze = function () {
        return this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze();
    };
    AbstraktniOpravneniComponent.prototype.opravneniObsluhovatEshopJakoOperator = function () {
        return this.autentizaceService.jePrihlasenyUzivatelOperator();
    };
    AbstraktniOpravneniComponent.prototype.jeUzivatelPrihlaseny = function (uzivatel) {
        return this.getInformaceOPrihlasenemUzivateli().informaceOUzivateli.id === uzivatel.id;
    };
    return AbstraktniOpravneniComponent;
}());
export { AbstraktniOpravneniComponent };
