import { ElementRef } from '@angular/core';
var PouzeCisliceDirective = /** @class */ (function () {
    function PouzeCisliceDirective(Element) {
    }
    PouzeCisliceDirective.prototype.inputChanged = function (event) {
        var element = $(event.target);
        var puvodniHodnota = element.val();
        var ciselnaHodnota = puvodniHodnota.replace(/[^0-9]/g, '');
        if (puvodniHodnota !== ciselnaHodnota) {
            element.val(ciselnaHodnota);
        }
    };
    return PouzeCisliceDirective;
}());
export { PouzeCisliceDirective };
