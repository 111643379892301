var NastrojePorovnani = /** @class */ (function () {
    function NastrojePorovnani() {
    }
    NastrojePorovnani.porovnatRetezec = function (prvniRetezec, druhyRetezec) {
        if (prvniRetezec < druhyRetezec) {
            return -1;
        }
        if (prvniRetezec > druhyRetezec) {
            return 1;
        }
        return 0;
    };
    NastrojePorovnani.porovnatDatum = function (prvniDatum, druheDatum) {
        if (prvniDatum.getTime() < druheDatum.getTime()) {
            return -1;
        }
        if (prvniDatum.getTime() > druheDatum.getTime()) {
            return 1;
        }
        return 0;
    };
    return NastrojePorovnani;
}());
export { NastrojePorovnani };
