var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniService } from '../obecne/abstraktni-service';
import { NastrojeOdrolovani } from '../../shared/nastroje/nastroje-odrolovani';
var AbstraktniSeznamService = /** @class */ (function (_super) {
    __extends(AbstraktniSeznamService, _super);
    function AbstraktniSeznamService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.idElementuProOdrolovani = 'formular-razeni';
        return _this;
    }
    AbstraktniSeznamService.prototype.odrolovatNaZacatekSeznamu = function () {
        NastrojeOdrolovani.odrolovat(this.idElementuProOdrolovani);
    };
    return AbstraktniSeznamService;
}(AbstraktniService));
export { AbstraktniSeznamService };
