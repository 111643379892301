import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NastrojeOptimalizaceProVyhledavace} from '../../shared/nastroje/nastroje-optimalizace-pro-vyhledavace';
import {prostredi} from '../../../prostredi/prostredi';

@Injectable()
export class OptimalizaceProVyhledavaceService {
    constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected title: Title,
        private metadata: Meta
    ) {
    }

    public optimalizovatStrankuProVyhledavace(): void {
        this.pridatStatickeInformaceDoHlavickyStranky();
        this.pridatDynamickeInformaceDoHlavickyStranky();
    }

    public pridatTitulek(titulek: string): void {
        NastrojeOptimalizaceProVyhledavace.pridatTitulek(titulek, this.title);
    }

    private pridatStatickeInformaceDoHlavickyStranky(): void {
        NastrojeOptimalizaceProVyhledavace.pridatAutora(this.metadata);
        NastrojeOptimalizaceProVyhledavace.pridatPovoleniProIndexaciRoboty(this.metadata);
    }

    private pridatDynamickeInformaceDoHlavickyStranky(): void {
        this.router.events.subscribe(udalost => {
            if (udalost instanceof NavigationEnd) {
                let prvek = this.activatedRoute.firstChild;

                while (prvek) {
                    if (prvek.firstChild) {
                        prvek = prvek.firstChild;
                    } else {
                        this.zpracovatData(prvek);
                        this.zaznamenatZobrazeniStranky(udalost.urlAfterRedirects);

                        return;
                    }
                }
            }
        });
    }

    private zpracovatData(prvek: ActivatedRoute) {
        if (prvek.snapshot.data) {
            this.pridatTitulek(prvek.snapshot.data['titulek']);
            NastrojeOptimalizaceProVyhledavace.pridatPopis(prvek.snapshot.data['popis'], this.metadata);
            NastrojeOptimalizaceProVyhledavace.pridatKlicovaSlova(prvek.snapshot.data['klicovaSlova'], this.metadata);
        }
    }

    private zaznamenatZobrazeniStranky(url: String) {
        if (prostredi.verze === 'Produkční') {
            (<any>window).ga('set', 'page', url);
            (<any>window).ga('send', 'pageview');
        }
    }
}
