var ValidacniZprava = /** @class */ (function () {
    function ValidacniZprava(klic) {
        var hodnoty = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            hodnoty[_i - 1] = arguments[_i];
        }
        this.klic = klic;
        this.hodnoty = hodnoty;
    }
    return ValidacniZprava;
}());
export { ValidacniZprava };
