import {Injectable} from '@angular/core';
import {PoradoveCisloKrokuObjednavky} from '../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import {Objednavka} from '../../data/e-shop/objednavka';
import {NastrojeGoogleAnalytics} from '../../shared/nastroje/nastroje-google-analytics';
import {NastrojeObecne} from '../../shared/nastroje/nastroje-obecne';

declare var gtag: Function;

@Injectable()
export class GoogleAnalyticsTrackingService {

    private readonly nazevSeznamu = 'Ceník';
    private readonly pocetProduktu = 1;

    constructor() {
    }

    public pridatConfig(userId: string) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('config', 'G-5G52QV1Z8L', {
                'user_id': userId,
                'send_page_view': false
            });
        }
    }

    public evidovatPridaniZboziDoKosiku(objednavka: Objednavka): void {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.addToCart, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    }

    public evidovatZapocetiCheckoutu(objednavka: Objednavka): void {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.beginCheckout, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    }

    public evidovatCheckoutProgress(objednavka: Objednavka, poradoveCisloKrokuObjednavky: PoradoveCisloKrokuObjednavky): void {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.checkoutProgress, {
                "checkout_step": poradoveCisloKrokuObjednavky,
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    }

    public evidovatCheckoutOption(objednavka: Objednavka, poradoveCisloKrokuObjednavky: PoradoveCisloKrokuObjednavky) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.setCheckoutOption, {
                'checkout_step': poradoveCisloKrokuObjednavky,
                'checkout_option': 'Způsob platby',
                'value': objednavka.zpusobPlatby
            });
        }
    }

    public evidovatPurchase(objednavka: Objednavka): void {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            const dan = objednavka.cena.cenaSDph - objednavka.cena.cenaBezDph;
            const eventContent = {
                'transaction_id': objednavka.identifikator,
                'affiliation': 'FEN eShop/PoDo',
                'value': objednavka.cena.cenaSDph,
                'currency': 'CZK',
                'tax': dan < 0 ? 0 : dan,
                'shipping': 0,
                'items': [
                    this.pripravitItem(objednavka)
                ]
            };

            gtag('event', NastrojeGoogleAnalytics.purchase, eventContent);
        }
    }

    public evidovatZruseni(objednavka: Objednavka) {
        if (NastrojeGoogleAnalytics.zasilatDataDoGA) {
            gtag('event', NastrojeGoogleAnalytics.removeFromCart, {
                'items': [
                    this.pripravitItem(objednavka)
                ]
            });
        }
    }

    private pripravitItem(objednavka: Objednavka): any {
        return {
            'id': NastrojeGoogleAnalytics.pripravitGAIdProduktu(objednavka.nazevProduktu, objednavka.typPredplatneho),
            'name': NastrojeGoogleAnalytics.pripravitGANazevProduktu(objednavka.nazevProduktu, objednavka.typPredplatneho),
            'list_name': this.nazevSeznamu,
            'list_position': NastrojeGoogleAnalytics.pripravitGAPoradiProduktuVSeznamu(objednavka.nazevProduktu),
            'quantity': this.pocetProduktu,
            'price': NastrojeObecne.jeHodnotaPrazdna(objednavka.cena) ? '' : objednavka.cena.cenaSDph
        };
    }
}
