var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { SeznamObjednavekOperatoraNastaveniService } from '../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import { NastrojeWizardu } from '../../../../../shared/nastroje/nastroje-wizardu';
import { AbstraktniFiltrComponent } from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import { FormBuilder } from '@angular/forms';
import { SeznamObjednavekOperatoraService } from '../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeFiltrace } from '../../../../../shared/nastroje/nastroje-filtrace';
import { FiltrSeznamuObjednavekOperatora } from '../../../../../data/seznam/seznam-objednavek-operatora/filtr/filtr-seznamu-objednavek-operatora';
var SeznamObjednavekOperatoraFiltrComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekOperatoraFiltrComponent, _super);
    function SeznamObjednavekOperatoraFiltrComponent(formBuilder, autentizaceService, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService) {
        var _this = _super.call(this, seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamObjednavekOperatoraNastaveniService = seznamObjednavekOperatoraNastaveniService;
        _this.seznamObjednavekOperatoraService = seznamObjednavekOperatoraService;
        return _this;
    }
    SeznamObjednavekOperatoraFiltrComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
    };
    SeznamObjednavekOperatoraFiltrComponent.prototype.ngAfterViewInit = function () {
        this.inicializovatWizard();
    };
    SeznamObjednavekOperatoraFiltrComponent.prototype.odeslat = function () {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuObjednavekOperatora(), this.formGroup));
    };
    SeznamObjednavekOperatoraFiltrComponent.prototype.inicializovatWizard = function () {
        NastrojeWizardu.inicializovatWizard(this.wizard.nativeElement);
    };
    return SeznamObjednavekOperatoraFiltrComponent;
}(AbstraktniFiltrComponent));
export { SeznamObjednavekOperatoraFiltrComponent };
