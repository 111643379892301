var NastrojeWizardu = /** @class */ (function () {
    function NastrojeWizardu() {
    }
    NastrojeWizardu.inicializovatWizard = function (element) {
        M.Tabs.init(element);
    };
    NastrojeWizardu.prejitNaKartu = function (idKarty) {
        $('.tab a').filter('a[href$="' + idKarty + '"]').click(function () {
            this.click();
        }).click();
    };
    return NastrojeWizardu;
}());
export { NastrojeWizardu };
