export enum StavObjednavky {
    VYTVORENA_ZAKAZNIKEM = 'VYTVORENA_ZAKAZNIKEM',
    VYTVORENA_OPERATOREM = 'VYTVORENA_OPERATOREM',
    ZAPLACENA_KARTOU = 'ZAPLACENA_KARTOU',
    ZAPLACENA_PREVODEM = 'ZAPLACENA_PREVODEM',
    CEKA_NA_ZAPLACENI = 'CEKA_NA_ZAPLACENI',
    STORNOVANA = 'STORNOVANA',
    SELHALA = 'SELHALA',
    NEZAPLACENA = 'NEZAPLACENA'
}
