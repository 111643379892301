import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {ResponsiveModule} from 'ng2-responsive';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {DomuComponent} from './obecne/domu/domu.component';
import {OrganizacniStrukturaModule} from './organizacni-struktura/organizacni-struktura.module';
import {AutentizaceService} from './service/bezpecnost/autentizace.service';
import {initializer} from './service/bezpecnost/keycloak-init';
import {GoogleAnalyticsTrackingService} from './service/obecne/google-analytics-tracking.service';
import {VerzeBackenduService} from './service/obecne/verze-backendu.service';
import {AbstraktniFormularModule} from './shared/formular/abstraktni-formular-module';
import {HlavniMenuComponent} from './shared/layout/hlavni-menu/hlavni-menu.component';
import {MobilniZobrazeniHlavnihoMenuComponent} from './shared/layout/hlavni-menu/typ-zobrazeni-hlavniho-menu/mobilni-zobrazeni-hlavniho-menu/mobilni-zobrazeni-hlavniho-menu.component';
import {StandardniZobrazeniHlavnihoMenuComponent} from './shared/layout/hlavni-menu/typ-zobrazeni-hlavniho-menu/standardni-zobrazeni-hlavniho-menu/standardni-zobrazeni-hlavniho-menu.component';
import {PatickaComponent} from './shared/layout/paticka/paticka.component';
import {VrtitkoModule} from './shared/layout/vrtitko/vrtitko.module';
import {NastrojeModule} from './shared/nastroje/nastroje.module';
import {OptimalizaceProVyhledavaceService} from './service/obecne/optimalizace-pro-vyhledavace.service';
import {EShopModule} from './e-shop/e-shop.module';
import {OperatorComponent} from './obecne/operator/operator.component';

registerLocaleData(localeCs);

@NgModule({
    declarations: [
        AppComponent,
        HlavniMenuComponent,
        StandardniZobrazeniHlavnihoMenuComponent,
        MobilniZobrazeniHlavnihoMenuComponent,
        PatickaComponent,
        DomuComponent,
        OperatorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ResponsiveModule,
        HttpClientModule,
        NastrojeModule,
        CommonModule,
        ReactiveFormsModule,
        AbstraktniFormularModule,
        VrtitkoModule,
        OrganizacniStrukturaModule,
        KeycloakAngularModule,
        EShopModule
    ],
    providers: [
        AutentizaceService,
        VerzeBackenduService,
        OptimalizaceProVyhledavaceService,
        GoogleAnalyticsTrackingService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy // HashLocationStrategy (# on/off)
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService]
        }],

    bootstrap: [AppComponent]
})

export class AppModule {
}
