import {FakturacniAdresa} from './fakturacni-adresa';
import {Cena} from './cena';
import {TypPredplatneho} from './enum/predplatne.enum';
import {ZpusobPlatby} from './enum/zpusob-platby.enum';
import {StavObjednavky} from './enum/stav-objednavky.enum';
import {Zdroj} from './enum/zdroj.enum';
import {ZruseniObjednavky} from './zruseni-objednavky';

export class Objednavka {
	identifikator: string;
	idUzivatele: number;
	prihlasovaciJmeno: string;
	jmeno: string;
	prijmeni: string;
	nazevOrganizace: string;
	email: string;
	telefon: string;
	fakturacniAdresa: FakturacniAdresa;
	ic: string;
	dic: string;
	typPredplatneho: TypPredplatneho;
	idProduktu: number;
	nazevProduktu: string;
	zpusobPlatby: ZpusobPlatby;
	cena: Cena;
	stavObjednavky: StavObjednavky;
	zruseniObjednavky: ZruseniObjednavky;
	datumVytvoreni: string;
	datumPlatnostiPredplatnehoDo: string;
	datumSplatnosti: string;
	datumUskutecneniZdanitelnehoPlneni: string;
	idUzivateleOperatora: string;
	prihlasovaciJmenoOperatora: string;
	jmenoOperatora: string;
	prijmeniOperatora: string;
	poznamka: string;
	poznamkaNaFakturu: string;
	idPlatby?: number;
	idZakladajiciPlatby?: number;
	ucetniIdentifikatorProduktu: string;
	identifikatorProduktu: string;
	zdroj: Zdroj;
	nazevSlevovehoKuponu: string;
	slevaVProcentech: number;
	delkaPlatnostiPredplatneho: number;
}
