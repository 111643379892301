<div class="legend">
    <div class="legend__item">
        <i class="material-icons">center_focus_weak</i>
        <label>{{ 'uzivatel.id' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">swap_horiz</i>
        <label>{{ 'uzivatel.stav' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">person_outline</i>
        <label>{{ 'uzivatel.jmeno' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">business</i>
        <label>{{ 'uzivatel.dodavatel' | lokalizace }}</label>
    </div>
    <div class="legend__item">
        <i class="material-icons">account_balance</i>
        <label>{{ 'uzivatel.ico' | lokalizace }}</label>
    </div>
</div>
