var NastrojeBase64 = /** @class */ (function () {
    function NastrojeBase64() {
    }
    NastrojeBase64.dekodovatBase64NaUnicode = function (hodnota) {
        return decodeURIComponent(Array.prototype.map.call(atob(hodnota), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    };
    return NastrojeBase64;
}());
export { NastrojeBase64 };
