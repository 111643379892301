import {Component, ElementRef, ViewChild} from '@angular/core';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {NastrojeCisla} from '../../../../../../shared/nastroje/nastroje-cisla';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';

declare var $: any;

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-aktualni-stranka',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-aktualni-stranka.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-aktualni-stranka.component.scss']
})
export class SeznamUzivateluSDodavatelemAktualniStrankaComponent {

    @ViewChild('vstupniPole', {static: false}) public vstupniPole: ElementRef;

    private maximalniPocetStranekProZobrazeni: number;

    constructor(private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService, private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService) {
    }

    public pripravitAktualniStranku(): number {
        return this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        const nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    }

    public nastavitStranku() {
        const element = $(this.vstupniPole.nativeElement);
        let hodnota = element.val();

        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }

        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (hodnota <= 0) {
            hodnota = 1;
        } else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }

        element.blur();
        element.val(hodnota);
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    }

    public pripravitMaximalniPocetStranekProZobrazeni() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }

        return this.maximalniPocetStranekProZobrazeni;
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    }
}
