import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NastrojeOdrolovani } from './shared/nastroje/nastroje-odrolovani';
import { AutentizaceService } from './service/bezpecnost/autentizace.service';
import { NastrojeAutentizace } from './shared/nastroje/nastroje-autentizace';
import { OptimalizaceProVyhledavaceService } from './service/obecne/optimalizace-pro-vyhledavace.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, autentizaceService, optimalizaceProVyhledavaceService) {
        this.router = router;
        this.autentizaceService = autentizaceService;
        this.optimalizaceProVyhledavaceService = optimalizaceProVyhledavaceService;
        this.idElementuProOdrolovani = 'zacatek-stranky';
    }
    AppComponent.prototype.ngOnInit = function () {
        this.optimalizaceProVyhledavaceService.optimalizovatStrankuProVyhledavace();
        this.odrolovatNaZacatekStranky();
        this.zkontrolovatExpiraciTokenuASmazatInformaceOUzivateliZLocalStorage();
    };
    AppComponent.prototype.odrolovatNaZacatekStranky = function () {
        NastrojeOdrolovani.odrolovatNaIdElementuPriPresmerovani(this.router, this.idElementuProOdrolovani);
    };
    AppComponent.prototype.zkontrolovatExpiraciTokenuASmazatInformaceOUzivateliZLocalStorage = function () {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            NastrojeAutentizace.odstranitInformaceOUzivateliZLocalStorage();
        }
    };
    return AppComponent;
}());
export { AppComponent };
