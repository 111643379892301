<app-vrtitko [zobrazit]="zobrazitVrtitko"></app-vrtitko>

<div class="frame">
    <div class="recapitulation-layout">
        <div class="recapitulation-item-1">
            <article>
                <h3 class="info-title">{{ 'objednavka.zpusobPlatby' | lokalizace }}</h3>

                <form [formGroup]="formGroup">
                    <div class="list__item no-hover clickable">
                        <label for="moznostPlatebniKartou" class="radio-input-with-icon">
                            <input id="moznostPlatebniKartou" type="radio" name="zpusobPlatby" value="{{ moznostPlatbyKartouValue }}" checked (change)="zmenenVybranyZpusobUhrady($event.target)"/>
                            <span>{{ 'zpusobPlatby.platbaKartou' | lokalizace }}</span>
                            <img src="{{ zakladniUrl }}assets/img/ikony/e-shop/platebni-karty.png" alt="{{ 'popisek.obrazek.gopayCards' | lokalizace }}">
                        </label>
                    </div>
                    <div *ngIf="vybranyTypPredplatneho === typPredplatneho.ROCNI" class="list__item clickable radio-without-img">
                        <label for="moznostBankovnimPrevodem" class="radio-input-with-icon">
                            <input id="moznostBankovnimPrevodem" type="radio" name="zpusobPlatby" value="{{ moznostBankovniPrevodValue }}" (change)="zmenenVybranyZpusobUhrady($event.target)"/>
                            <span>{{ 'zpusobPlatby.bankovniPrevod' | lokalizace }}</span>
                        </label>
                    </div>
                </form>

                <div class="order-text">
                    <p>{{ 'objednavka.zpusobPlatby.text.obchodniPodminky' | lokalizace }}
                        <a href="{{ urlPODO }}/resources/podo-vop.pdf" target="_blank">{{ 'objednavka.zpusobPlatby.text.obchodniPodminky.odkaz' | lokalizace }}</a>.
                    </p>
                </div>

                <app-formular-checkbox class="span-4"
                                       [vzdySkrytNeaktivniPrazdnouHodnotu]="false"
                                       [popisek]="'objednavka.zpusobPlatby.souhlas'"
                                       [odeslan]="odeslan"
                                       [ovladacFormulare]="formGroup.controls.souhlasSObchodnimiPodminkami"></app-formular-checkbox>
            </article>

            <article>
                <h3 class="info-title">{{ 'objednavka.slevovyKupon' | lokalizace }}</h3>

                <app-formular-input-s-akci class="span-6"
                                           [popisek]="'objednavka.slevovyKupon'"
                                           [odeslan]="odeslan"
                                           [nazevPrimarnihoTlacitka]="'tlacitko.uplatnitKupon'"
                                           [ikonkaPrimarnihoTlacitka]="'done'"
                                           [ikonkaSekundarnihoTlacitka]="'clear'"
                                           [ovladacFormulare]="formGroup.controls.nazevSlevovehoKuponu"
                                           (primarniAkce)="uplatnitSlevovyKupon()"
                                           (sekundarniAkce)="odebratSlevovyKupon()"></app-formular-input-s-akci>
            </article>

            <article *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU">
                <h3 class="info-title">{{ 'objednavka.opakovanaPlatba.nadpis' | lokalizace }}
                    <i class="material-icons info-icon" (click)="otevritModalniPanel()" title="{{ 'objednavka.opakovanaPlatba.napoveda' | lokalizace }}">info_outline</i>
                </h3>

                <div class="order-text order-text-footer">
                    <p>{{ 'objednavka.opakovanaPlatba.text.zalozeni' | lokalizace }}</p>
                    <br/>
                    <p>{{ 'objednavka.opakovanaPlatba.text.pristiPlatba' | lokalizace }}
                        <b>{{ datumPristiPlatby | datum: formatData }}</b>.
                    </p>
                </div>

                <app-formular-checkbox class="span-4"
                                       [vzdySkrytNeaktivniPrazdnouHodnotu]="false"
                                       [zmensitRozestupy]="true"
                                       [popisek]="'objednavka.opakovanaPlatba.souhlas'"
                                       [odeslan]="odeslan"
                                       [ovladacFormulare]="formGroup.controls.souhlasSOpakovanouPlatbou"></app-formular-checkbox>

                <div class="go-pay-additional-info">
                    <p>{{ 'objednavka.opakovanaPlatba.text.spravceUdaju' | lokalizace }}
                        <a href="https://www.gopay.com/blog/jak-jsme-splnili-nejvyssi-bezpecnosti-standard-kartovych-asociaci/" target="_blank">PCI-DSS Level 1</a> {{ 'objednavka.opakovanaPlatba.text.spravceUdaju.poznamka' | lokalizace }}
                    </p>
                </div>
            </article>

            <article *ngIf="vybranyTypPredplatneho === typPredplatneho.ROCNI">
                <h3 class="info-title">{{ 'objednavka.rocniPredplatne.nadpis' | lokalizace }}</h3>

                <div class="order-text order-text-footer">
                    <p>{{ 'objednavka.rocniPredplatne.text.dobaPlatnosti' | lokalizace }}</p>
                    <br/>
                    <p>{{ 'objednavka.rocniPredplatne.text.bankovniPrevod.postup' | lokalizace }}</p>
                </div>
            </article>
        </div>

        <div *ngIf="vybranyProdukt" class="recapitulation-item-2">
            <h3 class="info-title summary-title">{{ 'objednavka.souhrn' | lokalizace }}</h3>

            <div class="summary-container">
                <div>
                    <p class="summary-subtitle">{{ 'objednavka.produkt' | lokalizace }}</p>
                    <div class="summary-content-container summary-content-container__product">
                        <p class="summary-product-name">1x {{ vybranyProdukt.nazev }}</p>
                        <p *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU || vybranyTypPredplatneho === typPredplatneho.ROCNI" class="summary-product-subscription">{{ vybranyTypPredplatneho | vyctovyTyp: 'Predplatne' }}</p>
                        <p class="summary-product-price">
                            <span *ngIf="!jeNeplatceDph()">{{ vybranyProdukt.cena?.cenaSDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</span>
                            <span *ngIf="jeNeplatceDph()">{{ vybranyProdukt.cena?.cenaBezDph | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}</span>
                            <span *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU">{{ 'objednavka.cena.mesicne' | lokalizace }}</span>
                        </p>
                    </div>
                    <div *ngIf="slevovyKupon" class="summary-content-container summary-content-container__product">
                        <p class="summary-product-name">1x {{ slevovyKupon.nazev }}</p>
                        <p class="summary-product-price">
                            <span>{{ slevovyKupon.sleva | sleva }}</span>
                        </p>
                    </div>
                </div>
                <div>
                    <p class="summary-subtitle">{{ 'objednavka.cena.celkova' | lokalizace }}</p>
                    <div class="summary-content-container summary-content-container__price">
                        <div class="summary-price-row summary-price-row__first">
                            <p>{{ 'objednavka.cena.bezDPH' | lokalizace }}</p>
                            <p>{{ pripravitCenuVybranehoProduktuBezDph() | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}
                                <span *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU">{{ 'objednavka.cena.mesicne' | lokalizace }}</span>
                            </p>
                        </div>
                        <div *ngIf="!jeNeplatceDph()" class="summary-price-row">
                            <p>{{ 'objednavka.cena.kUhrade' | lokalizace }}</p>
                            <p class="summary-price-final">{{ pripravitCenuVybranehoProduktuSDph() | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}
                                <span *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU">{{ 'objednavka.cena.mesicne' | lokalizace }}</span>
                            </p>
                        </div>
                        <div *ngIf="jeNeplatceDph()" class="summary-price-row">
                            <p>{{ 'objednavka.cena.kUhradeBezDph' | lokalizace }}</p>
                            <p class="summary-price-final">{{ pripravitCenuVybranehoProduktuBezDph() | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs' }}
                                <span *ngIf="vybranyTypPredplatneho === typPredplatneho.MESICNI_S_OBNOVOU">{{ 'objednavka.cena.mesicne' | lokalizace }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="aktualniFakturacniUdajeObjednavky" class="summary-container">
                <div>
                    <p class="summary-subtitle">{{ 'objednavka.fakturacniUdaje' | lokalizace }}</p>
                    <div class="summary-content-container summary-content-container__price">
                        <div>
                            <div class="billing-summary">
                                <ng-container *ngIf="aktualniFakturacniUdajeObjednavky.nazevOrganizace != null">
                                    <p>{{ aktualniFakturacniUdajeObjednavky.nazevOrganizace }}</p>
                                    <p>
                                        <span>{{ ('objednavka.ic' | lokalizace) + ': ' }} {{ aktualniFakturacniUdajeObjednavky.ic != null ? aktualniFakturacniUdajeObjednavky.ic : ('objednavka.nevyplneno' | lokalizace) }}</span>
                                        <span>{{ ('fakturacniUdaje.dic' | lokalizace) + ': ' }} {{ aktualniFakturacniUdajeObjednavky.dic != null ? aktualniFakturacniUdajeObjednavky.dic : ('objednavka.nevyplneno' | lokalizace) }}</span>
                                    </p>
                                </ng-container>
                                <p>{{ aktualniFakturacniUdajeObjednavky.jmeno + ' ' + aktualniFakturacniUdajeObjednavky.prijmeni }}  </p>
                                <p>{{ aktualniFakturacniUdajeObjednavky.fakturacniAdresa | adresaPipe }}</p>
                                <p>{{ ('objednavka.email' | lokalizace) + ': ' + aktualniFakturacniUdajeObjednavky.email}} {{ ', ' + ('objednavka.tel' | lokalizace) + '.: ' + aktualniFakturacniUdajeObjednavky.telefon }}  </p>
                            </div>

                            <div *ngIf="jeNeplatceDph()">
                                <br/>
                                <p>
                                    <b>{{ 'objednavka.fakturacniUdaje.fakturaceZahranicnihoDodavateleVEU' | lokalizace }}</b>
                                </p>
                            </div>

                            <br/>
                            <div>
                                <p>
                                    <b>{{ 'objednavka.fakturacniUdaje.zkontrolujDoklad' | lokalizace }}</b>
                                </p>
                                <br/>
                                <p>{{ 'objednavka.fakturacniUdaje.vystavenyDoklad' | lokalizace }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="container__content-footer">
            <button type="button" (click)="presmerovatPriZruseni()" class="btn btn-secondary">
                <b>{{ 'tlacitko.zrusit' | lokalizace }}</b>
                <i class="material-icons right">clear</i>
            </button>
            <button type="button" (click)="predchoziKarta()" class="btn btn-secondary">
                <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                <i class="material-icons right">arrow_back</i>
            </button>
            <button *ngIf="vybranyProdukt && !jeNeplatceDph()" type="submit" (click)="onSubmit()" class="btn btn-primary">
                {{ dokoncitObjednavkuTextTlacitka | lokalizace }} {{ vybranyZpusobPlatby === zpusobPlatby.PLATBA_KARTOU ? (pripravitCenuVybranehoProduktuSDph() | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs') : ''}}
            </button>
            <button *ngIf="vybranyProdukt && jeNeplatceDph()" type="submit" (click)="onSubmit()" class="btn btn-primary">
                {{ dokoncitObjednavkuTextTlacitka | lokalizace }} {{ vybranyZpusobPlatby === zpusobPlatby.PLATBA_KARTOU ? (pripravitCenuVybranehoProduktuBezDph() | currency: mena.CZK: 'symbol-narrow': '1.2-2':'cs') : ''}}
            </button>
        </div>
    </div>
</div>

<app-textovy-modalni-panel (nesouhlas)="zavritModalniPanelZruseniOnlinePlateb()"
                           (souhlas)="zrusitOpakovaniPlatby()"
                           [zobrazit]="zobrazitModalniPanelProZruseniOpakovanychPlateb"
                           [idPrvku]="'modalniPanelZruseniOnlinePlateb'"
                           [nazevAkcnihoTlacitka]="'tlacitko.zruseniOpakovanePlatby'"
                           [nadpis]="'objednavka.zruseniOpakovanePlatby.potvrzeniZruseni.nadpis'"
                           [text]="'objednavka.zruseniOpakovanePlatby.udajeKOpakovani.potvrzeniZruseni.text'"></app-textovy-modalni-panel>

<div #modalniPanel id="opakovana-platba-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'objednavka.opakovanaPlatba.modal.nadpis' | lokalizace }}
    </div>
    <div class="modal-content modal-body">
        <div class="modal-text">
            <ul class="dash">
                <li>{{ 'objednavka.opakovanaPlatba.text.castka' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.dobaPlatnosti' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.frekvenceStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.datumStrhavani' | lokalizace }}</li>
                <li>{{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni1' | lokalizace }}
                    <a href="{{ platbyAObjednavkyUrl }}" target="_blank">{{ 'platbyAObjednavky.nadpis' | lokalizace }}</a> {{ 'objednavka.opakovanaPlatba.text.zmenaCiZruseni2' | lokalizace }}
                    <a href="mailto:podpora@fen.cz">podpora@fen.cz</a>.
                </li>
            </ul>
            <br/>
            <p>{{ 'objednavka.opakovanaPlatba.text.zmenaPodminek' | lokalizace }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="waves-effect waves-light btn-flat" (click)="zavritModalniPanel()">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
    </div>
</div>
