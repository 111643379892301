import {Component, OnInit} from '@angular/core';
import {prostredi} from '../../../../prostredi/prostredi';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {NastrojeUrl} from '../../../shared/nastroje/nastroje-url';
import {NastrojeObecne} from '../../../shared/nastroje/nastroje-obecne';
import {ActivatedRoute, Router} from '@angular/router';
import {OnlinePlatbyService} from '../../../service/e-shop/online-platby.service';
import {Platba} from '../../../data/e-shop/platba';
import {StavPlatby} from '../../../data/e-shop/enum/stav-platby.enum';
import {ZpusobPlatby} from '../../../data/e-shop/enum/zpusob-platby.enum';

@Component({
    selector: 'app-detail-platby',
    templateUrl: './detail-platby.component.html',
    styleUrls: ['./detail-platby.component.scss']
})
export class DetailPlatbyComponent implements OnInit {

    public jePrihlasenCDDUzivatel: boolean;
    public platba: Platba;
    public zobrazitPotvrzeni = false;
    public zobrazitVrtitko = false;

    private idPlatby: number;
    private identifikatorObjednavky: string;

    constructor(
        private autentizaceService: AutentizaceService,
        private onlinePlatbyService: OnlinePlatbyService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.inicializovatPromenne();
        this.zpracovatParametrRetezceDotazuZUrl();
        this.pripravitStavPlatby();
    }

    public prejitNaStrankuProfiluPODO(): void {
        NastrojeUrl.otevritOdkazVNoveZalozce(prostredi.urlPODO + '/profil');
    }

    public prejitNaStrankuDetailuObjednavky(): void {
        if (NastrojeObecne.jeHodnotaPrazdna(this.platba.identifikatorObjednavky)) {
            this.router.navigate(['/e-shop/objednavka/' + this.identifikatorObjednavky]);
            return;
        }

        this.router.navigate(['/e-shop/objednavka/' + this.platba.identifikatorObjednavky]);
    }

    public zavritModalniPanel(): void {
        this.zobrazitPotvrzeni = false;
    }

    private inicializovatPromenne(): void {
        this.jePrihlasenCDDUzivatel = this.autentizaceService.jePrihlasenyUzivatelCdd();
    }

    private zpracovatParametrRetezceDotazuZUrl(): void {
        const parametrIdPlatby = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'id');
        const parametrIdentifikatorObjednavky = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'identifikatorObjednavky');

        if (!NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby)) {
            this.idPlatby = Number(parametrIdPlatby);
        } else if (NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby) && !NastrojeObecne.jeHodnotaPrazdna(parametrIdentifikatorObjednavky)) {
            this.identifikatorObjednavky = parametrIdentifikatorObjednavky;
        } else {
            this.router.navigate([`/`]);
        }
    }

    private pripravitStavPlatby(): void {
        this.zobrazitVrtitko = true;

        if (NastrojeObecne.jeHodnotaPrazdna(this.identifikatorObjednavky)) {
            this.onlinePlatbyService.nacistStavPlatby(this.idPlatby)
                .toPromise()
                .then((odpoved) => {
                    this.platba = odpoved;

                    if (this.platba.zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU && this.platba.stav === StavPlatby.VYTVORENA) {
                        this.zobrazitPotvrzeni = true;
                    }
                    this.zobrazitVrtitko = false;
                }).catch(() => this.zobrazitVrtitko = false);
        } else {
            this.onlinePlatbyService.nacistPosledniPlatbuPodleObjednavky(this.identifikatorObjednavky)
                .toPromise()
                .then((odpoved) => {
                    this.platba = odpoved;

                    if (this.platba.zpusobPlatby === ZpusobPlatby.PLATBA_KARTOU && this.platba.stav === StavPlatby.VYTVORENA) {
                        this.zobrazitPotvrzeni = true;
                    }
                    this.zobrazitVrtitko = false;
                }).catch(() => this.zobrazitVrtitko = false);
        }
    }
}
