var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { AbstraktniSeznamService } from '../abstraktni-seznam-service';
import { SeznamSlevovychKuponu } from '../../../data/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu';
import { AutentizaceService } from '../../bezpecnost/autentizace.service';
import { SeznamSlevovychKuponuNastaveniService } from './seznam-slevovych-kuponu-nastaveni.service';
import { TypPozadavku } from '../../obecne/abstraktni-service';
import { NastrojeJsonu } from '../../../shared/nastroje/nastroje-jsonu';
import { prostredi } from '../../../../prostredi/prostredi';
import { NastrojeObecne } from '../../../shared/nastroje/nastroje-obecne';
var SeznamSlevovychKuponuService = /** @class */ (function (_super) {
    __extends(SeznamSlevovychKuponuService, _super);
    function SeznamSlevovychKuponuService(http, autentizaceService, seznamSlevovychKuponuNastaveniService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopSlevoveKupony;
        return _this;
    }
    SeznamSlevovychKuponuService.prototype.aktualizovat = function (odrolovatNaZacatekSeznamu) {
        var _this = this;
        if (odrolovatNaZacatekSeznamu === void 0) { odrolovatNaZacatekSeznamu = true; }
        this.seznam = new SeznamSlevovychKuponu();
        this.pozadavekSTokenem(TypPozadavku.POST, '/seznam-kuponu', NastrojeJsonu.stringifyRFC3339(this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni()))
            .toPromise()
            .then(function (odpoved) {
            _this.seznam = odpoved;
            if (NastrojeObecne.jeHodnotaPrazdna(_this.seznam.polozky)) {
                _this.seznam.polozky = [];
            }
        }).catch(this.zpracovatChybu);
        if (odrolovatNaZacatekSeznamu) {
            this.odrolovatNaZacatekSeznamu();
        }
    };
    return SeznamSlevovychKuponuService;
}(AbstraktniSeznamService));
export { SeznamSlevovychKuponuService };
