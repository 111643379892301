export class NastrojePorovnani {

    public static porovnatRetezec(prvniRetezec: string, druhyRetezec: string) {
        if (prvniRetezec < druhyRetezec) {
            return -1;
        }
        if (prvniRetezec > druhyRetezec) {
            return 1;
        }
        return 0;
    }

    public static porovnatDatum(prvniDatum: Date, druheDatum: Date) {
        if (prvniDatum.getTime() < druheDatum.getTime()) {
            return -1;
        }
        if (prvniDatum.getTime() > druheDatum.getTime()) {
            return 1;
        }
        return 0;
    }
}
