<div *ngIf="aktivniPredplatne" class="info-container">
    <h3 class="info-title span-2">{{ 'aktivniPredplatne.nadpis' | lokalizace }}</h3>

    <div class="container__content-body span-2">
        <table class="highlight">
            <thead>
            <tr>
                <th>{{ 'aktivniPredplatne.tabulka.nazev' | lokalizace }}</th>
                <th class="hide-on-med-and-down">{{ 'aktivniPredplatne.tabulka.typ' | lokalizace }}</th>
                <th>{{ 'aktivniPredplatne.tabulka.platnostOd' | lokalizace }}</th>
                <th>{{ 'aktivniPredplatne.tabulka.platnostDo' | lokalizace }}</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let radek of aktivniPredplatne"
                [class.active]="radek.idPlatby && radek.zpusobPlatby === zpusobPlatby.PLATBA_KARTOU"
                (click)="prejitNaDetailPlatby(radek.idPlatby, radek.zpusobPlatby)">
                <td>{{ radek.nazevProduktu }}</td>
                <td class="hide-on-med-and-down">{{ radek.typPredplatneho | vyctovyTyp: 'Predplatne' }}</td>
                <td>{{ radek.datumOd | datum : formatData : true }}</td>
                <td>{{ radek.datumDo | datum : formatData : true }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
