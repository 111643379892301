import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {NastrojeWizardu} from '../../../../../shared/nastroje/nastroje-wizardu';
import {AbstraktniFiltrComponent} from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import {FormBuilder} from '@angular/forms';
import {SeznamObjednavekOperatoraService} from '../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {AutentizaceService} from '../../../../../service/bezpecnost/autentizace.service';
import {TypUceluFormulare} from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {NastrojeFiltrace} from '../../../../../shared/nastroje/nastroje-filtrace';
import {FiltrSeznamuObjednavekOperatora} from '../../../../../data/seznam/seznam-objednavek-operatora/filtr/filtr-seznamu-objednavek-operatora';

@Component({
    selector: 'app-seznam-objednavek-operatora-filtr',
    templateUrl: './seznam-objednavek-operatora-filtr.component.html',
    styleUrls: ['./seznam-objednavek-operatora-filtr.component.scss']
})
export class SeznamObjednavekOperatoraFiltrComponent extends AbstraktniFiltrComponent implements OnInit, AfterViewInit {

    @ViewChild('wizard', {static: false}) public wizard: ElementRef;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        protected seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService,
        protected seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService
    ) {
        super(seznamObjednavekOperatoraNastaveniService, seznamObjednavekOperatoraService, autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
    }

    ngAfterViewInit(): void {
        this.inicializovatWizard();
    }

    odeslat(): void {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuObjednavekOperatora(), this.formGroup));
    }

    private inicializovatWizard(): void {
        NastrojeWizardu.inicializovatWizard(this.wizard.nativeElement);
    }
}
