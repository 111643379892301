import {Injectable} from '@angular/core';
import {AtributRazeniUzivateluSDodavatelem} from '../../../data/obecne/enum/atribut-razeni-uzivatelu-s-dodavatelem.enum';
import {TypRazeni} from '../../../data/obecne/enum/typ-razeni.enum';
import {NastaveniZobrazeniSeznamuUzivateluSDodavatelem} from '../../../data/seznam/seznam-uzivatelu-s-dodavatelem/filtr/nastaveni-zobrazeni-seznamu-uzivatelu-s-dodavatelem';
import {AbstraktniNastaveniSeznamuService} from '../abstraktni-nastaveni-seznamu-service';

@Injectable()
export class SeznamUzivateluSDodavatelemNastaveniService extends AbstraktniNastaveniSeznamuService {
    protected readonly typAtributuRazeni = AtributRazeniUzivateluSDodavatelem;
    protected readonly vychoziAtributRazeni = AtributRazeniUzivateluSDodavatelem.PRIHLASOVACI_JMENO;
    protected readonly typTypuRazeni = TypRazeni;
    protected readonly vychoziTypRazeni = TypRazeni.VZESTUPNE;

    protected readonly nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_strankovani-pocet-zaznamu-na-stranku';
    protected readonly nazevKliceAktualniStrankyVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_strankovani-aktualni-stranka';
    protected readonly nazevKliceAtributuRazeniVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_razeni-atribut-razeni';
    protected readonly nazevKliceTypuRazeniVLokalnimUlozisti = 'seznam-uzivatelu-s-dodavatelem_razeni-typ-razeni';

    protected readonly nastaveniZobrazeniSeznamu = new NastaveniZobrazeniSeznamuUzivateluSDodavatelem();
}
