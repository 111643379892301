import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-obsah',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-obsah.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-obsah.component.scss']
})
export class SeznamUzivateluSDodavatelemObsahComponent {

    @Input() ucelSeznamu;

}
