<div class="frame">
    <app-fakturacni-udaje-input [vybranyZpusobVytvoreniObjednavky]="vybranyZpusobVytvoreniObjednavky"
                                [objednavka]="objednavka"
                                [formGroup]="formGroup"
                                [odeslan]="odeslan"></app-fakturacni-udaje-input>

    <div>
        <div class="container__content-footer">
            <button type="button" (click)="presmerovatPriZruseni()"
                    class="btn btn-secondary">
                <b>{{ 'tlacitko.zrusit' | lokalizace }}</b>
                <i class="material-icons right">clear</i>
            </button>
            <button *ngIf="vybranyZpusobVytvoreniObjednavky === zpusobyVytvoreniObjednavky.OPERATOREM"
                    type="button" (click)="predchoziKarta()"
                    class="btn btn-secondary">
                <b>{{ 'tlacitko.zpet' | lokalizace }}</b>
                <i class="material-icons right">arrow_back</i>
            </button>
            <button type="submit" (click)="onSubmit()"
                    class="btn btn-primary">
                <b>{{ 'tlacitko.pokracovat' | lokalizace }}</b>
                <i class="material-icons right">arrow_forward</i>
            </button>
        </div>
    </div>
</div>

<app-textovy-modalni-panel (nesouhlas)="skrytModalniPanel()"
                           (souhlas)="potvrditSpravnostFakturacnichUdaju()"
                           [zobrazit]="zobrazitPotvrzeniNevyplneniIcoDic"
                           [idPrvku]="'potvrzeni-ico-dic-modalni-panel'"
                           [nazevAkcnihoTlacitka]="'tlacitko.pokracovat'"
                           [nazevNeakcnihoTlacitka]="'tlacitko.doplnit'"
                           [nadpis]="'objednavka.potvrzeni.nevyplneniIcoDic.nadpis'"
                           [text]="'objednavka.potvrzeni.nevyplneniIcoDic.text'"></app-textovy-modalni-panel>

<app-textovy-modalni-panel (souhlas)="skrytModalniPanel()"
                           [zobrazit]="zobrazitDicJeMimoEu"
                           [idPrvku]="'dic-je-mimo-eu-modalni-panel'"
                           [nazevNeakcnihoTlacitka]="'tlacitko.zavrit'"
                           [nadpis]="'objednavka.zamezeniVytvoreni.dicJeMimoEu.nadpis'"
                           [text]="'objednavka.zamezeniVytvoreni.dicJeMimoEu.text'"></app-textovy-modalni-panel>
