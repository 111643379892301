import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {prostredi} from '../../../prostredi/prostredi';
import {Polozka} from '../../data/obecne/polozka';
import {NastrojeRestu} from '../../shared/nastroje/nastroje-restu';
import {NastrojeUrl} from '../../shared/nastroje/nastroje-url';
import {AutentizaceService} from '../bezpecnost/autentizace.service';

export abstract class AbstraktniService {
    protected abstract resourceClassUri: string;
    protected url: string;
    protected hlavicky: HttpHeaders;
    protected hlavickySOverride: HttpHeaders;

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService) {
        this.url = prostredi.cddAdministraceBackendUrl;
        this.hlavicky = new HttpHeaders(prostredi.hlavickyBackend);
        this.hlavickySOverride = new HttpHeaders(prostredi.hlavickyBackendSGetOverride);
    }

    public pozadavekSTokenem(typPozadavku: TypPozadavku, cesta?: string, teloPozadavku?: string, parametry?: Polozka[]): Observable<any> {
        return this.pozadavek(typPozadavku, true, false, teloPozadavku, cesta, parametry);
    }

    public pozadavekBezTokenu(typPozadavku: TypPozadavku, cesta?: string, teloPozadavku?: string, parametry?: Polozka[]): Observable<any> {
        return this.pozadavek(typPozadavku, false, false, teloPozadavku, cesta, parametry);
    }

    public pozadavekStahnoutSoubor(cesta?: string, vlozitTokenDoHlavicky?: boolean): Observable<any> {
        if (this.jeVyzadovanaAutentizace(vlozitTokenDoHlavicky)) {
            return this.presmerovatNaPrihlasovaciStranku();
        }

        return this.http.get(NastrojeUrl.pripravitUrl(this.url + this.resourceClassUri, cesta), {'headers': vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky() : null, 'responseType': 'blob'});
    }

    public pozadavek(typPozadavku: TypPozadavku, vlozitTokenDoHlavicky: boolean, hlavickySOverride: boolean, teloPozadavku: string, cesta?: string, parametry?: Polozka[]): Observable<any> {
        if (this.jeVyzadovanaAutentizace(vlozitTokenDoHlavicky)) {
            return this.presmerovatNaPrihlasovaciStranku();
        }

        const urlPozadavku = NastrojeUrl.pripravitUrl(this.url + this.resourceClassUri, cesta, parametry);

        return this.pripravitPozadavek(typPozadavku, urlPozadavku, vlozitTokenDoHlavicky, hlavickySOverride, teloPozadavku);
    }

    private pripravitPozadavek(typPozadavku: TypPozadavku, urlPozadavku: string, vlozitTokenDoHlavicky: boolean, hlavickySOverride: boolean, teloPozadavku: string | null): Observable<any> {
        const hlavicky = this.pripravitHlavicky(vlozitTokenDoHlavicky, hlavickySOverride);

        switch (typPozadavku) {
            case TypPozadavku.GET:
                return this.http.get(urlPozadavku, hlavicky);
            case TypPozadavku.HEAD:
                return this.http.head(urlPozadavku, hlavicky);
            case TypPozadavku.PATCH:
                return this.http.patch(urlPozadavku, teloPozadavku, hlavicky);
            case TypPozadavku.POST:
                return this.http.post(urlPozadavku, teloPozadavku, hlavicky);
            case TypPozadavku.PUT:
                return this.http.put(urlPozadavku, teloPozadavku, hlavicky);
            default:
                return null;
        }
    }

    private pripravitHlavicky(vlozitTokenDoHlavicky: boolean, hlavickySOverride: boolean = false): any {
        return hlavickySOverride ? this.pripravitHlavickySOverride(vlozitTokenDoHlavicky) : this.pripravitHlavickyBezOverride(vlozitTokenDoHlavicky);
    }

    private pripravitHlavickyBezOverride(vlozitTokenDoHlavicky: boolean = false): any {
        return {
            headers: vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky(this.hlavicky) : this.hlavicky
        };
    }

    private pripravitHlavickySOverride(vlozitTokenDoHlavicky: boolean = false): any {
        return {
            headers: vlozitTokenDoHlavicky ? this.autentizaceService.vlozitTokenDoHlavicky(this.hlavickySOverride) : this.hlavickySOverride,
            observe: 'response',
            responseType: 'json'
        };
    }

    private jeVyzadovanaAutentizace(vlozitTokenDoHlavicky: boolean): boolean {
        return vlozitTokenDoHlavicky && !this.autentizaceService.jeUzivatelPrihlaseny();
    }

    private presmerovatNaPrihlasovaciStranku(): Observable<any> {
        return Observable.fromPromise(new Promise(async() => {
            if (this.autentizaceService.jePrihlasenyUzivatelSpravceDatabaze()) {
                this.autentizaceService.prihlasitAdministratora();
            } else if (this.autentizaceService.jePrihlasenyUzivatelOperator()) {
                this.autentizaceService.prihlasitOperatora();
            } else {
                this.autentizaceService.prihlasitCddUzivatele();
            }

            return;
        }));
    }

    protected zpracovatChybu(error: Response | any): Promise<any> {
        return NastrojeRestu.zpracovatChybu(error);
    }
}

export enum TypPozadavku {
    GET, HEAD, PATCH, POST, PUT
}
