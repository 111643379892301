import {Component} from '@angular/core';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';
import {SeznamSlevovychKuponuNastaveniService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';

@Component({
    selector: 'app-seznam-slevovych-kuponu-strankovani',
    templateUrl: './seznam-slevovych-kuponu-strankovani.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-strankovani.component.scss']
})
export class SeznamSlevovychKuponuStrankovaniComponent {

    constructor(
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
    }

    public predchoziStranka(): void {
        const hodnota = this.pripravitHodnotu();

        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamSlevovychKuponuService.aktualizovat();
    }

    public dalsiStranka(): void {
        const hodnota = this.pripravitHodnotu();
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        this.seznamSlevovychKuponuNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamSlevovychKuponuService.aktualizovat();
    }

    public jePrvniStranka(): boolean {
        return this.pripravitHodnotu() === 1;
    }

    public jePosledniStranka(): boolean {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    }

    private pripravitHodnotu(): number {
        return this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }

    public pripravitCekovyPocetZaznamu(): number {
        return this.seznamSlevovychKuponuService.seznam.celkovyPocetZaznamu;
    }

    public pripravitMaximalniPocetStranek(): number {
        const seznamSlevovychKuponu = this.seznamSlevovychKuponuService.seznam;
        const nastaveniZobrazeniSeznamuSlevovychKuponu = this.seznamSlevovychKuponuNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu);
    }
}
