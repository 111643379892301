declare var M: any;

export class NastrojeModalnihoPanelu {

    public static inicializovatModalniPanel(element: HTMLElement, zakazatScrollovaniPriZobrazeni = false, zakazatMoznostZavritPanelJinakNezTlacitkem = true) {
        M.Modal.init(element, {
            preventScrolling: zakazatScrollovaniPriZobrazeni,
            dismissible: !zakazatMoznostZavritPanelJinakNezTlacitkem
        });
    }

    public static otevritModalniPanel(element: HTMLElement) {
        this.pripravitInstanciModalnihoPanelu(element).open();
    }

    public static zavritModalniPanel(element: HTMLElement, okamziteZavreni = false) {
        if (okamziteZavreni) {
            this.pripravitInstanciModalnihoPanelu(element).options['outDuration'] = 0;
        }

        this.pripravitInstanciModalnihoPanelu(element).close();
    }

    public static pripravitInstanciModalnihoPanelu(element: HTMLElement) {
        return M.Modal.getInstance(element);
    }
}
