import { PipeTransform } from '@angular/core';
var KlicePipe = /** @class */ (function () {
    function KlicePipe() {
    }
    KlicePipe.prototype.transform = function (hodnota) {
        var klice = [];
        for (var _i = 0, _a = Object.keys(hodnota); _i < _a.length; _i++) {
            var clenEnumu = _a[_i];
            klice.push({
                key: clenEnumu.toString(),
                value: hodnota[clenEnumu.toString()]
            });
        }
        return klice;
    };
    return KlicePipe;
}());
export { KlicePipe };
