import {Lokalizace} from '../lokalizace';
import {Meta, Title} from '@angular/platform-browser';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeOptimalizaceProVyhledavace {

    private static readonly vzorTitulku = '{0} - {1}';

    public static pridatTitulek(titulek: string, title: Title): void {
        const prefixTitulku = titulek ? Lokalizace.pripravitText(titulek) : null;
        const suffixTitulku = Lokalizace.pripravitText('metadata.suffixTitulku');

        title.setTitle(prefixTitulku ? NastrojeObecne.formatovatRetezec(this.vzorTitulku, prefixTitulku, suffixTitulku) : suffixTitulku);
    }

    public static pridatPovoleniProIndexaciRoboty(metadata: Meta): void {
        this.pridatMetadata('robots', 'index,follow', metadata);
    }

    public static pridatPopis(popis: string, metadata: Meta): void {
        this.pridatMetadata('description', popis, metadata);
    }

    public static pridatKlicovaSlova(klicovaSlova: string, metadata: Meta): void {
        this.pridatMetadata('keywords', klicovaSlova, metadata);
    }

    public static pridatAutora(metadata: Meta): void {
        this.pridatMetadata('author', 'metadata.autor', metadata);
    }

    private static pridatMetadata(tag: string, hodnota: string, metadata: Meta): void {
        if (hodnota && tag && metadata) {
            metadata.updateTag({
                name: tag,
                content: Lokalizace.pripravitText(hodnota)
            });
        }
    }
}
