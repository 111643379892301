import {AbstraktniNastaveniSeznamuService} from '../../../service/seznam/abstraktni-nastaveni-seznamu-service';
import {AbstraktniSeznamService} from '../../../service/seznam/abstraktni-seznam-service';

export abstract class AbstraktniRazeniComponent {

    public abstract atributyRazeni: any;

    public abstract typyRazeni: any;

    constructor(protected abstraktniNastaveniSeznamuService: AbstraktniNastaveniSeznamuService, protected seznamService: AbstraktniSeznamService) {
    }

    public pripravitVychoziAtributRazeni(): any {
        return this.abstraktniNastaveniSeznamuService.pripravitAtributRazeni();
    }

    public pripravitVychoziTypRazeni(): any {
        return this.abstraktniNastaveniSeznamuService.pripravitTypRazeni();
    }

    public nastavitAtributRazeni(atribut: any) {
        this.abstraktniNastaveniSeznamuService.nastavitAtributRazeni(atribut);
        this.resetovatPoziciNaStrankachAAktualizovat();
    }

    public nastavitTypRazeni(typRazeni: any) {
        this.abstraktniNastaveniSeznamuService.nastavitTypRazeni(typRazeni);
        this.resetovatPoziciNaStrankachAAktualizovat();
    }

    private resetovatPoziciNaStrankachAAktualizovat() {
        this.abstraktniNastaveniSeznamuService.resetovatPoziciNaStrankach();
        this.seznamService.aktualizovat(true);
    }
}
