var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { AbstraktniService, TypPozadavku } from '../obecne/abstraktni-service';
import { AutentizaceService } from '../bezpecnost/autentizace.service';
import { NastrojeJsonu } from '../../shared/nastroje/nastroje-jsonu';
import { prostredi } from '../../../prostredi/prostredi';
var ObjednavkaService = /** @class */ (function (_super) {
    __extends(ObjednavkaService, _super);
    function ObjednavkaService(http, autentizaceService) {
        var _this = _super.call(this, http, autentizaceService) || this;
        _this.http = http;
        _this.autentizaceService = autentizaceService;
        _this.resourceClassUri = '';
        _this.url = prostredi.urlBackendEshopDetailObjednavky;
        return _this;
    }
    ObjednavkaService.prototype.vytvoritObjednavku = function (objednavka) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/', NastrojeJsonu.stringify(objednavka))
            .catch(this.zpracovatChybu);
    };
    ObjednavkaService.prototype.zrusitObjednavku = function (zruseniObjednavky) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zrusit-objednavku', NastrojeJsonu.stringify(zruseniObjednavky))
            .catch(this.zpracovatChybu);
    };
    ObjednavkaService.prototype.nacistObjednavku = function (identifikatorObjednavky) {
        return this.pozadavekSTokenem(TypPozadavku.GET, 'detail/' + identifikatorObjednavky)
            .catch(this.zpracovatChybu);
    };
    ObjednavkaService.prototype.stahnoutZalohovouFakturu = function (identifikator) {
        return this.pozadavekStahnoutSoubor('/stahnout-zalohovou-fakturu/' + identifikator, true)
            .catch(this.zpracovatChybu);
    };
    ObjednavkaService.prototype.pripravitSlevuZprechoduNaJinePredplatne = function (objednavka) {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/sleva-z-prechodu', NastrojeJsonu.stringify(objednavka))
            .catch(this.zpracovatChybu);
    };
    return ObjednavkaService;
}(AbstraktniService));
export { ObjednavkaService };
