var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstraktniRazeniComponent } from '../../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
import { SeznamObjednavekNastaveniService } from '../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import { SeznamObjednavekService } from '../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import { TypRazeni } from '../../../../../../../data/obecne/enum/typ-razeni.enum';
import { AtributRazeniObjednavek } from '../../../../../../../data/obecne/enum/atribut-razeni-objednavek.enum';
var SeznamObjednavekRazeniComponent = /** @class */ (function (_super) {
    __extends(SeznamObjednavekRazeniComponent, _super);
    function SeznamObjednavekRazeniComponent(seznamObjednavekNastaveniService, seznamObjednavekService) {
        var _this = _super.call(this, seznamObjednavekNastaveniService, seznamObjednavekService) || this;
        _this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        _this.seznamObjednavekService = seznamObjednavekService;
        _this.atributyRazeni = AtributRazeniObjednavek;
        _this.typyRazeni = TypRazeni;
        return _this;
    }
    return SeznamObjednavekRazeniComponent;
}(AbstraktniRazeniComponent));
export { SeznamObjednavekRazeniComponent };
