import { prostredi } from '../../../prostredi/prostredi';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { NastrojeJsonu } from '../../shared/nastroje/nastroje-jsonu';
import { NastrojeRestu } from '../../shared/nastroje/nastroje-restu';
import { NastrojeAutentizace } from '../../shared/nastroje/nastroje-autentizace';
import { LokalniUlozisteNastroje } from '../../shared/nastroje/lokalni-uloziste/lokalni-uloziste-nastroje';
import { NastrojeLokalizace } from '../../shared/nastroje/nastroje-lokalizace';
var AutentizaceService = /** @class */ (function () {
    function AutentizaceService(http, keycloakService) {
        this.http = http;
        this.keycloakService = keycloakService;
        this.hlavicky = new HttpHeaders();
    }
    AutentizaceService.prototype.vlozitTokenDoHlavicky = function (hlavicka) {
        if (!hlavicka) {
            hlavicka = new HttpHeaders();
        }
        hlavicka = hlavicka.append('Authorization', 'Bearer ' + this.keycloakService.getKeycloakInstance().token);
        return hlavicka;
    };
    AutentizaceService.prototype.pripravitInformaceOUzivateliAUlozitDoLocalStorage = function () {
        if (NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage() != null) {
            return Promise.resolve();
        }
        return this.aktualizovatInformaceOUzivateliDoLocalStorage();
    };
    AutentizaceService.prototype.aktualizovatInformaceOUzivateliDoLocalStorage = function () {
        return this.http.get(prostredi.cddAdministraceBackendUrl + '/prihlasenyUzivatel', { headers: this.vlozitTokenDoHlavicky() })
            .toPromise()
            .then(function (vysledek) {
            var info = NastrojeJsonu.stringifyAndParse(vysledek);
            NastrojeAutentizace.ulozitInformaceOUzivateliDoLocalStorage(info);
            return info;
        })
            .catch(NastrojeRestu.zpracovatChybu);
    };
    AutentizaceService.prototype.prihlasitCddUzivatele = function () {
        this.prihlasit(prostredi.cddRealm, prostredi.cddClientId, prostredi.cddClientSecret);
    };
    AutentizaceService.prototype.prihlasitAdministratora = function () {
        this.prihlasit(prostredi.administratorRealm, prostredi.administratorClientId, prostredi.administratorClientSecret);
    };
    AutentizaceService.prototype.prihlasitOperatora = function () {
        this.prihlasit(prostredi.eshopRealm, prostredi.eshopClientId, prostredi.eshopClientSecret);
    };
    AutentizaceService.prototype.odhlasit = function () {
        LokalniUlozisteNastroje.odstranitPolozku(AutentizaceService.nazevKliceAktualnihoRealmu);
        this.keycloakService.logout(prostredi.url);
    };
    AutentizaceService.prototype.jeUzivatelPrihlaseny = function () {
        return this.keycloakService.getKeycloakInstance().authenticated && !this.keycloakService.getKeycloakInstance().isTokenExpired();
    };
    AutentizaceService.prototype.jePrihlasenyUzivatelCdd = function () {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.cddRealm;
    };
    AutentizaceService.prototype.jePrihlasenyUzivatelSpravceDatabaze = function () {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.administratorRealm;
    };
    AutentizaceService.prototype.jePrihlasenyUzivatelOperator = function () {
        return this.keycloakService.getKeycloakInstance().authenticated && this.keycloakService.getKeycloakInstance().realm === prostredi.eshopRealm;
    };
    AutentizaceService.prototype.vratitRealmAktualnePrihlasenehoUzivatele = function () {
        return this.keycloakService.getKeycloakInstance().realm;
    };
    AutentizaceService.prototype.prihlasit = function (nazevRealmu, clientId, clientSecret) {
        NastrojeAutentizace.odstranitInformaceOUzivateliZLocalStorage();
        LokalniUlozisteNastroje.setPolozka(AutentizaceService.nazevKliceAktualnihoRealmu, nazevRealmu);
        this.keycloakService.init(NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.cddKeycloakUrl, nazevRealmu, clientId, clientSecret));
        this.keycloakService.login({ kcLocale: NastrojeLokalizace.vratitNastavenouJazykovouMutaci() });
    };
    AutentizaceService.nazevKliceAktualnihoRealmu = 'aktualniRealm';
    return AutentizaceService;
}());
export { AutentizaceService };
