/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-uzivatelu-s-dodavatelem-seznam.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/seznam/seznam.component.ngfactory";
import * as i3 from "../../../../../shared/seznam/seznam.component";
import * as i4 from "@angular/router";
import * as i5 from "./seznam-uzivatelu-s-dodavatelem-seznam.component";
import * as i6 from "../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service";
import * as i7 from "../../../../../shared/nastroje/pipes/vyctovy-typ.pipe";
import * as i8 from "../../../../../service/e-shop/vytvorit-objednavku.service";
var styles_SeznamUzivateluSDodavatelemSeznamComponent = [i0.styles];
var RenderType_SeznamUzivateluSDodavatelemSeznamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamUzivateluSDodavatelemSeznamComponent, data: {} });
export { RenderType_SeznamUzivateluSDodavatelemSeznamComponent as RenderType_SeznamUzivateluSDodavatelemSeznamComponent };
export function View_SeznamUzivateluSDodavatelemSeznamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam", [], null, [[null, "vybranRadek"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("vybranRadek" === en)) {
        var pd_0 = (_co.oznacitVybranyRadek($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SeznamComponent_0, i2.RenderType_SeznamComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamComponent, [i4.Router], { celkovyPocetZaznamu: [0, "celkovyPocetZaznamu"], radky: [1, "radky"], ucelSeznamu: [2, "ucelSeznamu"] }, { vybranRadek: "vybranRadek" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pripravitCekovyPocetZaznamu(); var currVal_1 = _co.pripravitRadkySeznamuProZobrazeni(); var currVal_2 = _co.ucelSeznamu; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SeznamUzivateluSDodavatelemSeznamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-uzivatelu-s-dodavatelem-seznam", [], null, null, null, View_SeznamUzivateluSDodavatelemSeznamComponent_0, RenderType_SeznamUzivateluSDodavatelemSeznamComponent)), i1.ɵdid(1, 49152, null, 0, i5.SeznamUzivateluSDodavatelemSeznamComponent, [i6.SeznamUzivateluSDodavatelemService, i7.VyctovyTypPipe, i8.VytvoritObjednavkuService], null, null)], null, null); }
var SeznamUzivateluSDodavatelemSeznamComponentNgFactory = i1.ɵccf("app-seznam-uzivatelu-s-dodavatelem-seznam", i5.SeznamUzivateluSDodavatelemSeznamComponent, View_SeznamUzivateluSDodavatelemSeznamComponent_Host_0, { ucelSeznamu: "ucelSeznamu" }, {}, []);
export { SeznamUzivateluSDodavatelemSeznamComponentNgFactory as SeznamUzivateluSDodavatelemSeznamComponentNgFactory };
