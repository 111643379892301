import {EventEmitter, Injectable, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ObjednavkaService} from './objednavka.service';
import {Objednavka} from '../../data/e-shop/objednavka';
import {Produkt} from '../../data/e-shop/produkt';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';
import {NastrojeObecne} from '../../shared/nastroje/nastroje-obecne';
import {RadekSeznamu} from '../../shared/seznam/struktura/radek-seznamu';
import {Cena} from '../../data/e-shop/cena';
import {ZpusobPlatby} from '../../data/e-shop/enum/zpusob-platby.enum';
import {NastrojeFormulare} from '../../shared/nastroje/nastroje-formulare';
import {FakturacniAdresa} from '../../data/e-shop/fakturacni-adresa';
import {SlevovyKupon} from '../../data/e-shop/slevovy-kupon';
import {NastrojeDic} from '../../shared/nastroje/nastroje-dic';

@Injectable()
export class VytvoritObjednavkuService {

	@Output() pripravitCenuProduktu: EventEmitter<Cena> = new EventEmitter<Cena>();

	public objednavka: Objednavka;

	constructor(private objednavkaService: ObjednavkaService) {
	}

	public inicializovatObjednavku(): void {
		this.objednavka = new Objednavka();
	}

	public doplnitVybranyProdukt(produkt: Produkt, predplatne: TypPredplatneho, sleva?: Cena, delkaPlatnostiPredplatneho?: number): void {
		this.objednavka.idProduktu = produkt.id;
		this.objednavka.identifikatorProduktu = produkt.identifikator;
		this.objednavka.ucetniIdentifikatorProduktu = produkt.ucetniIdentifikator;
		this.objednavka.nazevProduktu = produkt.nazev;
		this.objednavka.delkaPlatnostiPredplatneho = delkaPlatnostiPredplatneho;
		this.objednavka.typPredplatneho = predplatne;

		if (NastrojeObecne.jeHodnotaPrazdna(sleva)) {
			this.nastavitCenuObjednavky(produkt.cena);
		} else {
			this.nastavitCenuObjednavky(sleva);
		}
	}

	public doplnitZpusobPlatby(zpusobPlatby: ZpusobPlatby): void {
		this.objednavka.zpusobPlatby = zpusobPlatby;
	}

	public doplnitSlevovyKupon(slevovyKupon: SlevovyKupon): void {
		this.objednavka.nazevSlevovehoKuponu = slevovyKupon.nazev;
		this.objednavka.slevaVProcentech = slevovyKupon.sleva;
	}

	public doplnitVybranehoUzivatele(radek: RadekSeznamu): void {
		this.objednavka.idUzivatele = radek.id;
		this.objednavka.prihlasovaciJmeno = radek.hodnoty[0].hodnota;
	}

	public jeUzivatelVybran(): boolean {
		return !NastrojeObecne.jeHodnotaPrazdna(this.objednavka.idUzivatele);
	}

	public doplnitFakturacniUdaje(formGroup: FormGroup): void {
		this.objednavka.jmeno = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.krestniJmeno.value);
		this.objednavka.prijmeni = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.prijmeni.value);
		this.objednavka.email = formGroup.controls.email.value;
		this.objednavka.telefon = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.telefon.value);
		this.objednavka.nazevOrganizace = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.nazevOrganizace.value);
		this.objednavka.ic = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.ic.value);
		this.objednavka.dic = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.dic.value);

		this.objednavka.fakturacniAdresa = new FakturacniAdresa();
		this.objednavka.fakturacniAdresa.ulice = formGroup.controls.ulice.value;
		this.objednavka.fakturacniAdresa.cisloPopisne = formGroup.controls.cisloPopisne.value;
		this.objednavka.fakturacniAdresa.obec = formGroup.controls.obec.value;
		this.objednavka.fakturacniAdresa.psc = formGroup.controls.psc.value;
		this.objednavka.fakturacniAdresa.stat = formGroup.controls.stat.value;
	}

	public vytvoritObjednavku(): Promise<any> {
		if (NastrojeDic.jeNeplatceDph(this.objednavka.dic)) {
			this.objednavka.cena.cenaSDph = this.objednavka.cena.cenaBezDph;
			this.objednavka.cena.koeficientDph = 0;
		}

		return this.objednavkaService.vytvoritObjednavku(this.objednavka)
			.toPromise();
	}

	public nastavitCenuObjednavky(cena: Cena): void {
		this.objednavka.cena = new Cena();
		this.objednavka.cena.cenaBezDph = cena.cenaBezDph;
		this.objednavka.cena.cenaSDph = cena.cenaSDph;
		this.objednavka.cena.koeficientDph = cena.koeficientDph;
	}

	public vyvolatUdalostProPripravuCenyProduktu(): void {
		this.pripravitCenuProduktu.emit();
	}
}
