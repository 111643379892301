/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./platby-a-objednavky.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/nastroje/pipes/lokalizace.pipe";
import * as i3 from "../sablony/tabulka-aktivnich-predplatnych/tabulka-aktivnich-predplatnych.component.ngfactory";
import * as i4 from "../sablony/tabulka-aktivnich-predplatnych/tabulka-aktivnich-predplatnych.component";
import * as i5 from "../../service/e-shop/predplatne.service";
import * as i6 from "@angular/router";
import * as i7 from "../objednavka/seznam/seznam-objednavek/seznam-objednavek.component.ngfactory";
import * as i8 from "../objednavka/seznam/seznam-objednavek/seznam-objednavek.component";
import * as i9 from "../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni";
import * as i10 from "../../service/seznam/seznam-objednavek/seznam-objednavek.service";
import * as i11 from "./platby-a-objednavky.component";
var styles_PlatbyAObjednavkyComponent = [i0.styles];
var RenderType_PlatbyAObjednavkyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlatbyAObjednavkyComponent, data: {} });
export { RenderType_PlatbyAObjednavkyComponent as RenderType_PlatbyAObjednavkyComponent };
export function View_PlatbyAObjednavkyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "main", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "container__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "container__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "container__content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-tabulka-aktivnich-predplatnych", [], null, null, null, i3.View_TabulkaAktivnichPredplatnychComponent_0, i3.RenderType_TabulkaAktivnichPredplatnychComponent)), i1.ɵdid(8, 114688, null, 0, i4.TabulkaAktivnichPredplatnychComponent, [i5.PredplatneService, i6.Router], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-seznam-objednavek", [], null, null, null, i7.View_SeznamObjednavekComponent_0, i7.RenderType_SeznamObjednavekComponent)), i1.ɵdid(10, 114688, null, 0, i8.SeznamObjednavekComponent, [i9.SeznamObjednavekNastaveniService, i10.SeznamObjednavekService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "platbyAObjednavky.nadpis")); _ck(_v, 4, 0, currVal_0); }); }
export function View_PlatbyAObjednavkyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-platby-a-objednavky", [], null, null, null, View_PlatbyAObjednavkyComponent_0, RenderType_PlatbyAObjednavkyComponent)), i1.ɵdid(1, 49152, null, 0, i11.PlatbyAObjednavkyComponent, [], null, null)], null, null); }
var PlatbyAObjednavkyComponentNgFactory = i1.ɵccf("app-platby-a-objednavky", i11.PlatbyAObjednavkyComponent, View_PlatbyAObjednavkyComponent_Host_0, {}, {}, []);
export { PlatbyAObjednavkyComponentNgFactory as PlatbyAObjednavkyComponentNgFactory };
