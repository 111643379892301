/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/layout/hlavni-menu/hlavni-menu.component.ngfactory";
import * as i3 from "./shared/layout/hlavni-menu/hlavni-menu.component";
import * as i4 from "./service/bezpecnost/autentizace.service";
import * as i5 from "keycloak-angular/src/services/keycloak.service";
import * as i6 from "@angular/router";
import * as i7 from "./shared/layout/paticka/paticka.component.ngfactory";
import * as i8 from "./shared/layout/paticka/paticka.component";
import * as i9 from "./service/obecne/verze-backendu.service";
import * as i10 from "./shared/nastroje/pipes/lokalizace.pipe";
import * as i11 from "ng2-responsive/browsers/browsers-directives";
import * as i12 from "ng2-responsive/config/config";
import * as i13 from "./app.component";
import * as i14 from "./service/obecne/optimalizace-pro-vyhledavace.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "main__outdated"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "chyba.zastaralyProhlizec")); _ck(_v, 2, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-hlavni-menu", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HlavniMenuComponent_0, i2.RenderType_HlavniMenuComponent)), i1.ɵdid(2, 114688, null, 0, i3.HlavniMenuComponent, [i4.AutentizaceService, i5.KeycloakService], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-paticka", [], null, null, null, i7.View_PatickaComponent_0, i7.RenderType_PatickaComponent)), i1.ɵdid(6, 114688, null, 0, i8.PatickaComponent, [i9.VerzeBackenduService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "main", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "img", [["src", "/assets/img/loga/FEN-logo.png"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main__not-available"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "popisek.obrazek.fen")), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "chyba.nepodporovanyProhlizec")); _ck(_v, 5, 0, currVal_1); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.LokalizacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "zacatek-stranky"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(3, 212992, null, 0, i11.ShowIEVersion, [i1.TemplateRef, i1.ViewContainerRef, i12.ResponsiveState, i1.ChangeDetectorRef], { showIEVersion: [0, "showIEVersion"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_2)), i1.ɵdid(6, 212992, null, 0, i11.HideIEVersion, [i1.TemplateRef, i1.ViewContainerRef, i12.ResponsiveState, i1.ChangeDetectorRef], { hideIEVersion: [0, "hideIEVersion"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(9, 212992, null, 0, i11.ShowIEVersion, [i1.TemplateRef, i1.ViewContainerRef, i12.ResponsiveState, i1.ChangeDetectorRef], { showIEVersion: [0, "showIEVersion"] }, null), i1.ɵpad(10, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "ie 11"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, "ie 9", "ie 10"); _ck(_v, 6, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, "ie 9", "ie 10"); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i6.Router, i4.AutentizaceService, i14.OptimalizaceProVyhledavaceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
