<ng-template [ngIf]="zobrazit">
  {{ otevritModalniPanel() }}
</ng-template>

<ng-template [ngIf]="!zobrazit">
  {{ zavritModalniPanel() }}
</ng-template>

<div #modalniPanel id="{{ idPrvku }}" [attr.zobrazit]="zobrazit" class="modal">
  <div class="modal-content modal-title">
    {{ nadpis | lokalizace }}
  </div>
  <div class="modal-content modal-body">
    {{ text | lokalizace }}
  </div>
  <div class="modal-footer">
    <button *ngIf="nazevAkcnihoTlacitka" (click)="oznamitNesouhlas()" class="waves-effect waves-light btn-flat modal-close">
      {{ nazevNeakcnihoTlacitka | lokalizace }}
    </button>

    <button (click)="oznamitSouhlas()" class="waves-effect waves-light btn-flat modal-close">
      {{ (nazevAkcnihoTlacitka ? nazevAkcnihoTlacitka :'tlacitko.zavrit') | lokalizace }}
    </button>
  </div>
</div>
