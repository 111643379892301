import {Component, Input} from '@angular/core';
import {StavUzivatele} from '../../../../../data/dodavatel/enum/stav-uzivatele.enum';
import {PolozkaSeznamuUzivateluSDodavatelem} from '../../../../../data/seznam/seznam-uzivatelu-s-dodavatelem/polozka-seznamu-uzivatelu-s-dodavatelem';
import {SeznamUzivateluSDodavatelemService} from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {NastrojeObecne} from '../../../../../shared/nastroje/nastroje-obecne';
import {NastrojeOsoby} from '../../../../../shared/nastroje/nastroje-osoby';
import {VyctovyTypPipe} from '../../../../../shared/nastroje/pipes/vyctovy-typ.pipe';
import {TypHodnotySeznamu} from '../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import {HodnotaSeznamu} from '../../../../../shared/seznam/struktura/hodnota-seznamu';
import {RadekSeznamu} from '../../../../../shared/seznam/struktura/radek-seznamu';
import {VytvoritObjednavkuService} from '../../../../../service/e-shop/vytvorit-objednavku.service';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-seznam',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-seznam.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-seznam.component.scss']
})
export class SeznamUzivateluSDodavatelemSeznamComponent {

    @Input() ucelSeznamu;

    public radkySeznamuProZobrazeni: Array<RadekSeznamu>;

    private idVybranehoUzivatele: number;

    constructor(
        private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService,
        private vyctovyTypPipe: VyctovyTypPipe,
        private vytvoritObjednavkuService: VytvoritObjednavkuService
    ) {
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    }

    public pripravitRadkySeznamuProZobrazeni(): Array<RadekSeznamu> {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }

        return this.radkySeznamuProZobrazeni;
    }

    public oznacitVybranyRadek(radek: RadekSeznamu) {
        this.idVybranehoUzivatele = radek.id;
        this.vytvoritObjednavkuService.doplnitVybranehoUzivatele(radek);
    }

    private pripravitRadkySeznamu(): Array<RadekSeznamu> {
        const radky = new Array<RadekSeznamu>();

        for (const uzivatel of this.seznamUzivateluSDodavatelemService.seznam.polozky) {
            radky.push(new RadekSeznamu(uzivatel.id, this.pripravitHodnotyRadkuSeznamu(uzivatel), this.pripravitUrlDetailuUzivatele(uzivatel.id), NastrojeObecne.jeHodnotaPrazdna(this.idVybranehoUzivatele) ? false : uzivatel.id === this.idVybranehoUzivatele));
        }

        return radky;
    }

    private pripravitHodnotyRadkuSeznamu(uzivatel: PolozkaSeznamuUzivateluSDodavatelem): Array<HodnotaSeznamu> {
        const hodnoty = new Array<HodnotaSeznamu>();

        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, uzivatel.prihlasovaciJmeno));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, uzivatel.email));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.id.toString(), 'center_focus_weak'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.vyctovyTypPipe.transform(uzivatel.stav, StavUzivatele), 'swap_horiz'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, NastrojeOsoby.prevestNaRetezec(uzivatel.krestniJmeno, uzivatel.prijmeni), 'person_outline'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.nazevOrganizacniJednotky, 'business'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.ic, 'account_balance'));

        return hodnoty;
    }

    private pripravitUrlDetailuUzivatele(id: number): string {
        return `/uzivatel/${id}`;
    }
}
