import {NastaveniZobrazeniSeznamuUzivateluSDodavatelem} from '../../data/seznam/seznam-uzivatelu-s-dodavatelem/filtr/nastaveni-zobrazeni-seznamu-uzivatelu-s-dodavatelem';
import {SeznamUzivateluSDodavatelem} from '../../data/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem';
import {NastaveniZobrazeniSeznamuObjednavek} from '../../data/seznam/seznam-objednavek/filtr/nastaveni-zobrazeni-seznamu-objednavek';
import {SeznamObjednavek} from '../../data/seznam/seznam-objednavek/seznam-objednavek';
import {NastaveniZobrazeniSeznamuObjednavekOperatora} from '../../data/seznam/seznam-objednavek-operatora/filtr/nastaveni-zobrazeni-seznamu-objednavek-operatora';
import {SeznamObjednavekOperatora} from '../../data/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora';
import {NastaveniZobrazeniSeznamuSlevovychKuponu} from '../../data/seznam/seznam-slevovych-kuponu/filtr/nastaveni-zobrazeni-seznamu-slevovych-kuponu';
import {SeznamSlevovychKuponu} from '../../data/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu';

export class NastrojeStrankovani {

    public static pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniSeznamuUzivateluSDodavatelem: NastaveniZobrazeniSeznamuUzivateluSDodavatelem, seznamUzivateluSDodavatelem: SeznamUzivateluSDodavatelem): number {
        return Math.ceil(seznamUzivateluSDodavatelem.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuUzivateluSDodavatelem.strankovani.pocetZaznamu);
    }

    public static pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniSeznamuObjednavek: NastaveniZobrazeniSeznamuObjednavek, seznamObjednavek: SeznamObjednavek): number {
        return Math.ceil(seznamObjednavek.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuObjednavek.strankovani.pocetZaznamu);
    }

    public static pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniSeznamuObjednavekOperatora: NastaveniZobrazeniSeznamuObjednavekOperatora, seznamObjednavekOperatora: SeznamObjednavekOperatora): number {
        return Math.ceil(seznamObjednavekOperatora.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuObjednavekOperatora.strankovani.pocetZaznamu);
    }

    public static pripravitMaximalniPocetStranekSlevovychKuponu(nastaveniZobrazeniSeznamuSlevovychKuponu: NastaveniZobrazeniSeznamuSlevovychKuponu, seznamSlevovychKuponu: SeznamSlevovychKuponu): number {
        return Math.ceil(seznamSlevovychKuponu.celkovyPocetZaznamu / nastaveniZobrazeniSeznamuSlevovychKuponu.strankovani.pocetZaznamu);
    }
}
