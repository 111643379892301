import {Component, OnInit} from '@angular/core';
import {PoradoveCisloKrokuObjednavky} from '../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import {Objednavka} from '../../../../data/e-shop/objednavka';
import {GoogleAnalyticsTrackingService} from '../../../../service/obecne/google-analytics-tracking.service';
import {NastrojeBase64} from '../../../../shared/nastroje/nastroje-base64';
import {ActivatedRoute, Router} from '@angular/router';
import {NastrojeUrl} from '../../../../shared/nastroje/nastroje-url';
import {NastrojeJsonu} from '../../../../shared/nastroje/nastroje-jsonu';
import {NastrojeObecne} from '../../../../shared/nastroje/nastroje-obecne';
import {NastrojePrevoduObjednavky} from '../../../../shared/nastroje/nastroje-prevodu-objednavky';

@Component({
    selector: 'app-vytvorit-objednavku-uzivatele',
    templateUrl: './vytvorit-objednavku-uzivatele.component.html',
    styleUrls: ['./vytvorit-objednavku-uzivatele.component.scss']
})
export class VytvoritObjednavkuUzivateleComponent implements OnInit {

    public objednavka: Objednavka;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private googleAnalyticsTrackingService: GoogleAnalyticsTrackingService
        ) {
        }

    ngOnInit() {
        this.zpracovatParametrRetezceDotazuZUrl();
    }

    private zpracovatParametrRetezceDotazuZUrl(): void {
        const parametrRetezceDotazu = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'data');

        if (NastrojeObecne.jeHodnotaPrazdna(parametrRetezceDotazu)) {
            this.router.navigate([`/`]);
        } else {
            const podoObjekt = NastrojeJsonu.parse(NastrojeBase64.dekodovatBase64NaUnicode(parametrRetezceDotazu));

            this.objednavka = NastrojePrevoduObjednavky.prevestPodoObjednavkyJsonDoObjednavky(podoObjekt);

            this.googleAnalyticsTrackingService.pridatConfig(podoObjekt.user.podoId);
            this.googleAnalyticsTrackingService.evidovatPridaniZboziDoKosiku(this.objednavka);
            this.googleAnalyticsTrackingService.evidovatZapocetiCheckoutu(this.objednavka);
            this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
        }
    }
}
