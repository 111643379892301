import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NastrojeModalnihoPanelu} from '../../nastroje/nastroje-modalniho-panelu';

@Component({
    selector: 'app-textovy-modalni-panel',
    templateUrl: './textovy-modalni-panel.component.html',
    styleUrls: ['./textovy-modalni-panel.component.scss']
})
export class TextovyModalniPanelComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    @Output() souhlas = new EventEmitter<String>();

    @Output() nesouhlas = new EventEmitter<String>();

    @Input() idPrvku: string;

    @Input() nadpis: string;

    @Input() text: string;

    @Input() zobrazit: boolean;

    @Input() nazevAkcnihoTlacitka: string;

    @Input() nazevNeakcnihoTlacitka = 'tlacitko.zavrit';

    constructor() {
    }

    ngOnInit() {
        this.inicializovatModalniPanel();
    }

    public otevritModalniPanel() {
        NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
    }

    public zavritModalniPanel() {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    }

    public oznamitSouhlas() {
        this.souhlas.emit(this.idPrvku);
    }

    public oznamitNesouhlas() {
        this.nesouhlas.emit(this.idPrvku);
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }
}
