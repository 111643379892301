import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NastrojeModule} from '../nastroje/nastroje.module';
import {FormularInputComponent} from './formularove-prvky/vstup/formular-input/formular-input.component';
import {FormularTextovePoleComponent} from './formularove-prvky/vstup/formular-textove-pole/formular-textove-pole.component';
import {FormularCheckboxComponent} from './formularove-prvky/vstup/formular-checkbox/formular-checkbox.component';
import {FormularSelectComponent} from './formularove-prvky/vstup/formular-select/formular-select.component';
import {FormularKalendarComponent} from './formularove-prvky/vstup/formular-kalendar/formular-kalendar.component';
import {FormularOutputKalendarComponent} from './formularove-prvky/vystup/formular-kalendar/formular-output-kalendar.component';
import {FormularOutputComponent} from './formularove-prvky/vystup/formular-output/formular-output.component';
import {FormularInputSAkciComponent} from './formularove-prvky/vstup/formular-input-s-akci/formular-input-s-akci.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NastrojeModule
    ],
    declarations: [
        FormularInputComponent,
        FormularTextovePoleComponent,
        FormularCheckboxComponent,
        FormularSelectComponent,
        FormularKalendarComponent,
        FormularOutputKalendarComponent,
        FormularOutputComponent,
        FormularInputSAkciComponent
    ],
    exports: [
        FormularInputComponent,
        FormularTextovePoleComponent,
        FormularCheckboxComponent,
        FormularSelectComponent,
        FormularKalendarComponent,
        FormularOutputKalendarComponent,
        FormularOutputComponent,
        FormularInputSAkciComponent
    ]
})

export class AbstraktniFormularModule {
}
