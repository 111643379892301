var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TypUceluFormulare } from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AbstraktniFormularComponent } from '../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { NastrojeObecne } from '../../../shared/nastroje/nastroje-obecne';
import { Notifikace, Uroven } from '../../../shared/notifikace';
import { NastrojeSlevovehoKuponu } from '../../../shared/nastroje/nastroje-slevoveho-kuponu';
import { SlevovyKuponService } from '../../../service/e-shop/slevovy-kupon.service';
var VytvoritSlevovyKuponComponent = /** @class */ (function (_super) {
    __extends(VytvoritSlevovyKuponComponent, _super);
    function VytvoritSlevovyKuponComponent(formBuilder, autentizaceService, slevovykuponService, router) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.slevovykuponService = slevovykuponService;
        _this.router = router;
        _this.zobrazitPotvrzeni = false;
        return _this;
    }
    VytvoritSlevovyKuponComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.pripravitNovySlevovyKupon();
    };
    VytvoritSlevovyKuponComponent.prototype.odeslat = function () {
        var _this = this;
        if (!NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon.produkty) && this.slevovyKupon.produkty.length === 0) {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'slevovyKupon.validace.produkty');
            return;
        }
        this.zobrazitVrtitko = true;
        this.slevovyKupon = NastrojeSlevovehoKuponu.pripravitSlevovyKuponZFormulare(this.formGroup, this.slevovyKupon);
        this.slevovykuponService.vytvoritSlevovyKupon(this.slevovyKupon)
            .toPromise()
            .then(function () {
            _this.zobrazitPotvrzeni = true;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    VytvoritSlevovyKuponComponent.prototype.prejitZpet = function () {
        this.router.navigate(['/slevove-kupony']);
    };
    VytvoritSlevovyKuponComponent.prototype.pripravitNovySlevovyKupon = function () {
        this.slevovyKupon = NastrojeSlevovehoKuponu.pripravitNovySlevovyKupon();
    };
    return VytvoritSlevovyKuponComponent;
}(AbstraktniFormularComponent));
export { VytvoritSlevovyKuponComponent };
