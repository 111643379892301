import { ElementRef, OnInit } from '@angular/core';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { NastrojeTabu } from '../../../../../shared/nastroje/nastroje-tabu';
import { NastrojeOdrolovani } from '../../../../../shared/nastroje/nastroje-odrolovani';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { ZpusobVytvoreniObjednavky } from '../../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
var VytvoritObjednavkuUzivateleWizardComponent = /** @class */ (function () {
    function VytvoritObjednavkuUzivateleWizardComponent(vytvoritObjednavkuService) {
        this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        this.krokObjednavky = KrokObjednavky;
        this.zpusobVytvoreniObjednavky = ZpusobVytvoreniObjednavky;
        this.jeWizardInicializovan = false;
    }
    VytvoritObjednavkuUzivateleWizardComponent.prototype.ngOnInit = function () {
        this.vytvoritObjednavkuService.inicializovatObjednavku();
        this.inicializovatWizard();
    };
    VytvoritObjednavkuUzivateleWizardComponent.prototype.prejitNaKartu = function (idKarty) {
        if (idKarty === KrokObjednavky.REKAPITULACE_OBJEDNAVKY) {
            this.aktualizovanaObjednavka = this.vytvoritObjednavkuService.objednavka;
        }
        NastrojeTabu.prejitNaKartu(idKarty);
        NastrojeOdrolovani.odrolovat('zacatek-stranky');
    };
    VytvoritObjednavkuUzivateleWizardComponent.prototype.inicializovatWizard = function () {
        NastrojeTabu.inicializovatTaby();
        this.jeWizardInicializovan = true;
    };
    return VytvoritObjednavkuUzivateleWizardComponent;
}());
export { VytvoritObjednavkuUzivateleWizardComponent };
