<button *ngIf="pripravitMaximalniPocetStranek() !== undefined" class="btn btn-secondary waves-effect waves-light right modal-trigger" data-target="nastaveni"
        [disabled]="pripravitCekovyPocetZaznamu() === undefined">
    <b>{{ 'tlacitko.nastaveni' | lokalizace }}</b>
    <i class="material-icons right">settings</i>
</button>

<div #modalniPanel id="nastaveni" class="modal">
    <div class="modal-content modal-title">
        {{ 'seznam.nastaveni' | lokalizace }}
    </div>
    <div class="modal-content modal-body">
        <app-formular-select
                [popisek]="'seznam.nastaveni.pocetZaznamuNaStranku'"
                [polozky]="typyPoctuZaznamuNaStranku"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.pocetZaznamuNaStranku"
                [prvniHodnotaNull]="false"
                [hodnotaKliceZObjektu]="false"></app-formular-select>
    </div>
    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>
        <button (click)="onSubmit()" class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.nastavit' | lokalizace }}
        </button>
    </div>
</div>
