<div #modalniPanel id="volba-produktu-modalni-panel" class="modal">
    <div class="modal-content modal-title">
        {{ 'objednavka.wizard.zvolitProdukt' | lokalizace }}
    </div>

    <div class="modal-content modal-body">
        <form [formGroup]="formGroup" class="form-column-layout">
            <app-formular-select
                    class="span-12"
                    [popisek]="'objednavka.produkt'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.produkt"
                    [polozky]="produkty"
                    [prvniHodnotaNull]="true"
                    [hodnotaKliceZObjektu]="true"
                    [nazevKliceObjektu]="'nazev'"
                    [nazevHodnotyObjektu]="'nazev'"></app-formular-select>

            <app-formular-input *ngIf="povolenoZobrazeniVlastniDelkyPredplatneho()" class="span-12"
                                [popisek]="'objednavka.delkaPlatnostiPredplatneho'"
                                [odeslan]="odeslan"
                                [ovladacFormulare]="formGroup.controls.delkaPlatnostiPredplatneho"></app-formular-input>

            <app-formular-select
                    class="span-12"
                    [popisek]="'objednavka.platebniObdobi'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.predplatne"
                    [polozky]="dostupnePredplatne"
                    [prvniHodnotaNull]="true"
                    [hodnotaKliceZObjektu]="true"
                    [nazevKliceObjektu]="'klic'"
                    [nazevHodnotyObjektu]="'hodnota'"></app-formular-select>
        </form>
    </div>

    <div class="modal-footer">
        <button class="modal-close waves-effect waves-light btn-flat">
            {{ 'tlacitko.zavrit' | lokalizace }}
        </button>

        <button (click)="onSubmit()" class="waves-effect waves-light btn-flat">
            {{ 'tlacitko.ulozit' | lokalizace }}
        </button>
    </div>
</div>
