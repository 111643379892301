import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FakturaService} from '../../../service/e-shop/faktura.service';
import {Faktura} from '../../../data/e-shop/faktura';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';
import {prostredi} from '../../../../prostredi/prostredi';
import {saveAs} from 'file-saver';

declare var $: any;

@Component({
    selector: 'app-tabulka-faktur-strankovana',
    templateUrl: './tabulka-faktur-strankovana.component.html',
    styleUrls: ['./tabulka-faktur-strankovana.component.scss', './tabulka-faktur-strankovana.component.ie.scss']
})
export class TabulkaFakturStrankovanaComponent implements OnInit {

    @ViewChild('vstupniPole', {static: false}) public vstupniPole: ElementRef;
    @Input() identifikatorObjednavky: string;

    public readonly formatData = NastrojeDataACasu.FORMAT_DATA;
    public readonly pocetZaznamuNaStranku = 5;
    public readonly zakladniUrl = prostredi.zakladniUrl;

    public faktury: Faktura[] = [];
    public indexAktualniStranky = 1;
    public jeStrankaPrvni = true;
    public jeStrankaPosledni = false;
    public maximalniPocetStranek: number;
    public zobrazeneFaktury: Faktura[] = [];
    public zobrazitVrtitko = false;
    private readonly blobNastaveni: any = {type: 'application/pdf'};

    constructor(
        private fakturaService: FakturaService
    ) {
    }

    ngOnInit() {
        this.pripravitFaktury();
    }

    public predchoziStranka(): void {
        this.indexAktualniStranky -= 1;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    }

    public dalsiStranka(): void {
        this.indexAktualniStranky += 1;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    }

    public nastavitStranku(): void {
        const element = $(this.vstupniPole.nativeElement);
        let hodnota = element.val();

        if (hodnota <= 0) {
            hodnota = 1;
        } else if (hodnota > this.maximalniPocetStranek) {
            hodnota = this.maximalniPocetStranek;
        }

        element.blur();
        element.val(hodnota);

        this.indexAktualniStranky = hodnota;
        this.pripravitZobrazeneFaktury();
        this.pripravJeStrankaPrvni();
        this.pripravJeStrankaPosledni();
    }

    public stahnoutFakturu(identifikator: string): void {
        this.zobrazitVrtitko = true;
        this.fakturaService.stahnoutFakturu(identifikator)
            .subscribe(data => {
                const blob = new Blob([data], this.blobNastaveni);
                saveAs(blob, identifikator + '.pdf');
                this.zobrazitVrtitko = false;
            }, () => {
                this.zobrazitVrtitko = false;
            });
    }

    private pripravitFaktury(): void {
        this.zobrazitVrtitko = true;

        this.fakturaService.nacistFaktury(this.identifikatorObjednavky)
            .toPromise()
            .then((odpoved) => {
                this.faktury = odpoved;
                this.maximalniPocetStranek = Math.ceil(this.faktury.length / this.pocetZaznamuNaStranku);
                this.pripravitZobrazeneFaktury();
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private pripravitZobrazeneFaktury(): void {
        const noveZobrazeneFaktury = [];
        const index = this.indexAktualniStranky * this.pocetZaznamuNaStranku - this.pocetZaznamuNaStranku;
        const posledniPolozka = this.indexAktualniStranky * this.pocetZaznamuNaStranku < this.faktury.length + 1 ? this.indexAktualniStranky * this.pocetZaznamuNaStranku : this.faktury.length;

        for (let i = index; i < posledniPolozka; i++) {
            noveZobrazeneFaktury.push(this.faktury[i]);
        }

        this.zobrazeneFaktury = noveZobrazeneFaktury;
    }

    private pripravJeStrankaPrvni(): void {
        this.jeStrankaPrvni = this.indexAktualniStranky === 1;
    }

    private pripravJeStrankaPosledni(): void {
        this.jeStrankaPosledni = this.indexAktualniStranky === this.maximalniPocetStranek;
    }
}
