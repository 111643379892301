import {FormGroup} from '@angular/forms';
import {SlevovyKupon} from '../../data/e-shop/slevovy-kupon';
import {Rozsah} from '../../data/obecne/rozsah';
import {NastrojeDataACasu} from './nastroje-data-a-casu';
import {NastrojeFormulare} from './nastroje-formulare';
import {NastrojeCisla} from './nastroje-cisla';

export class NastrojeSlevovehoKuponu {

    public static pripravitNovySlevovyKupon(): SlevovyKupon {
        const slevovyKupon: SlevovyKupon = new SlevovyKupon();

        slevovyKupon.platnost = new Rozsah();
        slevovyKupon.produkty = [];

        return slevovyKupon;
    }

    public static pripravitPlatnostSlevovehoKuponuZFormulare(formGroup: FormGroup): Rozsah {
        const platnost: Rozsah = new Rozsah();

        platnost.rozsahOd = NastrojeDataACasu.prevestRetezecNaDatum(formGroup.controls.platnostRozsahOd.value, NastrojeDataACasu.FORMAT_DATA);
        platnost.rozsahDo = NastrojeDataACasu.nastavitCasKonecDne(NastrojeDataACasu.prevestRetezecNaDatum(formGroup.controls.platnostRozsahDo.value, NastrojeDataACasu.FORMAT_DATA));

        return platnost;
    }

    public static pripravitSlevovyKuponZFormulare(formGroup: FormGroup, slevovyKupon: SlevovyKupon): SlevovyKupon {
        slevovyKupon.nazev = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.nazev.value);
        slevovyKupon.sleva = NastrojeCisla.prevestNaCislo(formGroup.controls.sleva.value);
        slevovyKupon.platnost = this.pripravitPlatnostSlevovehoKuponuZFormulare(formGroup);

        return slevovyKupon;
    }
}
