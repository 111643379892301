import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { prostredi } from '../../../../prostredi/prostredi';
import { StavPlatby } from '../../../data/e-shop/enum/stav-platby.enum';
import { ObjednavkaService } from '../../../service/e-shop/objednavka.service';
import { OnlinePlatbyService } from '../../../service/e-shop/online-platby.service';
import { NastrojeObecne } from '../../../shared/nastroje/nastroje-obecne';
import { NastrojeUrl } from '../../../shared/nastroje/nastroje-url';
var PresmerovaniNaPODO = /** @class */ (function () {
    function PresmerovaniNaPODO(objednavkaService, onlinePlatbyService, route, router) {
        this.objednavkaService = objednavkaService;
        this.onlinePlatbyService = onlinePlatbyService;
        this.route = route;
        this.router = router;
        this.zobrazitVrtitko = false;
    }
    PresmerovaniNaPODO.prototype.ngOnInit = function () {
        this.zpracovatParametrRetezceDotazuZUrl();
        this.pripravitStavPlatby();
    };
    PresmerovaniNaPODO.prototype.zpracovatParametrRetezceDotazuZUrl = function () {
        var parametrIdPlatby = NastrojeUrl.ziskatParametrRetezceDotazuZUrl(this.route, 'id');
        if (!NastrojeObecne.jeHodnotaPrazdna(parametrIdPlatby)) {
            this.idPlatby = Number(parametrIdPlatby);
        }
        else {
            this.router.navigate(["/"]);
        }
    };
    PresmerovaniNaPODO.prototype.pripravitStavPlatby = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.onlinePlatbyService.nacistStavPlatby(this.idPlatby)
            .toPromise()
            .then(function (odpoved) {
            _this.platba = odpoved;
            _this.pripravitObjednavku();
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    PresmerovaniNaPODO.prototype.pripravitObjednavku = function () {
        var _this = this;
        this.objednavkaService.nacistObjednavku(this.platba.identifikatorObjednavky)
            .toPromise()
            .then(function (odpoved) {
            _this.objednavka = odpoved;
            _this.presmerovatNaPODO();
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    PresmerovaniNaPODO.prototype.presmerovatNaPODO = function () {
        NastrojeUrl.otevritOdkaz(this.platba.stav === StavPlatby.ZAPLACENA ? prostredi.urlPODO + '/payment/success/' + this.objednavka.identifikatorProduktu : prostredi.urlPODO + '/payment/failure');
    };
    return PresmerovaniNaPODO;
}());
export { PresmerovaniNaPODO };
