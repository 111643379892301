import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {HodnotaSeznamu} from './struktura/hodnota-seznamu';
import {RadekSeznamu} from './struktura/radek-seznamu';
import {TypHodnotySeznamu} from './enum/typ-hodnoty-seznamu';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';
import {UcelSeznamu} from './enum/ucel-seznamu.enum';

@Component({
    selector: 'app-seznam',
    templateUrl: './seznam.component.html',
    styleUrls: ['./seznam.component.scss']
})
export class SeznamComponent {

    @Input() celkovyPocetZaznamu: number;
    @Input() radky: Array<RadekSeznamu>;
    @Input() ucelSeznamu: UcelSeznamu = UcelSeznamu.PROKLIK_NA_DETAIL;

    @Output() vybranRadek = new EventEmitter<RadekSeznamu>();

    private readonly zastupnyRetezecPrazdneHodnoty = '---';

    constructor(private router: Router) {
    }

    public pripravitPrimarniHodnotuRadku(radek: RadekSeznamu): HodnotaSeznamu {
        return this.pripravitHodnotu(radek, TypHodnotySeznamu.PRIMARNI);
    }

    public pripravitSekundarniHodnotuRadku(radek: RadekSeznamu): HodnotaSeznamu {
        return this.pripravitHodnotu(radek, TypHodnotySeznamu.SEKUNDARNI);
    }

    public pripravitDoplnujiciHodnotyRadku(radek: RadekSeznamu): Array<HodnotaSeznamu> {
        return this.pripravitHodnoty(radek, TypHodnotySeznamu.DOPLNUJICI);
    }

    public pripravitSpecialniHodnotyRadku(radek: RadekSeznamu): Array<HodnotaSeznamu> {
        return this.pripravitHodnoty(radek, TypHodnotySeznamu.SPECIALNI);
    }

    public jeHodnotaPrazdna(hodnota: any): boolean {
        return NastrojeObecne.jeHodnotaPrazdna(hodnota);
    }

    public pripravitHodnotuProZobrazeni(hodnota: any): any {
        return this.jeHodnotaPrazdna(hodnota) ? this.zastupnyRetezecPrazdneHodnoty : hodnota;
    }

    private pripravitHodnotu(radek: RadekSeznamu, typHodnoty: TypHodnotySeznamu): HodnotaSeznamu {
        return this.pripravitHodnoty(radek, typHodnoty)[0];
    }

    private pripravitHodnoty(radek: RadekSeznamu, typHodnoty: TypHodnotySeznamu): Array<HodnotaSeznamu> {
        const hodnoty = Array<HodnotaSeznamu>();

        for (const hodnota of radek.hodnoty) {
            if (hodnota.typHodnoty === typHodnoty) {
                hodnoty.push(hodnota);
            }
        }

        return hodnoty;
    }

    public zpracovatKliknutiNaRadku(radek: RadekSeznamu): void {
        if (this.ucelSeznamu === UcelSeznamu.PROKLIK_NA_DETAIL) {
            if (!NastrojeObecne.jeHodnotaPrazdna(radek.url)) {
                this.router.navigate([radek.url]);
            }
        } else if (this.ucelSeznamu === UcelSeznamu.VYBER_RADKY) {
            this.vybranRadek.emit(radek);
        }
    }
}
