/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-obsah.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hlavicka/seznam-objednavek-hlavicka.component.ngfactory";
import * as i3 from "./hlavicka/seznam-objednavek-hlavicka.component";
import * as i4 from "./seznam/seznam-objednavek-seznam.component.ngfactory";
import * as i5 from "./seznam/seznam-objednavek-seznam.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../shared/nastroje/pipes/datum.pipe";
import * as i8 from "../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service";
import * as i9 from "../../../../../shared/nastroje/pipes/vyctovy-typ.pipe";
import * as i10 from "./hlavicka/paticka/seznam-objednavek-paticka.component.ngfactory";
import * as i11 from "./hlavicka/paticka/seznam-objednavek-paticka.component";
import * as i12 from "./seznam-objednavek-obsah.component";
var styles_SeznamObjednavekObsahComponent = [i0.styles];
var RenderType_SeznamObjednavekObsahComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekObsahComponent, data: {} });
export { RenderType_SeznamObjednavekObsahComponent as RenderType_SeznamObjednavekObsahComponent };
export function View_SeznamObjednavekObsahComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-hlavicka", [], null, null, null, i2.View_SeznamObjednavekHlavickaComponent_0, i2.RenderType_SeznamObjednavekHlavickaComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamObjednavekHlavickaComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-seznam-objednavek-seznam", [], null, null, null, i4.View_SeznamObjednavekSeznamComponent_0, i4.RenderType_SeznamObjednavekSeznamComponent)), i1.ɵdid(3, 49152, null, 0, i5.SeznamObjednavekSeznamComponent, [i6.DecimalPipe, i7.DatumPipe, i8.SeznamObjednavekService, i9.VyctovyTypPipe], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-seznam-objednavek-paticka", [], null, null, null, i10.View_SeznamObjednavekPatickaComponent_0, i10.RenderType_SeznamObjednavekPatickaComponent)), i1.ɵdid(5, 49152, null, 0, i11.SeznamObjednavekPatickaComponent, [], null, null)], null, null); }
export function View_SeznamObjednavekObsahComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-obsah", [], null, null, null, View_SeznamObjednavekObsahComponent_0, RenderType_SeznamObjednavekObsahComponent)), i1.ɵdid(1, 49152, null, 0, i12.SeznamObjednavekObsahComponent, [], null, null)], null, null); }
var SeznamObjednavekObsahComponentNgFactory = i1.ɵccf("app-seznam-objednavek-obsah", i12.SeznamObjednavekObsahComponent, View_SeznamObjednavekObsahComponent_Host_0, {}, {}, []);
export { SeznamObjednavekObsahComponentNgFactory as SeznamObjednavekObsahComponentNgFactory };
