import moment from 'moment';

export class NastrojeDataACasu {

    // k obecnemu pouziti
    public static readonly FORMAT_ROKU = 'YYYY';
    public static readonly FORMAT_DATA = 'DD. MM. YYYY';
    public static readonly FORMAT_CASU_PRESNOST_NA_MINUTY = 'HH:mm';
    public static readonly FORMAT_CASU_PRESNOST_NA_VTERINY = 'HH:mm:ss';
    public static readonly FORMAT_DATA_A_CASU_PRESNOST_NA_VTERINY = 'DD. MM. YYYY HH:mm:ss';
    public static readonly FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY = 'DD. MM. YYYY HH:mm';

    // pouzivat pouze pro ucely formulare
    public static readonly FORMULAR_KALENDAR_FORMAT_DATA = 'dd. mm. yyyy';
    public static readonly FORMULAR_VALIDACE_FORMAT_DATA = /^([1-9]|[0][1-9]|[1-9]\d)\.[ ]?([1-9]|[0][1-9]|[1-9]\d)\d?\.[ ]?[1-2]\d{3}$/;

    // pouzivat pouze pro ucely prevodu dat z/na JSON
    public static readonly NAHRAZOVAC_VSTUPNI_FORMAT_DATA = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    public static readonly NAHRAZOVAC_VYSTUPNI_FORMAT_DATA = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    public static readonly NAHRAZOVAC_FORMAT_OBJEKT = 'YYYY-MM-DD HH:mm:ss';

    public static prevestRetezecNaDatum(hodnota: string, vzor: string): Date {
        if (hodnota === undefined || hodnota === null) {
            return null;
        } else {
            return moment(hodnota, vzor).toDate();
        }
    }

    public static prevestRetezecNaDatumDleISO8601(datum: string | number): string {
        return moment(datum).toISOString();
    }

    public static prevestRetezecNaDatumDleRFC3339(datum: Date): string {
        return moment(datum).format();
    }

    public static nastavitCasZacatekDne(datum: Date): Date {
        datum.setHours(0, 0, 0);

        return datum;
    }

    public static nastavitCasKonecDne(datum: Date): Date {
        datum.setHours(23, 59, 59);

        return datum;
    }

    public static formatovatDatum(datum: Date, vzor: string): string {
        return moment(datum).format(vzor).toString();
    }

    public static jeValidniDatum(datum: Date) {
        return Object.prototype.toString.call(datum) === '[object Date]' && !isNaN(datum.getTime());
    }

    public static pridatMesic(datum: Date): Date {
        const pocetDnuVPristimMesici = new Date(datum.getFullYear(), datum.getMonth() + 2, 0).getDate();

        return new Date(datum.getFullYear(), datum.getMonth() + 1, datum.getDate() > pocetDnuVPristimMesici ? pocetDnuVPristimMesici : datum.getDate());
    }

    public static pridatDny(datum: Date, dny: number) {
        return datum.setDate(datum.getDate() + dny);
    }
}
