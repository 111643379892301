export class ZruseniObjednavky {
    identifikator: string;
    duvod: string;
    datum: Date;

    constructor(identifikator: string, duvod: string) {
        this.identifikator = identifikator;
        this.duvod = duvod;
    }
}
