<div class="filter-container">
    <form [formGroup]="formGroup" class="form-column-layout" autocomplete="off">
        <app-formular-input
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.id'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.identifikator"></app-formular-input>

        <app-formular-input
                class="span-8"
                [popisek]="'filtrSeznamuObjednavek.nazevProduktu'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.nazevProduktu"></app-formular-input>

        <app-formular-kalendar
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.datumVytvoreniRozsahOd'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.datumVytvoreniRozsahOd"></app-formular-kalendar>

        <app-formular-kalendar
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.datumVytvoreniRozsahDo'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.datumVytvoreniRozsahDo"></app-formular-kalendar>

        <app-formular-select
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.typProduktu'"
                [polozky]="predplatne"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.predplatne"
                [raditHodnotyPodleNazvu]="false"></app-formular-select>

        <app-formular-kalendar
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.datumSplatnostiRozsahOd'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.datumSplatnostiRozsahOd"></app-formular-kalendar>

        <app-formular-kalendar
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.datumSplatnostiRozsahDo'"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.datumSplatnostiRozsahDo"></app-formular-kalendar>

        <app-formular-select
                class="span-4"
                [popisek]="'filtrSeznamuObjednavek.stavObjednavky'"
                [polozky]="stavObjednavky"
                [odeslan]="odeslan"
                [ovladacFormulare]="formGroup.controls.stavObjednavky"
                [raditHodnotyPodleNazvu]="false"></app-formular-select>
    </form>
</div>
