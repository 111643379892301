import { PipeTransform } from '@angular/core';
import { NastrojeObecne } from '../nastroje-obecne';
var SlevaPipe = /** @class */ (function () {
    function SlevaPipe() {
    }
    SlevaPipe.prototype.transform = function (hodnota) {
        return NastrojeObecne.formatovatRetezec('{0}%', hodnota);
    };
    return SlevaPipe;
}());
export { SlevaPipe };
