var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { AbstraktniFormularovyPrvekComponent } from '../abstraktni-formularovy-prvek.component';
/**
 * Usage:
 * <app-formular-textove-pole
 *  [popisek]="'Popisek'"
 *  [odeslan]="<hodnota odeslano>"
 *  [ovladacFormulare]="<ovladacFormulare>">
 * </app-formular-textove-pole>
 */
var FormularTextovePoleComponent = /** @class */ (function (_super) {
    __extends(FormularTextovePoleComponent, _super);
    function FormularTextovePoleComponent() {
        return _super.call(this) || this;
    }
    FormularTextovePoleComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.nastavitPosluchaceZmenyHodnotyTextovehoPole();
    };
    FormularTextovePoleComponent.prototype.nastavitPosluchaceZmenyHodnotyTextovehoPole = function () {
        var _this = this;
        this.ovladacFormulare.valueChanges.subscribe(function () {
            M.textareaAutoResize($(_this.textovePole.nativeElement));
        });
    };
    return FormularTextovePoleComponent;
}(AbstraktniFormularovyPrvekComponent));
export { FormularTextovePoleComponent };
