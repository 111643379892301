<div class="wizard content-fade-in">
    <div>
        <ul #wizard class="tabs">
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.VYPLNIT_FAKTURACNI_UDAJE }}">{{ 'objednavka.wizard.fakturacniUdaje' | lokalizace }}</a>
            </li>
            <li class="tab disable-click">
                <a href="#{{ krokObjednavky.REKAPITULACE_OBJEDNAVKY }}">{{ 'objednavka.wizard.rekapitulaceObjednavky' | lokalizace }}</a>
            </li>
        </ul>
    </div>

    <div class="wizard-content" [class.hidden]="!jeWizardInicializovan">
        <app-vyplnit-fakturacni-udaje id="{{ krokObjednavky.VYPLNIT_FAKTURACNI_UDAJE }}"
                                      [vybranyZpusobVytvoreniObjednavky]="zpusobVytvoreniObjednavky.UZIVATELEM"
                                      [objednavka]="puvodniObjednavka"
                                      (prejitNaKartu)="prejitNaKartu($event)"></app-vyplnit-fakturacni-udaje>
        <app-rekapitulace-objednavky-s-vyberem id="{{ krokObjednavky.REKAPITULACE_OBJEDNAVKY }}"
                                               [puvodniObjednavka]="puvodniObjednavka"
                                               [vybranyTypPredplatneho]="puvodniObjednavka.typPredplatneho"
                                               [aktualniFakturacniUdajeObjednavky]="aktualizovanaObjednavka"
                                               (prejitNaKartu)="prejitNaKartu($event)"></app-rekapitulace-objednavky-s-vyberem>
    </div>
</div>
