/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-operatora-obsah.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hlavicka/seznam-objednavek-operatora-hlavicka.component.ngfactory";
import * as i3 from "./hlavicka/seznam-objednavek-operatora-hlavicka.component";
import * as i4 from "./seznam/seznam-objednavek-operatora-seznam.component.ngfactory";
import * as i5 from "./seznam/seznam-objednavek-operatora-seznam.component";
import * as i6 from "../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service";
import * as i7 from "../../../../../shared/nastroje/pipes/datum.pipe";
import * as i8 from "../../../../../shared/nastroje/pipes/vyctovy-typ.pipe";
import * as i9 from "./paticka/seznam-objednavek-operatora-paticka.component.ngfactory";
import * as i10 from "./paticka/seznam-objednavek-operatora-paticka.component";
import * as i11 from "./seznam-objednavek-operatora-obsah.component";
var styles_SeznamObjednavekOperatoraObsahComponent = [i0.styles];
var RenderType_SeznamObjednavekOperatoraObsahComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekOperatoraObsahComponent, data: {} });
export { RenderType_SeznamObjednavekOperatoraObsahComponent as RenderType_SeznamObjednavekOperatoraObsahComponent };
export function View_SeznamObjednavekOperatoraObsahComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-hlavicka", [], null, null, null, i2.View_SeznamObjednavekOperatoraHlavickaComponent_0, i2.RenderType_SeznamObjednavekOperatoraHlavickaComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamObjednavekOperatoraHlavickaComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-seznam-objednavek-operatora-seznam", [], null, null, null, i4.View_SeznamObjednavekOperatoraSeznamComponent_0, i4.RenderType_SeznamObjednavekOperatoraSeznamComponent)), i1.ɵdid(3, 49152, null, 0, i5.SeznamObjednavekOperatoraSeznamComponent, [i6.SeznamObjednavekOperatoraService, i7.DatumPipe, i8.VyctovyTypPipe], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-seznam-objednavek-operatora-paticka", [], null, null, null, i9.View_SeznamObjednavekOperatoraPatickaComponent_0, i9.RenderType_SeznamObjednavekOperatoraPatickaComponent)), i1.ɵdid(5, 49152, null, 0, i10.SeznamObjednavekOperatoraPatickaComponent, [], null, null)], null, null); }
export function View_SeznamObjednavekOperatoraObsahComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-obsah", [], null, null, null, View_SeznamObjednavekOperatoraObsahComponent_0, RenderType_SeznamObjednavekOperatoraObsahComponent)), i1.ɵdid(1, 49152, null, 0, i11.SeznamObjednavekOperatoraObsahComponent, [], null, null)], null, null); }
var SeznamObjednavekOperatoraObsahComponentNgFactory = i1.ɵccf("app-seznam-objednavek-operatora-obsah", i11.SeznamObjednavekOperatoraObsahComponent, View_SeznamObjednavekOperatoraObsahComponent_Host_0, {}, {}, []);
export { SeznamObjednavekOperatoraObsahComponentNgFactory as SeznamObjednavekOperatoraObsahComponentNgFactory };
