import {Injectable} from '@angular/core';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {prostredi} from '../../../prostredi/prostredi';
import {Observable} from 'rxjs';
import {Predplatne} from '../../data/e-shop/predplatne';
import {Faktura} from '../../data/e-shop/faktura';

@Injectable()
export class FakturaService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(
        protected http: HttpClient,
        protected autentizaceService: AutentizaceService
    ) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopDetailFaktury;
    }

    public nacistAktivniPredplatne(): Observable<Predplatne[]> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/aktivni-predplatne')
            .catch(this.zpracovatChybu);
    }

    public nacistFaktury(identifikator: string): Observable<Faktura[]> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/seznam-faktur/' + identifikator)
            .catch(this.zpracovatChybu);
    }

    public stahnoutFakturu(identifikator: string) {
        return this.pozadavekStahnoutSoubor('/stahnout-fakturu/'+ identifikator, true)
            .catch(this.zpracovatChybu);
    }
}
