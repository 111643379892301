<app-vrtitko [zobrazit]="celkovyPocetZaznamu === undefined"></app-vrtitko>

<section class="detail-card list">
    <div *ngIf="celkovyPocetZaznamu === 0" class="no-result">
        {{'seznam.zaznamNenalezen' | lokalizace}}
    </div>
    <div>
        <div class="list__item" [ngClass]="{'clickable': radek.url, 'selected': radek.jeVybrany}" *ngFor="let radek of radky" (click)="zpracovatKliknutiNaRadku(radek)">
            <div class="title-container">
                <div>
                    <div class="title">
                        <i *ngIf="pripravitPrimarniHodnotuRadku(radek).ikona" class="material-icons {{ pripravitPrimarniHodnotuRadku(radek).styl }}">{{pripravitPrimarniHodnotuRadku(radek).ikona}}</i>
                        <span>{{pripravitPrimarniHodnotuRadku(radek).hodnota}}</span>
                    </div>
                    <div class="subtitle">
                        {{pripravitSekundarniHodnotuRadku(radek).hodnota}}
                    </div>
                </div>
                <ng-container *ngFor="let specialniHodnota of pripravitSpecialniHodnotyRadku(radek)">
                    <div class="{{specialniHodnota.styl}}">
                        <i class="material-icons">{{specialniHodnota.ikona}}</i>
                        <label *ngIf="specialniHodnota.hodnota">{{pripravitHodnotuProZobrazeni(specialniHodnota.hodnota) | lokalizace}}</label>
                    </div>
                </ng-container>
            </div>
            <div class="information">
                <ng-container *ngFor="let doplnujiciHodnota of pripravitDoplnujiciHodnotyRadku(radek)">
                    <div class="information__item {{doplnujiciHodnota.styl}}" [class.disabledItem]="jeHodnotaPrazdna(doplnujiciHodnota.hodnota)">
                        <i class="material-icons">{{doplnujiciHodnota.ikona}}</i>
                        <label>{{pripravitHodnotuProZobrazeni(doplnujiciHodnota.hodnota) | lokalizace}}</label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>
