import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StavObjednavky } from '../../../data/e-shop/enum/stav-objednavky.enum';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { ObjednavkaService } from '../../../service/e-shop/objednavka.service';
import { saveAs } from 'file-saver';
var DetailObjednavkyComponent = /** @class */ (function () {
    function DetailObjednavkyComponent(autentizaceService, objednavkaService, router, route) {
        this.autentizaceService = autentizaceService;
        this.objednavkaService = objednavkaService;
        this.router = router;
        this.route = route;
        this.zobrazitVrtitko = false;
        this.zobrazitTabulkuFaktur = false;
        this.zobrazitZruseniObjednavky = false;
        this.zobrazitTlatickoZruseniObjednavky = false;
        this.blobNastaveni = { type: 'application/pdf' };
    }
    DetailObjednavkyComponent.prototype.ngOnInit = function () {
        this.inicializovatPromenne();
        this.zpracovatParametry();
    };
    DetailObjednavkyComponent.prototype.prejitZpet = function () {
        this.router.navigate(['/sekce-operatora']);
    };
    DetailObjednavkyComponent.prototype.prejitNaDetailPlatby = function () {
        this.router.navigate(['/e-shop/platba'], { queryParams: { id: this.objednavka.idPlatby } });
    };
    DetailObjednavkyComponent.prototype.prejitNaDetailPlatbyPodleObjednavky = function () {
        this.router.navigate(['/e-shop/platba'], { queryParams: { identifikatorObjednavky: this.objednavka.identifikator } });
    };
    DetailObjednavkyComponent.prototype.stahnoutZalohovouFakturu = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.objednavkaService.stahnoutZalohovouFakturu(this.objednavka.identifikator)
            .subscribe(function (data) {
            var blob = new Blob([data], _this.blobNastaveni);
            saveAs(blob, _this.objednavka.identifikator + '.pdf');
            _this.zobrazitVrtitko = false;
        }, function () {
            _this.zobrazitVrtitko = false;
        });
    };
    DetailObjednavkyComponent.prototype.prejitNaPlatbyAObjednavky = function () {
        this.router.navigate(['/e-shop/platby-a-objednavky/']);
    };
    DetailObjednavkyComponent.prototype.inicializovatPromenne = function () {
        this.jePrihlasenCDDUzivatel = this.autentizaceService.jePrihlasenyUzivatelCdd();
    };
    DetailObjednavkyComponent.prototype.zpracovatParametry = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.identifikatorObjednavky = params['identifikatorObjednavky'];
            _this.pripravitObjednavku();
        });
    };
    DetailObjednavkyComponent.prototype.pripravitObjednavku = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.objednavkaService.nacistObjednavku(this.identifikatorObjednavky)
            .toPromise()
            .then(function (odpoved) {
            _this.objednavka = odpoved;
            _this.zobrazitTabulkuFaktur = !(_this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_ZAKAZNIKEM || _this.objednavka.stavObjednavky === StavObjednavky.CEKA_NA_ZAPLACENI);
            _this.zobrazitZruseniObjednavky = _this.objednavka.stavObjednavky === StavObjednavky.STORNOVANA;
            _this.zobrazitTlatickoZruseniObjednavky = _this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_ZAKAZNIKEM || _this.objednavka.stavObjednavky === StavObjednavky.VYTVORENA_OPERATOREM || _this.objednavka.stavObjednavky === StavObjednavky.CEKA_NA_ZAPLACENI;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return DetailObjednavkyComponent;
}());
export { DetailObjednavkyComponent };
