import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Objednavka} from '../../../data/e-shop/objednavka';
import {TypPredplatneho} from '../../../data/e-shop/enum/predplatne.enum';
import {NastrojeDataACasu} from '../../../shared/nastroje/nastroje-data-a-casu';
import {Mena} from '../../../data/obecne/enum/mena.enum';
import {prostredi} from '../../../../prostredi/prostredi';
import {NastrojeModalnihoPanelu} from '../../../shared/nastroje/nastroje-modalniho-panelu';

@Component({
    selector: 'app-udaje-o-objednavce',
    templateUrl: './udaje-o-objednavce.component.html',
    styleUrls: ['./udaje-o-objednavce.component.scss']
})
export class UdajeOObjednavceComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    @Input() objednavka: Objednavka;

    public readonly formatData = NastrojeDataACasu.FORMAT_DATA;
    public readonly formatDataACasu = NastrojeDataACasu.FORMAT_DATA_A_CASU_PRESNOST_NA_MINUTY;
    public readonly mena = Mena;
    public readonly predplatne = TypPredplatneho;

    public readonly platbyAObjednavkyUrl: string = prostredi.url + '/#/e-shop/platby-a-objednavky';

    ngOnInit() {
        this.inicializovatModalniPanel();
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

}
