import {TypHodnotySeznamu} from '../enum/typ-hodnoty-seznamu';

export class HodnotaSeznamu {

    private _typHodnoty: TypHodnotySeznamu;

    private _hodnota: string;

    private _ikona: string;

    private _styl: string;

    constructor(typHodnoty: TypHodnotySeznamu, hodnota: string, ikona?: string, styl?: string) {
        this._typHodnoty = typHodnoty;
        this._hodnota = hodnota;
        this._ikona = ikona;
        this._styl = styl;
    }

    get typHodnoty(): TypHodnotySeznamu {
        return this._typHodnoty;
    }

    set typHodnoty(value: TypHodnotySeznamu) {
        this._typHodnoty = value;
    }

    get hodnota(): string {
        return this._hodnota;
    }

    set hodnota(value: string) {
        this._hodnota = value;
    }

    get ikona(): string {
        return this._ikona;
    }

    set ikona(value: string) {
        this._ikona = value;
    }

    get styl(): string {
        return this._styl;
    }

    set styl(value: string) {
        this._styl = value;
    }
}
