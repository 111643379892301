import { StavUzivatele } from '../../../../../data/dodavatel/enum/stav-uzivatele.enum';
import { SeznamUzivateluSDodavatelemService } from '../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { NastrojeOsoby } from '../../../../../shared/nastroje/nastroje-osoby';
import { VyctovyTypPipe } from '../../../../../shared/nastroje/pipes/vyctovy-typ.pipe';
import { TypHodnotySeznamu } from '../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import { HodnotaSeznamu } from '../../../../../shared/seznam/struktura/hodnota-seznamu';
import { RadekSeznamu } from '../../../../../shared/seznam/struktura/radek-seznamu';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
var SeznamUzivateluSDodavatelemSeznamComponent = /** @class */ (function () {
    function SeznamUzivateluSDodavatelemSeznamComponent(seznamUzivateluSDodavatelemService, vyctovyTypPipe, vytvoritObjednavkuService) {
        this.seznamUzivateluSDodavatelemService = seznamUzivateluSDodavatelemService;
        this.vyctovyTypPipe = vyctovyTypPipe;
        this.vytvoritObjednavkuService = vytvoritObjednavkuService;
    }
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    };
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.pripravitRadkySeznamuProZobrazeni = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }
        return this.radkySeznamuProZobrazeni;
    };
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.oznacitVybranyRadek = function (radek) {
        this.idVybranehoUzivatele = radek.id;
        this.vytvoritObjednavkuService.doplnitVybranehoUzivatele(radek);
    };
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.pripravitRadkySeznamu = function () {
        var radky = new Array();
        for (var _i = 0, _a = this.seznamUzivateluSDodavatelemService.seznam.polozky; _i < _a.length; _i++) {
            var uzivatel = _a[_i];
            radky.push(new RadekSeznamu(uzivatel.id, this.pripravitHodnotyRadkuSeznamu(uzivatel), this.pripravitUrlDetailuUzivatele(uzivatel.id), NastrojeObecne.jeHodnotaPrazdna(this.idVybranehoUzivatele) ? false : uzivatel.id === this.idVybranehoUzivatele));
        }
        return radky;
    };
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.pripravitHodnotyRadkuSeznamu = function (uzivatel) {
        var hodnoty = new Array();
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, uzivatel.prihlasovaciJmeno));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, uzivatel.email));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.id.toString(), 'center_focus_weak'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.vyctovyTypPipe.transform(uzivatel.stav, StavUzivatele), 'swap_horiz'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, NastrojeOsoby.prevestNaRetezec(uzivatel.krestniJmeno, uzivatel.prijmeni), 'person_outline'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.nazevOrganizacniJednotky, 'business'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, uzivatel.ic, 'account_balance'));
        return hodnoty;
    };
    SeznamUzivateluSDodavatelemSeznamComponent.prototype.pripravitUrlDetailuUzivatele = function (id) {
        return "/uzivatel/" + id;
    };
    return SeznamUzivateluSDodavatelemSeznamComponent;
}());
export { SeznamUzivateluSDodavatelemSeznamComponent };
