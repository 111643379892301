import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SlevovyKupon} from '../../../../data/e-shop/slevovy-kupon';
import {NastrojeModalnihoPanelu} from '../../../../shared/nastroje/nastroje-modalniho-panelu';
import {Produkt} from '../../../../data/e-shop/produkt';
import {ProduktService} from '../../../../service/e-shop/produkt.service';
import {TypUceluFormulare} from '../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {AbstraktniFormularComponent} from '../../../../shared/formular/abstraktni-formular.component';
import {AutentizaceService} from '../../../../service/bezpecnost/autentizace.service';
import {NastrojeTabulkyProduktu} from '../../../../shared/nastroje/nastroje-tabulky-produktu';
import {TypPredplatneho} from '../../../../data/e-shop/enum/predplatne.enum';

@Component({
	selector: 'app-slevovy-kupon-tabulka-produktu',
	templateUrl: './slevovy-kupon-tabulka-produktu.component.html',
	styleUrls: ['./slevovy-kupon-tabulka-produktu.component.scss']
})
export class SlevovyKuponTabulkaProduktuComponent extends AbstraktniFormularComponent implements OnInit {

	@ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

	@Input() formGroup: FormGroup;
	@Input() vytvorit = false;
	@Input() odeslan = false;
	@Input() slevovyKupon: SlevovyKupon;

	public produkty: Produkt[] = [];
	public zobrazitVrtitko = false;

	constructor(
		protected formBuilder: FormBuilder,
		protected autentizaceService: AutentizaceService,
		private produktService: ProduktService
	) {
		super(autentizaceService);
	}

	ngOnInit(): void {
		if (this.vytvorit) {
			this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
			this.inicializovatModalniPanel();
		}

		this.pripravitSeznamProduktu();
	}

	public odeslat(): void {
	}

	public pripravitNazevProduktu(klicProduktu: string): string {
		return this.produkty.find(produkt => produkt.identifikator === klicProduktu).nazev;
	}

	public smazatProdukt(index: number): void {
		this.slevovyKupon.produkty.splice(index, 1);
	}

	public otevritModalniPanel(): void {
		NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
		NastrojeTabulkyProduktu.aktualizovatVybraneProduktyVeFormulari(this.formGroup, this.slevovyKupon.produkty);
	}

	public hromadneNastavitStavVsemProduktum(stav: boolean): void {
		NastrojeTabulkyProduktu.hromadneNastavitStavVsemProduktum(this.formGroup, stav);
	}

	public pridatProdukty(): void {
		this.slevovyKupon.produkty = NastrojeTabulkyProduktu.pripravitKolekciVybranychProduktu(this.formGroup);

		this.zavritModalniPanel();
	}

	public zavritModalniPanel(): void {
		NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
	}

	private inicializovatModalniPanel(): void {
		NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
	}

	private pripravitSeznamProduktu(): void {
		this.zobrazitVrtitko = true;

		this.produktService.nacistSeznamProduktu().subscribe(data => {
			this.produkty = data.filter(produkt => !produkt.dostupnePredplatne.includes(TypPredplatneho.NA_MIRU));
			this.zobrazitVrtitko = false;

			if (this.vytvorit) {
				this.inicializovatFormularovaData();
			}
		}, () => this.zobrazitVrtitko = false);
	}

	private inicializovatFormularovaData(): void {
		this.produkty.forEach(produkt => this.formGroup.addControl(produkt.identifikator, new FormControl('', [])));
	}
}
