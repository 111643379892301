import {Pipe, PipeTransform} from '@angular/core';
import {Lokalizace} from '../../lokalizace';
import {NastrojeEnumu} from '../nastroje-enumu';
import {NastrojeObecne} from '../nastroje-obecne';

@Pipe({
    name: 'vyctovyTyp'
})

export class VyctovyTypPipe implements PipeTransform {
    transform(hodnota: any, typEnumu: any): string {
        if (!NastrojeObecne.jeHodnotaPrazdna(hodnota) && !NastrojeObecne.jeHodnotaPrazdna(typEnumu)) {
            const typEnumuJakoRetezec = (typeof typEnumu === 'string' || typEnumu instanceof String) ?
                typEnumu.toString() : NastrojeEnumu.pripravitTypEnumuJakoRetezec(typEnumu);

            if (!NastrojeObecne.jeHodnotaPrazdna(typEnumuJakoRetezec)) {
                return Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicEnumuVTextech(typEnumuJakoRetezec, hodnota));
            }
        }

        return hodnota;
    }
}
