import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TypPoctuZaznamuNaStranku} from '../../../../../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import {TypUceluFormulare} from '../../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import {AutentizaceService} from '../../../../../../service/bezpecnost/autentizace.service';
import {SeznamUzivateluSDodavatelemNastaveniService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem-nastaveni.service';
import {SeznamUzivateluSDodavatelemService} from '../../../../../../service/seznam/seznam-uzivatelu-s-dodavatelem/seznam-uzivatelu-s-dodavatelem.service';
import {AbstraktniFormularComponent} from '../../../../../../shared/formular/abstraktni-formular.component';
import {NastrojeEnumu} from '../../../../../../shared/nastroje/nastroje-enumu';
import {NastrojeModalnihoPanelu} from '../../../../../../shared/nastroje/nastroje-modalniho-panelu';
import {NastrojeStrankovani} from '../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-uzivatelu-s-dodavatelem-nastaveni',
    templateUrl: './seznam-uzivatelu-s-dodavatelem-nastaveni.component.html',
    styleUrls: ['./seznam-uzivatelu-s-dodavatelem-nastaveni.component.scss']
})
export class SeznamUzivateluSDodavatelemNastaveniComponent extends AbstraktniFormularComponent implements OnInit {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    public typyPoctuZaznamuNaStranku = TypPoctuZaznamuNaStranku;

    constructor(
        protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private seznamUzivateluSDodavatelemNastaveniService: SeznamUzivateluSDodavatelemNastaveniService,
        private seznamUzivateluSDodavatelemService: SeznamUzivateluSDodavatelemService
    ) {
        super(autentizaceService);
    }

    ngOnInit() {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
    }

    odeslat(): void {
        const pocetZaznamuNaStranku = this.formGroup.controls.pocetZaznamuNaStranku.value;
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitPocetZaznamuNaStranku(+TypPoctuZaznamuNaStranku[pocetZaznamuNaStranku]);
        this.seznamUzivateluSDodavatelemNastaveniService.nastavitAktualniStranku(1);
        this.seznamUzivateluSDodavatelemService.aktualizovat();
    }

    private inicializovatFormularovaData() {
        const pocetZaznamuNaStranku = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni().strankovani.pocetZaznamu;

        this.formGroup.addControl('pocetZaznamuNaStranku', new FormControl(NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStranku), [
            Validators.required
        ]));
    }

    private inicializovatModalniPanel() {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    public pripravitMaximalniPocetStranek() {
        const seznamUzivatelu = this.seznamUzivateluSDodavatelemService.seznam;
        const nastaveniZobrazeniUzivatelu = this.seznamUzivateluSDodavatelemNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekUzivateluSDodavatelem(nastaveniZobrazeniUzivatelu, seznamUzivatelu);
    }

    public pripravitCekovyPocetZaznamu() {
        return this.seznamUzivateluSDodavatelemService.seznam.celkovyPocetZaznamu;
    }
}
