import { ElementRef } from '@angular/core';
import { NastrojeStrankovani } from '../../../../../../../../shared/nastroje/nastroje-strankovani';
import { NastrojeCisla } from '../../../../../../../../shared/nastroje/nastroje-cisla';
import { SeznamObjednavekNastaveniService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek-nastaveni';
import { SeznamObjednavekService } from '../../../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
var SeznamObjednavekAktualniStrankaComponent = /** @class */ (function () {
    function SeznamObjednavekAktualniStrankaComponent(seznamObjednavekNastaveniService, seznamObjednavekService) {
        this.seznamObjednavekNastaveniService = seznamObjednavekNastaveniService;
        this.seznamObjednavekService = seznamObjednavekService;
    }
    SeznamObjednavekAktualniStrankaComponent.prototype.pripravitAktualniStranku = function () {
        return this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    };
    SeznamObjednavekAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranek = function () {
        var seznamObjednavek = this.seznamObjednavekService.seznam;
        var nastaveniZobrazeniObjednavek = this.seznamObjednavekNastaveniService.getNastaveniZobrazeni();
        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavek(nastaveniZobrazeniObjednavek, seznamObjednavek);
    };
    SeznamObjednavekAktualniStrankaComponent.prototype.nastavitStranku = function () {
        var element = $(this.vstupniPole.nativeElement);
        var hodnota = element.val();
        if (!NastrojeCisla.jeCislo(hodnota)) {
            element.val(this.seznamObjednavekNastaveniService.getNastaveniZobrazeni().strankovani.stranka);
        }
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (hodnota <= 0) {
            hodnota = 1;
        }
        else if (hodnota > maximalniPocetStranek) {
            hodnota = maximalniPocetStranek;
        }
        element.blur();
        element.val(hodnota);
        this.seznamObjednavekNastaveniService.nastavitAktualniStranku(hodnota);
        this.seznamObjednavekService.aktualizovat();
    };
    SeznamObjednavekAktualniStrankaComponent.prototype.pripravitMaximalniPocetStranekProZobrazeni = function () {
        var maximalniPocetStranek = this.pripravitMaximalniPocetStranek();
        if (NastrojeCisla.jeCislo(maximalniPocetStranek)) {
            this.maximalniPocetStranekProZobrazeni = maximalniPocetStranek;
        }
        return this.maximalniPocetStranekProZobrazeni;
    };
    SeznamObjednavekAktualniStrankaComponent.prototype.pripravitCekovyPocetZaznamu = function () {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    };
    return SeznamObjednavekAktualniStrankaComponent;
}());
export { SeznamObjednavekAktualniStrankaComponent };
