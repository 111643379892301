import { OnInit } from '@angular/core';
import { AutentizaceService } from '../../service/bezpecnost/autentizace.service';
import { Router } from '@angular/router';
var SekceOperatoraComponent = /** @class */ (function () {
    function SekceOperatoraComponent(autentizaceService, router) {
        this.autentizaceService = autentizaceService;
        this.router = router;
    }
    SekceOperatoraComponent.prototype.ngOnInit = function () {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        }
        else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate(["/"]);
        }
    };
    SekceOperatoraComponent.prototype.presmerovatNaVytvoritObjednavkuOperatorem = function () {
        this.router.navigate(['e-shop/objednavka-operatora']);
    };
    return SekceOperatoraComponent;
}());
export { SekceOperatoraComponent };
