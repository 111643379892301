import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AutentizaceService} from '../bezpecnost/autentizace.service';
import {AbstraktniService, TypPozadavku} from '../obecne/abstraktni-service';
import {SlevovyKupon} from '../../data/e-shop/slevovy-kupon';
import {Rozsah} from '../../data/obecne/rozsah';
import {NastrojeJsonu} from '../../shared/nastroje/nastroje-jsonu';
import {prostredi} from '../../../prostredi/prostredi';
import {Polozka} from '../../data/obecne/polozka';
import {TypPredplatneho} from '../../data/e-shop/enum/predplatne.enum';

@Injectable()
export class SlevovyKuponService extends AbstraktniService {

    protected resourceClassUri = '';

    constructor(protected http: HttpClient, protected autentizaceService: AutentizaceService) {
        super(http, autentizaceService);
        this.url = prostredi.urlBackendEshopSlevoveKupony;
    }

    public nacistSlevovyKupon(nazevSlevovehoKuponu: string): Observable<SlevovyKupon> {
        return this.pozadavekSTokenem(TypPozadavku.GET, '/' + nazevSlevovehoKuponu)
            .catch(this.zpracovatChybu);
    }

    public upravitPlatnostSlevovehoKuponu(nazevSlevovehoKuponu: string, platnost: Rozsah): Observable<any> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/zmena-platnosti/' + nazevSlevovehoKuponu, NastrojeJsonu.stringifyRFC3339(platnost))
            .catch(this.zpracovatChybu);
    }

    public vytvoritSlevovyKupon(kupon: SlevovyKupon): Observable<any> {
        return this.pozadavekSTokenem(TypPozadavku.POST, '/novy', NastrojeJsonu.stringifyRFC3339(kupon))
            .catch(this.zpracovatChybu);
    }

    public overitPlatnostSlevovehoKuponu(nazevSlevovehoKuponu: string, vybranyProdukt: string, vybranyTypPredplatneho: TypPredplatneho): Observable<SlevovyKupon> {
        const parametry = [
            new Polozka('nazevSlevovehoKuponu', nazevSlevovehoKuponu),
            new Polozka('vybranyProdukt', vybranyProdukt),
            new Polozka('vybranyTypPredplatneho', vybranyTypPredplatneho)
        ];

        return this.pozadavekSTokenem(TypPozadavku.GET, '/overeni-platnosti', null, parametry)
            .catch(this.zpracovatChybu);
    }
}
