import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { prostredi } from '../../../../../../prostredi/prostredi';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { PoradoveCisloKrokuObjednavky } from '../../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import { TypPredplatneho } from '../../../../../data/e-shop/enum/predplatne.enum';
import { ZpusobPlatby } from '../../../../../data/e-shop/enum/zpusob-platby.enum';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { Produkt } from '../../../../../data/e-shop/produkt';
import { Mena } from '../../../../../data/obecne/enum/mena.enum';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { ObjednavkaService } from '../../../../../service/e-shop/objednavka.service';
import { OnlinePlatbyService } from '../../../../../service/e-shop/online-platby.service';
import { ProduktService } from '../../../../../service/e-shop/produkt.service';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { GoogleAnalyticsTrackingService } from '../../../../../service/obecne/google-analytics-tracking.service';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { NastrojeDataACasu } from '../../../../../shared/nastroje/nastroje-data-a-casu';
import { NastrojeModalnihoPanelu } from '../../../../../shared/nastroje/nastroje-modalniho-panelu';
import { NastrojeUrl } from '../../../../../shared/nastroje/nastroje-url';
import { Notifikace, Uroven } from '../../../../../shared/notifikace';
import { SlevovyKupon } from '../../../../../data/e-shop/slevovy-kupon';
import { SlevovyKuponService } from '../../../../../service/e-shop/slevovy-kupon.service';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { NastrojeFormulare } from '../../../../../shared/nastroje/nastroje-formulare';
import { NastrojeDic } from '../../../../../shared/nastroje/nastroje-dic';
import { NastrojeObjednavky } from '../../../../../shared/nastroje/nastroje-objednavky';
import { NastrojeAutentizace } from '../../../../../shared/nastroje/nastroje-autentizace';
import { Predplatne } from '../../../../../data/e-shop/predplatne';
import { PredplatneService } from '../../../../../service/e-shop/predplatne.service';
import { StavPredplatneho } from '../../../../../data/e-shop/enum/stav-predplatneho.enum';

@Component({
    selector: 'app-rekapitulace-objednavky-s-vyberem',
    templateUrl: './rekapitulace-objednavky-s-vyberem.component.html',
    styleUrls: ['./rekapitulace-objednavky-s-vyberem.component.scss']
})
export class RekapitulaceObjednavkySVyberemComponent extends AbstraktniFormularComponent implements OnInit, OnDestroy {

    @ViewChild('modalniPanel', {static: true}) public modalniPanel: ElementRef;

    @Input() puvodniObjednavka;
    @Input() vybranyTypPredplatneho: TypPredplatneho;
    @Input() aktualniFakturacniUdajeObjednavky: Objednavka;

    @Output() prejitNaKartu = new EventEmitter<string>();

    public readonly datumPristiPlatby = NastrojeDataACasu.pridatMesic(new Date());
    public readonly formatData = NastrojeDataACasu.FORMAT_DATA;
    public readonly mena = Mena;
    public readonly platbyAObjednavkyUrl: string = prostredi.url + '/#/e-shop/platby-a-objednavky';
    public readonly typPredplatneho = TypPredplatneho;
    public readonly urlPODO: string = prostredi.urlPODO;
    public readonly zpusobPlatby = ZpusobPlatby;
    public readonly zakladniUrl: string = prostredi.zakladniUrl;

    public dokoncitObjednavkuTextTlacitka = 'tlacitko.zaplatit';
    public moznostPlatbyKartouValue: string;
    public moznostBankovniPrevodValue: string;
    public slevovyKupon: SlevovyKupon;
    public vybranyProdukt: Produkt;
    public vybranyZpusobPlatby = this.zpusobPlatby.PLATBA_KARTOU;
    public aktivniPredplatne: Predplatne[];
    public idPlatby: number;
    public zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
    public jmenoAPrijmeni = ''

    private posluchacPrijetiCenyProduktu: Subscription;

    constructor(protected formBuilder: FormBuilder,
        protected autentizaceService: AutentizaceService,
        private router: Router,
        private vytvoritObjednavkuService: VytvoritObjednavkuService,
        private objednavkuService: ObjednavkaService,
        private onlinePlatbyService: OnlinePlatbyService,
        private produktService: ProduktService,
        private slevovyKuponService: SlevovyKuponService,
        private googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
        private predplatneService: PredplatneService,
    ) {
        super(autentizaceService);
    }

    ngOnInit(): void {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.inicializovatModalniPanel();
        this.pripravitProdukt();
        this.zaregistrovatPosluchaceCenyProduktu();
        this.nacistAktivniPredplatneUzivatele();

        if (!NastrojeObecne.jeHodnotaPrazdna(this.aktualniFakturacniUdajeObjednavky.jmeno) && !NastrojeObecne.jeHodnotaPrazdna(this.aktualniFakturacniUdajeObjednavky.prijmeni)) {
            this.jmenoAPrijmeni = this.aktualniFakturacniUdajeObjednavky.jmeno + ' ' + this.aktualniFakturacniUdajeObjednavky.prijmeni
        }
    }

    ngOnDestroy(): void {
        this.posluchacPrijetiCenyProduktu.unsubscribe();
    }

    public odeslat(): void {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.getPrvekFormulare('nazevSlevovehoKuponu').value) && NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon)) {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.zadanySlevovyKuponNebylUplatnen');
            return;
        }

        if (this.jeNutneZrusitOpakovanePlatby()) {
            this.otevritModalniPanelZruseniOnlinePlateb();
            return;
        }

        this.zobrazitVrtitko = true;
        this.vytvoritObjednavkuService.doplnitVybranyProdukt(this.vybranyProdukt, this.vybranyTypPredplatneho);
        this.vytvoritObjednavkuService.doplnitZpusobPlatby(this.vybranyZpusobPlatby);

        if (!NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon)) {
            this.vytvoritObjednavkuService.doplnitSlevovyKupon(this.slevovyKupon);
        }

        this.vytvoritObjednavkuService.vytvoritObjednavku()
            .then(vysledek => {
                this.objednavkuService.nacistObjednavku(vysledek.identifikator)
                    .toPromise()
                    .then(obj => {
                        this.googleAnalyticsTrackingService.evidovatPurchase(obj);
                        this.googleAnalyticsTrackingService.evidovatCheckoutProgress(obj, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
                        this.googleAnalyticsTrackingService.evidovatCheckoutOption(obj, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);

                        // TODO osetrit asynchronim voláním na googleAnalyticsTrackingService evidence
                        setTimeout(() => {
                            this.dokoncitObjednavku(obj);
                        }, 4000);
                    });
            }).catch(() => {
            this.zobrazitVrtitko = false;
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.nelzeVytvoritPlatbu');
        });
    }

    public presmerovatPriZruseni(): void {
        this.googleAnalyticsTrackingService.evidovatZruseni(this.puvodniObjednavka);
        this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.puvodniObjednavka, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
        this.router.navigate(['/']);
    }

    public predchoziKarta(): void {
        this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.puvodniObjednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
        this.prejitNaKartu.emit(KrokObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
    }

    public zmenenVybranyZpusobUhrady(target): void {
        switch (target.value) {
            case 'platbaKartouRocni':
                this.upravitDleZvolenehoZpusobuUhrady(ZpusobPlatby.PLATBA_KARTOU, 'tlacitko.zaplatit');
                break;

            case 'platbaPrevodemRocni':
                this.upravitDleZvolenehoZpusobuUhrady(ZpusobPlatby.BANKOVNI_PREVOD, 'tlacitko.odeslatObjednavku');
                break;
        }
    }

    public otevritModalniPanel(): void {
        NastrojeModalnihoPanelu.otevritModalniPanel(this.modalniPanel.nativeElement);
    }

    public zavritModalniPanel(): void {
        NastrojeModalnihoPanelu.zavritModalniPanel(this.modalniPanel.nativeElement);
    }

    public otevritModalniPanelZruseniOnlinePlateb(): void {
        this.zobrazitModalniPanelProZruseniOpakovanychPlateb = true;
    }

    public zavritModalniPanelZruseniOnlinePlateb(): void {
        this.zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
    }

    public jeNeplatceDph(): boolean {
        if (this.vytvoritObjednavkuService.objednavka) {
            return NastrojeDic.jeNeplatceDph(this.vytvoritObjednavkuService.objednavka.dic);
        }
    }

    public uplatnitSlevovyKupon(): void {
        this.zobrazitVrtitko = true;

        this.slevovyKuponService.overitPlatnostSlevovehoKuponu(this.getPrvekFormulare('nazevSlevovehoKuponu').value, this.vybranyProdukt.identifikator, this.vybranyTypPredplatneho)
            .toPromise()
            .then(odpoved => {
                this.slevovyKupon = odpoved;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    public odebratSlevovyKupon(): void {
        this.slevovyKupon = null;

        NastrojeFormulare.nastavitHodnotuBezVyvolaniUdalosti(this.getPrvekFormulare('nazevSlevovehoKuponu'), '');
    }

    public pripravitCenuVybranehoProduktuBezDph(): number {
        return NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon) ?
            this.vybranyProdukt.cena.cenaBezDph :
            (this.vybranyProdukt.cena.cenaBezDph - this.pripravitVysiSlevyZVybranehoProduktu());
    }

    public pripravitCenuVybranehoProduktuSDph(): number {
        return NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon) ?
            this.vybranyProdukt.cena.cenaSDph :
            (this.pripravitCenuVybranehoProduktuBezDph() + (this.pripravitCenuVybranehoProduktuBezDph() * this.vybranyProdukt.cena.koeficientDph));
    }

    public pripravitVysiSlevyZVybranehoProduktu(): number {
        return this.vybranyProdukt.cena.cenaBezDph * (this.slevovyKupon.sleva / 100);
    }

    private inicializovatModalniPanel(): void {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    }

    private inicializovatFormularovaData(): void {
        this.formGroup.addControl('zpusobPlatby', new FormControl('', []));

        this.formGroup.addControl('nazevSlevovehoKuponu', new FormControl('', []));

        this.formGroup.addControl('souhlasSObchodnimiPodminkami', new FormControl('', [Validators.required,
            Validators.requiredTrue]));

        if (this.vybranyTypPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU) {
            this.formGroup.addControl('souhlasSOpakovanouPlatbou', new FormControl('', [Validators.required,
                Validators.requiredTrue]));
        }
    }

    private pripravitProdukt(): void {
        this.zobrazitVrtitko = true;

        this.produktService.nacistProdukt(this.puvodniObjednavka.nazevProduktu, this.vybranyTypPredplatneho)
            .subscribe(data => {
                this.vybranyProdukt = data;
                this.inicializovatPromenne();
                this.zobrazitVrtitko = false;
            }, () => this.zobrazitVrtitko = false);
    }

    private nacistAktivniPredplatneUzivatele(): void {
        this.zobrazitVrtitko = true;

        this.predplatneService.nacistAktivniPredplatne()
            .toPromise()
            .then(odpoved => {
                this.aktivniPredplatne = odpoved;
                this.zobrazitVrtitko = false;
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private jeNutneZrusitOpakovanePlatby(): boolean {
        for (let i = 0; i < this.aktivniPredplatne.length; i++) {
            if (this.aktivniPredplatne[i].typPredplatneho === TypPredplatneho.MESICNI_S_OBNOVOU && this.aktivniPredplatne[i].stavPredplatneho === StavPredplatneho.AKTIVNI && this.aktivniPredplatne[i].identifikatorProduktu === 'premium' && this.vybranyTypPredplatneho === TypPredplatneho.ROCNI && this.vybranyProdukt.identifikator === 'profi') {
                this.idPlatby = this.aktivniPredplatne[i].idPlatby;
                return true;
            }
        }
        return false;
    }

    public zrusitOpakovaniPlatby(): void {
        this.zobrazitVrtitko = true;

        this.onlinePlatbyService.zrusitOpakovanePlatby(this.idPlatby)
            .toPromise()
            .then(() => {
                this.zobrazitVrtitko = false;
                this.zobrazitModalniPanelProZruseniOpakovanychPlateb = false;
                this.nacistAktivniPredplatneUzivatele();
                Notifikace.zobrazitLokalizovanouZpravu(Uroven.INFORMACE, 'platba.udajeKOpakovani.zrusit.uspech');
            }).catch(() => this.zobrazitVrtitko = false);
    }

    private inicializovatPromenne(): void {
        switch (this.vybranyTypPredplatneho) {
            case (TypPredplatneho.MESICNI_S_OBNOVOU):
                this.moznostPlatbyKartouValue = 'platbaKartouMesicne';
                break;
            case (TypPredplatneho.ROCNI):
                this.moznostPlatbyKartouValue = 'platbaKartouRocni';
                this.moznostBankovniPrevodValue = 'platbaPrevodemRocni';
                break;
        }
    }

    private zaregistrovatPosluchaceCenyProduktu(): void {
        this.posluchacPrijetiCenyProduktu = this.vytvoritObjednavkuService.pripravitCenuProduktu.subscribe(() => this.zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu());
    }

    private upravitDleZvolenehoZpusobuUhrady(zpusobPlatby: ZpusobPlatby,
        textTlacitka: string
    ): void {
        this.vybranyZpusobPlatby = zpusobPlatby;
        this.dokoncitObjednavkuTextTlacitka = textTlacitka;
    }

    private dokoncitObjednavku(obj): void {
        if (this.vybranyZpusobPlatby === this.zpusobPlatby.PLATBA_KARTOU) {
            this.onlinePlatbyService.vytvoritPlatbu(obj)
                .toPromise()
                .then((odpoved) => {
                    this.zobrazitVrtitko = false;
                    NastrojeUrl.otevritOdkaz(odpoved.urlPlatebniBrany);
                })
                .catch(() => Notifikace.zobrazitLokalizovanouZpravu(Uroven.CHYBA, 'chyba.nelzeVytvoritObjednavku'));
        } else {
            Notifikace.zobrazitLokalizovanouZpravu(Uroven.USPECH, 'uspech.objednavkaOdeslana');
            this.router.navigate(['/e-shop/platby-a-objednavky']);
        }
    }

    private getPrvekFormulare(nazev: string): AbstractControl {
        return this.formGroup.controls[nazev];
    }

    private zkontrolovatJestliMaUzivatelAktivniPredplatneAVypocitatSlevu(): void {
        this.zobrazitVrtitko = true;

        const idUzivatele: number = NastrojeAutentizace.vratitInformaceOUzivateliZLocalStorage().informaceOUzivateli.id;
        const dataProVypocetSlevy: Objednavka = NastrojeObjednavky.pripravitDataObjednavkyProVypocetSlevy(idUzivatele, this.vybranyTypPredplatneho, this.vytvoritObjednavkuService.objednavka.dic, this.vybranyProdukt.id, this.vybranyProdukt.identifikator, this.slevovyKupon);

        this.objednavkuService.pripravitSlevuZprechoduNaJinePredplatne(dataProVypocetSlevy)
            .toPromise()
            .then(cena => {
                if (!NastrojeObecne.jeHodnotaPrazdna(cena)) {
                    this.vybranyProdukt.cena = cena;
                }

                this.zobrazitVrtitko = false;
            })
            .catch(() => this.zobrazitVrtitko = false);
    }
}
