var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AbstraktniTypZobrazeniHlavnihoMenuComponent } from '../abstraktni-typ-zobrazeni-hlavniho-menu.component';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
var StandardniZobrazeniHlavnihoMenuComponent = /** @class */ (function (_super) {
    __extends(StandardniZobrazeniHlavnihoMenuComponent, _super);
    function StandardniZobrazeniHlavnihoMenuComponent(autentizaceService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.autentizaceService = autentizaceService;
        return _this;
    }
    StandardniZobrazeniHlavnihoMenuComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.inicializovatRozbalovaciMenu();
    };
    StandardniZobrazeniHlavnihoMenuComponent.prototype.inicializovatRozbalovaciMenu = function () {
        var neinicializovanaRozbalovaciMenu = $('.dropdown-trigger:not([initialized])');
        neinicializovanaRozbalovaciMenu.dropdown();
        neinicializovanaRozbalovaciMenu.attr('initialized', true);
    };
    return StandardniZobrazeniHlavnihoMenuComponent;
}(AbstraktniTypZobrazeniHlavnihoMenuComponent));
export { StandardniZobrazeniHlavnihoMenuComponent };
