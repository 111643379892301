import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AutentizaceService} from '../../service/bezpecnost/autentizace.service';

@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit {

    constructor(protected autentizaceService: AutentizaceService, private router: Router) {
    }

    ngOnInit(): void {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        } else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate([`/`]);
        }
    }

    public presmerovatNaSekciOperatora(): void {
        this.router.navigate(['/sekce-operatora']);
    }

    public presmerovatNaSekciSlevoveKupony(): void {
        this.router.navigate(['/slevove-kupony']);
    }
}
