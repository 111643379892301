/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-operatora-hlavicka.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./razeni/seznam-objednavek-operatora-razeni.component.ngfactory";
import * as i3 from "./razeni/seznam-objednavek-operatora-razeni.component";
import * as i4 from "../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service";
import * as i5 from "../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service";
import * as i6 from "./legenda/seznam-objednavek-operatora-legenda.component.ngfactory";
import * as i7 from "./legenda/seznam-objednavek-operatora-legenda.component";
import * as i8 from "./seznam-objednavek-operatora-hlavicka.component";
var styles_SeznamObjednavekOperatoraHlavickaComponent = [i0.styles];
var RenderType_SeznamObjednavekOperatoraHlavickaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekOperatoraHlavickaComponent, data: {} });
export { RenderType_SeznamObjednavekOperatoraHlavickaComponent as RenderType_SeznamObjednavekOperatoraHlavickaComponent };
export function View_SeznamObjednavekOperatoraHlavickaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "detail-card header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-seznam-objednavek-operatora-razeni", [], null, null, null, i2.View_SeznamObjednavekOperatoraRazeniComponent_0, i2.RenderType_SeznamObjednavekOperatoraRazeniComponent)), i1.ɵdid(2, 49152, null, 0, i3.SeznamObjednavekOperatoraRazeniComponent, [i4.SeznamObjednavekOperatoraNastaveniService, i5.SeznamObjednavekOperatoraService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-seznam-objednavek-operatora-legenda", [], null, null, null, i6.View_SeznamObjednavekOperatoraLegendaComponent_0, i6.RenderType_SeznamObjednavekOperatoraLegendaComponent)), i1.ɵdid(4, 49152, null, 0, i7.SeznamObjednavekOperatoraLegendaComponent, [], null, null)], null, null); }
export function View_SeznamObjednavekOperatoraHlavickaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-hlavicka", [], null, null, null, View_SeznamObjednavekOperatoraHlavickaComponent_0, RenderType_SeznamObjednavekOperatoraHlavickaComponent)), i1.ɵdid(1, 49152, null, 0, i8.SeznamObjednavekOperatoraHlavickaComponent, [], null, null)], null, null); }
var SeznamObjednavekOperatoraHlavickaComponentNgFactory = i1.ɵccf("app-seznam-objednavek-operatora-hlavicka", i8.SeznamObjednavekOperatoraHlavickaComponent, View_SeznamObjednavekOperatoraHlavickaComponent_Host_0, {}, {}, []);
export { SeznamObjednavekOperatoraHlavickaComponentNgFactory as SeznamObjednavekOperatoraHlavickaComponentNgFactory };
