/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seznam-objednavek-operatora-seznam.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/seznam/seznam.component.ngfactory";
import * as i3 from "../../../../../../shared/seznam/seznam.component";
import * as i4 from "@angular/router";
import * as i5 from "./seznam-objednavek-operatora-seznam.component";
import * as i6 from "../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service";
import * as i7 from "../../../../../../shared/nastroje/pipes/datum.pipe";
import * as i8 from "../../../../../../shared/nastroje/pipes/vyctovy-typ.pipe";
var styles_SeznamObjednavekOperatoraSeznamComponent = [i0.styles];
var RenderType_SeznamObjednavekOperatoraSeznamComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeznamObjednavekOperatoraSeznamComponent, data: {} });
export { RenderType_SeznamObjednavekOperatoraSeznamComponent as RenderType_SeznamObjednavekOperatoraSeznamComponent };
export function View_SeznamObjednavekOperatoraSeznamComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam", [], null, null, null, i2.View_SeznamComponent_0, i2.RenderType_SeznamComponent)), i1.ɵdid(1, 49152, null, 0, i3.SeznamComponent, [i4.Router], { celkovyPocetZaznamu: [0, "celkovyPocetZaznamu"], radky: [1, "radky"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pripravitCelkovyPocetZaznamu(); var currVal_1 = _co.pripravitRadkySeznamuProZobrazeni(); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SeznamObjednavekOperatoraSeznamComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seznam-objednavek-operatora-seznam", [], null, null, null, View_SeznamObjednavekOperatoraSeznamComponent_0, RenderType_SeznamObjednavekOperatoraSeznamComponent)), i1.ɵdid(1, 49152, null, 0, i5.SeznamObjednavekOperatoraSeznamComponent, [i6.SeznamObjednavekOperatoraService, i7.DatumPipe, i8.VyctovyTypPipe], null, null)], null, null); }
var SeznamObjednavekOperatoraSeznamComponentNgFactory = i1.ɵccf("app-seznam-objednavek-operatora-seznam", i5.SeznamObjednavekOperatoraSeznamComponent, View_SeznamObjednavekOperatoraSeznamComponent_Host_0, {}, {}, []);
export { SeznamObjednavekOperatoraSeznamComponentNgFactory as SeznamObjednavekOperatoraSeznamComponentNgFactory };
