import {Component} from '@angular/core';
import {AbstraktniRazeniComponent} from '../../../../../../shared/seznam/razeni/abstraktni-razeni.component';
import {AtributRazeniSlevovychKuponu} from '../../../../../../data/obecne/enum/atribut-razeni-slevovych-kuponu.enum';
import {TypRazeni} from '../../../../../../data/obecne/enum/typ-razeni.enum';
import {SeznamSlevovychKuponuNastaveniService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import {SeznamSlevovychKuponuService} from '../../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';

@Component({
    selector: 'app-seznam-slevovych-kuponu-razeni',
    templateUrl: './seznam-slevovych-kuponu-razeni.component.html',
    styleUrls: ['./seznam-slevovych-kuponu-razeni.component.scss']
})
export class SeznamSlevovychKuponuRazeniComponent extends AbstraktniRazeniComponent {

    public atributyRazeni = AtributRazeniSlevovychKuponu;
    public typyRazeni = TypRazeni;

    constructor(
        private seznamSlevovychKuponuNastaveniService: SeznamSlevovychKuponuNastaveniService,
        private seznamSlevovychKuponuService: SeznamSlevovychKuponuService
    ) {
        super(seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService);
    }
}
