import { TypPoctuZaznamuNaStranku } from '../../data/obecne/enum/pocet-zaznamu-na-stranku.enum';
import { LokalniUlozisteNastroje } from '../../shared/nastroje/lokalni-uloziste/lokalni-uloziste-nastroje';
import { NastrojeEnumu } from '../../shared/nastroje/nastroje-enumu';
import { NastrojeObecne } from '../../shared/nastroje/nastroje-obecne';
import { Razeni } from '../../shared/seznam/razeni/razeni';
var AbstraktniNastaveniSeznamuService = /** @class */ (function () {
    function AbstraktniNastaveniSeznamuService() {
        this.vychoziPocetZaznamuNaStranku = TypPoctuZaznamuNaStranku.PET;
    }
    AbstraktniNastaveniSeznamuService.prototype.inicializovat = function () {
        this.inicializovatPocetZaznamuNaStranku();
        this.inicializovatAktualniStranku();
        this.inicializovatRazeni();
    };
    AbstraktniNastaveniSeznamuService.prototype.inicializovatPocetZaznamuNaStranku = function () {
        var pocetZaznamuNaStrankuZUloziste = LokalniUlozisteNastroje.getPolozka(this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti);
        if (NastrojeEnumu.pripravitKlicEnumuZHodnoty(TypPoctuZaznamuNaStranku, pocetZaznamuNaStrankuZUloziste) != null) {
            this.nastavitPocetZaznamuNaStranku(+pocetZaznamuNaStrankuZUloziste);
        }
        else {
            this.nastavitPocetZaznamuNaStranku(+this.vychoziPocetZaznamuNaStranku);
        }
    };
    AbstraktniNastaveniSeznamuService.prototype.inicializovatAktualniStranku = function () {
        var aktualniStrankaZUloziste = LokalniUlozisteNastroje.getPolozka(this.nazevKliceAktualniStrankyVLokalnimUlozisti);
        var aktualniStranka = !NastrojeObecne.jeHodnotaPrazdna(aktualniStrankaZUloziste) ? +aktualniStrankaZUloziste : 1;
        this.nastavitAktualniStranku(aktualniStranka);
    };
    AbstraktniNastaveniSeznamuService.prototype.inicializovatRazeni = function () {
        this.nastavitRazeni(this.pripravitAtributRazeni(), this.pripravitTypRazeni());
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitRazeni = function (atributRazeni, typRazeni) {
        this.nastaveniZobrazeniSeznamu.razeni = new Razeni();
        this.nastavitAtributRazeni(atributRazeni);
        this.nastavitTypRazeni(typRazeni);
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitPocetZaznamuNaStranku = function (hodnota) {
        this.nastaveniZobrazeniSeznamu.strankovani.pocetZaznamu = +hodnota;
        LokalniUlozisteNastroje.setPolozka(this.nazevKlicePoctuZaznamuNaStrankuVLokalnimUlozisti, String(hodnota));
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitAktualniStranku = function (hodnota) {
        this.nastaveniZobrazeniSeznamu.strankovani.stranka = +hodnota;
        LokalniUlozisteNastroje.setPolozka(this.nazevKliceAktualniStrankyVLokalnimUlozisti, String(hodnota));
    };
    AbstraktniNastaveniSeznamuService.prototype.resetovatPoziciNaStrankach = function () {
        this.nastavitAktualniStranku(1);
    };
    AbstraktniNastaveniSeznamuService.prototype.resetovatFiltr = function () {
        this.nastaveniZobrazeniSeznamu.filtr = new this.nastaveniZobrazeniSeznamu.filtr.constructor();
    };
    AbstraktniNastaveniSeznamuService.prototype.pripravitAtributRazeni = function () {
        return this.pripravitHodnotu(this.typAtributuRazeni, this.nazevKliceAtributuRazeniVLokalnimUlozisti, this.vychoziAtributRazeni);
    };
    AbstraktniNastaveniSeznamuService.prototype.pripravitTypRazeni = function () {
        return this.pripravitHodnotu(this.typTypuRazeni, this.nazevKliceTypuRazeniVLokalnimUlozisti, this.vychoziTypRazeni);
    };
    AbstraktniNastaveniSeznamuService.prototype.pripravitHodnotu = function (typHodnoty, nazevKliceHodnotyVLokalnimUlozisti, vychoziHodnota) {
        var polozka = LokalniUlozisteNastroje.getPolozka(nazevKliceHodnotyVLokalnimUlozisti);
        var typHodnotyZUloziste = typHodnoty[polozka];
        if (typHodnotyZUloziste !== undefined) {
            return typHodnotyZUloziste;
        }
        else {
            return vychoziHodnota;
        }
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitAtributRazeni = function (atributRazeni) {
        this.nastavitHodnotu(this.typAtributuRazeni, atributRazeni, this.nazevKliceAtributuRazeniVLokalnimUlozisti, 'atribut');
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitTypRazeni = function (typRazeni) {
        this.nastavitHodnotu(this.typTypuRazeni, typRazeni, this.nazevKliceTypuRazeniVLokalnimUlozisti, 'typRazeni');
    };
    AbstraktniNastaveniSeznamuService.prototype.nastavitHodnotu = function (typHodnoty, hodnota, nazevKliceHodnotyVLokalnimUlozisti, nazevHodnotyRazeni) {
        var klic = NastrojeEnumu.pripravitKlicEnumuZHodnoty(typHodnoty, hodnota);
        this.nastaveniZobrazeniSeznamu.razeni[nazevHodnotyRazeni] = klic;
        LokalniUlozisteNastroje.setPolozka(nazevKliceHodnotyVLokalnimUlozisti, klic);
    };
    AbstraktniNastaveniSeznamuService.prototype.getNastaveniZobrazeni = function () {
        return this.nastaveniZobrazeniSeznamu;
    };
    return AbstraktniNastaveniSeznamuService;
}());
export { AbstraktniNastaveniSeznamuService };
