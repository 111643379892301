var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstraktniFormularComponent } from '../../../shared/formular/abstraktni-formular.component';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { TypUceluFormulare } from '../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { SlevovyKuponService } from '../../../service/e-shop/slevovy-kupon.service';
import { NastrojeSlevovehoKuponu } from '../../../shared/nastroje/nastroje-slevoveho-kuponu';
var UpravitSlevovyKuponComponent = /** @class */ (function (_super) {
    __extends(UpravitSlevovyKuponComponent, _super);
    function UpravitSlevovyKuponComponent(formBuilder, autentizaceService, route, router, slevovyKuponService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.route = route;
        _this.router = router;
        _this.slevovyKuponService = slevovyKuponService;
        return _this;
    }
    UpravitSlevovyKuponComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.UPRAVA);
        this.zpracovatParametry();
    };
    UpravitSlevovyKuponComponent.prototype.odeslat = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.slevovyKuponService.upravitPlatnostSlevovehoKuponu(this.nazevSlevovehoKuponu, NastrojeSlevovehoKuponu.pripravitPlatnostSlevovehoKuponuZFormulare(this.formGroup))
            .toPromise()
            .then(function () {
            _this.zobrazitVrtitko = false;
            _this.prejitZpet();
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    UpravitSlevovyKuponComponent.prototype.prejitZpet = function () {
        this.router.navigate(['/slevove-kupony/detail/' + this.nazevSlevovehoKuponu]);
    };
    UpravitSlevovyKuponComponent.prototype.zpracovatParametry = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.nazevSlevovehoKuponu = params['nazev'];
            _this.pripravitSlevovyKupon();
        });
    };
    UpravitSlevovyKuponComponent.prototype.pripravitSlevovyKupon = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.slevovyKuponService.nacistSlevovyKupon(this.nazevSlevovehoKuponu)
            .toPromise()
            .then(function (odpoved) {
            _this.slevovyKupon = odpoved;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    return UpravitSlevovyKuponComponent;
}(AbstraktniFormularComponent));
export { UpravitSlevovyKuponComponent };
