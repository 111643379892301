var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { KrokObjednavky } from '../../../../../data/e-shop/enum/krok-objednavky.enum';
import { PoradoveCisloKrokuObjednavky } from '../../../../../data/e-shop/enum/poradove-cislo-kroku-objednavky.enum';
import { ZpusobVytvoreniObjednavky } from '../../../../../data/e-shop/enum/zpusob-vytvoreni-objednavky.enum';
import { Objednavka } from '../../../../../data/e-shop/objednavka';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { VytvoritObjednavkuService } from '../../../../../service/e-shop/vytvorit-objednavku.service';
import { GoogleAnalyticsTrackingService } from '../../../../../service/obecne/google-analytics-tracking.service';
import { AbstraktniFormularComponent } from '../../../../../shared/formular/abstraktni-formular.component';
import { NastrojeFakturacnichUdaju } from '../../../../../shared/nastroje/nastroje-fakturacnich-udaju';
import { NastrojeObecne } from '../../../../../shared/nastroje/nastroje-obecne';
import { ZahranicniStatEu } from '../../../../../data/organizace/enum/zahranicni-stat-eu.enum';
import { Stat } from '../../../../../data/organizace/enum/stat.enum';
var VyplnitFakturacniUdajeComponent = /** @class */ (function (_super) {
    __extends(VyplnitFakturacniUdajeComponent, _super);
    function VyplnitFakturacniUdajeComponent(formBuilder, autentizaceService, router, vytvoritObjednavkuService, googleAnalyticsTrackingService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.router = router;
        _this.vytvoritObjednavkuService = vytvoritObjednavkuService;
        _this.googleAnalyticsTrackingService = googleAnalyticsTrackingService;
        _this.prejitNaKartu = new EventEmitter();
        _this.zpusobyVytvoreniObjednavky = ZpusobVytvoreniObjednavky;
        _this.zobrazitPotvrzeniNevyplneniIcoDic = false;
        _this.zobrazitDicJeMimoEu = false;
        return _this;
    }
    VyplnitFakturacniUdajeComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        NastrojeFakturacnichUdaju.inicializovatFormularovaData(this.formGroup, this.vybranyZpusobVytvoreniObjednavky);
    };
    VyplnitFakturacniUdajeComponent.prototype.odeslat = function () {
        if (!this.muzeVytvoritObjednavku()) {
            this.zobrazitDicJeMimoEu = true;
            return;
        }
        if (this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.OPERATOREM) {
            if (NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.ic.value) && NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.dic.value)) {
                this.zobrazitPotvrzeniNevyplneniIcoDic = true;
            }
            else {
                this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
            }
        }
        else {
            this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
        }
    };
    VyplnitFakturacniUdajeComponent.prototype.potvrditSpravnostFakturacnichUdaju = function () {
        this.zobrazitPotvrzeniNevyplneniIcoDic = false;
        this.ulozitFakturacniUdajeAPrejitNaDalsiKartu();
    };
    VyplnitFakturacniUdajeComponent.prototype.skrytModalniPanel = function () {
        this.zobrazitPotvrzeniNevyplneniIcoDic = false;
        this.zobrazitDicJeMimoEu = false;
    };
    VyplnitFakturacniUdajeComponent.prototype.presmerovatPriZruseni = function () {
        switch (this.vybranyZpusobVytvoreniObjednavky) {
            case ZpusobVytvoreniObjednavky.OPERATOREM:
                this.router.navigate(['/sekce-operatora']);
                break;
            case ZpusobVytvoreniObjednavky.UZIVATELEM:
                this.googleAnalyticsTrackingService.evidovatZruseni(this.objednavka);
                this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.VYPLNIT_FAKTURACNI_UDAJE);
                this.router.navigate(['/']);
                break;
            default:
                this.router.navigate(['/']);
                break;
        }
    };
    VyplnitFakturacniUdajeComponent.prototype.predchoziKarta = function () {
        this.prejitNaKartu.emit(KrokObjednavky.ZVOLIT_PRODUKT);
    };
    VyplnitFakturacniUdajeComponent.prototype.ulozitFakturacniUdajeAPrejitNaDalsiKartu = function () {
        this.vytvoritObjednavkuService.doplnitFakturacniUdaje(this.formGroup);
        this.vytvoritObjednavkuService.vyvolatUdalostProPripravuCenyProduktu();
        this.nasledujiciKarta();
    };
    VyplnitFakturacniUdajeComponent.prototype.nasledujiciKarta = function () {
        if (this.vybranyZpusobVytvoreniObjednavky === ZpusobVytvoreniObjednavky.UZIVATELEM) {
            this.googleAnalyticsTrackingService.evidovatCheckoutProgress(this.objednavka, PoradoveCisloKrokuObjednavky.REKAPITULACE_OBJEDNAVKY);
        }
        this.prejitNaKartu.emit(KrokObjednavky.REKAPITULACE_OBJEDNAVKY);
    };
    VyplnitFakturacniUdajeComponent.prototype.muzeVytvoritObjednavku = function () {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.formGroup.controls.dic.value)) {
            var statZDic = this.formGroup.controls.dic.value.substr(0, 2);
            return Stat.CZ.toString() === statZDic || !NastrojeObecne.jeHodnotaPrazdna(ZahranicniStatEu[statZDic]);
        }
        return true;
    };
    return VyplnitFakturacniUdajeComponent;
}(AbstraktniFormularComponent));
export { VyplnitFakturacniUdajeComponent };
