var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { AbstraktniFiltrComponent } from '../../../../../shared/seznam/filtr/abstraktni-filtr-component';
import { AutentizaceService } from '../../../../../service/bezpecnost/autentizace.service';
import { SeznamSlevovychKuponuService } from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu.service';
import { TypUceluFormulare } from '../../../../../data/obecne/enum/typ-ucelu-formulare.enum';
import { NastrojeFiltrace } from '../../../../../shared/nastroje/nastroje-filtrace';
import { SeznamSlevovychKuponuNastaveniService } from '../../../../../service/seznam/seznam-slevovych-kuponu/seznam-slevovych-kuponu-nastaveni.service';
import { FiltrSeznamuSlevovychKuponu } from '../../../../../data/seznam/seznam-slevovych-kuponu/filtr/filtr-seznamu-slevovych-kuponu';
import { ValidatoryRozsahu } from '../../../../../shared/formular/validator/validatory-rozsahu';
var SeznamSlevovychKuponuFiltrComponent = /** @class */ (function (_super) {
    __extends(SeznamSlevovychKuponuFiltrComponent, _super);
    function SeznamSlevovychKuponuFiltrComponent(formBuilder, autentizaceService, seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService) {
        var _this = _super.call(this, seznamSlevovychKuponuNastaveniService, seznamSlevovychKuponuService, autentizaceService) || this;
        _this.formBuilder = formBuilder;
        _this.autentizaceService = autentizaceService;
        _this.seznamSlevovychKuponuNastaveniService = seznamSlevovychKuponuNastaveniService;
        _this.seznamSlevovychKuponuService = seznamSlevovychKuponuService;
        return _this;
    }
    SeznamSlevovychKuponuFiltrComponent.prototype.ngOnInit = function () {
        this.inicializovatFormular(TypUceluFormulare.VYTVORENI);
        this.inicializovatFormularovaData();
        this.nastavitVychoziHodnoty();
    };
    SeznamSlevovychKuponuFiltrComponent.prototype.odeslat = function () {
        this.vyhledat(NastrojeFiltrace.pripravitFiltr(new FiltrSeznamuSlevovychKuponu(), this.formGroup));
    };
    SeznamSlevovychKuponuFiltrComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('nazev', new FormControl('', []));
        this.formGroup.addControl('datumPlatnostiRozsahOd', new FormControl('', []));
        this.formGroup.addControl('datumPlatnostiRozsahDo', new FormControl('', []));
        this.formGroup.setValidators([
            ValidatoryRozsahu.rozsah('datumPlatnostiRozsahOd', 'datumPlatnostiRozsahDo', 'Date')
        ]);
    };
    return SeznamSlevovychKuponuFiltrComponent;
}(AbstraktniFiltrComponent));
export { SeznamSlevovychKuponuFiltrComponent };
