import {Component} from '@angular/core';
import {SeznamObjednavekOperatoraNastaveniService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora-nastaveni.service';
import {SeznamObjednavekOperatoraService} from '../../../../../../../service/seznam/seznam-objednavek-operatora/seznam-objednavek-operatora.service';
import {NastrojeStrankovani} from '../../../../../../../shared/nastroje/nastroje-strankovani';

@Component({
    selector: 'app-seznam-objednavek-operatora-strankovani',
    templateUrl: './seznam-objednavek-operatora-strankovani.component.html',
    styleUrls: ['./seznam-objednavek-operatora-strankovani.component.scss', './seznam-objednavek-operatora-strankovani.component.ie.scss']
})
export class SeznamObjednavekOperatoraStrankovaniComponent {

    constructor(private seznamObjednavekOperatoraNastaveniService: SeznamObjednavekOperatoraNastaveniService, private seznamObjednavekOperatoraService: SeznamObjednavekOperatoraService) {
    }

    public predchoziStranka() {
        const hodnota = this.pripravitHodnotu();
        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota > 1 ? hodnota - 1 : 1);
        this.seznamObjednavekOperatoraService.aktualizovat();
    }

    public dalsiStranka() {
        const hodnota = this.pripravitHodnotu();
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        this.seznamObjednavekOperatoraNastaveniService.nastavitAktualniStranku(hodnota < maximalniPocetStranek ? hodnota + 1 : maximalniPocetStranek);
        this.seznamObjednavekOperatoraService.aktualizovat();
    }

    public jePrvniStranka() {
        return this.pripravitHodnotu() === 1;
    }

    public jePosledniStranka() {
        const maximalniPocetStranek = this.pripravitMaximalniPocetStranek();

        return this.pripravitHodnotu() === maximalniPocetStranek || maximalniPocetStranek === 0;
    }

    public pripravitCelkovyPocetZaznamu() {
        return this.seznamObjednavekOperatoraService.seznam.celkovyPocetZaznamu;
    }

    public pripravitMaximalniPocetStranek() {
        const seznamObjednavek = this.seznamObjednavekOperatoraService.seznam;
        const nastaveniZobrazeniObjednavek = this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni();

        return NastrojeStrankovani.pripravitMaximalniPocetStranekObjednavekOperatora(nastaveniZobrazeniObjednavek, seznamObjednavek);
    }

    private pripravitHodnotu() {
        return this.seznamObjednavekOperatoraNastaveniService.getNastaveniZobrazeni().strankovani.stranka;
    }
}
