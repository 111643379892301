var NastrojeRetezce = /** @class */ (function () {
    function NastrojeRetezce() {
    }
    NastrojeRetezce.prevestUpperSnakeCaseNaLowerCamelCase = function (retezec) {
        return retezec.toLowerCase().replace(/_(\w)/g, (function (m) { return m[1].toUpperCase(); }));
    };
    NastrojeRetezce.prevestUpperCamelCaseNaLowerCamelCase = function (retezec) {
        return retezec.charAt(0).toLowerCase() + retezec.slice(1);
    };
    NastrojeRetezce.odstranitDiakritiku = function (retezec) {
        return retezec.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };
    NastrojeRetezce.odebratMezeryZRetezce = function (retezec) {
        return retezec.replace(/\s/g, '');
    };
    return NastrojeRetezce;
}());
export { NastrojeRetezce };
