import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SlevovyKupon } from '../../../../data/e-shop/slevovy-kupon';
import { NastrojeDataACasu } from '../../../../shared/nastroje/nastroje-data-a-casu';
import { NastrojeCisla } from '../../../../shared/nastroje/nastroje-cisla';
import { ValidatoryRozsahu } from '../../../../shared/formular/validator/validatory-rozsahu';
import { NastrojeObecne } from '../../../../shared/nastroje/nastroje-obecne';
import { NastrojeFormulare } from '../../../../shared/nastroje/nastroje-formulare';
var SlevovyKuponZakladniUdajeComponent = /** @class */ (function () {
    function SlevovyKuponZakladniUdajeComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.vytvorit = false;
        this.editovat = false;
        this.odeslan = false;
        this.formatData = NastrojeDataACasu.FORMAT_DATA;
    }
    SlevovyKuponZakladniUdajeComponent.prototype.ngOnInit = function () {
        if (this.vytvorit || this.editovat) {
            this.inicializovatFormularovaData();
        }
    };
    SlevovyKuponZakladniUdajeComponent.prototype.inicializovatFormularovaData = function () {
        this.formGroup.addControl('nazev', new FormControl(this.slevovyKupon.nazev, [
            Validators.required
        ]));
        this.formGroup.addControl('sleva', new FormControl(this.slevovyKupon.sleva, [
            Validators.pattern(NastrojeCisla.VZOR_INTEGER),
            Validators.max(100),
            Validators.required
        ]));
        this.formGroup.addControl('platnostRozsahOd', new FormControl(NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon.platnost.rozsahOd) ? '' : NastrojeDataACasu.formatovatDatum(this.slevovyKupon.platnost.rozsahOd, NastrojeDataACasu.FORMAT_DATA), [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA),
            Validators.required
        ]));
        this.formGroup.addControl('platnostRozsahDo', new FormControl(NastrojeObecne.jeHodnotaPrazdna(this.slevovyKupon.platnost.rozsahDo) ? '' : NastrojeDataACasu.formatovatDatum(this.slevovyKupon.platnost.rozsahDo, NastrojeDataACasu.FORMAT_DATA), [
            Validators.pattern(NastrojeDataACasu.FORMULAR_VALIDACE_FORMAT_DATA),
            Validators.required
        ]));
        this.formGroup.setValidators([
            ValidatoryRozsahu.rozsah('platnostRozsahOd', 'platnostRozsahDo', 'Date')
        ]);
        if (this.editovat) {
            NastrojeFormulare.deaktivovatPrvekFormulareBezVyvolaniUdalosti(this.formGroup.controls.nazev);
            NastrojeFormulare.deaktivovatPrvekFormulareBezVyvolaniUdalosti(this.formGroup.controls.sleva);
        }
    };
    return SlevovyKuponZakladniUdajeComponent;
}());
export { SlevovyKuponZakladniUdajeComponent };
