var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { prostredi } from '../../../../prostredi/prostredi';
import { AbstraktniOpravneniComponent } from '../../../service/bezpecnost/abstraktni-opravneni.component';
import { AutentizaceService } from '../../../service/bezpecnost/autentizace.service';
import { KeycloakService } from 'keycloak-angular';
var HlavniMenuComponent = /** @class */ (function (_super) {
    __extends(HlavniMenuComponent, _super);
    function HlavniMenuComponent(autentizaceService, keycloakService) {
        var _this = _super.call(this, autentizaceService) || this;
        _this.autentizaceService = autentizaceService;
        _this.keycloakService = keycloakService;
        _this.urlFEN = prostredi.urlFEN;
        _this.zakladniUrl = prostredi.zakladniUrl;
        _this.aktivniMobilniZobrazeni = false;
        _this.zobrazitMobilniZobrazeni = false;
        _this.zobrazitVrtitko = false;
        _this.prihlaseny = false;
        _this.prihlasenyAdministrator = false;
        _this.prihlasenyOperator = false;
        return _this;
    }
    HlavniMenuComponent.prototype.onResize = function (event) {
        this.nastavitAktivniMobilniZobrazeni();
    };
    HlavniMenuComponent.prototype.ngOnInit = function () {
        this.nastavitAktivniMobilniZobrazeni();
        this.nastavitPromenne();
        this.nastavitCeleJmenoPrihlasenehoUzivatele();
    };
    HlavniMenuComponent.prototype.prepnoutNaMobilniZobrazeni = function () {
        this.zobrazitMobilniZobrazeni = !this.zobrazitMobilniZobrazeni;
    };
    HlavniMenuComponent.prototype.pripravitInformaceOPrihlasenemUzivateli = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.autentizaceService.pripravitInformaceOUzivateliAUlozitDoLocalStorage()
            .then(function () {
            _this.zobrazitVrtitko = false;
        })
            .catch(function () { return _this.zobrazitVrtitko = false; });
    };
    HlavniMenuComponent.prototype.nacistJmenoAPrijmeniSpravceDatabazeCiOperatora = function () {
        var _this = this;
        this.zobrazitVrtitko = true;
        this.keycloakService.loadUserProfile()
            .then(function (data) {
            _this.jmenoAPrijmeniSpravceDatabazeCiOperatora = data.firstName + ' ' + data.lastName;
            _this.zobrazitVrtitko = false;
        }).catch(function () { return _this.zobrazitVrtitko = false; });
    };
    HlavniMenuComponent.prototype.nastavitAktivniMobilniZobrazeni = function () {
        this.aktivniMobilniZobrazeni = window.innerWidth <= 900;
    };
    HlavniMenuComponent.prototype.nastavitPromenne = function () {
        this.prihlaseny = this.autentizaceService.jeUzivatelPrihlaseny();
        this.prihlasenyAdministrator = this.opravneniAdministraceJakoSpravceDatabaze();
        this.prihlasenyOperator = this.opravneniObsluhovatEshopJakoOperator();
    };
    HlavniMenuComponent.prototype.nastavitCeleJmenoPrihlasenehoUzivatele = function () {
        if (this.prihlaseny) {
            if (this.prihlasenyAdministrator || this.prihlasenyOperator) {
                this.nacistJmenoAPrijmeniSpravceDatabazeCiOperatora();
            }
            else {
                this.pripravitInformaceOPrihlasenemUzivateli();
            }
        }
    };
    return HlavniMenuComponent;
}(AbstraktniOpravneniComponent));
export { HlavniMenuComponent };
