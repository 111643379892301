import {Component} from '@angular/core';
import {RadekSeznamu} from '../../../../../../shared/seznam/struktura/radek-seznamu';
import {SeznamObjednavekService} from '../../../../../../service/seznam/seznam-objednavek/seznam-objednavek.service';
import {DatumPipe} from '../../../../../../shared/nastroje/pipes/datum.pipe';
import {VyctovyTypPipe} from '../../../../../../shared/nastroje/pipes/vyctovy-typ.pipe';
import {NastrojeObecne} from '../../../../../../shared/nastroje/nastroje-obecne';
import {PolozkaSeznamuObjednavek} from '../../../../../../data/seznam/seznam-objednavek/polozka-seznamu-objednavky';
import {HodnotaSeznamu} from '../../../../../../shared/seznam/struktura/hodnota-seznamu';
import {TypHodnotySeznamu} from '../../../../../../shared/seznam/enum/typ-hodnoty-seznamu';
import {TypPredplatneho} from '../../../../../../data/e-shop/enum/predplatne.enum';
import {NastrojeDataACasu} from '../../../../../../shared/nastroje/nastroje-data-a-casu';
import {StavObjednavky} from '../../../../../../data/e-shop/enum/stav-objednavky.enum';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'app-seznam-objednavek-seznam',
    templateUrl: './seznam-objednavek-seznam.component.html',
    styleUrls: ['./seznam-objednavek-seznam.component.scss']
})
export class SeznamObjednavekSeznamComponent {

    private radkySeznamuProZobrazeni: Array<RadekSeznamu>;

    constructor(
        private decimalPipe: DecimalPipe,
        private datumPipe: DatumPipe,
        private seznamObjednavekService: SeznamObjednavekService,
        private vyctovyTypPipe: VyctovyTypPipe
    ) {
    }

    public pripravitCelkovyPocetZaznamu() {
        return this.seznamObjednavekService.seznam.celkovyPocetZaznamu;
    }

    public pripravitRadkySeznamuProZobrazeni(): Array<RadekSeznamu> {
        if (!NastrojeObecne.jeHodnotaPrazdna(this.seznamObjednavekService.seznam.celkovyPocetZaznamu)) {
            this.radkySeznamuProZobrazeni = this.pripravitRadkySeznamu();
        }

        return this.radkySeznamuProZobrazeni;
    }

    private pripravitRadkySeznamu(): Array<RadekSeznamu> {
        const radky = new Array<RadekSeznamu>();

        for (const objednavka of this.seznamObjednavekService.seznam.polozky) {
            radky.push(new RadekSeznamu(objednavka.identifikator, this.pripravitHodnotyRadkuSeznamu(objednavka), this.pripravitUrlDetailuObjednavky(objednavka.identifikator)));
        }

        return radky;
    }

    private pripravitHodnotyRadkuSeznamu(objednavka: PolozkaSeznamuObjednavek): Array<HodnotaSeznamu> {
        const hodnoty = new Array<HodnotaSeznamu>();

        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.PRIMARNI, objednavka.nazevProduktu));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.SEKUNDARNI, this.vyctovyTypPipe.transform(objednavka.typPredplatneho, TypPredplatneho)));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, objednavka.identifikator, 'center_focus_weak'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumVytvoreni, NastrojeDataACasu.FORMAT_DATA), 'add'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumSplatnosti, NastrojeDataACasu.FORMAT_DATA), 'payment'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.vyctovyTypPipe.transform(objednavka.stavObjednavky, StavObjednavky), 'transform'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.datumPipe.transform(objednavka.datumPlatnostiPredplatnehoDo, NastrojeDataACasu.FORMAT_DATA, true), 'laptop_chromebook'));
        hodnoty.push(new HodnotaSeznamu(TypHodnotySeznamu.DOPLNUJICI, this.decimalPipe.transform(objednavka.cenaSDph, '1.2-2', 'cs'), 'local_atm'));

        return hodnoty;
    }

    private pripravitUrlDetailuObjednavky(id: string): string {
        return `e-shop/objednavka/${id}`;
    }

}
