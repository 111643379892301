import { SlevovyKupon } from '../../data/e-shop/slevovy-kupon';
import { Rozsah } from '../../data/obecne/rozsah';
import { NastrojeDataACasu } from './nastroje-data-a-casu';
import { NastrojeFormulare } from './nastroje-formulare';
import { NastrojeCisla } from './nastroje-cisla';
var NastrojeSlevovehoKuponu = /** @class */ (function () {
    function NastrojeSlevovehoKuponu() {
    }
    NastrojeSlevovehoKuponu.pripravitNovySlevovyKupon = function () {
        var slevovyKupon = new SlevovyKupon();
        slevovyKupon.platnost = new Rozsah();
        slevovyKupon.produkty = [];
        return slevovyKupon;
    };
    NastrojeSlevovehoKuponu.pripravitPlatnostSlevovehoKuponuZFormulare = function (formGroup) {
        var platnost = new Rozsah();
        platnost.rozsahOd = NastrojeDataACasu.prevestRetezecNaDatum(formGroup.controls.platnostRozsahOd.value, NastrojeDataACasu.FORMAT_DATA);
        platnost.rozsahDo = NastrojeDataACasu.nastavitCasKonecDne(NastrojeDataACasu.prevestRetezecNaDatum(formGroup.controls.platnostRozsahDo.value, NastrojeDataACasu.FORMAT_DATA));
        return platnost;
    };
    NastrojeSlevovehoKuponu.pripravitSlevovyKuponZFormulare = function (formGroup, slevovyKupon) {
        slevovyKupon.nazev = NastrojeFormulare.normalizovatHodnotu(formGroup.controls.nazev.value);
        slevovyKupon.sleva = NastrojeCisla.prevestNaCislo(formGroup.controls.sleva.value);
        slevovyKupon.platnost = this.pripravitPlatnostSlevovehoKuponuZFormulare(formGroup);
        return slevovyKupon;
    };
    return NastrojeSlevovehoKuponu;
}());
export { NastrojeSlevovehoKuponu };
