import {Component, OnInit} from '@angular/core';
import {AutentizaceService} from '../../service/bezpecnost/autentizace.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-sekce-operatora',
    templateUrl: './sekce-operatora.component.html',
    styleUrls: ['./sekce-operatora.component.scss']
})
export class SekceOperatoraComponent implements OnInit {

    constructor(
        protected autentizaceService: AutentizaceService,
        private router: Router
    ) {
    }

    ngOnInit() {
        if (!this.autentizaceService.jeUzivatelPrihlaseny()) {
            this.autentizaceService.prihlasitOperatora();
        } else if (this.autentizaceService.jePrihlasenyUzivatelCdd()) {
            this.router.navigate([`/`]);
        }
    }

    public presmerovatNaVytvoritObjednavkuOperatorem() {
        this.router.navigate(['e-shop/objednavka-operatora']);
    }
}
