var NastrojeTabu = /** @class */ (function () {
    function NastrojeTabu() {
    }
    NastrojeTabu.inicializovatTaby = function () {
        $(document).ready(function () {
            $('ul.tabs').tabs();
        });
    };
    NastrojeTabu.prejitNaKartu = function (idKarty) {
        $('.tab a').filter('a[href$="' + idKarty + '"]').click(function () {
            this.click();
        }).click();
    };
    return NastrojeTabu;
}());
export { NastrojeTabu };
