import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NastrojeModule} from '../../nastroje/nastroje.module';
import {TextovyModalniPanelComponent} from './textovy-modalni-panel.component';

@NgModule({
    imports: [
        CommonModule,
        NastrojeModule
    ],
    declarations: [
        TextovyModalniPanelComponent
    ],
    exports: [
        TextovyModalniPanelComponent
    ],
})

export class TextovyModalniPanelModule {
}
