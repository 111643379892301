<section class="filter-container">
    <div>
        <form [formGroup]="formGroup" class="form-column-layout">
            <h3 class="info-title span-12">{{ 'seznam.vyhledaniUzivatele' | lokalizace }}</h3>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.id'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.id"></app-formular-input>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.prihlasovaciJmeno'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.prihlasovaciJmeno"></app-formular-input>

            <app-formular-input
                    class="span-4"
                    [popisek]="'obecne.email'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.email"></app-formular-input>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.krestniJmeno'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.krestniJmeno"></app-formular-input>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.prijmeni'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.prijmeni"></app-formular-input>

            <app-formular-select
                    class="span-4"
                    [popisek]="'uzivatel.stav'"
                    [polozky]="stavUzivatele"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.stav"
                    [prvniHodnotaNull]="true"
                    [hodnotaKliceZObjektu]="false">
            </app-formular-select>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.dodavatel'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.nazevOrganizacniJednotky"></app-formular-input>

            <app-formular-input
                    class="span-4"
                    [popisek]="'uzivatel.ico'"
                    [odeslan]="odeslan"
                    [ovladacFormulare]="formGroup.controls.ic"></app-formular-input>
        </form>
    </div>

    <div class="button-area span-12">
        <button (click)="onSubmit()" class="btn waves-effect waves-light">
            <b>{{ 'tlacitko.vyhledat' | lokalizace }}</b>
            <i class="material-icons right icon-flipped">search</i>
        </button>

        <button (click)="resetovatFiltr()" class="btn btn-secondary waves-effect waves-light">
            <b>{{ 'tlacitko.resetovat' | lokalizace }}</b>
            <i class="material-icons right">clear</i>
        </button>
    </div>
</section>
