var RadekSeznamu = /** @class */ (function () {
    function RadekSeznamu(id, hodnoty, url, jeVybrany) {
        this._id = id;
        this._hodnoty = hodnoty;
        this._url = url;
        this._jeVybrany = jeVybrany;
    }
    Object.defineProperty(RadekSeznamu.prototype, "id", {
        get: function () {
            return this._id;
        },
        set: function (value) {
            this._id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RadekSeznamu.prototype, "hodnoty", {
        get: function () {
            return this._hodnoty;
        },
        set: function (value) {
            this._hodnoty = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RadekSeznamu.prototype, "url", {
        get: function () {
            return this._url;
        },
        set: function (value) {
            this._url = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RadekSeznamu.prototype, "jeVybrany", {
        get: function () {
            return this._jeVybrany;
        },
        set: function (value) {
            this._jeVybrany = value;
        },
        enumerable: true,
        configurable: true
    });
    return RadekSeznamu;
}());
export { RadekSeznamu };
