import { ElementRef, OnInit } from '@angular/core';
import { prostredi } from '../../../../prostredi/prostredi';
import verzeJson from '../../../../version.json';
import { VerzeBackenduService } from '../../../service/obecne/verze-backendu.service';
import { NastrojeDataACasu } from '../../nastroje/nastroje-data-a-casu';
import { NastrojeModalnihoPanelu } from '../../nastroje/nastroje-modalniho-panelu';
var PatickaComponent = /** @class */ (function () {
    function PatickaComponent(verzeBackenduService) {
        this.verzeBackenduService = verzeBackenduService;
        this.urlPODO = prostredi.urlPODO;
        this.aktualniRok = new Date();
        this.formatRoku = NastrojeDataACasu.FORMAT_ROKU;
        this.zakladniUrl = prostredi.zakladniUrl;
    }
    PatickaComponent.prototype.ngOnInit = function () {
        this.inicializovatModalniPanel();
    };
    PatickaComponent.prototype.inicializovat = function () {
        this.pripravitVerziBackendu();
        this.pripravitVerziFrontendu();
    };
    PatickaComponent.prototype.pripravitVerziBackendu = function () {
        var _this = this;
        return this.verzeBackenduService.pripravitCisloVerzeBackendu()
            .subscribe(function (data) {
            _this.verzeBackendu = data;
        });
    };
    PatickaComponent.prototype.pripravitVerziFrontendu = function () {
        this.verzeFrontendu = verzeJson.version;
    };
    PatickaComponent.prototype.inicializovatModalniPanel = function () {
        NastrojeModalnihoPanelu.inicializovatModalniPanel(this.modalniPanel.nativeElement);
    };
    return PatickaComponent;
}());
export { PatickaComponent };
