<div class="filter">
    <div class="filter__body">
        <div class="container__content-body">

            <div>
                <div class="tabs-container">
                    <ul #wizard class="tabs tabs-fixed-width">
                        <li class="tab">
                            <a href="#filtr-zakladni">
                                <i class="medium material-icons">search</i>
                                <div class="tab-header-content">
                                    {{ 'filtrSeznamuObjednavek.zakladni' | lokalizace }}
                                </div>
                            </a>
                        </li>
                        <li class="tab">
                            <a href="#filtr-rozsirene">
                                <i class="medium material-icons">zoom_in</i>
                                <div class="tab-header-content">
                                    {{ 'filtrSeznamuObjednavek.rozsirene' | lokalizace }}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="divider"></div>

                <div class="wizard-content">
                    <app-seznam-objednavek-operatora-filtr-zakladni id="filtr-zakladni" [formGroup]="formGroup"></app-seznam-objednavek-operatora-filtr-zakladni>
                    <app-seznam-objednavek-operatora-filtr-rozsirene id="filtr-rozsirene" [formGroup]="formGroup"></app-seznam-objednavek-operatora-filtr-rozsirene>
                </div>
            </div>
        </div>


        <div class="button-area">
            <button (click)="onSubmit()" class="btn waves-effect waves-light">
                <b>{{ 'tlacitko.vyhledat' | lokalizace }}</b>
                <i class="material-icons right">search</i>
            </button>

            <button (click)="resetovatFiltr()" class="btn btn-secondary waves-effect waves-light">
                <b>{{ 'tlacitko.resetovat' | lokalizace }}</b>
                <i class="material-icons right">clear</i>
            </button>
        </div>
    </div>
</div>
